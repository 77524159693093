import { Component } from "react";

// Renderless component to control time for title slides
export default class PreviewTitleSlideController extends Component {
  componentDidMount() {
    if (this.props.playing) {
      this.startClock();
    }
    this.props.resetCurrentTime();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.playing !== this.props.playing) {
      if (this.props.playing) this.startClock();
      else this.stopClock();
    }
    if (prevProps.blockId !== this.props.blockId) {
      this.props.resetCurrentTime();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  startClock = () => {
    this.interval = setInterval(() => {
      this.props.onTimeUpdate(0.1);
    }, 100);
  };

  stopClock = () => {
    clearInterval(this.interval);
  };

  render() {
    return null;
  }
}
