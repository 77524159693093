import { notification } from "./utils";
import fetchWithAuth from "utils/fetchWithAuth";

export function breakFileIntoParts(file) {
  const FILE_CHUNK_SIZE = 5_242_880;
  let chunkList = [];
  let index = 0;
  while (index < parseInt(file.size / FILE_CHUNK_SIZE)) {
    const start = index * FILE_CHUNK_SIZE;
    const end = (index + 1) * FILE_CHUNK_SIZE;
    const chunk = file.slice(start, end, file.type);
    chunkList.push(chunk);
    index++;
  }
  chunkList.push(file.slice(index * FILE_CHUNK_SIZE, file.size, file.type));
  return chunkList;
}

export async function uploadParts(
  chunkList,
  presignedUrls,
  uploadId,
  objectName,
  updateProgress,
  dispatch,
  getState,
  baseUrl,
  path
) {
  // Generates upload requests for each part
  let i = 0;
  let status = 0;
  var partPromises = [];
  while (i < presignedUrls.length) {
    const params = {
      method: "PUT",
      body: chunkList[i],
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Expose-Headers": ["ETag"],
        "Access-Control-Allow-Methods": "PUT",
        "Access-Control-Allow-Headers": "Origin, Content-Type",
      },
    };
    partPromises.push(
      fetch(presignedUrls[i], params).then((res) => {
        var partResp = res;
        // Retries up to 2 times if something causes the request to fail
        if (res.status >= 400 && res.status < 600) {
          partResp = fetch(res.url, params).then((retryRes) => {
            if (retryRes.status >= 400 && retryRes.status < 600) {
              partResp = fetch(retryRes.url, params).then((secondRetryRes) => {
                if (
                  secondRetryRes.status >= 400 &&
                  secondRetryRes.status < 600
                ) {
                  throw new Error(
                    "Upload Failed - UploadId:" +
                      uploadId +
                      "Object Name:" +
                      objectName
                  );
                }
              });
            }
          });
        }
        // Updates progress to indicate a part has finished uploading, accounts for 80% of total progress
        status += 99 / chunkList.length;
        updateProgress(status);
        return partResp;
      })
    );
    i++;
  }
  // Waits for individual parts to finish uploading
  return Promise.all(partPromises)
    .then((partResponses) => {
      // Extracts ETag header for each part and associates it with part number
      var etags = partResponses.map((part, index) => ({
        ETag: part.headers.get("ETag"),
        PartNumber: index + 1,
      }));
      return etags;
    })
    .catch((e) => {
      handleUploadError(e, dispatch, getState, baseUrl, path);
    });
}

export function handleUploadError(e, dispatch, getState, baseUrl, path) {
  dispatch({
    type: "SET_FILE_UPLOAD_STATUS",
    payload: { [path]: "FAILED" },
  });
  console.log("e");
  // Aborts multipart upload on AWS if it failed after creation
  if (e.message.indexOf("Upload Failed") !== -1) {
    var idIndex = e.message.indexOf(":") + 1;
    var idEndIndex = e.message.indexOf(",");
    var objectIndex = e.message.indexOf(":", idEndIndex) + 1;
    const abortData = {
      uploadId: e.message.slice(idIndex, idEndIndex),
      objectName: e.message.slice(objectIndex),
    };
    fetchWithAuth(
      baseUrl,
      getState().authToken,
      "/files/upload_abort",
      "PUT",
      abortData
    );
  }
  notification("Unable to upload file.", "Please try again.", "warning");
  console.error(e);
}
