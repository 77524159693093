import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import NewReportTile from "components/Tiles/NewReportTile";
import LoaderTile from "components/Tiles/LoaderTile";
import ReportPopupMenu from "components/ReportPopupMenu";
import { getReports, setPageTitle } from "redux/actions";
import { Helmet } from "react-helmet";

const mapStateToProps = (state) => {
  return {
    reports: state.reports,
    reportError: state.reportError,
    loading: state.loading && state.loading["MyReports"],
  };
};

const mapDispatchToProps = {
  getReports,
  setPageTitle,
};

class MyReports extends Component {
  componentDidMount() {
    this.props.getReports();
    this.props.setPageTitle();
  }

  routeToEdit = (reportId) => {
    this.props.history.push(`reports/${reportId}/create`);
  };

  render() {
    const { history, reports, reportError, loading } = this.props;

    return (
      <>
        <Helmet>
          <title>Reports</title>
        </Helmet>
        <div className="content">
          {/* Header */}
          <div className="b-row">
            <div className="b-col-6">
              <h1 className="page-title">Reports</h1>
            </div>
          </div>
          {/* Reports */}
          <Grid container>
            {/* New Report Tile */}
            <NewReportTile routeToNew={() => history.push("/reports_create")} />
            {!reports && loading && <LoaderTile />}
            {reports &&
              !reportError &&
              reports.map((report) => (
                <Grid
                  key={report._id}
                  item
                  onClick={() => this.routeToEdit(report._id)}
                  className="main-survey-tile"
                >
                  <div className="top-status-background report-banner" />
                  <div className="top-status report-banner">Report</div>
                  <div className="top-hamburger">
                    <ReportPopupMenu
                      reportId={report._id}
                      reportName={report.name}
                    />
                  </div>
                  <h3>
                    {report.name.length > 40
                      ? `${report.name.substring(0, 40)}...`
                      : report.name}
                  </h3>
                  <p className="bottom-date">{report.createdAt}</p>
                </Grid>
              ))}
          </Grid>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyReports);
