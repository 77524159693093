import React, { useState } from "react";
import clsx from "clsx";

const HighlightedTranscription = ({
  time,
  onWordClick,
  transcriptionItems,
  transcriptionEdited,
  transcription,
}) => {
  const [currentIdx, setCurrentIdx] = useState(0);
  return (
    <>
      {Array.isArray(transcriptionItems) && !transcriptionEdited
        ? transcriptionItems.map((item, idx) => {
            if (item.type === "punctuation") {
              return (
                <span
                  key={idx}
                  className={clsx(
                    "transcription-text",
                    idx <= currentIdx && "dimmed"
                  )}
                >
                  {item &&
                    item.alternatives &&
                    item.alternatives.length &&
                    item.alternatives[0].content}
                </span>
              );
            } else {
              let highlightedClass =
                time >= parseFloat(item.start_time) &&
                time < parseFloat(item.end_time)
                  ? "transcription-text inherit large highlighted"
                  : time >= parseFloat(item.end_time)
                  ? "transcription-text inherit large dimmed"
                  : "transcription-text inherit large";
              if (idx > currentIdx && time >= parseFloat(item.end_time)) {
                setCurrentIdx(idx);
              }
              return (
                <React.Fragment key={idx}>
                  {idx === 0 ? "" : <>&nbsp;</>}
                  <span
                    className={highlightedClass}
                    onClick={(e) => {
                      onWordClick(e, item);
                    }}
                  >
                    {item && item.alternatives
                      ? item.alternatives[0].content
                      : ""}
                  </span>
                </React.Fragment>
              );
            }
          })
        : transcription
        ? transcription
        : "No transcription found."}
    </>
  );
};

export default HighlightedTranscription;
