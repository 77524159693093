import React from "react";
import { Alert } from "@material-ui/lab";
import PieChart from "./Graphs/PieChart";

const UsageChart = ({ creditBalance, monthlyCreditUsage, monthlyCredits }) => {
  return (
    <div className="usage">
      <div className="mt-20 mb-20" style={{ width: "50%" }}>
        <PieChart
          chartData={{
            labels: ["Credits remaining", "Credits used"],
            data: [creditBalance, monthlyCreditUsage],
          }}
        />
      </div>
      <Alert severity="info">
        <ul className="pl-10">
          <li>
            Your plan includes <strong>{monthlyCredits}</strong> credits per
            month
          </li>
          <li>
            You have used <strong>{monthlyCreditUsage}</strong> credits this
            month
          </li>
          <li>
            You have <strong>{creditBalance}</strong> credits remaining
          </li>
        </ul>
      </Alert>
    </div>
  );
};

export default UsageChart;
