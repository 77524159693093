import React from "react";
import Paper from "@material-ui/core/Paper";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

const ShareCard = ({ icon, title, embed, text, children, ...other }) => {
  const wrappedIcon = embed ? (
    <span className="mr-2 embed-icon">{icon}</span>
  ) : (
    icon
  );

  return embed ? (
    <Accordion {...other} variant="outlined">
      <AccordionSummary aria-controls={`panel-content`} id={`panel-header`}>
        <div className="share-card-header">
          {wrappedIcon}
          <div className="py-1">{title}</div>
        </div>
        {text}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  ) : (
    <Paper className="share-card" {...other}>
      <div className="share-card-header">
        {wrappedIcon}
        <div className="py-1">{title}</div>
      </div>
      {children}
    </Paper>
  );
};

export default ShareCard;
