import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import ClipLoader from "react-spinners/ClipLoader";
import { makeStyles } from "@material-ui/core/styles";
import { getFeatures, getPrice, getColor } from "utils/tiers";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import UpgradePopup from "components/Popups/UpgradePopup";

function getName(tierName, discount) {
  // Always show Premium, unless Academic discount.
  if (tierName === "Premium" && discount === "ACADEMIC") return "Academic";
  return tierName;
}

function getButtonText(tier, selectedTier, newUsage, oldUsage, loading) {
  if (loading)
    return <ClipLoader size={14} color={"black"} loading={loading} />;

  if (tier === "ENTERPRISE") return "Contact Us";
  if (tier === "FREE") return "Start Trial";

  if (!oldUsage) return "Upgrade";

  if (newUsage == oldUsage) return "Current Plan";
  else if (newUsage < oldUsage) return "Downgrade";
  else if (newUsage > oldUsage) return "Upgrade";
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 5,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "black",
    borderRadius: 16,
    width: 280,
    margin: 8,
  },
  hoverable: {
    "&:hover": {
      boxShadow: "0 1px 6px 0 rgba(32, 33, 36, 0.4);",
    },
  },
  tier: {
    fontSize: 20,
    fontWeight: 700,
  },
  priceContainer: {
    display: "inline-block",
    fontWeight: "bold",
  },
  price: {
    display: "inline-block",
    fontSize: 36,
  },
  priceDescriptor: {
    display: "inline-block",
    fontSize: 12,
    verticalAlign: "top",
    marginTop: 18
  },
  perMonth: {
    fontSize: 20,
    color: "gray",
    display: "inline-block",
    position: "relative",
    top: -5,
    margin: 4,
  },
  subText: { marginTop: 12, marginLeft: 14, marginRight: 14 },
  features: {
    textAlign: "left",
  },
  feature: { width: 220, marginTop: 5, marginBottom: 5 },
  free: { color: getColor("FREE") },
  starter: { color: getColor("PREMIUM") },
  premium: { color: getColor("PREMIUM") },
  enterprise: { color: getColor("ENTERPRISE") },
}));

const FeatureRow = ({ tier, feature, allowIncrement, incrementAction }) => {
  return (
    <div className="flex-container">
      <div className="check-block-plan">
        <div className={clsx("check-plan", tier.toLowerCase())}>
          <svg
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 21 17"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              id="Page-1"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="ui-icons"
                transform="translate(-109.000000, -67.000000)"
                fill={getColor(tier)}
              >
                <polygon
                  id="check"
                  points="108.994099 76.4000626 115.987848 83.419577 129.407253 69.9978283 126.587674 67.1592372 115.987848 77.7490936 111.827057 73.5894775"
                ></polygon>
              </g>
            </g>
          </svg>
        </div>
        <div className="text-check-plan">{feature}</div>
      </div>
      {allowIncrement && (
        <div className="plan-increment-container">
          <FontAwesomeIcon
            className={clsx("plan-increment", tier.toLowerCase())}
            icon={faMinusCircle}
            onClick={() => incrementAction(-1)}
          />{" "}
          <FontAwesomeIcon
            className={clsx("plan-increment", tier.toLowerCase())}
            icon={faPlusCircle}
            onClick={() => incrementAction(1)}
          />
        </div>
      )}
    </div>
  );
};

export default function PricingCard(props) {
  const [creditBundles, setCreditBundles] = useState(0);
  const [upgradePopupOpen, setUpgradePopupOpen] = useState(false);
  const classes = useStyles();
  const features = getFeatures(props.tier, creditBundles);
  var disabled = props.tier === props.selectedTier;
  var elevation = 2;

  let maxBundles = 0;

  if (props.tier == "STARTER") maxBundles = 2;
  if (props.tier == "PREMIUM") maxBundles = 20;

  const action = () => {
    if (props.userTier === undefined) {
      props.routeToSignup();
    } else if (props.tier === "ENTERPRISE") return props.contact();
    else if (props.userTier === "FREE")
      props.createSubscription(props.tier, creditBundles, props.billingPeriod);
    else if (props.tier === "STARTER" || props.tier === "PREMIUM") {
      setUpgradePopupOpen(true);
    }
  };

  // If plan is selected
  if (props.tier === props.selectedTier) elevation = 10;

  var tierClass;
  if (props.tier === "FREE") tierClass = classes.free;
  else if (props.tier === "STARTER") tierClass = classes.starter;
  else if (props.tier === "PREMIUM") tierClass = classes.premium;
  else if (props.tier === "ENTERPRISE") tierClass = classes.enterprise;
  return (
    <Paper
      className={clsx(classes.paper, !disabled ? classes.hoverable : null)}
      elevation={elevation}
    >
      <UpgradePopup
        open={upgradePopupOpen}
        handleClose={() => setUpgradePopupOpen(false)}
        oldTier={props.userTier}
        oldUsage={props.monthlyCredits}
        newTier={props.tier}
        newUsage={features.credits}
        newBillingPeriod={props.billingPeriod}
        updateSubscription={() => {
          props.updateSubscription(
            props.tier,
            creditBundles,
            props.billingPeriod
          );
        }}
      />
      <h3 className={clsx(classes.tier, tierClass)}>
        {getName(features.name, props.discount)}
      </h3>
      <div className={classes.priceContainer}>
        {features.hasStartPrice && <div className={classes.priceDescriptor}>Starting at</div>}
        <div className={classes.perMonth}>$</div>
        <div className={classes.price}>
          {getPrice(
            props.tier,
            creditBundles,
            props.billingPeriod,
            props.discount
          )}{" "}
        </div>
        <div className={classes.perMonth}>/mo</div>
      </div>
      Billed {props.billingPeriod === "YEARLY" ? "Yearly" : "Monthly"}
      <p className={classes.subText}>{features.description}</p>
      {!props.disableButtons && (
        <Button
          className="upgrade-button"
          disabled={
            props.selectedTier != "FREE" &&
            features.credits == props.monthlyCredits
          }
          variant="contained"
          color="primary"
          onClick={action}
        >
          {getButtonText(
            props.tier,
            props.selectedTier,
            features.credits,
            props.monthlyCredits,
            props.loading
          )}
        </Button>
      )}
      <div className="grid-check-plan">
        {features.credits && (
          <FeatureRow
            tier={props.tier}
            feature={`${features.credits} credits/mo`}
            allowIncrement
            incrementAction={(inc) => {
              const newCreditBundles = creditBundles + inc;
              if (newCreditBundles >= 0 && newCreditBundles <= maxBundles)
                setCreditBundles(newCreditBundles);
            }}
          />
        )}
        {features.featureList.map((feature, idx) => {
          return (
            <FeatureRow
              tier={props.tier}
              feature={feature}
              key={`PricingCardFeature-${props.tier}-${idx}`}
            />
          );
        })}
      </div>
    </Paper>
  );
}
