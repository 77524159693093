import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { withRouter } from "react-router-dom";
import {
  questionTypesAndGroups,
  questionsAllowedInGroup,
} from "utils/questionTypes";
import TypeTile from "components/TypeTile";
import { Chip } from "@material-ui/core";

const SurveyBuilderItem = ({ item, handleClick }) => {
  return (
    <div
      key={`QTYPE-${item.id}`}
      className="tile-section"
      onClick={handleClick}
    >
      <div className="mr-10">
        <TypeTile type={item.key} shape="square" />
      </div>
      <div className="info">
        <p className="type-header">{item.name}</p>
        <p className="type-body">{item.description}</p>
      </div>
      {item.isNew && <Chip label="New" />}
    </div>
  );
};

const SurveyBuilderCategory = ({ category, handleClickForItem, ...props }) => {
  let items = questionTypesAndGroups.filter((e) => e.category === category);
  if (props.questionGroupPopup)
    items = items.filter((e) => questionsAllowedInGroup.includes(e.key));
  return (
    <>
      <div>
        {items.length !== 0 && <p className="tile-section-text">{category}</p>}
        {items.map((item, idx) => (
          <SurveyBuilderItem
            key={`survey-builder-item-${idx}`}
            item={item}
            handleClick={handleClickForItem(item)}
          />
        ))}
      </div>
    </>
  );
};

class NewQuestionPopup extends React.Component {
  state = {
    selected: undefined,
  };

  handleClickForItem = (item) => {
    return () => {
      switch (item.category) {
        case "QUESTION GROUP":
          this.props.modFunctions.addQuestionGroup(this.props.idx, item.key);
          break;
        case "BASIC":
        case "AUDIO":
        case "VIDEO":
          this.props.modFunctions.addQuestion(
            this.props.idx,
            item.key,
            this.props.groupId
          );
          break;
        default:
      }
      this.props.handleClose();
    };
  };

  handleWelcomeScreenClick = () => {
    return () => {
      this.props.changeInfo({ removeWelcomeScreen: false }, true);
      this.props.modFunctions.previewQuestion(undefined);
      this.props.handleClose();
    };
  };

  getTitle = () =>
    this.props.groupId === undefined
      ? "Add Item to Survey"
      : "Add Question to Group";

  showWelcomeScreen = () =>
    this.props.groupId === undefined &&
    this.props.info &&
    this.props.info.removeWelcomeScreen;

  orderedCategories = this.props.groupId
    ? ["VIDEO", "BASIC"]
    : ["BASIC", "AUDIO", "VIDEO", "QUESTION GROUP"];

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
        >
          <div id="question-picker-dialog">
            <div className="title-box">
              <h2 className="title">{this.getTitle()}</h2>
            </div>
            <div id="question-types" style={{ marginBottom: 20 }}>
              {this.showWelcomeScreen() && (
                <SurveyBuilderCategory
                  category={"WELCOME"}
                  handleClickForItem={this.handleWelcomeScreenClick}
                />
              )}
              {this.orderedCategories.map((category) => (
                <SurveyBuilderCategory
                  key={`survey-builder-category-${category}`}
                  category={category}
                  handleClickForItem={this.handleClickForItem}
                  questionGroupPopup={this.props.groupId !== undefined}
                />
              ))}
              <div className="bottom-spacing" />
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}
export default withRouter(NewQuestionPopup);
