import React, { useMemo } from "react";
import Editor from "./Editor";
import {
  itemsToEditorValue,
  updateTranscriptionItems,
} from "utils/transcription";

const ResponseEditor = ({
  transcriptionItems,
  time,
  onWordClick,
  saveTranscription,
  readOnly,
  className,
}) => {
  // Minimize re-calculation of editor value across renders
  const editorValue = useMemo(
    () => itemsToEditorValue(transcriptionItems),
    [transcriptionItems]
  );

  const updateTranscription = (doc) => {
    const editorContent = doc.toJSON().content;

    const updatedTranscriptionItems = updateTranscriptionItems(
      editorContent,
      transcriptionItems
    );

    if (saveTranscription) {
      saveTranscription(updatedTranscriptionItems);
    }
  };

  return (
    <Editor
      initialValue={editorValue}
      time={time}
      onWordClick={onWordClick}
      readOnly={readOnly}
      onUpdate={updateTranscription}
      className={className}
      config="items"
    />
  );
};

export default ResponseEditor;
