import { MarkExtension, ExtensionTag, isElementDomNode } from "@remirror/core";

/**
 * <span start-time="0.04" end-time="1.17">
 * {
 *   type: "text",
 *   text: "Tonight",
 *   marks: [
 *     {
 *       type: "time",
 *       attrs: { startTime: "0.04", endTime: "1.17" }
 *     }
 *   ],
 * }
 */
class ItemMarkExtension extends MarkExtension {
  get name() {
    return "time";
  }

  createTags() {
    return [ExtensionTag.Behavior, ExtensionTag.ExcludeInputRules];
  }

  createMarkSpec(extra, override) {
    const attributeStartTime = "start-time";
    const attributeEndTime = "end-time";

    return {
      /**
       * extra.defaults() defines extra attributes, so it must be called when
       * creating a specification (unless extra attributes are disabled).
       * Attributes with no default MUST be defined.
       */
      inclusive: true,
      ...override,
      attrs: {
        ...extra.defaults(),
        startTime: {},
        endTime: {},
      },
      /**
       * Function returns DOM node or array that describes one.
       * Optional `0` indicates where to insert content.
       */
      toDOM: (mark) => {
        return [
          "span",
          {
            ...extra.dom(mark),
            [attributeStartTime]: mark.attrs.startTime,
            [attributeEndTime]: mark.attrs.endTime,
          },
          0,
        ];
      },
      // toDOM and parseDOM should be symmetric as best practice
      parseDOM: [
        {
          // `tag` string should be a CSS selector
          tag: `span[${attributeStartTime}][${attributeEndTime}]`,
          getAttrs: (dom) => {
            if (!isElementDomNode(dom)) return;
            const startTime = dom.getAttribute(attributeStartTime);
            const endTime = dom.getAttribute(attributeEndTime);
            return { ...extra.parse(dom), startTime, endTime };
          },
        },
        ...(override.parseDOM ?? []),
      ],
    };
  }

  createEventHandlers() {
    return {
      clickMark: (event, clickState) => {
        const markRange = clickState.getMark(this.type);

        if (!markRange) return;

        return this.options.onClick
          ? this.options.onClick(event, markRange)
          : undefined;
      },
    };
  }
}

export default ItemMarkExtension;
