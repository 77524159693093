import React, { useEffect, useState } from "react";
import ChartContainer from "components/Graphs/ChartContainer";
import BarChart from "components/Graphs/BarChart";
import { connect } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { getCreditUsage, getCreditSummary } from "redux/actions";
import {
  Paper,
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
} from "@material-ui/core";
import { Table } from "reactstrap";
import { getUsageData } from "utils/graphData";
import { rainbow } from "utils/brandPalette";
import { withRouter } from "react-router";
import { dateToString } from "utils/utils";
import { Alert } from "@material-ui/lab";

const mapStateToProps = (state) => {
  return {
    usage: state.creditUsage,
    summary: state.creditSummary,
    balance: state.creditBalance,
  };
};

const mapDispatchToProps = {
  getCreditUsage,
  getCreditSummary,
};

const Credit = (props) => {
  let today = new Date(),
    year = today.getFullYear(),
    month = today.getMonth();
  var firstDay = new Date(year, month, 1);
  var lastDay = new Date(year, month + 1, 0);
  const [startDate, setStartDate] = useState(firstDay);
  const [endDate, setEndDate] = useState(lastDay);

  const getTotalUsage = () =>
    props.usage && props.usage.length > 0
      ? props.usage.reduce((partialSum, usage) => usage.count + partialSum, 0)
      : 0;

  const fetchNewData = async () => {
    const dateParams = {
      startDate: startDate.toISOString().split("T")[0],
      endDate: endDate.toISOString().split("T")[0],
    };
    props.getCreditUsage(dateParams);
    props.getCreditSummary(dateParams);
  };

  const readableDate = (date) =>
    date.toDateString().split(" ").slice(1).join(" ");

  useEffect(() => {
    fetchNewData();
  }, [startDate, endDate]);

  const fillUsage = (usage) => {
    let usageMap = {};
    usage.forEach((u) => {
      usageMap[u.date] = u.count;
    });
    for (
      var d = new Date(startDate);
      d <= endDate;
      d.setDate(d.getDate() + 1)
    ) {
      usageMap[dateToString(d)] |= 0;
    }

    const newUsage = Object.keys(usageMap)
      .sort()
      .map((date) => ({
        date: date.slice(5),
        count: usageMap[date],
      }));
    return newUsage;
  };

  const graphData = (usage) =>
    usage ? getUsageData(fillUsage(usage)) : undefined;

  const getName = (data) => {
    if (
      data.documentType == "file" &&
      data.filePath &&
      data.filePath.length > 0
    ) {
      return data.filePath[0];
    } else if (
      data.documentType == "session" &&
      data.surveyName &&
      data.surveyName.length > 0
    ) {
      return data.surveyName[0];
    }
  };

  const getPath = (data) => {
    if (
      data.documentType == "file" &&
      data.filePath &&
      data.filePath.length > 0
    ) {
      return props.history.push(`/files/${data.filePath[0]}`);
    } else if (
      data.documentType == "session" &&
      data.surveyId &&
      data.surveyId.length > 0
    ) {
      return props.history.push(`/surveys/${data.surveyId[0]}`);
    }
  };

  const getType = (data) => {
    if (data.documentType == "file") {
      return (
        <Chip
          label="File"
          style={{ background: rainbow.blue, color: "white" }}
        />
      );
    } else if (data.documentType == "session") {
      return <Chip label="Survey" color="primary" />;
    }
  };

  return (
    <>
      <Paper style={{ padding: 30, margin: 20 }}>
        You have <strong>{props.balance} credits available</strong> in the
        current month ({readableDate(firstDay)} - {readableDate(lastDay)})
      </Paper>
      <Grid
        container
        spacing={3}
        style={{
          margin: 0,
          width: "100%",
          padding: 10,
        }}
      >
        <Grid item xs={6}>
          <ChartContainer
            title="Daily Credit Usage"
            loading={!props.usage}
            hideMenu
          >
            <BarChart
              chartData={graphData(props.usage)}
              backgroundColor={rainbow.green}
            />
          </ChartContainer>
        </Grid>
        <Grid item xs={3}>
          <Paper style={{ padding: 30 }}>
            You have used <strong>{getTotalUsage()} credits</strong>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div className="pb-10 pt-10" style={{ display: "flex" }}>
                <DatePicker
                  className="date-picker"
                  margin="normal"
                  label="From"
                  value={startDate}
                  onChange={setStartDate}
                  variant="inline"
                  autoOk
                />
                <div className="ml-10 mr-10"></div>
                <DatePicker
                  className="date-picker"
                  margin="normal"
                  label="To"
                  value={endDate}
                  onChange={setEndDate}
                  variant="inline"
                  autoOk
                />
              </div>
            </MuiPickersUtilsProvider>
          </Paper>
        </Grid>
      </Grid>
      <div style={{ margin: 20 }}>
        <Paper id="response-table">
          <TableContainer>
            <h3>
              Credit Use Summary ({readableDate(startDate)} -{" "}
              {readableDate(endDate)})
            </h3>
            <Alert severity="info" style={{ margin: 20 }}>
              1 credit equals 1 survey response or 10 file transcription
              minutes.{" "}
              <strong>
                <a
                  href="https://docs.phonic.ai/docs/credits"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click here to learn more about credits.
                </a>
              </strong>
            </Alert>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell key="Name">Name</TableCell>
                  <TableCell key="Type">Type</TableCell>
                  <TableCell key="Credits Consumed">Credits Used</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.summary &&
                  props.summary.map((t, idx) => {
                    return (
                      <TableRow
                        onClick={() => {
                          getPath(t);
                        }}
                        key={idx}
                      >
                        <TableCell>{getName(t)}</TableCell>
                        <TableCell>{getType(t)}</TableCell>
                        <TableCell>{t.creditsConsumed}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </>
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Credit));
