import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 160,
    marginTop: theme.spacing(1),
  },
  label: {
    color: "#507b82",
    "&.Mui-focused": {
      color: "#507b82",
    },
  },
  shrink: {
    fontSize: "1.25rem",
  },
  select: {
    "&:after": {
      borderColor: "#507b82",
    },
  },
}));

const SORT_OPTIONS = [
  { value: "OLDEST", label: "Oldest Responses" },
  { value: "NEWEST", label: "Newest Responses" },
];

const PickResponseSort = ({ change, initialState }) => {
  const classes = useStyles();

  const [item, setItem] = useState(initialState ? initialState : "");

  const handleSelectChange = (evt) => {
    setItem(evt.target.value);
    change({ responseSort: evt.target.value });
  };
  return (
    <FormControl className={classes.formControl}>
      <InputLabel
        id="response-sort"
        shrink
        classes={{ root: classes.label, shrink: classes.shrink }}
      >
        Sort by:
      </InputLabel>
      <Select
        id="response-sort"
        labelId="response-sort-label"
        value={item}
        onChange={handleSelectChange}
        displayEmpty
        className={classes.select}
      >
        {SORT_OPTIONS.map((item) => (
          <MenuItem value={item.value} key={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PickResponseSort;