import { ageRanges, genderOptions } from "./Recruit/recruitOptions";
import { getObjectId } from "./utils";

export function getBlankLogicBlock() {
  return {
    _id: getObjectId(),
    subject: "ANSWER",
    conditionSubject: "",
    operator: "IS",
    keyword: "",
    action: "ASSIGN CONDITION",
    operand: "",
    conditionAssign: "",
    execute: "AFTER",
  };
}

export function getBlankSurvey() {
  return {
    questions: [],
    questionGroups: {},
    demographics: {
      age: false,
      gender: false,
      hHIncome: false,
      location: false,
      firstName: false,
      lastName: false,
      email: false,
      ageOptional: false,
      genderOptional: false,
      hHIncomeOptional: false,
      firstNameOptional: false,
      lastNameOptional: false,
      emailOptional: false,
    },
    surveyId: undefined,
    name: "",
    title: "",
    subTitle: "",
    summary: "",
    completionLink: "",
    defaultLanguage: "en",
    includeMicCheck: false,
    welcomeImageUrl: undefined,
    topLeftImageUrl: undefined,
    font: "plain",
    align: "center",
    background: "midnight",
    state: "DRAFT",
    randomGroups: [],
    removeWelcomeScreen: false,
    translations: [],
    randomConditions: [],
    audioRecordingFormat: "wav",
    redirectToCompletionLink: false,
    redirectToScreenOutLink: false,
    hidePreviousButton: false,
    hideSupportText: false,
    hideLogo: false,
    hideProgressBar: false,
    showProgressBarPercentage: false,
    useNativeCamera: false,
    mergeDuplicateEmails: false,
    persistResponses: false,
    allowKeyboardShortcuts: false,
  };
}

export function getBlankQuestion() {
  return {
    text: "",
    type: "AUDIO",
    options: [{ text: "", fixed: false, skip: 0 }],
    optionsMatrix: {
      inputType: 'RADIO',
      inputs: [],
      labels: { position: 'left' }
    },
    bounds: {
      lower: 0,
      upper: 10,
      lowerLabel: "",
      upperLabel: "",
      interval: 1,
    },
    exclusive: true,
    randomize: false,
    doTranscription: true,
    doSentiment: true,
    doTopics: false,
    allowBackupText: false,
    optional: false,
    showAudioPlayback: true,
    transcriptionLanguage: "en-US",
    includeCountdown: false,
    countdownTimer: 0,
    maxResponseLength: 10,
    includeCountdownTimer: false,
    questionId: getObjectId(),
    redirectLink: "",
    logic: [],
    showSelectionIcons: false
  };
}

export function getBlankQuestionGroup() {
  return {
    id: getObjectId(),
    type: "",
    title: "",
    description: "",
    frameLink: "",
  };
}

export function getBlankShowreel() {
  return {
    name: "",
    addSubtitles: true,
    addWatermark: true,
    showDemographics: false,
    content: [],
  };
}

export function getBlankPanelRequest() {
  return {
    status: "DRAFT",
    audiences: [getBlankAudience()],
  };
}

export function getBlankAudience() {
  return {
    name: "New Audience",
    limit: 30,
    gender: genderOptions,
    age: ageRanges,
  };
}

export function getBlankTitleBlock() {
  return {
    type: "TITLE",
    title: "Title",
    subTitle: "Subtitle",
    durationSec: 5,
    fontColor: "white",
    backgroundColor: "black",
    verticalAlign: "center",
    horizontalAlign: "center",
  };
}

export function getBlankDisplayAndFilter() {
  return {
    filters: {
      processedMedia: false,
      emotions: new Set([]),
      bookmarked: false,
      word: "",
      searchText: "",
    },
    displayOptions: {
      demographics: true,
      externalId: false,
      public: false,
      responseSort: "OLDEST",
    },
  };
}

export function getDefaultWidgetStyle() {
  return {
    recordButtonColor: "red",
    recordButtonTextColor: "white",
    size: "large",
    theme: "square",
    spacing: "10px",
  };
}

export function getDefaultWidgetBehavior() {
  return {
    backupText: false,
    audioRecordingFormat: "WAV",
    startRecordingImmediately: false,
    micCheck: false,
    autoSubmit: false,
    showCounter: true,
    showPlayback: true,
    platform: "",
  };
}

export function getDefaultWidgetText() {
  return {
    recordText: "Record",
    rerecordText: "Re-Record",
    submitText: "Submit",
    doneText: "Done!",
  };
}
