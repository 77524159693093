import React, { useState, useRef, useCallback, useEffect } from "react";
import { useRemirrorContext, useCommands } from "@remirror/react";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SpeakerSubmenu from "./SpeakerSubmenu";

const SpeakerPicker = ({ speakers, updateSpeakers }) => {
  const [anchorPos, setAnchorPos] = useState(null);
  const [expanded, setExpanded] = useState(null);
  const { view } = useRemirrorContext();
  const pos = useRef(-1);
  const commands = useCommands();

  const handleClickName = useCallback(
    (e) => {
      e.preventDefault();

      const target = e.target;

      if (target.matches("span[speaker]")) {
        pos.current = view.posAtDOM(target, 0);
        const { top, x: left, height } = target.getBoundingClientRect();
        setAnchorPos({ top: top + height, left });
      }
    },
    [view]
  );

  const handleSelectName = (idx) => {
    setAnchorPos(null);
    commands.updateSegment({ speaker: (idx + 1).toString() }, pos.current);
  };

  const handleClose = () => {
    setAnchorPos(null);
  };

  const handleClickMore = (e) => {
    setExpanded(expanded ? null : e.currentTarget);
  };

  const closeExpanded = () => setExpanded(null);

  const saveSpeakers = (speakerIdx, newSpeakerInfo) => {
    updateSpeakers([
      ...speakers.slice(0, speakerIdx),
      newSpeakerInfo,
      ...speakers.slice(speakerIdx + 1),
    ]);
    commands.updateSpeaker(speakerIdx, newSpeakerInfo);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickName);

    return () => document.removeEventListener("click", handleClickName);
  }, [handleClickName]);

  const open = Boolean(anchorPos);

  return (
    <Popover
      open={open}
      anchorReference="anchorPosition"
      anchorPosition={anchorPos}
      onClose={handleClose}
      disableRestoreFocus
    >
      <List aria-label="speaker list">
        {speakers.map((speaker, i) => (
          <ListItem
            key={`speaker-${i}`}
            onClick={() => handleSelectName(i)}
            dense
            button
            disableRipple
          >
            <ListItemText primary={speaker.name || "Unnamed Speaker"} />
            <ListItemSecondaryAction
              onClick={(e) => handleClickMore(e, speaker)}
              speaker={i}
            >
              <IconButton edge="end" aria-label="more" disableRipple>
                <MoreVertIcon fontSize="small" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <Popover
        open={!!expanded}
        anchorEl={expanded}
        onClose={closeExpanded}
        TransitionProps={{
          timeout: { enter: 150, exit: 0 },
        }}
      >
        {expanded && (
          <SpeakerSubmenu
            speaker={speakers[parseInt(expanded.getAttribute("speaker"))]}
            speakerIdx={parseInt(expanded.getAttribute("speaker"))}
            updateSpeaker={(speakerInfo) =>
              saveSpeakers(
                parseInt(expanded.getAttribute("speaker")),
                speakerInfo
              )
            }
          />
        )}
      </Popover>
    </Popover>
  );
};

export default SpeakerPicker;
