import React from "react";
import SignUpForm from "views/SignUpForm";
import SignUpFormPart2 from "views/SignUpFormPart2";
import { withRouter } from "react-router-dom";
import firebase from "firebase/app";
import {
  addUser,
  getOrganizationLogo,
  sendVerificationEmail,
  checkVerificationCode,
  startLoading,
  addAuthToken,
} from "redux/actions";
import { connect } from "react-redux";
import { trackPage } from "../analytics";
import { parseQuery } from "../utils/utils";
import { Helmet } from "react-helmet";

const ENABLE_EMAIL_VERIFICATION = false;

const mapStateToProps = (state) => {
  return {
    authToken: state.authToken,
    user: state.user,
    organization: state.organization,
    emailVerified: state.emailVerified,
    emailVerificationLoading: state.loading["EmailVerification"],
    sendVerificationCodeLoading: state.loading["SendVerificationEmail"],
    addUserLoading: state.loading["AddUser"],
  };
};

const mapDispatchToProps = {
  addUser,
  getOrganizationLogo,
  sendVerificationEmail,
  checkVerificationCode,
  startLoading,
  addAuthToken,
};

class SignUp extends React.Component {
  state = {
    subscribed: true,
    userLoading: false,
    initialVerificationRun: false, // Run the verification check at least once in case it is passed by URL param
  };

  constructor(props) {
    super(props);
    trackPage("SignUp");
    if (firebase.auth().currentUser) {
      if (this.props.user) this.routeToSurveys();
    }
    if (this.props.match.params.organization) {
      this.props.getOrganizationLogo(this.props.match.params.organization);
    }

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.props.addAuthToken();
      }
    });
  }
  componentDidUpdate = () => {
    if (firebase.auth().currentUser) {
      // Routing depending on signup progress
      if (this.props.user) this.routeToSurveys();

      // Run initial verification check once
      if (!this.state.initialVerificationRun) {
        // Auto-fill verification code if provided in URL
        let qp = parseQuery(this.props.location.search);
        if (qp.verification) {
          this.setState(
            {
              verificationCode: qp.verification,
              initialVerificationRun: true,
            },
            this.checkVerificationCode
          );
        }
      }
    }
  };

  routeToSignIn = () => {
    if (this.props.match.params.organization) {
      this.props.history.push(
        `/${this.props.match.params.organization}/signin`
      );
      return;
    }
    this.props.history.push("/signin");
  };

  routeToSurveys = () => {
    this.props.history.push("/home", { referrer: "signUp" });
  };

  sendVerificationEmail = () => {
    this.props.startLoading(() => {
      this.props.sendVerificationEmail(firebase.auth().currentUser.email);
    }, "SendVerificationEmail");
  };

  checkVerificationCode = () => {
    this.props.startLoading(() => {
      this.props.checkVerificationCode(
        firebase.auth().currentUser.email,
        this.state.verificationCode
      );
    }, "EmailVerification");
  };

  addUser = (e) => {
    e.preventDefault();
    if (!this.props.emailVerified && ENABLE_EMAIL_VERIFICATION) {
      alert("Please enter a verification code.");
      return;
    }
    let currentUser = firebase.auth().currentUser;
    var user = {
      firstName: currentUser.displayName.split(" ")[0],
      lastName: currentUser.displayName.split(" ")[1],
      email: currentUser.email,
      subscribed: this.state.subscribed,
      useCase: this.state.useCase,
      orgSize: this.state.orgSize,
    };
    if (this.props.match.params.organization)
      user.organization = this.props.match.params.organization;

    this.props.startLoading(() => {
      this.props.addUser(user);
    }, "AddUser");
  };

  signOutAndGoToPage1 = () => {
    firebase
      .app()
      .auth()
      .signOut()
      .then(() => {
        this.props.history.push("/signup");
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  signUp = (e) => {
    e.preventDefault();
    if (this.state.userLoading) alert("Currently creating user account.");

    this.setState({ userLoading: true });
    firebase
      .app()
      .auth()
      .createUserWithEmailAndPassword(this.state.email, this.state.password)
      .then(() => {
        var user = firebase.auth().currentUser;
        user
          .updateProfile({
            displayName: `${this.state.firstName} ${this.state.lastName}`,
          })
          .then(() => {
            this.setState({ userLoading: false });
            if (ENABLE_EMAIL_VERIFICATION) this.sendVerificationEmail();
          })
          .catch((error) => {
            alert(error.message);
            this.setState({ userLoading: false });
          });
      })
      .catch((error) => {
        alert(error.message);
        this.setState({ userLoading: false });
      });
  };

  googleSignIn = () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        var googleUser = result.user;
        this.setState({
          firstName: googleUser.displayName.includes(" ")
            ? googleUser.displayName.split(" ")[0]
            : googleUser.displayName,
          lastName: googleUser.displayName.includes(" ")
            ? googleUser.displayName.split(" ")[1]
            : "",
          email: googleUser.email,
          signInMethod: "Google",
        });
        if (ENABLE_EMAIL_VERIFICATION)
          this.props.sendVerificationEmail(googleUser.email);
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  formChange = (key, event) => {
    event.persist(); //Events are re-used, this stops fields from nullifying.
    if (key === "firstName") {
      this.setState({ firstName: event.target.value });
    } else if (key === "lastName") {
      this.setState({ lastName: event.target.value });
    } else if (key === "email") {
      this.setState({ email: event.target.value });
    } else if (key === "password") {
      this.setState({ password: event.target.value });
    } else if (key === "subscribed") {
      this.setState({ subscribed: event.target.checked });
    } else if (key === "discoveryMethod") {
      this.setState({ discoveryMethod: event.target.value });
    } else if (key === "useCase") {
      this.setState({ useCase: event.target.value });
    } else if (key === "orgSize") {
      this.setState({ orgSize: event.target.value });
    } else if (key === "verificationCode") {
      this.setState(
        { verificationCode: event.target.value },
        this.checkVerificationCode
      );
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Sign Up</title>
        </Helmet>
        {!firebase.auth().currentUser ? (
          <SignUpForm
            routeToSignIn={this.routeToSignIn}
            signUp={this.signUp}
            formChange={this.formChange}
            userLoading={this.state.userLoading}
            organization={this.props.organization}
            organizationId={this.props.match.params.organization}
            googleSignIn={this.googleSignIn}
          />
        ) : (
          <SignUpFormPart2
            style={!firebase.auth().currentUser ? { display: "none" } : {}}
            routeToSignIn={this.routeToSignIn}
            addUser={this.addUser}
            formChange={this.formChange}
            addUserLoading={this.props.addUserLoading}
            signOutAndGoToPage1={this.signOutAndGoToPage1}
            shouldVerifyCode={ENABLE_EMAIL_VERIFICATION} // {this.state.signInMethod !== "Google"}
            email={firebase.auth().currentUser.email}
            verificationCode={
              parseQuery(this.props.location.search).verification
            }
            emailVerificationLoading={this.props.emailVerificationLoading}
            sendVerificationCodeLoading={this.props.sendVerificationCodeLoading}
            verificationSuccessful={this.props.emailVerified}
            sendVerificationEmail={this.sendVerificationEmail}
          />
        )}
      </>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUp));
