import React, { Component } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ResponseEditor from "components/ResponseEditor";
import PlainTextEditor from "components/PlainTextEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { updateResponse, getShowreel, updateFile } from "redux/actions";

const mapDispatchToProps = {
  updateResponse,
  updateFile,
  getShowreel,
};

class ShowreelsSubtitlesPopup extends Component {
  state = { editing: false };

  saveTranscription = (key) => {
    if (!key) return;
    if (this.state.localTranscriptionItems) {
      if (this.props.showreel.content[this.props.clipIdx].responseId) {
        this.props
          .updateResponse(
            this.props.showreel.content[this.props.clipIdx].response._id,
            {
              [key]: this.state.localTranscriptionItems,
            }
          )
          .then(() => this.props.getShowreel(this.props.showreel._id));
      }
      if (this.props.showreel.content[this.props.clipIdx].fileId) {
        this.props
          .updateFile(this.props.showreel.content[this.props.clipIdx].fileId, {
            [key]: this.state.localTranscriptionItems,
          })
          .then(() => this.props.getShowreel(this.props.showreel._id));
      }
    }
    this.setState({ editing: false });
  };

  updateLocalTranscription = (updatedTranscription) => {
    this.setState({ localTranscriptionItems: updatedTranscription });
  };

  handleClose = () => {
    this.setState({ editing: false });
    this.props.onClose();
  };

  render() {
    const key =
      this.props.showreel.content[this.props.clipIdx] &&
      this.props.showreel.content[this.props.clipIdx].response &&
      this.props.showreel.content[this.props.clipIdx].response.features &&
      (Array.isArray(
        this.props.showreel.content[this.props.clipIdx].response.features
          .transcription_items
      )
        ? "transcription_items"
        : typeof this.props.showreel.content[this.props.clipIdx].response
            .features.transcription === "string"
        ? "transcription"
        : null);

    return (
      <Dialog
        id="showreels-subtitles-popup"
        open={this.props.open}
        onClose={this.handleClose}
        fullWidth
      >
        <div className="x-button">
          <IconButton
            aria-label="close"
            onClick={this.handleClose}
            style={{ float: "right" }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent className="popup-container">
          <h2>Edit Subtitles</h2>
          {key === "transcription_items" ? (
            <ResponseEditor
              transcriptionItems={
                this.props.showreel.content[this.props.clipIdx].response
                  .features.transcription_items
              }
              saveTranscription={this.updateLocalTranscription}
              readOnly={!this.state.editing}
              className={`subtitles-container${
                this.state.editing ? "" : " disabled"
              }`}
            />
          ) : (
            key === "transcription" && (
              <PlainTextEditor
                transcriptionString={
                  this.props.showreel.content[this.props.clipIdx].response
                    .features.transcription
                }
                saveTranscription={this.updateLocalTranscription}
                readOnly={!this.state.editing}
                className={`subtitles-container${
                  this.state.editing ? "" : " disabled"
                }`}
              />
            )
          )}
          <DialogActions style={{ padding: "8px 0" }}>
            {!this.state.editing ? (
              <Button
                className="secondary-button-color"
                type="submit"
                variant="contained"
                onClick={() =>
                  this.setState({
                    editing: true,
                    localTranscriptionItems:
                      this.props.showreel.content[this.props.clipIdx].response
                        .features[key],
                  })
                }
              >
                <FontAwesomeIcon icon={faUnlock} />
                &nbsp;Edit
              </Button>
            ) : (
              <Button
                className="secondary-button-color"
                type="submit"
                variant="contained"
                onClick={() => this.saveTranscription(key)}
              >
                <FontAwesomeIcon icon={faSave} />
                &nbsp;Save
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
}

export default connect(null, mapDispatchToProps)(ShowreelsSubtitlesPopup);
