import React from "react";
import ReactDOMServer from "react-dom/server";

const halfSkeleton = (
  <>
    <div className="skeleton half" />
    <div className="skeleton" />
    <div className="skeleton" />
  </>
);

const skeletonSpacer = (
  <>
    <div className="skeleton spacer" />
  </>
);

const fullSkeleton = (
  <>
    <div className="skeleton" />
    <div className="skeleton" />
    <div className="skeleton" />
    <div className="skeleton" />
  </>
);

const getIframeContent = (embed, embedCode) => {
  var body;
  switch (embed) {
    case "AUDIO":
    case "VIDEO":
      body = (
        <div className="content">
          {halfSkeleton}
          {embedCode}
          {halfSkeleton}
        </div>
      );
      break;
    case "inline":
      body = (
        <div className="content">
          {halfSkeleton}
          {embedCode}
          {halfSkeleton}
        </div>
      );
      break;
    case "popover":
    case "sidedrawer":
    default:
      body = (
        <div className="content">
          {halfSkeleton}
          {embedCode}
          {skeletonSpacer}
          {fullSkeleton}
        </div>
      );
      break;
  }

  var content = ReactDOMServer.renderToString(body);
  content = content.concat(
    `<style>
      html, body {
        height: 100%;
        margin: 0;
        padding: 0 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 8px;
      }
      .skeleton {
        height: 20px;
        background-color: #D0CFCE;
        transform: scale(1, 0.60);
        border-radius: 4px;
        margin-top: 14px;
        margin-bottom: 14px;
      }
      .half {
        width: 50%;
      }
      .spacer {
        background-color: white;
      }
    </style>`
  );
  return content;
};

const EmbedPreview = ({ embed, embedCode }) => {
  return (
    <div className="preview-wrapper dots">
      <iframe
        title="preview"
        frameBorder="0"
        srcDoc={getIframeContent(embed, embedCode)}
      />
    </div>
  );
};

export default EmbedPreview;
