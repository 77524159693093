import React from "react";
import _ from "lodash";
import { faPlusSquare, faMinusSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select, MenuItem, TextField } from "@material-ui/core";

import { removeItemFromArray } from "utils/utils";
import { normalizedLikertInputsConfig, LikertInputTypes } from "utils/questionConfig/likert"
import PropCheckbox from "../../../components/SurveyCreation/PropCheckbox";

export default class LikertConfig extends React.Component {

  static getDerivedStateFromProps(nextProps, prevState) {
    const options = nextProps.getQInfoIfMissingState('options');
    const optionsMatrix = nextProps.getQInfoIfMissingState('optionsMatrix')

    let configSelectionIdx = (prevState ? prevState.configSelectionIdx : 0);
    if(prevState && prevState.configData.inputType !== optionsMatrix.inputType) {
      configSelectionIdx = 0;
    }

    return {
      configSelectionIdx: configSelectionIdx,
      options: options, optionsMatrix: optionsMatrix,
      configData: {
        inputType: optionsMatrix.inputType || 'RADIO',
        inputs: normalizedLikertInputsConfig(optionsMatrix, options),
        labels: optionsMatrix.labels
      }
    }
  }

  render() {
    const { configSelectionIdx, configData } = this.state;
    console.log(this.state);
    return(
      <>
        {
          LikertInputTypes[configData.inputType].optionValuesConfig.indexOf('custom') !== -1 && (
            <div>
              <PropCheckbox
                label={{ text: "Enable Custom Values", placement: "right" }}
                update={(e) => {
                  const cData = { ...this.state.configData, inputs: _.cloneDeep(configData.inputs) }
                  cData.inputs[configSelectionIdx].isCustom = e.target.checked;
                  this.props.updateQInfo({ optionsMatrix: { ...cData } }, true)
                }}
                value={configData.inputs[configSelectionIdx].isCustom}
                disabled={false}
              />
            </div>
          )
        }
        
        {
          LikertInputTypes[configData.inputType].optionalLabels && (
            <>
              <PropCheckbox
                label={{ text: "Display Slider Labels", placement: "right" }}
                update={(e) => {
                  const position = (e.target.checked ? 'left' : 'none');
                  const cData = { ...this.state.configData, labels: { position } }
                  this.props.updateQInfo({ optionsMatrix: { ...cData } }, true)
                }}
                value={configData.labels.position === 'left'}
                disabled={false}
              />
            </>
          )
        }
        <br />
        <Select
          fullWidth
          variant="outlined"
          value={configData.inputType}
          onChange={(e) => {
            const cData = { ...configData, inputType: e.target.value }
            this.props.updateQInfo({ optionsMatrix: { ...cData } }, true);
          }}
        >
          {
            Object.keys(LikertInputTypes).map((type, idx) => (
              <MenuItem value={type} key={idx}>{ Object.values(LikertInputTypes)[idx].description }</MenuItem>
            ))
          }
        </Select><br/><br/>
        {
          LikertInputTypes[configData.inputType].configByOption && (
            <>
              <Select
                fullWidth
                variant="outlined"
                value={configSelectionIdx}
                onChange={(e) => this.setState({ ...this.state, configSelectionIdx: e.target.value })}
              >
                {
                  this.state.options.map((option, idx) => (
                    <MenuItem value={idx} key={idx}>{ `Option ${idx + 1}. ${option.text}` }  </MenuItem>
                  ))
                }
              </Select><br/><br/>
            </>
          )
        }
        {
          configData.inputs[configSelectionIdx].isCustom && 
          LikertInputTypes[configData.inputType].optionValuesConfig.indexOf('custom') !== -1 && (
          configData.inputs[configSelectionIdx].custom.map((option, index) => {
              return (
                <div
                  className="translation-line"
                  style={{ display: "flex", alignItems: "center" }}
                  key={index}
                >
                  <TextField
                    value={option.label}
                    variant="outlined"
                    margin="dense"
                    className="translation-flex custom-likert-label-input"
                    placeholder="Value"
                    onChange={(e) => {
                      const cData = { ...configData, inputs: _.cloneDeep(configData.inputs) }
                      cData.inputs[configSelectionIdx]['custom'][index]['label'] = e.target.value;
                      this.props.updateQInfo({ optionsMatrix: { ...cData } }, true);
                    }}
                  />
                  <div className="selection-options-updown add-translation-lang">
                    <FontAwesomeIcon
                      icon={faPlusSquare}
                      className="add-response"
                      onClick={() => {
                        const cData = { ...configData, inputs: _.cloneDeep(configData.inputs) };
                        cData.inputs[configSelectionIdx].custom.push({ label: "", value: "" })
                        this.props.updateQInfo({ optionsMatrix: { ...cData } }, true);
                      }}
                    />
                    <FontAwesomeIcon
                      icon={faMinusSquare}
                      className="add-response"
                      onClick={() => {
                        if (configData.inputs[configSelectionIdx].custom.length <= 1) { return }
                        const cData = { ...configData, inputs: _.cloneDeep(configData.inputs) }
                        cData.inputs[configSelectionIdx]['custom'] = removeItemFromArray(
                          configData.inputs[configSelectionIdx].custom, 
                          index
                        )
                        this.props.updateQInfo({ optionsMatrix: { ...cData } }, true);
                      }}
                    />
                  </div>
                  <TextField
                      type="number"
                      value={option.value}
                      variant="outlined"
                      margin="dense"
                      className="translation-flex recode-likert-label-input"
                      placeholder="Recode #"
                      onChange={(e) => {
                        const cData = { ...configData, inputs: _.cloneDeep(configData.inputs) }
                        cData.inputs[configSelectionIdx]['custom'][index]['value'] = e.target.value;
                        this.props.updateQInfo({ optionsMatrix: { ...cData } }, true);
                      }}
                  />
                </div>
              )
            })
          )
        }
        {
          LikertInputTypes[configData.inputType].optionValuesConfig.indexOf('bounds') !== -1 && (
            <>
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                label="Lower Bound"
                type='number'
                value={configData.inputs[configSelectionIdx].bounds.lower.value}
                onChange={(e) => {
                  const cData = { ...this.state.configData, inputs: _.cloneDeep(configData.inputs)};
                  cData.inputs[configSelectionIdx]['bounds']['lower']['value'] = Number(e.target.value);
                  this.props.updateQInfo({ optionsMatrix: { ...cData } }, true);
                }}
                style={{ marginBottom: 16, marginTop: 0 }}
              />
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                label="Lower Bound Label"
                value={configData.inputs[configSelectionIdx].bounds.lower.label}
                onChange={(e) => {
                  const cData = { ...this.state.configData, inputs: _.cloneDeep(configData.inputs)};
                  cData.inputs[configSelectionIdx]['bounds']['lower']['label'] = e.target.value;
                  this.props.updateQInfo({ optionsMatrix: { ...cData } }, true);
                }}
                style={{ marginBottom: 16, marginTop: 0 }}
              />
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                label="Upper Bound"
                type='number'
                value={configData.inputs[configSelectionIdx].bounds.upper.value}
                onChange={(e) => {
                  const cData = { ...this.state.configData, inputs: _.cloneDeep(configData.inputs)};
                  cData.inputs[configSelectionIdx]['bounds']['upper']['value'] = Number(e.target.value);
                  this.props.updateQInfo({ optionsMatrix: { ...cData } }, true);
                }}
                style={{ marginBottom: 16, marginTop: 0 }}
              />
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                label="Upper Bound Label"
                value={configData.inputs[configSelectionIdx].bounds.upper.label}
                onChange={(e) => {
                  const cData = { ...this.state.configData, inputs: _.cloneDeep(configData.inputs)};
                  cData.inputs[configSelectionIdx]['bounds']['upper']['label'] = e.target.value;
                  this.props.updateQInfo({ optionsMatrix: { ...cData } }, true);
                }}
                style={{ marginBottom: 16, marginTop: 0 }}
              />
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                label="Interval"
                type='number'
                value={configData.inputs[configSelectionIdx].bounds.interval}
                onChange={(e) => {
                  const cData = { ...this.state.configData, inputs: _.cloneDeep(configData.inputs)};
                  cData.inputs[configSelectionIdx]['bounds']['interval'] = Number(e.target.value);
                  this.props.updateQInfo({ optionsMatrix: { ...cData } }, true);
                }}
                style={{ marginBottom: 16, marginTop: 0 }}
              />
            </>
          )
        }
      </>
    );
  }
}