import React from "react";
import { Card, CardContent, CardMedia, Grid, Chip } from "@material-ui/core";
import { truncateStringWithEllipsis } from "../../utils/utils";

export default function TemplateTile({
  action,
  name,
  image,
  template,
  description,
}) {
  return (
    <Grid item align="center">
      <Card
        variant="outlined"
        className={"template-survey-tile"}
        onClick={() => action(template)}
      >
        <CardMedia component="img" alt={name} height="150" image={image} />
        <CardContent height="120">
          <h3>{name}</h3>
          <p>{truncateStringWithEllipsis(description, 50)}</p>
          <Chip
            size="small"
            className="template-chip"
            label={template.templateCategory}
          />
        </CardContent>
      </Card>
    </Grid>
  );
}
