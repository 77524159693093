import React from "react";
import { Grid } from "@material-ui/core";
import {
  getSelectionData,
  getAverageSentimentPieChartData,
  getSliderNumberChartData,
  getLikertChartData,
  getAverageRank,
  getEmotionBarChartData,
  getWordCloudData,
  getTopicChartData,
} from "utils/graphData";
import { getQuestionType } from "utils/questionTypes";
import {
  getChartColors,
  changeOpacity,
  isOpenEndType,
  isAudioOrVideoType,
  getTransparentChartColors,
} from "utils/utils";
import { isTextType } from "utils/utils";
import { getLikertInputsConfig, LikertInputTypes } from "utils/questionConfig/likert"
import ResultsChart from "components/Graphs/ResultsChart";

class QuestionAnalytics extends React.Component {
  state = { addedStopWords: [] };

  getBackgroundAndBorder = () => {
    return {
      backgroundColor: changeOpacity(
        getQuestionType(this.props.question.type).color,
        0.4
      ),
      borderColor: getQuestionType(this.props.question.type).color,
    };
  };


  getLikertCharts = () => {
    const { question } = this.props;
    if (question.type !== "LIKERT") return null;

    const { configByOption } = LikertInputTypes[question.optionsMatrix.inputType];
    const inputsConfig = getLikertInputsConfig(question.optionsMatrix, question.options);

    let labels = [];
    if(configByOption) {
      question.options.forEach((_option, optionIdx) => {
        inputsConfig[optionIdx].values.forEach((label) => labels.push(label))
        labels = [...new Set(labels)]
      });
    } else {
      labels = inputsConfig[0].values
    }

    return question.options.map((option, optionIdx) => {
      return (
        <Grid
          item
          xs={12}
          sm={4}
          key={`LIKERT-CHART-${question.questionId}-${optionIdx}`}
        >
          <ResultsChart
            title={option}
            loading={Boolean(!this.props.responses)}
            loadingColor={getQuestionType(question.type).color}
            chartType={"BAR"}
            chartDataFn={() =>
              getLikertChartData(this.props.responses, optionIdx, labels)
            }
            {...this.getBackgroundAndBorder()}
            question={question}
            openReportsPopup={this.props.openReportsPopup}
          />
        </Grid>
      );
    });
  };

  addStopWord = () => {
    this.setState((prevState) => ({
      newStopWord: "",
      addedStopWords: [...prevState.addedStopWords, prevState.newStopWord],
    }));
  };

  render() {
    const { responses, question, entities } = this.props;

    const showSelectionChart =
      question.type.includes("SELECTION") || question.type.includes("DROPDOWN");
    const showEmotionChart = question.doEmotion && isOpenEndType(question.type);
    const showSliderChart =
      question.type.includes("NUMBER") || question.type.includes("SLIDER");
    const showSentimentChart =
      question &&
      question.doSentiment &&
      isOpenEndType(question.type) &&
      !isTextType(question.type);
    const showRankingChart = question.type.includes("RANKING");
    const showTopicChart = entities && entities.length > 0;
    const showWordCloudChart = isAudioOrVideoType(question.type);

    return (
      <Grid id="question-analytics" container spacing={2}>
        {/* SELECTION CHART */}
        {showSelectionChart && (
          <Grid item xs={12} sm={4}>
            <ResultsChart
              key={`SELECTION-CHART-${question.questionId}`}
              title={"Selections"}
              loading={Boolean(!responses)}
              loadingColor={getQuestionType(question.type).color}
              chartType={"BAR"}
              chartDataFn={() => getSelectionData(responses, question.options)}
              {...this.getBackgroundAndBorder()}
              question={question}
              openReportsPopup={this.props.openReportsPopup}
            />
          </Grid>
        )}
        {/* EMOTION CHART */}
        {showEmotionChart && (
          <Grid item xs={12} sm={4}>
            <ResultsChart
              key={`EMOTION-CHART-${question.questionId}`}
              title={"Emotions"}
              loading={Boolean(!responses)}
              loadingColor={getQuestionType(question.type).color}
              chartType={"BAR"}
              chartDataFn={() => getEmotionBarChartData(responses)}
              {...this.getBackgroundAndBorder()}
              question={question}
              openReportsPopup={this.props.openReportsPopup}
            />
          </Grid>
        )}
        {/* SLIDER CHART */}
        {showSliderChart && (
          <Grid item xs={12} sm={4}>
            <ResultsChart
              key={`SLIDER-CHART-${question.questionId}`}
              title={"Slider Responses"}
              loading={Boolean(!responses)}
              loadingColor={getQuestionType(question.type).color}
              chartType={"BAR"}
              chartDataFn={() => getSliderNumberChartData(responses)}
              {...this.getBackgroundAndBorder()}
              question={question}
              openReportsPopup={this.props.openReportsPopup}
            />
          </Grid>
        )}
        {/* Likert Charts */}
        {this.getLikertCharts()}
        {/* Ranking Chart */}
        {showRankingChart && (
          <Grid item xs={12} sm={4}>
            <ResultsChart
              key={`RANKING-CHART-${question.questionId}`}
              title={"Ranking Positions"}
              loading={Boolean(!responses)}
              loadingColor={getQuestionType(question.type).color}
              chartType={"BAR"}
              chartDataFn={() => getAverageRank(responses, question.options)}
              {...this.getBackgroundAndBorder()}
              question={question}
              openReportsPopup={this.props.openReportsPopup}
            />
          </Grid>
        )}
        {/* Sentiment Chart */}
        {showSentimentChart && (
          <Grid item xs={12} sm={4}>
            <ResultsChart
              key={`SENTIMENT-CHART-${question.questionId}`}
              title={"Sentiment"}
              loading={Boolean(!responses)}
              loadingColor={getQuestionType(question.type).color}
              chartType={"PIE"}
              chartDataFn={() => getAverageSentimentPieChartData(responses)}
              backgroundColor={getTransparentChartColors(4, 0.4)}
              borderColor={getChartColors(4)}
              question={question}
              openReportsPopup={this.props.openReportsPopup}
              additionalChartProps={{
                interactive: true,
                filterSentiment: (sentiment) =>
                  this.props.updateFilters({ sentiment: sentiment }),
              }}
            />
          </Grid>
        )}
        {/* Topic Graph */}
        {showTopicChart && (
          <Grid item xs={12} sm={4}>
            <ResultsChart
              key={`TOPICS-CHART-${question.questionId}`}
              title={"Topics"}
              loading={Boolean(!responses || !entities)}
              loadingColor={getQuestionType(question.type).color}
              chartType={"BAR"}
              chartDataFn={() => getTopicChartData(this.props.topics)}
              {...this.getBackgroundAndBorder()}
              question={question}
              openReportsPopup={this.props.openReportsPopup}
            />
          </Grid>
        )}
        {/* Word Cloud */}
        {showWordCloudChart && (
          <Grid item xs={12} sm={4}>
            <ResultsChart
              key={`WORD-CLOUD-CHART-${question.questionId}`}
              title={"Word Cloud"}
              loading={Boolean(!responses)}
              loadingColor={getQuestionType(question.type).color}
              chartType={"WORD_CLOUD"}
              chartDataFn={() =>
                getWordCloudData(responses, this.state.addedStopWords)
              }
              {...this.getBackgroundAndBorder()}
              question={question}
              openReportsPopup={this.props.openReportsPopup}
            />
          </Grid>
        )}
      </Grid>
    );
  }
}

export default QuestionAnalytics;
