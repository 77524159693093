import React, { useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import {
  Grid,
  Paper,
  Button,
  TextField,
  Popover,
  Card,
  Tabs,
  Tab,
  Select,
  MenuItem,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Slider,
  Switch,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faTimes, faCode } from "@fortawesome/free-solid-svg-icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import debounce from "lodash/debounce";
import { SketchPicker } from "react-color";
import { ReactComponent as InlineIcon } from "assets/img/inline.svg";
import { ReactComponent as PopoverIcon } from "assets/img/popover.svg";
import { ReactComponent as SideDrawerIcon } from "assets/img/side-drawer.svg";
import ShareCard from "./ShareCard";
import LinkTextField from "components/Text/LinkTextField";
import EmbedPreview from "components/EmbedPreview";
import {
  copyToClipboard,
  getContrastYIQ,
  getEmbedJSX,
  getQuestionEmbedJSX,
  QuestionDropDownItem,
  getFrontEndBaseUrl
} from "utils/utils";
import { isAudioType } from "utils/utils";

const EMBED_TYPES = [
  {
    icon: <InlineIcon />,
    type: "inline",
    label: "Inline",
    text: "Embed your survey into an existing web page.",
  },
  {
    icon: <PopoverIcon />,
    type: "popover",
    label: "Popover",
    text: "Launch your survey as a floating popover, triggered by a button.",
    color: true,
  },
  {
    icon: <SideDrawerIcon />,
    type: "sidedrawer",
    label: "Side Drawer",
    text: "Launch your survey as a floating drawer, triggered by a tab.",
    color: true,
    helperText: true,
  },
];

let BASE_URL = getFrontEndBaseUrl()

const Share = ({ surveyId, linkClick, questions }) => {
  const [currentEmbed, setCurrentEmbed] = useState({});
  const [tab, setTab] = useState("survey");
  const [helperText, setHelperText] = useState("");
  const [color, setColor] = useState("#62cb88");
  const [colorPickerEl, setColorPickerEl] = useState(undefined);
  const [justCopied, setJustCopied] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [questionEmbedStyle, setQuestionEmbedStyle] = useState({
    recordButtonColor: "red",
    recordButtonTextColor: "white",
    size: "large",
    theme: "square",
    spacing: "10px",
  });
  const [recordColorEl, setRecordColorEl] = useState(false);
  const [recordTextColorEl, setRecordTextColorEl] = useState(false);
  const [questionEmbedBehavior, setQuestionEmbedBehavior] = useState({
    backupText: false,
    audioRecordingFormat: "WAV",
    startRecordingImmediately: false,
    micCheck: false,
    autoSubmit: false,
    showCounter: true,
    showPlayback: true,
    platform: "",
  });
  const [questionEmbedText, setQuestionEmbedText] = useState({
    recordText: "Record",
    rerecordText: "Re-Record",
    submitText: "Submit",
    doneText: "Done!",
  });
  const PRESET_COLORS = [
    "#62cb88",
    "#D0021B",
    "#F5A623",
    "#F8E71C",
    "#8B572A",
    "#7ED321",
    "#417505",
    "#BD10E0",
    "#9013FE",
    "#4A90E2",
    "#50E3C2",
    "#B8E986",
    "#000000",
    "#4A4A4A",
    "#9B9B9B",
    "#FFFFFF",
  ];
  const debounceHelperText = debounce((text) => setHelperText(text), 500);
  const handleChange = (e) => {
    debounceHelperText(e.target.value);
  };
  const debounceText = debounce((text, field) => {
    let newText = { ...questionEmbedText };
    newText[field] = text;
    setQuestionEmbedText(newText);
  }, 500);

  const handlePreview = (embed) => (_, isExpanded) => {
    setCurrentEmbed(isExpanded ? embed : {});
    if (justCopied) {
      setJustCopied(false);
    }
  };

  const closePreview = () => {
    setCurrentEmbed({});
  };

  // Copyable code for embedding a full survey.
  const getEmbedFullSurveyCodeStr = (embed) => {
    return renderToStaticMarkup(
      getEmbedJSX({
        phonicEmbed: embed,
        surveyId,
        color,
        helperText,
      })
    );
  };

  // Copyable code for embedding a single question.
  const getEmbedQuestionCodeStr = (questionId, type, prompt) => {
    return renderToStaticMarkup(
      getQuestionEmbedJSX({
        surveyId,
        questionId,
        type,
        prompt,
        style: questionEmbedStyle,
        behavior: questionEmbedBehavior,
        text: questionEmbedText,
      })
    );
  };

  const copyCode = (embed) => {
    copyToClipboard(getEmbedFullSurveyCodeStr(embed));
    setJustCopied(true);
    setTimeout(() => {
      setJustCopied(false);
    }, 2000);
  };

  const copyQuestionCode = (questionId, type, text) => {
    copyToClipboard(getEmbedQuestionCodeStr(questionId, type, text));
    setJustCopied(true);
    setTimeout(() => {
      setJustCopied(false);
    }, 2000);
  };

  const getBehaviorMenuJSX = () => {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className="bottom-border"
        >
          <div className="py-1">Behavior</div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <span className="share-toggle-header">Show Counter</span>
              <br />
              <span className="documentation-help light">
                <p>Display a timer counting up during recording</p>
              </span>
            </Grid>
            <Grid item xs={3}>
              <Switch
                color="primary"
                checked={questionEmbedBehavior.showCounter}
                onChange={(e) =>
                  setQuestionEmbedBehavior({
                    ...questionEmbedBehavior,
                    showCounter: e.target.checked,
                  })
                }
              />
            </Grid>
            <Grid item xs={9}>
              <span className="share-toggle-header">Show Playback</span>
              <br />
              <span className="documentation-help light">
                <p>Allow the user to play back their audio afterwards</p>
              </span>
            </Grid>
            <Grid item xs={3}>
              <Switch
                color="primary"
                checked={questionEmbedBehavior.showPlayback}
                onChange={(e) =>
                  setQuestionEmbedBehavior({
                    ...questionEmbedBehavior,
                    showPlayback: e.target.checked,
                  })
                }
              />
            </Grid>
            <Grid item xs={9}>
              <span className="share-toggle-header">Auto Submit</span>
              <br />
              <span className="documentation-help light">
                <p>
                  Submits automatically when recording is stopped, preventing
                  re-recording
                </p>
              </span>
            </Grid>
            <Grid item xs={3}>
              <Switch
                color="primary"
                checked={questionEmbedBehavior.autoSubmit}
                onChange={(e) =>
                  setQuestionEmbedBehavior({
                    ...questionEmbedBehavior,
                    autoSubmit: e.target.checked,
                  })
                }
              />
            </Grid>
            {isAudioType(currentQuestion.type) && (
              <>
                <Grid item xs={9}>
                  <span className="share-toggle-header">
                    Enable Backup Text
                  </span>
                  <br />
                  <span className="documentation-help light">
                    <p>Let respondents answer with either voice or text</p>
                  </span>
                </Grid>
                <Grid item xs={3}>
                  <Switch
                    color="primary"
                    checked={questionEmbedBehavior.backupText}
                    onChange={(e) => {
                      setQuestionEmbedBehavior({
                        ...questionEmbedBehavior,
                        backupText: e.target.checked,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={9}>
                  <span className="share-toggle-header">
                    Start Recording Immediately
                  </span>
                  <br />
                  <span className="documentation-help light">
                    <p>
                      Begins recording immediately. This is only available for
                      WAV (not MP3)
                    </p>
                  </span>
                </Grid>
                <Grid item xs={3}>
                  <Switch
                    color="primary"
                    checked={questionEmbedBehavior.startRecordingImmediately}
                    onChange={(e) =>
                      setQuestionEmbedBehavior({
                        ...questionEmbedBehavior,
                        startRecordingImmediately: e.target.checked,
                      })
                    }
                    disabled={
                      questionEmbedBehavior.audioRecordingFormat === "MP3"
                    }
                  />
                </Grid>
                <Grid item xs={9}>
                  <span className="share-toggle-header">Mic Check</span>
                  <br />
                  <span className="documentation-help light">
                    <p>
                      Lets respondents verify their microphone works without
                      submitting a response
                    </p>
                  </span>
                </Grid>
                <Grid item xs={3}>
                  <Switch
                    color="primary"
                    checked={questionEmbedBehavior.micCheck}
                    onChange={(e) =>
                      setQuestionEmbedBehavior({
                        ...questionEmbedBehavior,
                        micCheck: e.target.checked,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className="share-toggle-header">
                    Audio Recording Format
                  </div>
                  <ToggleButtonGroup
                    exclusive
                    value={questionEmbedBehavior.audioRecordingFormat}
                    onChange={(e, v) => {
                      setQuestionEmbedBehavior({
                        ...questionEmbedBehavior,
                        audioRecordingFormat: v
                          ? v
                          : questionEmbedBehavior.audioRecordingFormat,
                        startRecordingImmediately:
                          v === "MP3"
                            ? false
                            : questionEmbedBehavior.startRecordingImmediately,
                      });
                    }}
                    style={{ display: "block" }}
                  >
                    <ToggleButton value={"WAV"} style={{ width: "50%" }}>
                      WAV
                    </ToggleButton>
                    <ToggleButton value={"MP3"} style={{ width: "50%" }}>
                      MP3
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12}>
                  <span className="share-toggle-header">Platform</span>
                  <br />
                  <span className="documentation-help light">
                    <p>
                      Selecting a platform makes the widget question mandatory.
                      If enabled, the platform skip/next buttons are disabled
                      and the Submit button advances to the next question.{" "}
                      <a
                        href="https://docs.phonic.ai/widget-integrations"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Click here
                      </a>{" "}
                      for more info on widget integrations.
                    </p>
                  </span>
                  <Select
                    fullWidth
                    variant="outlined"
                    value={questionEmbedBehavior.platform}
                    onChange={(e) =>
                      setQuestionEmbedBehavior({
                        ...questionEmbedBehavior,
                        platform: e.target.value,
                      })
                    }
                  >
                    <MenuItem value="qualtrics">Qualtrics</MenuItem>
                    <MenuItem value="decipher">Decipher</MenuItem>
                    <MenuItem value="spss">SPSS</MenuItem>
                    <MenuItem value={null}>Something else</MenuItem>
                  </Select>
                </Grid>
              </>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };

  const getStyleMenuJSX = () => {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className="bottom-border"
        >
          <div className="py-1">Style</div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {isAudioType(currentQuestion.type) && (
              <>
                <Grid item xs={12} sm={6}>
                  <div className="color-preview-wrapper">
                    <div className="share-toggle-header">
                      Record Button Color
                    </div>
                    <div
                      className="color-preview-tile"
                      style={{
                        backgroundColor: questionEmbedStyle.recordButtonColor,
                      }}
                      onClick={(e) => setRecordColorEl(e.target)}
                    >
                      <div
                        aria-hidden="true"
                        style={{
                          backgroundColor: getContrastYIQ(color, 170),
                        }}
                        className="triangle"
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="color-preview-wrapper">
                    <div className="share-toggle-header">
                      Record Button Text Color
                    </div>
                    <div
                      className="color-preview-tile"
                      style={{
                        backgroundColor:
                          questionEmbedStyle.recordButtonTextColor,
                      }}
                      onClick={(e) => setRecordTextColorEl(e.target)}
                    >
                      <div
                        aria-hidden="true"
                        style={{
                          backgroundColor: getContrastYIQ(color, 170),
                        }}
                        className="triangle"
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="share-toggle-header">Size</div>
                  <ToggleButtonGroup
                    exclusive
                    value={questionEmbedStyle.size}
                    onChange={(e, v) => {
                      setQuestionEmbedStyle({
                        ...questionEmbedStyle,
                        size: v ? v : questionEmbedStyle.size,
                      });
                    }}
                    style={{ display: "block" }}
                  >
                    <ToggleButton value={"large"} style={{ width: "50%" }}>
                      Large
                    </ToggleButton>
                    <ToggleButton value={"small"} style={{ width: "50%" }}>
                      Small
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12}>
                  <div className="share-toggle-header">Style</div>
                  <ToggleButtonGroup
                    exclusive
                    value={questionEmbedStyle.theme}
                    onChange={(e, v) => {
                      setQuestionEmbedStyle({
                        ...questionEmbedStyle,
                        theme: v ? v : questionEmbedStyle.theme,
                      });
                    }}
                    style={{ display: "block" }}
                  >
                    <ToggleButton value={"square"} style={{ width: "50%" }}>
                      Square
                    </ToggleButton>
                    <ToggleButton value={"round"} style={{ width: "50%" }}>
                      Round
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <div className="share-toggle-header">Spacing</div>
              <Slider
                value={parseInt(questionEmbedStyle.spacing)}
                onChange={(e, v) => {
                  setQuestionEmbedStyle({
                    ...questionEmbedStyle,
                    spacing: v + "px",
                  });
                }}
                min={0}
                max={50}
                valueLabelDisplay="auto"
                valueLabelFormat={(num) => num + "px"}
                marks
              />
            </Grid>
          </Grid>
        </AccordionDetails>
        <Popover
          open={Boolean(recordColorEl)}
          anchorEl={recordColorEl}
          onClose={() => setRecordColorEl(false)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <SketchPicker
            color={questionEmbedStyle.recordButtonColor}
            onChangeComplete={(color) =>
              setQuestionEmbedStyle({
                ...questionEmbedStyle,
                recordButtonColor: color.hex,
              })
            }
            presetColors={PRESET_COLORS}
          />
        </Popover>
        <Popover
          open={Boolean(recordTextColorEl)}
          anchorEl={recordTextColorEl}
          onClose={() => setRecordTextColorEl(false)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <SketchPicker
            color={questionEmbedStyle.recordTextButtonColor}
            onChangeComplete={(color) =>
              setQuestionEmbedStyle({
                ...questionEmbedStyle,
                recordButtonTextColor: color.hex,
              })
            }
            presetColors={PRESET_COLORS}
          />
        </Popover>
      </Accordion>
    );
  };

  const getTextMenuJSX = () => {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className="bottom-border"
        >
          <div className="py-1">Text</div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Record Button Text"
                defaultValue={questionEmbedText.recordText}
                onChange={(e) => debounceText(e.target.value, "recordText")}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Re-Record Button Text"
                defaultValue={questionEmbedText.rerecordText}
                onChange={(e) => debounceText(e.target.value, "rerecordText")}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Submit Button Text"
                defaultValue={questionEmbedText.submitText}
                onChange={(e) => debounceText(e.target.value, "submitText")}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Done Text"
                defaultValue={questionEmbedText.doneText}
                onChange={(e) => debounceText(e.target.value, "doneText")}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <>
      <Grid id="share-screen" container spacing={2} alignContent="flex-start">
        <Grid item xs={12} style={{ maxHeight: "200px" }}>
          <ShareCard
            onClick={(e) => linkClick(e, surveyId)}
            icon={
              <FontAwesomeIcon
                icon={faLink}
                size="2x"
                className="mr-2"
                style={{ color: "#1d1c29" }}
              />
            }
            title="Copy Link"
          >
            <LinkTextField
              id={`${surveyId}-SHARE}`}
              label="Survey Link"
              link={`${BASE_URL}/${surveyId}`}
              onClick={(e) => linkClick(e, surveyId)}
            />
          </ShareCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <Tabs
              id="embed-tabs"
              className="create-survey-tabs nomargin"
              value={tab}
              onChange={(e, v) => {
                setTab(v);
                setCurrentEmbed({});
                setCurrentQuestion("");
              }}
              TabIndicatorProps={{ style: { background: "#51a871" } }}
              style={{ marginBottom: 0 }}
            >
              <Tab label="Embed Full Survey" value="survey" />
              <Tab label="Embed a Question" value="question" />
            </Tabs>
            {tab === "survey" && (
              <div className="m-10">
                <Grid container spacing={2}>
                  {EMBED_TYPES.map((embed, i) => (
                    <Grid item xs={12} key={`${embed.type}-${i}`}>
                      <ShareCard
                        onChange={handlePreview(embed)}
                        icon={embed.icon}
                        title={embed.label}
                        text={embed.text}
                        embed
                        className="share-card"
                        expanded={embed.label === currentEmbed.label}
                      >
                        <div className="embed-options">
                          {embed.color && (
                            <div className="color-preview-wrapper">
                              <div>Set button color</div>
                              <div
                                className="color-preview-tile"
                                style={{
                                  backgroundColor: color,
                                }}
                                onClick={(e) => setColorPickerEl(e.target)}
                              >
                                <div
                                  aria-hidden="true"
                                  style={{
                                    backgroundColor: getContrastYIQ(color, 170),
                                  }}
                                  className="triangle"
                                />
                              </div>
                            </div>
                          )}
                          {embed.helperText && (
                            <TextField
                              variant="outlined"
                              label="helper text"
                              defaultValue={helperText}
                              onChange={handleChange}
                            />
                          )}
                        </div>
                      </ShareCard>
                    </Grid>
                  ))}
                  {currentEmbed.type && (
                    <Grid item xs={12} key={`jsxDisplay`}>
                      <pre className="code-block">
                        <code>
                          {getEmbedFullSurveyCodeStr(currentEmbed.type)}
                        </code>
                      </pre>
                      <Button
                        className="preview-outlined-button"
                        variant="outlined"
                        color="primary"
                        onClick={() => copyCode(currentEmbed.type)}
                        style={{ minWidth: "170px" }}
                      >
                        <FontAwesomeIcon icon={faCode} className="mr-1" />
                        {justCopied ? "Copied!" : "Copy embed code"}
                      </Button>
                    </Grid>
                  )}
                  <Popover
                    open={Boolean(colorPickerEl)}
                    anchorEl={colorPickerEl}
                    onClose={() => setColorPickerEl(undefined)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <SketchPicker
                      color={color}
                      onChangeComplete={(color) => setColor(color.hex)}
                      presetColors={PRESET_COLORS}
                    />
                  </Popover>
                </Grid>
              </div>
            )}
            {tab === "question" && (
              <div className="m-10">
                <Grid container spacing={2}>
                  <Grid item xs={12} key="questionDropdown">
                    <div className="py-1">Select question from survey:</div>
                    <Select
                      fullWidth
                      variant="outlined"
                      defaultValue="Select Question"
                      value={currentQuestion}
                      onChange={(e) => setCurrentQuestion(e.target.value)}
                    >
                      {questions.map((q, index) => {
                        return (
                          <MenuItem
                            disabled={
                              // Only strict AUDIO and VIDEO types can be embedded at the moment.
                              q.type !== "AUDIO" && q.type !== "VIDEO"
                            }
                            value={q}
                            key={index}
                          >
                            {QuestionDropDownItem(index + 1, q.text, q.type)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                  {currentQuestion.type && (
                    <>
                      <Grid item xs={12}>
                        {getStyleMenuJSX()}
                        {getBehaviorMenuJSX()}
                        {getTextMenuJSX()}
                      </Grid>
                      <Grid item xs={12} key={`jsxDisplay`}>
                        <pre className="code-block">
                          <code>
                            {getEmbedQuestionCodeStr(
                              currentQuestion.questionId,
                              currentQuestion.type,
                              currentQuestion.text
                            )}
                          </code>
                        </pre>
                        <Button
                          className="preview-outlined-button"
                          variant="outlined"
                          color="primary"
                          onClick={() =>
                            copyQuestionCode(
                              currentQuestion.questionId,
                              currentQuestion.type,
                              currentQuestion.text
                            )
                          }
                          style={{ minWidth: "170px" }}
                        >
                          <FontAwesomeIcon icon={faCode} className="mr-1" />
                          {justCopied ? "Copied!" : "Copy embed code"}
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
              </div>
            )}
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            className={clsx(
              "share-preview",
              !currentEmbed.type && !currentQuestion && "hidden"
            )}
          >
            {tab === "question" && currentQuestion.type && (
              <EmbedPreview
                embedCode={getQuestionEmbedJSX({
                  surveyId,
                  questionId: currentQuestion.questionId,
                  type: currentQuestion.type,
                  prompt: currentQuestion.text,
                  style: questionEmbedStyle,
                  // Platform and lang are null only for preview,
                  // otherwise the widget throws errors.
                  behavior: {
                    ...questionEmbedBehavior,
                    platform: null,
                    lang: null,
                  },
                  text: questionEmbedText,
                  preview: true,
                })}
                embed={currentQuestion.type}
              />
            )}
            {tab === "survey" && currentEmbed.type && (
              <>
                <span className="x-button" onClick={closePreview}>
                  <FontAwesomeIcon icon={faTimes} />
                </span>
                <EmbedPreview
                  embedCode={getEmbedJSX({
                    phonicEmbed: currentEmbed.type,
                    surveyId,
                    color,
                    helperText,
                    showOptions: true,
                    preview: true,
                  })}
                  embed={currentEmbed.type}
                />
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Share;
