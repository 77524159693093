import React, { useState } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  TextField,
} from "@material-ui/core";
import { ClipLoader } from "react-spinners";

export default function CreatePersonPopup({
  open,
  onClose,
  loading,
  onSubmit,
}) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  function submit(e) {
    e.preventDefault();
    onSubmit({ firstName: firstName, lastName: lastName, email: email });
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogContent className="popup-container">
        <h2>New Person</h2>
        <p className="dialog-text">
          Create a person for linking survey responses and files.
        </p>
        <form onSubmit={submit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                autoFocus
                id="First Name"
                type="First Name"
                label="First Name"
                placeholder="First"
                fullWidth
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                autoFocus
                id="Last Name"
                type="Last Name"
                label="Last Name"
                placeholder="Last"
                fullWidth
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                autoFocus
                id="Email"
                type="Email"
                label="Email"
                placeholder="ada@phonic.ai"
                fullWidth
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                style={{ marginBottom: 16, marginTop: 8, float: "right" }}
                disabled={loading}
              >
                {!loading ? (
                  "Create Person"
                ) : (
                  <ClipLoader size={21} color={"white"} />
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}
