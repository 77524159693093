import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip, Divider } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { readableDate } from "utils/utils";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    fontSize: "0.875rem",
    boxShadow: theme.shadows[1],
  },
  text: {
    fontSize: 12,
    color: "#9da7bb",
  },
}));

const FileContext = ({ updateSaving, createdDate }) => {
  const classes = useStyles();
  return (
    <div style={{ color: "#9da7bb" }}>
      {updateSaving ? (
        "Saving..."
      ) : (
        <Tooltip
          title={
            <div className={classes.text}>
              Changes saved
              <Divider />
              Created <div>{readableDate(createdDate)}</div>
            </div>
          }
          classes={{ tooltip: classes.tooltip }}
        >
          <CheckCircleOutlineIcon />
        </Tooltip>
      )}
    </div>
  );
};

export default FileContext;
