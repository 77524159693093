import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  IconButton,
  FormControl,
  OutlinedInput,
  DialogTitle,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

const useStyles = makeStyles({
  closeButton: {
    float: "right",
  },
  backButton: {
    float: "left",
  },
  backButtonText: {
    fontSize: 14,
  },
  titleButton: {
    margin: "0px 8px",
    color: "grey",
  },
  templateFilterSearch: {
    width: 240,
  },
});

export default function TemplateSurveyPopup({
  open,
  handleClose,
  children,
  backToMainView,
  isMainView,
  filterTemplates,
}) {
  const classes = useStyles();
  return (
    <Dialog
      id="builder-popup"
      aria-labelledby="survey creation popup"
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle style={{ borderBottom: "1px lightgrey solid" }}>
        {!isMainView ? (
          <IconButton
            aria-label="go-back"
            className={classes.backButton}
            onClick={backToMainView}
          >
            <KeyboardBackspaceIcon />{" "}
            <span className={classes.backButtonText}>Back to Templates</span>
          </IconButton>
        ) : (
          <FormControl className={classes.templateFilterSearch}>
            <OutlinedInput
              margin="dense"
              startAdornment={<SearchIcon />}
              placeholder="Search Templates..."
              onChange={(e) => filterTemplates(e.target.value)}
            />
          </FormControl>
        )}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <div id="builder-popup-body">{children}</div>
    </Dialog>
  );
}
