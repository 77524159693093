import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";

const StyledCheckbox = withStyles({
  root: {
    "&&:hover": {
      backgroundColor: "transparent",
    },
    cursor: "default",
  },
})(Checkbox);

const StyledRadio = withStyles({
  root: {
    "&&:hover": {
      backgroundColor: "transparent",
    },
    cursor: "default",
  },
})(Radio);

const StyledTypography = withStyles({
  root: {
    cursor: "default",
  },
})(Typography);

const SelectedOptions = ({
  responseData,
  options,
  exclusive,
  customLikertOptions,
}) => {
  if (responseData.every((value) => typeof value !== "boolean")) {
    const labels = customLikertOptions || ["1", "2", "3", "4", "5"];
    return (
      <div className="likert">
        <table>
          <thead>
            <tr>
              <th></th>
              {labels.map((label) => (
                <th key={label}>{label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {options.map((option, i) => (
              <tr key={option}>
                <th>{option}</th>
                {labels.map((label) => (
                  <td key={`${option}-${label}`}>
                    <StyledRadio
                      checked={label === responseData[i]}
                      disableRipple
                      color="default"
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <Grid container spacing={1}>
      {options.map((option, i) => {
        let selection = null;

        if (exclusive) {
          selection = (
            <StyledRadio
              checked={Boolean(responseData[i])}
              name={option}
              disableRipple
              color="default"
            />
          );
        } else {
          selection = (
            <StyledCheckbox
              checked={Boolean(responseData[i])}
              name={option}
              disableRipple
              color="default"
            />
          );
        }

        return (
          <Grid container item xs={6} md={3} key={option} alignItems="center">
            <FormControlLabel
              control={selection}
              label={
                <StyledTypography variant="body2">{option}</StyledTypography>
              }
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default SelectedOptions;
