import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  removeSurveyFromState,
  deleteSurvey,
  updateSurvey,
  duplicateSurvey,
} from "redux/actions";
import { getFrontEndBaseUrl } from "utils/utils";

let BASE_URL = getFrontEndBaseUrl()

const mapDispatchToProps = (dispatch) => {
  return {
    deleteSurvey: (surveyId, shared) =>
      dispatch(deleteSurvey(surveyId, shared)),
    updateSurvey: (surveyId, surveyProps) =>
      dispatch(updateSurvey(surveyId, surveyProps)),
    removeSurveyFromState: (surveyId) =>
      dispatch(removeSurveyFromState(surveyId)),
    duplicateSurvey: (surveyId, redirectFunction) =>
      dispatch(duplicateSurvey(surveyId, redirectFunction)),
  };
};

function SurveyPopupMenu(props) {
  const markCompleted = (event) => {
    event.stopPropagation();
    props.updateSurvey(props.surveyId, { state: "COMPLETED" });
    props.handleCloseMenu();
  };
  const reOpenSurvey = (event) => {
    event.stopPropagation();
    props.updateSurvey(props.surveyId, { state: "PENDING" });
    props.handleCloseMenu();
  };
  const handleClose = (event) => {
    event.stopPropagation();
    props.handleCloseMenu();
  };
  const editSurvey = (event) => {
    event.stopPropagation();
    props.history.push({
      pathname: `/surveys/${props.surveyId}/create`,
    });
  };
  const openSurvey = (event) => {
    event.stopPropagation();
    var surveyUrl = `${BASE_URL}/${props.surveyId}`;
    if (props.surveyState === "DRAFT") {
      surveyUrl += "?staging=true";
    }
    window.open(surveyUrl, "_blank");
  };
  const shareSurvey = (event) => {
    event.stopPropagation();
    props.openShareSurveyPopup();
  };
  const redirectToDrafts = () => {
    props.history.push("/surveys?viewing=drafts");
  };

  const duplicateSurvey = (event) => {
    event.stopPropagation();
    props.duplicateSurvey(props.surveyId, redirectToDrafts);
  };

  const deleteWarningMessage = () => {
    return (
      <ul style={{ paddingLeft: 0 }}>
        <li>Your survey and all its responses will be lost for good</li>
        <li>
          Deleting survey <strong>does not</strong> restore credits consumed by
          responses to this survey
        </li>
      </ul>
    );
  };

  return (
    <Menu
      id={`menu-${props.surveyId}`}
      anchorEl={props.menuAnchorEl}
      keepMounted
      open={Boolean(props.menuAnchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={openSurvey}>Open Survey</MenuItem>
      {props.user &&
        !props.user.readOnly && [
          <MenuItem key="EDIT" onClick={editSurvey}>
            Edit Survey
          </MenuItem>,
          <MenuItem key="DUPLICATE" onClick={duplicateSurvey}>
            Duplicate Survey
          </MenuItem>,
        ]}
      {props.user &&
        !props.user.readOnly && [
          props.surveyState === "PENDING" && (
            <MenuItem key="MARK-COMPLETED" onClick={markCompleted}>
              Mark Completed
            </MenuItem>
          ),
          props.surveyState === "COMPLETED" && (
            <MenuItem key="REPOEN" onClick={reOpenSurvey}>
              Re-open Survey
            </MenuItem>
          ),
          props.surveyState !== "DRAFT" && (
            <MenuItem
              key="OPEN-SHARING"
              onClick={shareSurvey}
              disabled={props.user.tier !== "ENTERPRISE"}
            >
              Team Sharing
            </MenuItem>
          ),
        ]}
      {!props.shared ? (
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            props.openDeletePopup({
              title: "Delete this survey permanently?",
              warning: deleteWarningMessage(),
              surveyId: props.surveyId,
              buttonText: "Delete",
              typeToConfirm: true,
              confirmText: props.surveyName,
              confirmTextPlaceholder: "Survey Name",
              deleteFunction: () => {
                props.deleteSurvey(props.surveyId, false);
                props.handleCloseMenu();
              },
            });
          }}
        >
          Delete Survey
        </MenuItem>
      ) : (
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            props.openDeletePopup({
              title: "Remove this survey from your account?",
              subTitle:
                "Survey responses will not be deleted. Contact the owner of this survey to fully delete all data.",
              buttonText: "Remove",
              deleteFunction: () => props.deleteSurvey(props.surveyId, true),
            });
          }}
        >
          Remove from Account
        </MenuItem>
      )}
    </Menu>
  );
}

export default connect(null, mapDispatchToProps)(withRouter(SurveyPopupMenu));
