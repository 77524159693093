import React from "react";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

import GraphicEqIcon from "@material-ui/icons/GraphicEq";
import VideocamIcon from "@material-ui/icons/Videocam";
import YouTubeIcon from "@material-ui/icons/YouTube";
import ImageIcon from "@material-ui/icons/Image";
import AudiotrackIcon from "@material-ui/icons/Audiotrack";
import MovieIcon from "@material-ui/icons/Movie";
import CodeIcon from "@material-ui/icons/Code";
import ComputerIcon from "@material-ui/icons/Computer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogleDrive } from "@fortawesome/free-brands-svg-icons";
import PropCheckbox from "components/SurveyCreation/PropCheckbox";
import AudioRecorder from "components/Recorders/AudioRecorder";
import VideoRecorder from "components/Recorders/VideoRecorder";
import TextButton from "components/Buttons/TextButton";
import CurrentStimuli from "components/CurrentStimuli";
import CodeEditor from "components/CodeEditor";
import { notification } from "utils/utils";

const typeMap = {
  AUDIO: "audio/*",
  IMAGE: "image/*",
  VIDEO: "video/*",
  YOUTUBE: undefined,
};
const youtubePlaceholder = "Ex. https://www.youtube.com/watch?v=0S6pHex-KCo";
const drivePlaceholder =
  "Ex. https://drive.google.com/file/d/1obVMtDNFgZ2jo335O1Ck_vnoREAfbeZ_/view?usp=sharing";
const youtubeLabel = "Youtube Link";
const driveLabel = "Drive Link";

class StimuliPopup extends React.Component {
  stimuliTypeMap = {
    UPLOAD_AUDIO: "AUDIO",
    UPLOAD_IMAGE: "IMAGE",
    UPLOAD_VIDEO: "VIDEO",
    YOUTUBE: "YOUTUBE",
    GOOGLE_DRIVE: "GOOGLE_DRIVE",
    RECORD_AUDIO: "AUDIO",
    RECORD_VIDEO: "VIDEO",
    HTML: "HTML",
    IFRAME: "IFRAME",
  };
  state = {
    file: null,
    type: this.props.stimuli && this.props.stimuli.type === "HTML" ? "HTML" : "NONE",
    url: "",
    mediaProps: {
      htmlContent: this.props.stimuli && this.props.stimuli.type === "HTML" ? this.props.stimuli.htmlContent : null,
      autoPlay: false 
      },
    mode:
      this.props.stimuli &&
      (this.props.stimuli.type === "AUDIO" ||
        this.props.stimuli.type === "VIDEO" ||
        this.props.stimuli.type === "HTML")
        ? "current"
        : "new",
  };

  componentDidUpdate() {
    if (this.props.stimuli === undefined && this.state.mode === "current") {
      this.setState({ mode: "new" });
    }
  }

  handleToggle = () => {
    if (this.state.mode === "new") {
      this.setState({ mode: "current" });
    } else {
      this.setState({ mode: "new" });
    }
  };
  handleClose = () => {
    this.props.onClose();
  };

  handleSubmit = () => {
    if (
      this.state.type === "NONE" ||
      (!this.state.file && this.state.type === "UPLOAD_AUDIO") ||
      (!this.state.file && this.state.type === "UPLOAD_IMAGE") ||
      (!this.state.file && this.state.type === "UPLOAD_VIDEO") ||
      (!this.state.url && this.state.type === "YOUTUBE") ||
      (!this.state.url && this.state.type === "GOOGLE_DRIVE")
    ) {
      alert("You have not uploaded or provided a stimuli link.");
      return;
    }
    if (this.state.type === "HTML") {
      if (!this.state.mediaProps.htmlContent) {
        alert("You have not provided any HTML.");
        return;
      } else {
        this.props.onSubmit(this.state.type, null, this.state.mediaProps);
      }
    } else if (
      this.state.type === "YOUTUBE" ||
      this.state.type === "GOOGLE_DRIVE" ||
      this.state.type === "IFRAME"
    ) {
      this.props.onSubmit(
        this.state.type,
        this.state.url,
        this.state.mediaProps
      );
    } else {
      if (!this.state.file) {
        notification(
          "No file found.",
          "Please make sure your file is selected correctly and try again.",
          "info"
        );
      }
      if (this.state.file.size > 32000000) {
        var message = "File too large.";
        if (this.state.type === "UPLOAD_VIDEO")
          message +=
            " Try uploading to Youtube or reach out to us at contact@phonic.ai if this is urgent";
        notification("File too large.", message, "info");
        return;
      }
      this.props.onSubmit(
        this.stimuliTypeMap[this.state.type],
        this.state.file,
        this.state.mediaProps
      );
    }
    this.handleClose();
  };

  fileInputChange = (event) => {
    this.setState({ file: event.target.files[0] });
  };
  render() {
    const acceptedTypes = typeMap[this.state.type];
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <div className="image-upload-container">
          <DialogTitle id="form-dialog-title">
            Question {this.props.idx + 1} Media
          </DialogTitle>

          <DialogContent>
            {this.props.stimuli &&
              (this.props.stimuli.type === "AUDIO" ||
                this.props.stimuli.type === "VIDEO") && (
                <TextButton
                  onClick={this.handleToggle}
                  style={{ marginBottom: "16px" }}
                  size="small"
                >
                  {this.state.mode === "current"
                    ? "Replace media"
                    : "Edit existing media"}
                </TextButton>
              )}
            {this.state.mode === "current" && this.props.stimuli.type !== "HTML" && (
              <>
                <div>{this.props.children}</div>
                <CurrentStimuli
                  stimuli={this.props.stimuli}
                  changeStimuliAutoplay={this.props.changeStimuliAutoplay}
                  closePopup={this.handleClose}
                />
              </>
            )}
            {(this.state.mode === "new" || this.props.stimuli.type === "HTML") && (
              <>
                <p>Media Type</p>
                <Select
                  value={this.state.type}
                  onChange={(e) => this.setState({ type: e.target.value })}
                  fullWidth
                  variant="outlined"
                  style={{
                    marginBottom: 10,
                  }}
                  className="media-selection"
                >
                  <MenuItem value="NONE">Choose Media Format</MenuItem>
                  <MenuItem value="UPLOAD_IMAGE">
                    <ImageIcon />
                    &nbsp; Upload Image
                  </MenuItem>
                  <MenuItem value="UPLOAD_AUDIO">
                    <AudiotrackIcon />
                    &nbsp; Upload Audio
                  </MenuItem>
                  <MenuItem value="UPLOAD_VIDEO">
                    <MovieIcon />
                    &nbsp; Upload Video
                  </MenuItem>
                  <MenuItem value="YOUTUBE">
                    <YouTubeIcon /> &nbsp; Embed Youtube Video
                  </MenuItem>
                  <MenuItem value="GOOGLE_DRIVE">
                    <FontAwesomeIcon
                      icon={faGoogleDrive}
                      style={{ fontSize: 18 }}
                    />{" "}
                    &nbsp; Embed Google Drive Video
                  </MenuItem>
                  <MenuItem value="RECORD_AUDIO">
                    <GraphicEqIcon />
                    &nbsp; Record Audio
                  </MenuItem>
                  <MenuItem value="RECORD_VIDEO">
                    <VideocamIcon />
                    &nbsp; Record Video
                  </MenuItem>
                  <MenuItem value="HTML">
                    <CodeIcon />
                    &nbsp; Custom HTML Code
                  </MenuItem>
                  <MenuItem value="IFRAME">
                    <ComputerIcon />
                    &nbsp; Embed Another Website
                  </MenuItem>
                </Select>
                <div>
                  {this.state.type !== "NONE" &&
                    this.state.type.includes("UPLOAD") && (
                      <>
                        <p>Choose your file</p>
                        <form method="post" action="#" id="#">
                          <div className="form-group files">
                            <input
                              type="file"
                              className="form-control"
                              multiple=""
                              accept={acceptedTypes}
                              onChange={this.fileInputChange}
                            />
                          </div>
                        </form>
                      </>
                    )}
                  {this.state.type !== "UPLOAD_IMAGE" &&
                    (this.state.type.includes("UPLOAD") ||
                      this.state.type.includes("RECORD")) && (
                      <div className="transcription-options">
                        <PropCheckbox
                          label={{
                            text: "Autoplay Stimuli",
                            placement: "right",
                          }}
                          update={(e) => {
                            this.setState({
                              mediaProps: {
                                ...this.state.mediaProps,
                                autoPlay: e.target.checked,
                              },
                            });
                          }}
                          value={this.state.mediaProps.autoPlay}
                        />
                      </div>
                    )}
                </div>
                {(this.state.type === "YOUTUBE" ||
                  this.state.type === "GOOGLE_DRIVE") && (
                  <TextField
                    variant="outlined"
                    fullWidth
                    label={
                      this.state.type === "YOUTUBE" ? youtubeLabel : driveLabel
                    }
                    autoFocus
                    placeholder={
                      this.state.type === "YOUTUBE"
                        ? youtubePlaceholder
                        : drivePlaceholder
                    }
                    onChange={(e) => {
                      this.setState({
                        url: e.target.value,
                      });
                    }}
                    value={this.state.url}
                  />
                )}
                {this.state.type === "RECORD_AUDIO" && (
                  <AudioRecorder
                    onSubmit={(file) => {
                      this.setState({ file: file });
                    }}
                  />
                )}
                {this.state.type === "RECORD_VIDEO" && (
                  <VideoRecorder
                    onSubmit={(file) => {
                      this.setState({ file: file });
                    }}
                    replayVideoAutoplayAndLoopOff
                    showReplayControls
                  />
                )}
                {this.state.type === "HTML" && (
                  <CodeEditor
                    language={"javascript"}
                    value={this.state.mediaProps.htmlContent}
                    onValueChange={(code) =>
                      this.setState({
                        mediaProps: {
                          ...this.state.mediaProps,
                          htmlContent: code,
                        },
                      })
                    }
                  />
                )}
                {this.state.type === "IFRAME" && (
                  <TextField
                    variant="outlined"
                    fullWidth
                    placeholder="https://google.com"
                    onChange={(e) => {
                      this.setState({
                        url: e.target.value,
                      });
                    }}
                    value={this.state.url}
                  />
                )}
                {this.state.type !== "NONE" && (
                  <>
                    <div className="dialog-button-wrapper">
                      <Button
                        variant="contained"
                        component="label"
                        onClick={this.handleSubmit}
                        color="primary"
                      >
                        {this.props.stimuli && this.state.type === "HTML" ? "Save Changes and Upload" : "Upload to Phonic"}
                      </Button>
                    </div>
                  </>
                )}
                <div className="cancel-button dialog-button-wrapper">
                  <Button onClick={this.handleClose} color="primary">
                    Cancel
                  </Button>
                </div>
              </>
            )}
          </DialogContent>
        </div>
      </Dialog>
    );
  }
}
export default StimuliPopup;
