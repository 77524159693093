import React from "react";

import BookmarkIcon from "@material-ui/icons/Bookmark";
import BookmarkBorderOutlinedIcon from "@material-ui/icons/BookmarkBorderOutlined";
import { Grid } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

function getStateJSXTile(survey) {
  var stateWord = "",
    stateClass = "",
    stateBackgroundClass = "";
  if (survey.state === "PENDING") {
    stateWord = "Collecting";
    stateClass = "top-status pending";
    stateBackgroundClass = "top-status-background pending";
  } else if (survey.state === "COMPLETED") {
    stateWord = "Completed";
    stateClass = "top-status";
    stateBackgroundClass = "top-status-background";
  } else if (survey.state === "DRAFT") {
    stateWord = "Draft";
    stateClass = "top-status";
    stateBackgroundClass = "top-status-background";
  }
  if (survey.blocked && survey.state !== "DRAFT") {
    stateWord = "Blocked";
    stateClass = "top-status blocked";
    stateBackgroundClass = "top-status-background blocked";
  }
  return (
    <div>
      <div className={`top-status-background ${stateBackgroundClass}`} />
      <div className={`${stateClass}`}>{stateWord}</div>
    </div>
  );
}

export default function SurveyTile({
  survey,
  action,
  updateSurvey,
  handleTriggerMenu,
}) {
  return (
    <Grid
      key={`MYSURVEY-GRID-${survey._id}`}
      item
      onClick={action}
      className="main-survey-tile"
    >
      {getStateJSXTile(survey)}
      <div className="top-hamburger flex-container mt-10">
        <div
          className="rounded-icon-container m-0"
          onClick={(e) => {
            updateSurvey(survey._id, {
              bookmarked: survey.bookmarked ? false : true,
            });
            e.stopPropagation();
          }}
        >
          {survey.bookmarked && <BookmarkIcon />}
          {!survey.bookmarked && <BookmarkBorderOutlinedIcon />}
        </div>
        <div
          className="rounded-icon-container m-0 mr-10"
          onClick={(event) =>
            handleTriggerMenu(
              event,
              survey._id,
              survey.name,
              survey.state,
              survey.shared
            )
          }
        >
          <FontAwesomeIcon
            icon={faEllipsisV}
            aria-controls="simple-menu"
            aria-haspopup="true"
            className="survey-menu-icon"
          />
        </div>
      </div>
      <h3>
        {survey.name.length > 40
          ? survey.name.substring(0, 40) + "..."
          : survey.name}
      </h3>
      <p>
        Responses: <b>{survey.numResponses}</b>
      </p>
      <p className="bottom-date">{survey.createdAt}</p>
    </Grid>
  );
}
