import React from "react";
import { Tabs, Tab, Box } from "@material-ui/core";

import { isSelectionNatureStrict } from "utils/utils";

import Conditions from "./Conditions"
import Selection from "./Selection"
import Options from "./Options"

class CreateLogicPopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tab: 'conditions',
      question: this.props.questions[this.props.questionIdx]
    }
  }

  componentDidUpdate() {
    if (this.state.question !== this.props.questions[this.props.questionIdx])
      this.setState({ question: this.props.questions[this.props.questionIdx] });
  }

  changeTab = (value) => {
    this.setState({...this.state, tab: value })
  }

  tabs() {
    const tabs = [{label: 'Conditions', value: 'conditions'}]

    if(this.state.question && isSelectionNatureStrict(this.state.question.type)) {
      tabs.push({ label: 'Skip', value: 'selection' })
      tabs.push({ label: 'Display Options', value: 'options' })
    }

    if(tabs.length <= 1) { return null }

    return (
      <Tabs value={this.state.tab} onChange={(e, value) => this.changeTab(value) } TabIndicatorProps={{ style: { background: "#51a871" } }}>
        { tabs.map((tab, idx) => <Tab key={idx} {...tab}/>) }
      </Tabs>
    )
  }

  content() {
    const views =  {
      'conditions':  <Conditions {...this.props} question={ this.state.question }/>,
      'selection': <Selection {...this.props} question={ this.state.question }/>,
      'options': <Options {...this.props} question={ this.state.question }/> 
    }

    return views[this.state.tab]
  }

  render() {
    return (
      <>
        { this.tabs() }
        <Box sx={{ p: 1, pt: 3 }}>
          { this.content() }
        </Box>
      </>
    );
  }
}
export default CreateLogicPopup;
