import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Onboarding from "./Onboarding";
import Usage from "./Usage";
import { getUsage, setPageTitle, getCreditInfo } from "redux/actions";
import { Paper, Grid, Tabs, Tab } from "@material-ui/core";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import HomeScreenTile from "components/Tiles/HomeScreenTile";
import Credit from "./Credit";
import OverUsageAlert from "components/Alerts/OverUsageAlert";
import FreeTrialAlert from "components/Alerts/FreeTrialAlert";
import UpgradeConfirmation from "components/Popups/UpgradeConfirmation";

const mapStateToProps = (state) => {
  return {
    user: state.user,
    usage: state.usage,
    creditBalance: state.creditBalance,
  };
};

const mapDispatchToProps = { getUsage, setPageTitle, getCreditInfo };

class Home extends Component {
  state = { tab: "onboarding", upgradeConfirmationPopup: false };

  componentDidMount() {
    this.props.setPageTitle(null);
    this.props.getCreditInfo();
    this.props.getUsage({
      collection: "survey-sessions",
      userKey: "surveys",
      documentKey: "surveyId",
      dateRange: "30",
    });
    this.props.getUsage({
      collection: "files",
      userKey: "fileCollections",
      documentKey: "collection",
      dateRange: "30",
    });
    this.props.getUsage({
      collection: "showreels",
      userKey: "showreels",
      documentKey: "_id",
      dateRange: "30",
    });
    // Check if an upgrade was just made.
    const newTier = new URLSearchParams(this.props.location.search).get(
      "newTier"
    );
    const oldTier = new URLSearchParams(this.props.location.search).get(
      "oldTier"
    );
    if (newTier && oldTier) {
      this.setState({
        upgradeConfirmationPopup: true,
        newTier: newTier,
        oldTier: oldTier,
      });
      this.props.history.push("/home");
    }

    // TODO: Remove this.
    const createdSurvey =
      this.props.user &&
      this.props.user.surveys &&
      this.props.user.surveys.length > 1;
    const createdConversation =
      this.props.user &&
      this.props.user.fileCollections &&
      this.props.user.fileCollections.length > 0;
    const createdShowreel =
      this.props.user &&
      this.props.user.showreels &&
      this.props.user.showreels.length > 1;
    if (createdSurvey && createdConversation && createdShowreel)
      this.setState({ tab: "home" });
  }

  routeToPlans = () => this.props.history.push("/plans");

  render() {
    const showTrialEndingAlert =
      this.props.user &&
      this.props.user.tier === "FREE" &&
      this.props.user.trialDaysLeft >= 0 &&
      this.props.user.trialDaysLeft <= 3;

    const showOverUsageAlert =
      !showTrialEndingAlert && this.props.user && this.props.creditBalance <= 0;

    return (
      <>
        <Helmet>
          <title>Home</title>
        </Helmet>
        <UpgradeConfirmation
          open={this.state.upgradeConfirmationPopup}
          handleClose={() => this.setState({ upgradeConfirmationPopup: false })}
          newTier={this.state.newTier}
          oldTier={this.state.oldTier}
        />
        <div id="home-screen">
          <Tabs
            id="tabs"
            className="create-survey-tabs nomargin"
            value={this.state.tab}
            onChange={(e, v) => this.setState({ tab: v })}
            TabIndicatorProps={{ style: { background: "#51a871" } }}
          >
            <Tab label="Onboarding" value="onboarding" />
            <Tab label="Home" value="home" />
            <Tab label="Credits" value="credit" />
          </Tabs>
          {showTrialEndingAlert && (
            <div style={{ margin: 20 }}>
              <FreeTrialAlert
                user={this.props.user}
                routeToPlans={this.routeToPlans}
              />
            </div>
          )}
          {showOverUsageAlert && (
            <div style={{ margin: 20 }}>
              <OverUsageAlert
                creditExpiryDate={this.props.user.creditsExpired}
                routeToPlans={this.routeToPlans}
              />
            </div>
          )}
          {this.state.tab === "onboarding" && (
            <Grid container>
              <Grid item xs={12}>
                <Onboarding user={this.props.user} />
              </Grid>
            </Grid>
          )}
          {this.state.tab === "home" && (
            <Grid
              container
              spacing={3}
              style={{
                margin: 0,
                width: "100%",
                padding: 10,
              }}
            >
              <Usage usage={this.props.usage} />
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <Paper>
                  <div className="tile-header">
                    <h3 className="pt-10 pb-10 m-0">Public Updates</h3>
                  </div>
                  <TwitterTimelineEmbed
                    noHeader
                    sourceType="profile"
                    screenName="phonicAI"
                    options={{ height: 400 }}
                  />
                </Paper>
              </Grid>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <HomeScreenTile
                  src={require("assets/img/home-screen/3ddocs_green.png")}
                  title="Visit our documentation"
                  description="Have a question about something in Phonic? Browse our Frequently Asked Questions and other documentation."
                  action={() => window.open("https://docs.phonic.ai", "_blank")}
                />
              </Grid>
            </Grid>
          )}
          {this.state.tab === "credit" && <Credit />}
        </div>
      </>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
