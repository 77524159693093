import React from "react";
import { Button } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { dateToString } from "utils/utils";

export default class OverUsageAlert extends React.Component {
  render() {
    let date = null;
    if (this.props.creditExpiryDate) {
      date = new Date(this.props.creditExpiryDate);
      date.setDate(date.getDate() + 3);
    }

    const expiry_date = date ? dateToString(date) : null;

    return (
      <Alert
        severity={"warning"}
        style={{
          border: "1px #ff9800 solid",
        }}
      >
        <AlertTitle>Whoops! You're out of credits</AlertTitle>
        <div style={{ marginBottom: 12 }}>
          Your surveys will stop collecting responses on {expiry_date}. All
          responses collected over usage are safely stored and can be accessed
          by increasing monthly credit limit
        </div>
        <Button
          className="secondary-button-color"
          margin="normal"
          variant="contained"
          style={{ marginRight: 12 }}
          onClick={this.props.routeToPlans}
        >
          {"Increase Monthly Credits"}
        </Button>
      </Alert>
    );
  }
}
