import React from "react";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Translation from "components/SurveyCreation/Translation";
import { removeItemFromArray } from "utils/utils";
import { updateItemInArray } from "utils/utils";

class TranslationPopup extends React.Component {
  state = {
    translations: [],
    deleteAllClicked: false,
  };

  componentDidMount() {
    const translationInfo = this.props.translationInfo;

    this.setState({
      translations: this.convertTranslationDictIntoList(translationInfo),
    });
  }

  convertTranslationDictIntoList(translationInfo) {
    return translationInfo.map((translationDict) => ({
      text: translationDict.text,
      translations: Object.entries(translationDict).filter(
        ([k, _]) => k !== "text"
      ),
      ident: uuidv4(),
    }));
  }

  convertTranslationListIntoDict(translations) {
    return translations.map((translation) => ({
      text: translation.text,
      ...Object.fromEntries(translation.translations),
    }));
  }

  handleSave = () => {
    this.props.onSubmit(
      this.convertTranslationListIntoDict(this.state.translations)
    );
    this.props.onClose();
  };

  setTranslations = (val) => this.setState({ translations: val });

  debouncedChangeTranslationInfo = _.debounce(this.setTranslations, 150);

  updateTranslation = (idx, translation) => {
    this.debouncedChangeTranslationInfo(
      updateItemInArray(this.state.translations, idx, {
        ...this.state.translations[idx],
        ...translation,
      })
    );
  };

  deleteTranslation = (idx) => {
    this.setState({
      translations: removeItemFromArray(this.state.translations, idx),
    });
  };

  deleteAllTranslations = () => {
    this.setState({
      deleteAllClicked: true,
      translations: [],
    });
  };

  addTranslation = () => {
    const blankTranslation = {
      text: "",
      translations: [["", ""]],
      ident: uuidv4(),
    };
    this.setState({
      translations: this.state.translations.concat(blankTranslation),
      deleteAllClicked: false,
    });
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.onClose}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Edit Translations</DialogTitle>
          <DialogContent>
            {this.state.deleteAllClicked && (
              <DialogContentText className="dialog-text">
                You are about to delete all translations. Are you sure you'd
                like to proceed?
              </DialogContentText>
            )}
            {!this.state.deleteAllClicked &&
              this.state.translations.length === 0 && (
                <DialogContentText className="dialog-text">
                  This survey has no translations. Click Add Translation to add
                  your first survey translation.
                </DialogContentText>
              )}
            <Translation
              updateTranslation={this.updateTranslation}
              deleteTranslation={this.deleteTranslation}
              translations={this.state.translations}
              textEditable={this.props.textEditable}
            />
          </DialogContent>
          {this.props.allowCustomTranslations && !this.state.deleteAllClicked && (
            <DialogActions style={{ justifyContent: "center" }}>
              <Button
                className="gray-outlined-button"
                variant="outlined"
                onClick={this.addTranslation}
                style={{ marginTop: 10 }}
              >
                + Add Translation
              </Button>
            </DialogActions>
          )}
          <DialogActions style={{ justifyContent: "center" }}>
            {this.props.allowCustomTranslations &&
              !this.state.deleteAllClicked &&
              this.state.translations.length > 3 && (
                <Button
                  className="delete-item-button"
                  onClick={this.deleteAllTranslations}
                >
                  Delete All Translations
                </Button>
              )}
            <Button
              className="secondary-button-color"
              variant="contained"
              component="label"
              onClick={this.handleSave}
            >
              {this.state.deleteAllClicked ? "Delete" : "Save"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default TranslationPopup;
