import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
} from "@material-ui/core";
import CodeEditor from "components/CodeEditor";
import { Alert } from "@material-ui/lab";

const DEFAULT_HTML_HEAD = `<script>
document.addEventListener('surveystarted', function(){
  // Triggered when the user clicks Begin.
});
document.addEventListener('surveycompleted', function(){});
document.addEventListener('surveyscreenedout', function(){});
</script>`;

class SurveyHtmlHeadPopup extends React.Component {
  state = {
    htmlHead: DEFAULT_HTML_HEAD,
  };
  componentDidUpdate(prevProps) {
    if (
      prevProps.open !== this.props.open &&
      this.props.open === true &&
      this.props.htmlHead !== undefined
    ) {
      this.setState({ htmlHead: this.props.htmlHead });
    }
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.onClose}
          fullWidth
          maxWidth="lg"
          aria-labelledby="form-dialog-title"
        >
          <DialogContent className="popup-container large">
            <h2 className="dialog-title-small" style={{ marginBottom: 20 }}>
              Survey Event Bindings
            </h2>
            <Alert severity="warning" className="survey-info-alert">
              Phonic doesn't validate custom code for you, so be sure to check
              your work before publishing.
            </Alert>
            <CodeEditor
              language={"js"}
              value={this.state.htmlHead}
              onValueChange={(htmlHead) => this.setState({ htmlHead })}
            />
            <DialogActions>
              <Button
                variant="outlined"
                component="label"
                onClick={(e) => {
                  this.setState({ htmlHead: DEFAULT_HTML_HEAD });
                }}
                className="Mui-Overrides outlined-dark"
              >
                Reset
              </Button>
              <Button
                variant="outlined"
                component="label"
                onClick={(e) => {
                  this.props.onClose(e);
                }}
                className="Mui-Overrides outlined-dark"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                component="label"
                onClick={() => {
                  this.props.onSave(this.state.htmlHead);
                  this.props.onClose();
                }}
                color="primary"
              >
                Save
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default SurveyHtmlHeadPopup;
