import React, { useState, useEffect } from "react";
import { Grid, Button, TextField } from "@material-ui/core";
import {
  addSurvey,
  startLoading,
  getTemplates,
  addSurveyFromCSV,
} from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import TemplateTile from "components/Tiles/TemplateTile";
import { ClipLoader, ScaleLoader } from "react-spinners";
import TemplateSummary from "./TemplateSummary";
import { getBlankSurvey } from "utils/defaults";
import { checkSurveyCount } from "utils/utils";
import _ from "lodash";
import clsx from "clsx";

const CREATE_ICON = require("assets/img/create-survey.png");
const DRAFTS_ICON = require("assets/img/drafts.png");
const CSV_ICON = require("assets/img/upload-csv.png");

function CountedCategory({ name, count, onClick }) {
  return (
    <div className="counted-category" onClick={onClick}>
      <div className="counted-category-left">{name}</div>
      <div className="counted-category-right">{count}</div>
    </div>
  );
}

function TemplateOptionTile(props) {
  const [text, updateText] = useState("");
  const [file, updateFile] = useState(null);
  const submit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    !file && props.onSubmit(text);
    file && props.onSubmit(text, file);
  };
  return (
    <Grid item>
      <div
        className="template-survey-new-outer phonic-green-hover p-20"
        onClick={props.onClick}
      >
        <div className="template-survey-new">
          <div className="template-survey-new-left flex-container">
            <img src={props.src} />
          </div>
          <div className="template-survey-new-right">
            <h3>{props.title}</h3>
            <p>{props.subTitle}</p>
          </div>
        </div>
        <div
          className={clsx(
            props.type === "CSV" && "expandable-large",
            props.type !== "CSV" && "expandable",
            "mt-10",
            props.expanded && "expanded"
          )}
        >
          {props.expanded && (
            <form onSubmit={submit}>
              <TextField
                variant="outlined"
                autoFocus
                label="Survey Name"
                required
                fullWidth
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => updateText(e.target.value)}
              />
              {props.type === "CSV" && (
                <input
                  type="file"
                  className="form-control"
                  onChange={(input) => {
                    var newFiles = [];
                    // Files use special collections that are most easily iterated over with lodash
                    _.forEach(input.target.files, (f) => {
                      newFiles.push(f);
                    });
                    updateFile(newFiles[0]);
                  }}
                  multiple={false}
                  accept="text/csv"
                  required
                  style={{ marginTop: "10px" }}
                />
              )}
              <Button
                color="primary"
                type="submit"
                variant="contained"
                style={{ marginTop: 10 }}
                fullWidth
                onClick={(e) => e.stopPropagation()}
              >
                {props.loading ? (
                  <ClipLoader size={21} color={"white"} />
                ) : (
                  "Create Survey"
                )}
              </Button>
            </form>
          )}
        </div>
      </div>
    </Grid>
  );
}

export default function Templates({
  templateSurveySwitch,
  isMainPage,
  turnOffMainView,
  searchKey,
  user,
  loadingCreateSurvey,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector((state) => state);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [newSurveyExpanded, setNewSurveyExpanded] = useState(false);
  const [csvSurveyExpanded, setCSVSurveyExpanded] = useState(false);
  const [filterCategory, setFilterCategory] = useState(null);

  const groupTemplatesByCategory = (templatesArr) => {
    const categorized = {};
    for (let template of templatesArr) {
      if (categorized[template.templateCategory]) {
        categorized[template.templateCategory].push(template);
      } else {
        categorized[template.templateCategory] = [template];
      }
    }
    return categorized;
  };

  var templatesArr = state.templates;
  var categorizedTemplates = {};
  // Filters
  if (templatesArr) {
    categorizedTemplates = groupTemplatesByCategory(templatesArr);
    // Filter by search box.
    if (searchKey) {
      templatesArr = state.templates.filter((template) => {
        return template.name.toLowerCase().includes(searchKey.toLowerCase());
      });
      state.searchKey = searchKey;
    }
    // Filter by category selection.
    if (state.templates && templatesArr && filterCategory !== null) {
      templatesArr = templatesArr.filter((t) => {
        return t.templateCategory === filterCategory;
      });
    }
  }

  const createSurveyFromTemplate = (templateId) => {
    if (!checkSurveyCount(user)) return;
    dispatch(
      startLoading(() => dispatch(addSurvey({ templateId })), "CreateSurvey")
    );
  };
  const createSurvey = (name) => {
    if (!checkSurveyCount(user)) return;
    dispatch(
      startLoading(
        () =>
          dispatch(
            addSurvey({
              ...getBlankSurvey(),
              name: name,
            })
          ),
        "CreateSurvey"
      )
    );
  };

  const createSurveyFromCSV = (name, file) => {
    if (!checkSurveyCount(user)) return;
    dispatch(
      startLoading(
        () =>
          dispatch(
            addSurveyFromCSV(
              {
                ...getBlankSurvey(),
                name: name,
              },
              file
            )
          ),
        "CreateSurvey"
      )
    );
  };

  const getTemplateDetails = (template) => {
    // sets a template to be feed into TemplateConfirmationView and shuts off mainView (this.state.popupOptions.mainView) in CreateSurvey
    turnOffMainView();
    setSelectedTemplate(template);
  };

  let areTemplatesAvailable = templatesArr && templatesArr.length > 0;
  let templatesLoading = state.loading["GetTemplates"];

  useEffect(() => {
    // setTemplates
    dispatch(startLoading(() => dispatch(getTemplates()), "GetTemplates"));
  }, [dispatch]);

  useEffect(() => {
    // when a survey is created, reroutes only after the id is set to the reducer
    if (state.createSurveyId) {
      history.push(`/surveys/${state.createSurveyId}/create`);
    }
  }, [state.createSurveyId, history]);

  return (
    <>
      {selectedTemplate && !isMainPage ? (
        <TemplateSummary
          template={selectedTemplate}
          createSurveyFromTemplate={createSurveyFromTemplate}
          setSelectedTemplate={setSelectedTemplate}
        />
      ) : (
        <>
          <div id="builder-popup-left">
            <div id="category-container">
              <div className="template-category-wrapper">
                <h4>
                  <b>Templates</b>
                </h4>
                <CountedCategory
                  key="CATEGORY-ALL"
                  name="All Templates"
                  count={12}
                  onClick={() => setFilterCategory(null)}
                />
                {Object.keys(categorizedTemplates).map((category) => {
                  return (
                    <CountedCategory
                      key={`CATEGORY-${category}`}
                      name={category}
                      count={categorizedTemplates[category].length}
                      onClick={() => setFilterCategory(category)}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div id="builder-popup-right">
            <div className="template-category-wrapper">
              <h4>
                <b>Create a custom study</b>
              </h4>
            </div>
            <Grid container spacing={2}>
              <TemplateOptionTile
                createSurveyFromTemplate={getTemplateDetails}
                title="New Study"
                subTitle="Start from scratch and build a study just the way you like it."
                type="NEW"
                onClick={() => {
                  if (csvSurveyExpanded) setCSVSurveyExpanded(false);
                  setNewSurveyExpanded(!newSurveyExpanded);
                }}
                onSubmit={createSurvey}
                expanded={newSurveyExpanded}
                src={CREATE_ICON}
                loading={loadingCreateSurvey}
              />
              <TemplateOptionTile
                title="Continue from Draft"
                subTitle="Pick up where you left off building another study."
                createSurveyFromTemplate={getTemplateDetails}
                type="DRAFT"
                onClick={() => templateSurveySwitch("DRAFTS")}
                src={DRAFTS_ICON}
              />
              <TemplateOptionTile
                createSurveyFromTemplate={getTemplateDetails}
                title="Upload a CSV"
                subTitle={
                  <span className="documentation-help">
                    Upload a new study in a .csv file.{" "}
                    <a href="https://docs.phonic.ai/docs/create-survey-from-csv">
                      Click here
                    </a>{" "}
                    to learn more.
                  </span>
                }
                type="CSV"
                onClick={(e) => {
                  if (newSurveyExpanded) setNewSurveyExpanded(false);
                  e.target.tagName !== "INPUT" &&
                    e.target.tagName !== "A" &&
                    setCSVSurveyExpanded(!csvSurveyExpanded);
                }}
                onSubmit={createSurveyFromCSV}
                expanded={csvSurveyExpanded}
                src={CSV_ICON}
                loading={loadingCreateSurvey}
              />
            </Grid>
            {templatesLoading && !areTemplatesAvailable && (
              <div
                style={{
                  marginLeft: 10,
                  marginTop: 50,
                  marginBottom: 50,
                  width: "100%",
                }}
              >
                <ScaleLoader
                  size={"10px"}
                  color={"#62cb88"}
                  loading={templatesLoading}
                />
              </div>
            )}
            {areTemplatesAvailable && (
              <>
                <div className="template-category-wrapper">
                  <h4>
                    <b>Start from a template</b>
                  </h4>
                </div>
                <Grid container alignContent="stretch" spacing={2} className="">
                  {templatesArr.map((template) => {
                    return (
                      <TemplateTile
                        key={template["_id"]}
                        action={getTemplateDetails}
                        name={template.name}
                        image={template.templateImageUrl}
                        template={template}
                        description={template.templateDescription}
                      />
                    );
                  })}
                </Grid>
              </>
            )}
            {!areTemplatesAvailable && !templatesLoading && (
              <div>
                <p className="no-results-text">There are no results...</p>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}
