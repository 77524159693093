import React, { Component } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { uploadQuestionStimuli } from "redux/actions";
import NewQuestionPopup from "./Popups/NewQuestionPopup";
import SurveyQuestion from "./SurveyQuestion";
import { Button, Chip, Input } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faPlus } from "@fortawesome/free-solid-svg-icons";

const mapStateToProps = (state) => {
  return {
    loadingQuestionMedia: state.loadingQuestionMedia,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadQuestionStimuli: (
      surveyId,
      surveyQuestions,
      stimuliType,
      file,
      mediaProps
    ) =>
      dispatch(
        uploadQuestionStimuli(
          surveyId,
          surveyQuestions,
          stimuliType,
          file,
          mediaProps
        )
      ),
  };
};

class QuestionList extends Component {
  /* Using shouldComponentUpdate to avoid rendering of all Questions when a
  Drag is initiated

  See the following link for explanation:
  https://egghead.io/lessons/react-optimize-performance-in-react-beautiful-dnd-with-shouldcomponentupdate-and-purecomponent
  */
  shouldComponentUpdate(nextProps) {
    if (nextProps.questionList === this.props.questionList) {
      return false;
    }
    return true;
  }

  render() {
    return this.props.questionList.map((question, idx) => {
      const isQuestionSelected =
        this.props.selectedQuestionId === question.questionId;
      const draggableId = `DRAGGABLE-GROUP-QUESTION-${question.questionId}`;
      return (
        <Draggable key={draggableId} draggableId={draggableId} index={idx}>
          {(provided) => {
            const style = {
              position: "relative",
              zIndex: isQuestionSelected ? "1" : "10",
              ...provided.draggableProps.style,
            };
            return (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={style}
              >
                <SurveyQuestion
                  key={`QUESTION-${question.questionId}`}
                  id={question.questionId}
                  idx={this.props.idx + idx}
                  qinfo={question}
                  info={this.props.info}
                  groupId={this.props.groupId}
                  groupType={this.props.groupType}
                  modFunctions={this.props.modFunctions}
                  borderBottom={this.props.borderBottom}
                  surveyId={this.props.surveyId}
                  tier={this.props.tier}
                  addQuestionWithPopup={this.props.addQuestionWithPopup}
                  selected={isQuestionSelected}
                  questionFocusOverride={this.props.questionFocusOverride}
                  // Hide the add question popup during drag
                  // actions to prevent layering issues
                  hideAddQuestionPopup={
                    this.props.droppableSnapshot.isDraggingOver
                  }
                  openLogicPopup={this.props.openLogicPopup}
                />
              </div>
            );
          }}
        </Draggable>
      );
    });
  }
}
class SurveyQuestionGroup extends Component {
  state = {
    addQuestionPopup: false,
    questionGroup: this.props.questionGroup,
    updateTimeout: 0,
    editFrameLink: false,
  };

  addQuestionWithPopup = (idx) => {
    this.setState({ addQuestionPopup: true, addQuestionIdx: idx });
  };

  updateParent = () => {
    this.props.modFunctions.updateQuestionGroup(
      this.state.questionGroup,
      this.props.qinfo[0].questionId,
      true
    );
  };

  // Update the local state and set a timeout to update the parent too
  updateQuestionGroupPropWithTimedUpdate = (prop, value) => {
    if (this.state.updateTimeout) {
      clearTimeout(this.state.updateTimeout);
    }
    this.setState({
      questionGroup: {
        ...this.state.questionGroup,
        [prop]: value,
      },
      updateTimeout: setTimeout(() => {
        this.updateParent();
      }, 500),
    });
  };

  render() {
    const droppableId = `GROUP-DROPPABLE-${this.props.questionGroup.id}`;
    return (
      <>
        <NewQuestionPopup
          key={this.props.questionGroup.id}
          open={this.state.addQuestionPopup}
          handleClose={() => this.setState({ addQuestionPopup: false })}
          modFunctions={this.props.modFunctions}
          idx={this.state.addQuestionIdx}
          groupId={this.props.questionGroup.id}
        />
        <Droppable
          droppableId={droppableId}
          isDropDisabled={this.props.sourceDroppable !== droppableId}
        >
          {(provided, droppableSnapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <div className="builder-question-group">
                <div
                  className="group-line"
                  {...this.props.providedDragHandleProps}
                />
                <div className="group-content">
                  <div
                    className="group-info-section"
                    {...this.props.providedDragHandleProps}
                  >
                    <Button
                      className="new-question-button"
                      onClick={() =>
                        this.addQuestionWithPopup(
                          this.props.idx + this.props.qinfo.length
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                    <Input
                      multiline
                      autoFocus
                      disableUnderline
                      className="Mui-Overrides text"
                      placeholder="Mission Title..."
                      value={this.state.questionGroup.title}
                      onChange={(e) =>
                        this.updateQuestionGroupPropWithTimedUpdate(
                          "title",
                          e.target.value
                        )
                      }
                    />
                    <div style={{ margin: "5px 0px" }}>
                      {this.state.questionGroup.frameLink &&
                      !this.state.editFrameLink ? (
                        <Chip
                          icon={<FontAwesomeIcon icon={faLink} />}
                          label={this.state.questionGroup.frameLink}
                          style={{ maxWidth: 200 }}
                          onClick={() => {
                            this.setState({ editFrameLink: true });
                          }}
                        />
                      ) : (
                        <Input
                          multiline
                          autoFocus
                          disableUnderline
                          className="Mui-Overrides sub text"
                          placeholder="Link of App or Prototype: https://..."
                          value={this.state.questionGroup.frameLink}
                          onChange={(e) => {
                            if (!this.state.editFrameLink)
                              this.setState({ editFrameLink: true });
                            const value = e.target.value;
                            this.updateQuestionGroupPropWithTimedUpdate(
                              "frameLink",
                              value
                            );
                          }}
                          onBlur={() => {
                            if (
                              this.state.questionGroup.frameLink &&
                              !this.state.questionGroup.frameLink.startsWith(
                                "https://"
                              )
                            )
                              this.updateQuestionGroupPropWithTimedUpdate(
                                "frameLink",
                                "https://" + this.state.questionGroup.frameLink
                              );
                            this.setState({ editFrameLink: false });
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div {...provided.droppableProps}>
                    <QuestionList
                      idx={this.props.idx}
                      groupId={this.props.questionGroup.id}
                      groupType={this.props.questionGroup.type}
                      questionList={this.props.qinfo}
                      modFunctions={this.props.modFunctions}
                      borderBottom={this.props.borderBottom}
                      surveyId={this.props.surveyId}
                      tier={this.props.tier}
                      addQuestionWithPopup={this.addQuestionWithPopup}
                      droppableSnapshot={droppableSnapshot}
                      openLogicPopup={this.props.openLogicPopup}
                      selectedQuestionId={this.props.selectedQuestionId}
                      info={this.props.info}
                    />
                    {provided.placeholder}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Droppable>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyQuestionGroup);
