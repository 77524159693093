import React, { useState } from "react";
import Input from "@material-ui/core/Input";
import ListItem from "@material-ui/core/ListItem";

const SpeakerSubmenu = ({ speaker, updateSpeaker }) => {
  const [value, setValue] = useState(speaker.name);

  const handleChange = (e) => setValue(e.target.value);
  const handleBlur = () => {
    updateSpeaker({ name: value });
  };

  return (
    <ListItem>
      <Input
        value={value}
        onChange={handleChange}
        fullWidth
        inputProps={{ "aria-label": "edit speaker name", maxLength: 100 }}
        autoFocus
        onBlur={handleBlur}
      />
    </ListItem>
  );
};

export default SpeakerSubmenu;
