import React from "react";
import SignInForm from "views/SignInForm";
import { withRouter } from "react-router-dom";
import firebase from "firebase/app";
import { addAuthToken } from "redux/actions";
import { connect } from "react-redux";
import { trackPage } from "../analytics";
import { getOrganizationLogo, addUser } from "redux/actions";
import { Helmet } from "react-helmet";

const mapStateToProps = (state) => {
  return {
    authToken: state.authToken,
    organization: state.organization,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAuthToken: () => dispatch(addAuthToken()),
    addUser: (data) => dispatch(addUser(data)),
    getOrganizationLogo: (organizationId) =>
      dispatch(getOrganizationLogo(organizationId)),
  };
};

class SignIn extends React.Component {
  state = { email: "", password: "" };

  componentDidMount() {
    // TODO: This is a bit of a hack, since the easiest way to ensure a full auth
    // reset at signin (no stale cookies, for ex.) is to just reload the page.
    if (this.props.history.action === "PUSH") window.location.reload(true);

    trackPage("SignIn");

    firebase
      .app()
      .auth()
      .onAuthStateChanged((user) => {
        if (user) this.props.addAuthToken();
      });

    if (this.props.match.params.organization) {
      this.props.getOrganizationLogo(this.props.match.params.organization);
    }
  }
  componentDidUpdate = () => {
    if (this.props.authToken) this.routeToSurveys();
  };

  routeToSignUp = () => {
    if (this.props.match.params.organization) {
      this.props.history.push(
        `/${this.props.match.params.organization}/signup`
      );
      return;
    }
    this.props.history.push("/signup");
  };

  routeToSurveys = () => {
    this.props.history.push("/home");
  };

  signIn = (e) => {
    e.preventDefault();
    firebase
      .app()
      .auth()
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then(() => {
        this.props.addAuthToken();
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  addUserGoogle = (googleUser) => {
    const user = {
      firstName: googleUser.displayName.split(" ")[0],
      lastName: googleUser.displayName.split(" ")[1],
      email: googleUser.email,
      subscribed: this.state.subscribed,
    };
    this.props.addUser(user);
    this.props.addAuthToken();
  };

  googleSignIn = () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        var user = result.user;
        this.addUserGoogle(user);
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  formChange = (key, event) => {
    event.persist(); //Events are re-used, this stops fields from nullifying.
    if (key === "email") {
      this.setState({ email: event.target.value });
    } else if (key === "password") {
      this.setState({ password: event.target.value });
    } else if (key === "forgot-password") {
      firebase
        .app()
        .auth()
        .sendPasswordResetEmail(this.state.email)
        .then(() => {
          alert(`An password reset email was sent to ${this.state.email}.`);
        })
        .catch(() => {
          alert(
            `We were unable to issue a password reset email. Please contact us: help@phonic.ai`
          );
        });
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Sign In</title>
        </Helmet>
        <SignInForm
          routeToSignUp={this.routeToSignUp}
          signIn={this.signIn}
          formChange={this.formChange}
          organization={this.props.organization}
          organizationId={this.props.match.params.organization}
          googleSignIn={this.googleSignIn}
        />
      </>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn));
