import React from "react";
import Popover from "@material-ui/core/Popover";
import { withRouter } from "react-router";
import { Chip } from "@material-ui/core";
import { toTitleCase } from "utils/utils";

function SearchBar(props) {
  const filterSurveys = (needle, surveys) => {
    var searchResults = [];
    if (surveys && needle) {
      searchResults = Object.values(surveys).filter((s) => {
        if (s && s.name && s.name.toLowerCase().includes(needle.toLowerCase()))
          return true;
        return false;
      });
    }
    return searchResults;
  };

  const routeToSurvey = (state, id) => {
    if (state === "DRAFT") {
      props.history.push(`/surveys/${id}/create`);
      props.handleClose();
      return;
    }
    props.history.push(`/surveys/${id}`);
    props.handleClose();
  };

  const mapSurveys = () => {
    var s = [];
    if (props.surveys) s = { ...props.surveys };
    if (props.drafts) s = { ...s, ...props.drafts };
    var surveys = filterSurveys(props.searchText, s);

    if (!surveys || surveys.length === 0)
      return (
        <div className="search-result final">
          <div>
            <h2 className="search-result">No results found</h2>
          </div>
        </div>
      );
    return surveys.map((s, idx) => {
      if (!s) return null;
      return (
        <div
          className={
            idx !== surveys.length - 1 ? "search-result" : "search-result final"
          }
          onClick={() => routeToSurvey(s.state, s._id)}
          key={`SEARCHBAR-RESULT-${s._id}`}
        >
          <div>
            <h2 className="search-result">{s.name}</h2>
            <Chip
              label={toTitleCase(s.state)}
              size="small"
              className={`${s.state === "PENDING" && "pending"} ${
                s.state === "COMPLETED" && "completed"
              }`}
            />
          </div>
        </div>
      );
    });
  };
  return (
    <div>
      <Popover
        open={props.open}
        anchorEl={props.anchorEl}
        onClose={props.handleClose}
        disableAutoFocus
        disableEnforceFocus
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{ marginTop: 20, maxHeight: 600 }}
      >
        {mapSurveys()}
      </Popover>
    </div>
  );
}

export default withRouter(SearchBar);
