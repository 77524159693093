import React, { Component } from "react";
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  IconButton
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
export default class CrosstabChart extends Component {
  // Cell colors are white when incidence is normal, greener when it is unusally high,
  // and redder when it is unusually low.
  getCellColor = (actualIR, numClasses) => {
    const THRESHOLD = 0.1;
    const expectedIR = 1.0 / numClasses;
    var x = 1.0;
    var color = [255, 255, 255];
    if (actualIR >= (1 + THRESHOLD) * expectedIR) {
      color = [112, 220, 140];
      x = 1 - expectedIR / actualIR;
    } else if (actualIR <= expectedIR / (1 + THRESHOLD)) {
      color = [255, 28, 28];
      x = 1 - actualIR / expectedIR;
      x = x / 3.0; // The red is very strong and requires extra alpha;
    }
    var rgba = `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${x})`;
    return rgba;
  };

  render() {
    return (
      <div className="crosstab-container">
        <div className="graph-title">
          <span>{this.props.title}</span>
          <IconButton
            aria-label="close"
            onClick={() => {this.props.deleteCrossTab(this.props.idx)}}
            style={{
              float: "right",
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {this.props.chart.data.v2Labels.map((label, labelIdx) => (
                <TableCell key={`CROSSTAB-TH-${this.props.idx}-${labelIdx}`}>{label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.chart.data.crosstabData.map((row, dataIdx) => (
              <TableRow key={`CROSSTAB-TR-${this.props.idx}-${dataIdx}`}>
                <TableCell>
                  <span className="fw-500">
                    {this.props.chart.data.v1Labels[dataIdx]}
                  </span>
                </TableCell>
                {row.map((resCount, resIdx) => (
                  <TableCell
                    key={`CROSSTAB-TD-${this.props.idx}-${dataIdx}-${resIdx}`}
                    style={{
                      backgroundColor: this.getCellColor(
                        resCount / row[row.length - 1],
                        row.length
                      ),
                    }}
                  >
                    {resCount}
                    <br />
                    {row[row.length - 1]
                      ? Math.round((10000 * resCount) / row[row.length - 1]) /
                        100
                      : "---"}
                    %
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }
}
