import {
  faTimes,
  faPlusSquare,
  faMinusSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItem, Select, TextField } from "@material-ui/core";
import React, { PureComponent } from "react";
import { languageShortCodes } from "./../../utils/data";
import { insertItemIntoArray, removeItemFromArray } from "utils/utils";

class TranslationTextInput extends PureComponent {
  render() {
    return (
      <div className="translation-line">
        <TextField
          value={this.props.text}
          variant="outlined"
          margin="dense"
          className="translation-long background-white"
          placeholder="Original text"
          multiline
          disabled={!this.props.textEditable}
          onChange={this.props.updateText}
        />
      </div>
    );
  }
}

class LanguageItem extends PureComponent {
  render() {
    return (
      <div className="translation-line">
        <Select
          value={this.props.language}
          variant="outlined"
          className="translation-short"
          margin="dense"
          onChange={(e) => this.props.selectLanguage(e, this.props.idx)}
          style={{ maxHeight: 38 }}
        >
          {Object.keys(languageShortCodes).map((sc) => {
            return (
              <MenuItem
                key={`TRANSLATION_MENU_ITEM_${this.props.text}_${sc}`}
                value={sc}
              >
                {languageShortCodes[sc]}
              </MenuItem>
            );
          })}
        </Select>
        <TextField
          value={this.props.translatedText}
          variant="outlined"
          margin="dense"
          className="translation-flex background-white"
          placeholder="Translated text"
          multiline
          maxRows={4}
          onChange={(e) => this.props.updateTranslatedText(e, this.props.idx)}
        />
        <div className="selection-options-updown add-translation-lang">
          <FontAwesomeIcon
            icon={faPlusSquare}
            className="add-response"
            onClick={() => this.props.addLanguage(this.props.idx)}
          />
          <FontAwesomeIcon
            icon={faMinusSquare}
            className="add-response"
            onClick={() => this.props.removeLanguage(this.props.idx)}
          />
        </div>
      </div>
    );
  }
}

class TranslationItem extends PureComponent {
  state = {
    text: this.props.translation.text,
    translations: this.props.translation.translations,
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.text !== prevState.text) {
      this.props.updateTranslation(this.props.idx, {
        ...this.state,
        text: this.state.text,
      });
    }

    if (this.state.translations !== prevState.translations) {
      this.props.updateTranslation(this.props.idx, {
        ...this.state,
        translations: this.state.translations,
      });
    }
  }

  selectLanguage = (e, idx) => {
    let newTransArray = [...this.state.translations];
    newTransArray[idx] = [e.target.value, newTransArray[idx][1]];
    this.setState({ translations: newTransArray });
  };

  updateTranslatedText = (e, idx) => {
    let newTransArray = [...this.state.translations];
    newTransArray[idx] = [newTransArray[idx][0], e.target.value];
    this.setState({ translations: newTransArray });
  };

  addLanguage = (idx) => {
    this.setState({
      translations: insertItemIntoArray(
        this.state.translations,
        ["", ""],
        idx + 1
      ),
    });
  };

  removeLanguage = (idx) => {
    if (this.state.translations.length <= 1) return; // Need at least 1 translation
    this.setState({
      translations: removeItemFromArray(this.state.translations, idx),
    });
  };

  updateText = (e) => this.setState({ text: e.target.value });
  deleteTranslation = () => this.props.deleteTranslation(this.props.idx);

  render() {
    if (this.state.text === null && this.state.translations === null)
      return null;

    return (
      <div id="translation-container">
        {this.props.textEditable && (
          <div className="remove-button">
            <FontAwesomeIcon icon={faTimes} onClick={this.deleteTranslation} />
          </div>
        )}
        <TranslationTextInput
          text={this.state.text}
          textEditable={this.props.textEditable}
          updateText={this.updateText}
        />
        {this.state.translations.map((translation, idx) => {
          return (
            <LanguageItem
              key={`TRANSLATION_LINE_${this.props.translation.ident}_${translation[0]}_${idx}`}
              language={translation[0]}
              translatedText={translation[1]}
              idx={idx}
              selectLanguage={this.selectLanguage}
              updateTranslatedText={this.updateTranslatedText}
              addLanguage={this.addLanguage}
              removeLanguage={this.removeLanguage}
            />
          );
        })}
      </div>
    );
  }
}

class Translation extends React.Component {
  render() {
    return (
      this.props.translations &&
      this.props.translations.map((t, idx) => (
        <TranslationItem
          key={`TRANSLATION_ITEM_${t.ident}`}
          idx={idx}
          updateTranslation={this.props.updateTranslation}
          deleteTranslation={this.props.deleteTranslation}
          translation={t}
          textEditable={this.props.textEditable}
        />
      ))
    );
  }
}

export default Translation;
