import { stopWords } from "./data";
import { hHiLabels, parseQuery } from "./utils";

export function getIncomeData(responses) {
  if (!responses) return { data: [], labels: [] };
  var data = new Array(hHiLabels.length).fill(0);
  for (var i = 0; i < responses.length; ++i) {
    const response = responses[i];
    if (!response.demographics || !response.demographics.hHIncome) continue;
    var income = response.demographics.hHIncome;
    if (hHiLabels.indexOf(income)) data[hHiLabels.indexOf(income)]++;
  }
  return {
    data: data,
    labels: hHiLabels,
  };
}

export function getLocationData(responses) {
  if (!responses) return { data: [], labels: [] };
  // Scan the data for headers
  var headers = [];
  var data = [];
  responses.forEach((r) => {
    if (!r.demographics || !r.demographics.location) return;
    if (!headers.includes(r.demographics.location.display)) {
      headers.push(r.demographics.location.display);
      data.push(1);
    } else {
      data[headers.indexOf(r.demographics.location.display)]++;
    }
  });
  return {
    data: data,
    labels: headers,
  };
}

export function getGenderData(responses) {
  if (!responses) return { data: [], labels: [] };
  // Scan the data for headers
  var headers = [];
  var data = [];
  responses.forEach((r) => {
    if (!r.demographics || !r.demographics.gender) return;
    if (!headers.includes(r.demographics.gender)) {
      headers.push(r.demographics.gender);
      data.push(1);
    } else {
      data[headers.indexOf(r.demographics.gender)] += 1;
    }
  });
  return {
    data: data,
    labels: headers,
  };
}

export function fullUrlToParamMap(url) {
  var [domainString, ...queryString] = url.split("?");
  var queryString = queryString.join("?");
  return parseQuery(queryString);
}

const occurences = (data) =>
  data.reduce((res, item) => {
    Object.entries(item).forEach(([k, v]) => {
      const existing = res[k] || {};
      existing[v] = (existing[v] || 0) + 1;
      res[k] = existing;
    });
    return res;
  }, {});

export function getInitialConditionData(sessions) {
  if (!sessions) return {};
  var validSessions = sessions.filter(
    (s) => s.features && s.features.url && s.features.url.includes("?")
  );
  var urls = validSessions.map((s) => fullUrlToParamMap(s.features.url));
  var conditionsCount = occurences(urls);
  return conditionsCount;
}

export function getSelectionData(responses, options) {
  if (!responses) return { data: [], labels: [] };
  var data = [];
  const labels = options;
  labels.forEach(() => {
    data.push(0);
  });
  for (var i = 0; i < responses.length; ++i) {
    const response = responses[i];
    // Can be no data if optional AUDIO_SELECTION response is partially missing
    if (!response.data) continue;
    for (var j = 0; j < response.data.length; ++j) {
      if (response.data[j]) {
        data[j]++;
      }
    }
  }
  return {
    data: data,
    labels: labels,
  };
}

export function getSliderNumberChartData(responses) {
  if (!responses) return { data: [], labels: [] };
  let data = [],
    labels = [],
    rawData = [];
  for (let i = 0; i < responses.length; ++i) {
    rawData[i] = responses[i].data;
  }
  rawData.sort((a, b) => a - b);
  for (let i = 0; i < rawData.length; i++) {
    if (!labels.includes(rawData[i])) {
      labels.push(rawData[i]);
      data.push(0);
    }
    data[labels.indexOf(rawData[i])]++;
  }
  return {
    data: data,
    labels: labels,
  };
}

export function getLikertChartData(responses, optionIdx, labels) {
  if (!responses) return { data: [], labels: [] };
  let data = Object.fromEntries(labels.map((key) => [key, 0])); // Assigns keys from labels, like Dict comprehension
  for (let i = 0; i < responses.length; ++i) {
    if (responses[i].data)
      data[responses[i].data[optionIdx]] !== undefined &&
        data[responses[i].data[optionIdx]]++;
  }
  const orderedData = labels.map((label) => data[label]); // This map will make sure values are ordered correctly
  return { data: orderedData, labels: labels };
}

export function getAgeData(responses) {
  if (!responses) return { data: [], labels: [] };
  var data = [0, 0, 0, 0, 0];
  const labels = ["<18", "18-29", "30-44", "45-60", ">60"];
  for (var i = 0; i < responses.length; ++i) {
    const response = responses[i];
    if (!response.demographics) continue;
    var age = response.demographics.age;
    if (age === "< 18") data[0]++;
    else if (typeof age === "string") age = parseInt(age);
    if (response.demographics.age < 18) {
      data[0]++;
    } else if (
      response.demographics.age >= 18 &&
      response.demographics.age < 29
    ) {
      data[1]++;
    } else if (
      response.demographics.age >= 30 &&
      response.demographics.age < 44
    ) {
      data[2]++;
    } else if (
      response.demographics.age >= 45 &&
      response.demographics.age < 60
    ) {
      data[3]++;
    } else if (response.demographics.age >= 60) {
      data[4]++;
    }
  }
  return {
    data: data,
    labels: labels,
  };
}

export function getAverageSentimentPieChartData(responses) {
  if (!responses) return { data: [], labels: [] };
  var data = [0.0, 0.0, 0.0, 0.0];
  const labels = ["Positive", "Negative", "Mixed", "Neutral"];
  for (var i = 0; i < responses.length; i++) {
    const response = responses[i];
    if (!response.features || !response.features.sentiment_score) continue;
    data[0] += response.features.sentiment_score[labels[0]];
    data[1] += response.features.sentiment_score[labels[1]];
    data[2] += response.features.sentiment_score[labels[2]];
    data[3] += response.features.sentiment_score[labels[3]];
  }
  /* Filter sentiment data to 3 significant digits */
  return { data: data.map((d) => parseFloat(d.toFixed(3))), labels: labels };
}

export const getEmotionBarChartData = (responses) => {
  if (!responses) return { data: [], labels: [] };
  var data = {};
  for (var i = 0; i < responses.length; i++) {
    const response = responses[i];
    if (response.features && response.features.emotions) {
      const textEmotions = response.features.emotions.text;
      for (var j = 0; j < textEmotions.length; j++) {
        const textEmotion = textEmotions[j];
        if (!data[textEmotion.text]) data[textEmotion.text] = 0.0;
        data[textEmotion.text] += parseFloat(textEmotion.prob);
      }
    }
  }
  const labels = Object.keys(data);
  data = labels.map((k) => {
    return data[k];
  });
  return { data: data, labels: labels };
};

export const emotionConfig = {
  HAPPY: { idx: 0, borderColor: "#f6fcbb", backgroundColor: "#f6fcbb" },
  SAD: { idx: 1, borderColor: "#54a3e8", backgroundColor: "#54a3e8" },
  ANGRY: { idx: 2, borderColor: "#f08d8d", backgroundColor: "#f08d8d" },
  CONFUSED: { idx: 3, borderColor: "#f7ca86", backgroundColor: "#f7ca86" },
  DISGUSTED: { idx: 4, borderColor: "#a6dfff", backgroundColor: "#a6dfff" },
  SURPRISED: { idx: 5, borderColor: "#f28acc", backgroundColor: "#f28acc" },
  CALM: { idx: 6, borderColor: "#a1edb6", backgroundColor: "#a1edb6" },
  FEAR: { idx: 7, borderColor: "#c2a1e6", backgroundColor: "#c2a1e6" },
  UNKNOWN: { idx: 8, borderColor: "#d9d9d9", backgroundColor: "#d9d9d9" },
};

function movingAvg(array, countBefore, countAfter) {
  if (countAfter === undefined) countAfter = 0;
  const result = [];
  for (let i = 0; i < array.length; i++) {
    const subArr = array.slice(
      Math.max(i - countBefore, 0),
      Math.min(i + countAfter + 1, array.length)
    );
    const avg =
      subArr.reduce((a, b) => a + (isNaN(b) ? 0 : b), 0) / subArr.length;
    result.push(avg);
  }
  return result;
}

export function getEmotionLineChartData(faces) {
  var emotions = [];
  // Initialize empty 2D array (numEmotions x numFaces)
  emotionConfig.forEach(() => emotions.push(new Array(faces.length)));

  // Copy the face predictions into the new array, re-ordering.
  for (const f in faces) {
    for (const e in faces[f].Face.Emotions) {
      const emotion = faces[f].Face.Emotions[e];
      emotions[emotionConfig[emotion.Type].idx][f] = emotion.Confidence;
    }
  }

  // Apply moving average to smooth data.
  for (let i = 1; i < emotions.length; i++) {
    emotions[i] = movingAvg(emotions[i], 3, 3);
  }

  // Re-normalize after smoothing.
  for (let i = 0; i < faces.length; i++) {
    let newSum = 0.0;
    for (let j = 0; j < emotions.length; j++) newSum += emotions[j][i];
    for (let j = 0; j < emotions.length; j++) emotions[j][i] *= 100.0 / newSum;
  }

  return {
    labels: faces.map((f) => Math.round(f.Timestamp / 100.0) / 10.0), // Convert to seconds, rounded to nearest 100ms.
    data: Object.keys(emotionConfig).map((e) => {
      return {
        data: emotions[emotionConfig[e].idx],
        label: e,
        borderColor: emotionConfig[e].borderColor,
        backgroundColor: emotionConfig[e].backgroundColor,
      };
    }),
  };
}

export function getAverageEmotionChartData(faces) {
  var emotions = new Array(Object.keys(emotionConfig).length).fill(0.0);
  for (const f in faces) {
    for (const e in faces[f].Face.Emotions) {
      const emotion = faces[f].Face.Emotions[e];
      emotions[emotionConfig[emotion.Type].idx] += emotion.Confidence;
    }
  }

  return {
    labels: Object.keys(emotionConfig),
    data: [
      {
        data: emotions.map((e) => Math.log(e)),
        borderColor: "#22ce81",
        backgroundColor: "#22ce8199",
        scales: {
          r: {
            display: false,
            angleLines: {
              display: false,
            },
            suggestedMin: 50,
            suggestedMax: 100,
          },
        },
      },
    ],
  };
}

export const getSurveyDateData = (sessions) => {
  if (!sessions)
    return {
      data: null,
      labels: null,
    };
  var data = {};
  for (var i = 0; i < sessions.length; i++) {
    var d = new Date(sessions[i].createdAt.split(" ")[0]); // Only look at date (not time).
    if (!(d in data)) {
      data[d] = 0;
    }
    data[d]++;
  }
  return {
    data: Object.keys(data)
      .sort((a, b) => new Date(a) - new Date(b))
      .map((k) => data[k]),
    labels: Object.keys(data)
      .sort((a, b) => new Date(a) - new Date(b))
      .map((k) => new Date(k).toISOString()),
  };
};

export const getDevicePieChartData = (sessions) => {
  if (!sessions || !Array.isArray(sessions)) return null;
  const data = [0, 0, 0];
  const labels = ["desktop", "tablet", "mobile"];
  for (let i = 0; i < sessions.length; i++) {
    if (sessions[i].features.device === "desktop") data[0] += 1;
    if (sessions[i].features.device === "tablet") data[1] += 1;
    if (sessions[i].features.device === "mobile") data[2] += 1;
  }
  if (data.every((val) => val === 0)) return null;
  return { data, labels };
};

export const getAverageRank = (responses, options) => {
  if (!responses || !options) return { data: [], labels: [] };
  let data = [];
  options.forEach((option) => {
    let rankCounter = 0;
    let numResponses = 0;
    for (let j = 0; j < responses.length; j++) {
      if (responses[j] && responses[j].data) {
        rankCounter = rankCounter + (responses[j].data.indexOf(option) + 1);
        numResponses++;
      }
    }
    rankCounter = rankCounter / numResponses;
    data.push(rankCounter);
    rankCounter = 0;
  });
  return { data, labels: options };
};

export const getWordCloudData = (responses, additionalStopWords) => {
  if (!responses) return [];
  let wordCounterHash = {};
  for (var i = 0; i < responses.length; i++) {
    // Iterate through all possible sources of text.
    let text = "";
    if (responses[i].features && responses[i].features.transcription)
      text += responses[i].features.transcription + " ";
    if (responses[i].backupText) text += responses[i].backupText + " ";
    if (typeof responses[i].data === "string") text += responses[i].data + " ";

    let words = text.split(" ");
    for (var j = 0; j < words.length; j++) {
      var word = words[j];
      word = word.toLowerCase();
      word = word.replace(/,/g, ""); // Remove commas
      word = word.replace(/\./g, ""); // Remove periods
      if (!stopWords.has(word) && !additionalStopWords.includes(word)) {
        // Check if word is a stopword
        if (wordCounterHash[word]) {
          // If map has word, increase counter by one
          wordCounterHash[word]++;
        } else {
          // If map doesn't have word, add word and counter of one
          wordCounterHash[word] = 1;
        }
      }
    }
    // If it isn't a transcription question: send nothing
  }
  if (!Object.keys(wordCounterHash).length) return [];

  // Output list format: [{text:"abc", value: 34}, {text: "hello", value: 23}]
  return Object.keys(wordCounterHash).map((key) => {
    return {
      text: key,
      value: wordCounterHash[key],
    };
  });
};

export const getTopicChartData = (entities) => {
  if (!entities) return { data: [], labels: [] };
  var data = [],
    labels = [],
    e = entities;
  e.sort((a, b) => {
    if (!a.mentions || !b.mentions) return 0;
    return b.mentions.length - a.mentions.length;
  });
  for (var i = 0; i < entities.length; i++) {
    const entity = entities[i];
    if (entity.mentions && entity.Text) {
      data.push(entity.mentions.length);
      labels.push(entity.Text);
    }
  }
  return { data: data, labels: labels };
};

export const getUsageData = (usage) => {
  let data = [],
    labels = [];
  usage.forEach((u) => {
    data.push(u.count);
    labels.push(u.date);
  });
  return { data: data, labels: labels };
};

export const getCumulativeUsageData = (usage) => {
  let data = [],
    labels = [],
    cumulativeCount = 0;
  usage.forEach((u) => {
    cumulativeCount += u.count;
    data.push(cumulativeCount);
    labels.push(u.date);
  });
  return { data: data, labels: labels };
};
