import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
  IconButton,
  TextField,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup, Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import ClipLoader from "react-spinners/ClipLoader";

class CancelPopup extends React.Component {
  state = { message: "", nps: null };

  componentDidUpdate(prevProps) {
    if (prevProps.cancelLoading === true && this.props.cancelLoading === false)
      this.handleClose();
  }
  handleClose = () => {
    this.props.onClose();
  };
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          Cancel Subscription
          <IconButton
            aria-label="close"
            onClick={this.handleClose}
            style={{ float: "right" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Alert severity="warning">
            Your account will be downgraded to Free upon cancelling. You will no longer be able to:
            <ul>
              <li>Export your data</li>
              <li>View all responses</li>
              <li>Access certain features</li>
            </ul>
            To schedule a cancellation for a future date (e.g., the end of the current billing cycle), please go to the Billing Portal.
          </Alert>
        </DialogContent>
        <DialogContent>
          <DialogContentText>
            We're sorry to see you go! Why are you cancelling?
          </DialogContentText>
          <TextField
            variant="outlined"
            fullWidth
            label=""
            placeholder="I have completed my research project..."
            multiline
            rows={4}
            value={this.state.message}
            onChange={(e) => this.setState({ message: e.target.value })}
          ></TextField>
        </DialogContent>
        <DialogContent>
          <DialogContentText>
            How would you rate your experience with Phonic? (10 = awesome, 1 =
            terrible)
          </DialogContentText>

          <ToggleButtonGroup
            onChange={(e, v) => {
              this.setState({ nps: v });
            }}
            exclusive={true}
            value={this.state.nps}
          >
            <ToggleButton value={1}>1</ToggleButton>
            <ToggleButton value={2}>2</ToggleButton>
            <ToggleButton value={3}>3</ToggleButton>
            <ToggleButton value={4}>4</ToggleButton>
            <ToggleButton value={5}>5</ToggleButton>
            <ToggleButton value={6}>6</ToggleButton>
            <ToggleButton value={7}>7</ToggleButton>
            <ToggleButton value={8}>8</ToggleButton>
            <ToggleButton value={9}>9</ToggleButton>
            <ToggleButton value={10}>10</ToggleButton>
          </ToggleButtonGroup>
          <DialogActions>
            <Button
              className="cancel-plan-button"
              variant="contained"
              component="label"
              onClick={this.props.cancel.bind(this, {
                message: this.state.message,
                nps: this.state.nps,
              })}
              disabled={!this.state.message || !this.state.nps}
            >
              {this.props.cancelLoading ? (
                <ClipLoader
                  size={18}
                  color={"rgb(163, 83, 83)"}
                  loading={this.props.cancelLoading}
                />
              ) : (
                "Cancel Plan"
              )}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
}
export default CancelPopup;
