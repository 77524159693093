import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
} from "@material-ui/core";
import CodeEditor from "components/CodeEditor";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css";

class QuestionJavascriptPopup extends React.Component {
  state = { code: "// Write your own custom Javascript here." };
  componentDidUpdate(prevProps) {
    if (
      prevProps.open !== this.props.open &&
      this.props.open === true &&
      this.props.customScript !== undefined
    ) {
      this.setState({ code: this.props.customScript });
    }
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.onClose}
          fullWidth
          maxWidth="lg"
          aria-labelledby="form-dialog-title"
        >
          <DialogContent className="popup-container large">
            <h2 className="dialog-title-small" style={{ marginBottom: 20 }}>
              Question Javascript
            </h2>
            <CodeEditor
              language={"js"}
              value={this.state.code}
              onValueChange={(code) => this.setState({ code })}
            />
            <DialogActions>
              <Button
                variant="outlined"
                component="label"
                onClick={(e) => {
                  this.props.onClose(e);
                }}
                className="Mui-Overrides outlined-dark"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                component="label"
                onClick={() => this.props.onSave(this.state.code)}
                color="primary"
              >
                Save
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default QuestionJavascriptPopup;
