import React from "react";
import Button from "@material-ui/core/Button";
import { Dialog, DialogContent, MenuItem, Grid } from "@material-ui/core";
import CrosstabVarMenu from "components/CrosstabVarMenu";
import { QuestionDropDownItem } from "utils/utils";

class CrosstabPopup extends React.Component {
  state = {
    varOptions: [],
    var1: "",
    var2: "",
  };

  componentDidMount() {
    if (this.props.survey) {
      this.setState({ varOptions: this.generateVarOptions() });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.survey && !prevProps.survey) {
      this.setState({ varOptions: this.generateVarOptions() });
    }
  }

  setVar1 = (newVar) => {
    var stateUpdates = { var1: newVar };
    if (newVar.thresholdEligible && !newVar.intervalEligible) {
      stateUpdates["var1Transformer"] = "THRESHOLD";
    } else {
      stateUpdates["var1Transformer"] = null;
    }
    if (this.state.var1Threshold) {
      stateUpdates["var1Threshold"] = null;
    }
    if (this.state.var1Intervals) {
      stateUpdates["var1Intervals"] = null;
    }
    this.setState(stateUpdates);
  };

  setVar2 = (newVar) => {
    var stateUpdates = { var2: newVar };
    if (newVar.thresholdEligible && !newVar.intervalEligible) {
      stateUpdates["var2Transformer"] = "THRESHOLD";
    } else {
      stateUpdates["var2Transformer"] = null;
    }
    if (this.state.var2Threshold) {
      stateUpdates["var2Threshold"] = null;
    }
    if (this.state.var2Intervals) {
      stateUpdates["var2Intervals"] = null;
    }
    this.setState(stateUpdates);
  };

  readyToSubmit = () => {
    if (!this.state.var1 || !this.state.var2) {
      return false;
    }
    if (this.state.var1 === this.state.var2) {
      return false;
    }
    if (!this.state.var1Transformer) {
      if (
        this.state.var1.thresholdEligible ||
        this.state.var1.intervalEligible
      ) {
        return false;
      }
    } else if (this.state.var1Transformer === "THRESHOLD") {
      if (
        this.state.var1Threshold === null ||
        this.state.var1Threshold === undefined
      ) {
        return false;
      }
    }
    if (!this.state.var2Transformer) {
      if (
        this.state.var2.thresholdEligible ||
        this.state.var2.intervalEligible
      ) {
        return false;
      }
    } else if (this.state.var2Transformer === "THRESHOLD") {
      if (
        this.state.var2Threshold === null ||
        this.state.var2Threshold === undefined
      ) {
        return false;
      }
    }
    return true;
  };

  submitVars = () => {
    let var1Data = {
      type: this.state.var1.type,
      path: this.state.var1.path,
    };
    if (this.state.var1.questionId) {
      var1Data.questionId = this.state.var1.questionId;
    }
    if (this.state.var1Transformer) {
      var1Data.transformer = {
        type: this.state.var1Transformer,
        value:
          this.state.var1Transformer === "THRESHOLD"
            ? this.state.var1Threshold
            : this.state.var1Intervals,
      };
    }
    let var2Data = {
      type: this.state.var2.type,
      path: this.state.var2.path,
    };
    if (this.state.var2.questionId) {
      var2Data.questionId = this.state.var2.questionId;
    }
    if (this.state.var2Transformer) {
      var2Data.transformer = {
        type: this.state.var2Transformer,
        value:
          this.state.var2Transformer === "THRESHOLD"
            ? this.state.var2Threshold
            : this.state.var2Intervals,
      };
    }
    let vars = [var1Data, var2Data];
    var chartTitle = `${this.state.var1.readableName} / ${this.state.var2.readableName}`;
    this.props.submit(vars, chartTitle);
    this.props.onClose();
  };

  onClose = () => {
    this.setState({
      var1: "",
      var2: "",
      var1Transformer: null,
      var2Transformer: null,
      var1Threshold: null,
      var2Threshold: null,
      var1Intervals: null,
      var2Intervals: null,
    });
    this.props.onClose();
  };

  generateVarOptions = () => {
    const varOptsByType = {
      THINKALOUD: ["topics", "features.sentiment", "features.sentiment_score"],
      DATE: [],
      FILE: [],
      DISPLAY: [],
      TEXT: ["topics"],
      SELECTION: ["data"],
      NUMBER: ["data"],
      SLIDER: ["data"],
      DROPDOWN: ["data"],
      RANKING: ["data"],
      AUDIO: ["topics", "features.sentiment", "features.sentiment_score"],
      AUDIO_TEXT: ["topics", "features.sentiment", "features.sentiment_score"],
      VIDEO: ["topics", "features.sentiment", "features.sentiment_score"],
      SCREEN: ["topics", "features.sentiment", "features.sentiment_score"],
      AUDIO_SLIDER: [
        "data",
        "topics",
        "features.sentiment",
        "features.sentiment_score",
      ],
      AUDIO_RANKING: [
        "data",
        "topics",
        "features.sentiment",
        "features.sentiment_score",
      ],
      AUDIO_SELECTION: [
        "data",
        "topics",
        "features.sentiment",
        "features.sentiment_score",
      ],
    };
    const eligibleTypesForThreshold = [
      "SLIDER",
      "NUMBER",
      "AUDIO_RANKING",
      "AUDIO_SLIDER",
      "RANKING",
    ];
    const eligibleTypesForIntervals = ["SLIDER", "NUMBER", "AUDIO_SLIDER"];
    const supportedDemographics = ["age", "gender", "hHIncome"];
    const transformableDemographics = ["age"];

    var optList = [];

    for (let i = 0; i < supportedDemographics.length; i++) {
      let demoStat = supportedDemographics[i];
      if (this.props.survey.demographics[demoStat]) {
        optList.push(
          <MenuItem
            value={{
              type: "demographics",
              path: demoStat,
              thresholdEligible: transformableDemographics.includes(demoStat),
              intervalEligible: transformableDemographics.includes(demoStat),
              readableName: `Respondent ${
                demoStat === "hHIncome" ? "household income" : demoStat
              }`,
            }}
          >
            Respondent {demoStat === "hHIncome" ? "household income" : demoStat}
          </MenuItem>
        );
      }
    }
    let questions = this.props.survey.questions;
    for (let i = 0; i < questions.length; i++) {
      if (questions[i].type === "LIKERT") {
        optList.push(
          ...questions[i].options.map((o, index) => (
            <MenuItem
              key={`CROSSTAB-LIKERT-${index}`}
              value={{
                type: "question",
                questionId: questions[i].questionId.toString(),
                path: "data." + index,
                thresholdEligible: false,
                intervalEligible: false,
                readableName: `Question ${i + 1} option ${index}: ${o}`,
              }}
            >
              Question {i + 1} option {index}: {o}
            </MenuItem>
          ))
        );
      } else {
        optList.push(
          ...varOptsByType[questions[i].type].map((o, index) => {
            if (o === "features.sentiment") {
              return (
                <MenuItem
                  key={`CROSSTAB-SENTIMENT-${index}-${i}`}
                  value={{
                    type: "question",
                    questionId: questions[i].questionId.toString(),
                    path: o,
                    thresholdEligible: false,
                    intervalEligible: false,
                    readableName: `Question ${i + 1} overall sentiment`,
                  }}
                >
                  {QuestionDropDownItem(
                    i + 1,
                    `Question ${i + 1} overall sentiment`,
                    questions[i].type
                  )}
                </MenuItem>
              );
            } else if (o === "features.sentiment_score") {
              return (
                <MenuItem
                  key={`CROSSTAB-SENTIMENTSCORE-${index}-${i}`}
                  value={{
                    type: "question",
                    questionId: questions[i].questionId.toString(),
                    path: o,
                    thresholdEligible: true,
                    intervalEligible: false,
                    readableName: `Question ${i + 1} sentiment score`,
                  }}
                >
                  {QuestionDropDownItem(
                    i + 1,
                    `Question ${i + 1} sentiment score`,
                    questions[i].type
                  )}
                </MenuItem>
              );
            } else {
              return (
                <MenuItem
                  key={`CROSSTAB-QUESTION-${index}-${i}`}
                  value={{
                    type: "question",
                    questionId: questions[i].questionId.toString(),
                    path: o,
                    thresholdEligible:
                      o === "data" &&
                      eligibleTypesForThreshold.includes(questions[i].type),
                    intervalEligible:
                      o === "data" &&
                      eligibleTypesForIntervals.includes(questions[i].type),
                    readableName: `Question ${i + 1} ${o}`,
                  }}
                >
                  {QuestionDropDownItem(
                    i + 1,
                    `Question ${i + 1} ${o}`,
                    questions[i].type
                  )}
                </MenuItem>
              );
            }
          })
        );
      }
    }
    return optList;
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogContent className="popup-container">
          <h2>New Crosstab</h2>
          <p className="dialog-text">
            Compare two distinct variables in your data in a cross tabulation.
          </p>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CrosstabVarMenu
                var={this.state.var1}
                label="Variable 1"
                varOptions={this.state.varOptions}
                transformer={this.state.var1Transformer}
                intervals={this.state.var1Intervals}
                threshold={this.state.var1Threshold}
                setVar={(v) => this.setVar1(v)}
                setTransformer={(t) => this.setState({ var1Transformer: t })}
                setThreshold={(t) => this.setState({ var1Threshold: t })}
                setIntervals={(i) => this.setState({ var1Intervals: i })}
              />
            </Grid>
            <Grid item xs={6}>
              <CrosstabVarMenu
                var={this.state.var2}
                label="Variable 2"
                varOptions={this.state.varOptions}
                transformer={this.state.var2Transformer}
                intervals={this.state.var2Intervals}
                threshold={this.state.var2Threshold}
                setVar={(v) => this.setVar2(v)}
                setTransformer={(t) => this.setState({ var2Transformer: t })}
                setThreshold={(t) => this.setState({ var2Threshold: t })}
                setIntervals={(i) => this.setState({ var2Intervals: i })}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                className="phonic-outlined-button"
                variant="outlined"
                onClick={this.props.onClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={!this.readyToSubmit()}
                onClick={this.submitVars}
                style={{ margin: 8 }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

export default CrosstabPopup;
