import React, { Component } from "react";
import Chart from "chart.js/auto";
import "chartjs-adapter-date-fns";

export default class LineGraph extends Component {
  chartRef = React.createRef();
  chartInstance = null;

  componentDidMount() {
    this.buildChart();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.chartData.data === null) {
      this.buildChart();
    }
  }

  buildChart = () => {
    const myChartRef = this.chartRef.current.getContext("2d");

    var gradient = myChartRef.createLinearGradient(0, 0, 0, 450);

    gradient.addColorStop(0, "rgb(34, 206, 129, 0.5)");
    gradient.addColorStop(0.5, "rgba(34, 206, 129, 0.1)");
    gradient.addColorStop(1, "rgba(34, 206, 129, 0)");

    if (this.chartInstance) this.chartInstance.destroy();
    this.chartInstance = new Chart(myChartRef, {
      type: "line",
      data: {
        labels: this.props.chartData.labels,

        datasets: [
          {
            data: this.props.chartData.data,
            backgroundColor: gradient,
            pointHoverBorderWidth: 1,
            pointRadius: 1,
            borderColor: "rgb(34, 206, 129)",
            fill: true,
            borderWidth: 2,
            tension: 0.15,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            grid: {
              display: null,
            },
            type: "time",
            time: {
              displayFormats: {
                quarter: "MMM YYYY",
              },
              unit: "day",
              tooltipFormat: "dd MMM yyyy",
            },
            distribution: "series",
            scaleLabel: {
              display: this.props.xLabel ? true : false,
              labelString: this.props.xLabel,
            },
          },
          y: {
            grid: {
              display: null,
            },
            ticks: {
              beginAtZero: true,
              precision: 0,
            },
            scaleLabel: {
              display: this.props.yLabel ? true : false,
              labelString: this.props.yLabel,
            },
          },
        },
      },
    });
  };

  render() {
    return (
      <div>
        <canvas id="time-chart" className="basic-chart" ref={this.chartRef} />
      </div>
    );
  }
}
