import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import {
  CircularProgress,
  DialogActions,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import {
  runModelSample,
  runJobOnSurveyQuestion,
  clearModelSample,
} from "../../redux/actions";
import JobStatusTable from "components/JobStatusTable";
import { Ballot, KeyboardBackspace } from "@material-ui/icons";
import BarChart from "components/Graphs/BarChart";
import { getTransparentChartColors } from "utils/utils";
import { getChartColors } from "utils/utils";

const useStyles = makeStyles({
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
  },
  backButton: {
    position: "absolute",
    top: 10,
    left: 10,
  },
  dialog: {
    position: "relative",
  },
  titleBlock: {
    borderBottom: "1px lightgrey solid",
    minHeight: 60,
    display: "flex",
    alignItems: "center",
  },
  secondTitle: {
    fontSize: 16,
    marginTop: 10,
    marginBottom: 20,
  },
  tileContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
  verticalMargins: {
    marginBottom: 10,
  },
  description: {
    marginTop: 10,
    marginBottom: 20,
  },
});

const mapStateToProps = (state) => {
  return {
    modelSample: state.modelSample,
    loadingSample: state.loading.ModelSample,
    loadingJobRequest: state.loading.JobRequest,
  };
};

const mapDispatchToProps = {
  runModelSample,
  runJobOnSurveyQuestion,
  clearModelSample,
};

function ClassificationPopup({
  getRandomResponse,
  runModelSample,
  runJobOnSurveyQuestion,
  open,
  onClose,
  modelSample,
  loadingSample,
  loadingJobRequest,
  surveyId,
  questionId,
  clearModelSample,
}) {
  const classes = useStyles();
  const [sampleData, setSampleData] = useState({});
  const [chartKey, setChartKey] = useState(0);
  const [screen, setScreen] = useState("base");
  const [screenConfig, setScreenConfig] = useState(undefined);

  const loadRandomData = () => {
    setSampleData({
      ...sampleData,
      content: getRandomResponse(),
    });
  };

  function handleClose() {
    setScreen("base");
    setScreenConfig(undefined);
    setSampleData({});
    clearModelSample();
    onClose();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
      className={classes.dialog}
    >
      <DialogTitle className={classes.titleBlock}>
        {screen === "base" ? (
          <>Response Classification</>
        ) : (
          <IconButton
            aria-label="go-back"
            className={classes.backButton}
            onClick={() => setScreen("base")}
          >
            <KeyboardBackspace />
          </IconButton>
        )}

        <div className={classes.closeButton}>
          {screen !== "jobs" && (
            <IconButton
              aria-label="close"
              onClick={() => {
                setScreen("jobs");
              }}
            >
              <Ballot />
            </IconButton>
          )}
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className="popup-container small">
        {screen === "base" && (
          <>
            <div>
              <p className={classes.description}>
                Which categories would you like bucketize your data into? Read
                more about{" "}
                <a
                  href="https://docs.phonic.ai/docs/themes"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  auto-classification
                </a>
                .
              </p>
              {screenConfig === "sample" && (
                <>
                  <Typography variant={"body1"}>
                    Sample data (
                    <span className="link-style" onClick={loadRandomData}>
                      load response
                    </span>
                    )
                  </Typography>
                  <TextField
                    className={classes.verticalMargins}
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    placeholder="I really liked the experience. It made me feel..."
                    value={sampleData.content}
                    onChange={(e) =>
                      setSampleData({
                        ...sampleData,
                        content: e.target.value,
                      })
                    }
                  />
                </>
              )}
              <Typography variant={"body1"}>
                Possible Classes (Comma Separated)
              </Typography>
              <TextField
                className={classes.verticalMargins}
                variant="outlined"
                fullWidth
                placeholder="class,class,class"
                value={sampleData.classes}
                onChange={(e) =>
                  setSampleData({
                    ...sampleData,
                    classes: e.target.value,
                  })
                }
              />
              {modelSample && modelSample.scores && (
                <BarChart
                  key={chartKey}
                  chartData={{
                    data: modelSample.scores,
                    labels: modelSample.labels,
                  }}
                  backgroundColor={getTransparentChartColors(1, 0.4)}
                  borderColor={getChartColors(1)}
                />
              )}
            </div>

            <DialogActions>
              {screenConfig === "sample" ? (
                <>
                  <Button
                    variant="outlined"
                    component="label"
                    onClick={() => {
                      let sampleDataCopy = _.cloneDeep(sampleData);
                      sampleDataCopy = {
                        language: "en",
                        multi_label: true,
                        score_threshold: 0.6,
                        model: "roberta",
                        ...sampleDataCopy,
                      };
                      runModelSample("classification", sampleDataCopy, () =>
                        setChartKey(chartKey + 1)
                      );
                    }}
                    className="Mui-Overrides outlined-dark"
                  >
                    {loadingSample ? (
                      <CircularProgress
                        size={16}
                        style={{ color: "#1d1c29" }}
                      />
                    ) : (
                      <>Run Sample</>
                    )}
                  </Button>
                </>
              ) : (
                <Button
                  variant="outlined"
                  component="label"
                  onClick={() => {
                    setScreenConfig("sample");
                  }}
                  className="Mui-Overrides outlined-dark"
                >
                  Try a Sample
                </Button>
              )}
              <Button
                variant="contained"
                component="label"
                onClick={() => {
                  let jobProps = _.cloneDeep(sampleData);
                  jobProps = {
                    bulk: true,
                    language: "en",
                    multi_label: true,
                    score_threshold: 0.6,
                    model: "roberta",
                    task: "CLASSIFICATION",
                    ...jobProps,
                  };
                  runJobOnSurveyQuestion(surveyId, questionId, jobProps, () =>
                    setScreen("jobs")
                  );
                }}
                color="primary"
              >
                {loadingJobRequest ? (
                  <CircularProgress size={16} style={{ color: "white" }} />
                ) : (
                  <>Run Classification</>
                )}
              </Button>
            </DialogActions>
          </>
        )}
        {screen === "jobs" && (
          <>
            <JobStatusTable surveyId={surveyId} questionId={questionId} />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClassificationPopup);
