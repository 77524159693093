import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

let reportTheme = createTheme({
  typography: {
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 700,
    },
    h4: {
      fontWeight: 700,
    },
    h5: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 700,
      textTransform: "none", // overrides light-bootstrap-dashboard-react.css
    },
    body2: {
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: "1rem",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 860, // custom value
      lg: 1280,
      xl: 1920,
    },
  },
  // Override MUI classes
  overrides: {
    MuiChip: {
      colorPrimary: {
        backgroundColor: "#22ce81",
      },
      clickableColorPrimary: {
        "&:hover": {
          backgroundColor: "#22ce81",
        },
      },
    },
  },
});

reportTheme = responsiveFontSizes(reportTheme);

export default reportTheme;
