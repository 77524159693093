import React from "react";
import Popover from "@material-ui/core/Popover";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Checkbox } from "@material-ui/core";
import PickDate from "components/PickDate";
import PickLang from "components/PickLang";
import PickGeneric from "components/PickGeneric";
import PickCompletionStatus from "components/PickCompletionStatus";

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    languages:
      state.languages && state.languages[ownProps.match.params.questionId],
  };
};

function SimplePopover(props) {
  return (
    <div>
      <Popover
        open={props.open}
        anchorEl={props.anchorEl}
        onClose={props.handleClose}
        disableAutoFocus
        disableEnforceFocus
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{ marginTop: 20, maxHeight: 600 }}
      >
        <div className="filter-popover">
          <h3>Display Options</h3>
          <div className="section">
            <div className="checkbox-container">
              <Checkbox
                checked={props.displayOptions.demographics}
                onChange={(e) =>
                  props.updateDisplayOptions({ demographics: e.target.checked })
                }
              />
              <p>Demographics</p>
            </div>
            <div className="checkbox-container">
              <Checkbox
                checked={props.displayOptions.externalId}
                onChange={(e) =>
                  props.updateDisplayOptions({ externalId: e.target.checked })
                }
              />
              <p>External ID</p>
            </div>
            <div className="checkbox-container">
              <Checkbox
                checked={props.displayOptions.public}
                onChange={(e) =>
                  props.updateDisplayOptions({ public: e.target.checked })
                }
              />
              <p>Private/Public</p>
            </div>
            {props.user && props.user.tier === "ENTERPRISE"}
            <div className="checkbox-container">
              <Checkbox
                checked={Boolean(props.displayOptions.translations)}
                onChange={(e) =>
                  props.updateDisplayOptions({
                    translations: e.target.checked ? "en" : null,
                  })
                }
              />
              <p>Show English Translations</p>
            </div>
          </div>
          <div className="section">
            <h3>Filters</h3>
            <div className="checkbox-container">
              <Checkbox
                checked={props.filters.processedMedia}
                onChange={(e) =>
                  props.updateFilters({ processedMedia: e.target.checked })
                }
              />
              <p>Hide Untranscribed Media</p>
            </div>
            <div className="checkbox-container">
              <Checkbox
                checked={props.filters.bookmarked}
                onChange={(e) =>
                  props.updateFilters({ bookmarked: e.target.checked })
                }
              />
              <p>Show only Bookmarked</p>
            </div>
          </div>
          <div className="section">
            <PickDate
              changeDate={props.updateFilters}
              initialDatesPresent={props.filters.startDate}
            />
          </div>
          <div className="section">
            <PickCompletionStatus
              change={props.updateFilters}
              initialState={props.filters.completionStatus}
            />
          </div>
          <div className="section">
            <PickLang
              languages={props.languages}
              submitLanguages={props.submitLanguages}
              initialLanguages={props.initialLanguages}
            />
          </div>
          {props.urlParams &&
            Object.keys(props.urlParams).map((k) => {
              return (
                <div key={`PICK-GENERIC-${k}`} className="section">
                  <PickGeneric
                    param={k}
                    options={props.urlParams[k]}
                    submitOptions={props.submitOptions}
                    initialOptions={props.initialOptions[k]}
                  />
                </div>
              );
            })}
          {props.questionInfo.doEmotion && (
            <div className="section">
              <h3>Emotions</h3>
              <p>
                {props.filters.emotions.size !== 0
                  ? Array.from(props.filters.emotions).map((emoji, idx) => {
                      return (
                        <span
                          key={`${idx}-EMOJI`}
                          className="emoji-filter-element"
                          onClick={() => {
                            let emojiFilter = new Set(props.filters.emotions);
                            emojiFilter.delete(emoji);
                            props.updateFilters({ emotions: emojiFilter });
                          }}
                        >
                          {emoji}
                        </span>
                      );
                    })
                  : "No emotion filter."}
              </p>
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
}

export default withRouter(connect(mapStateToProps, undefined)(SimplePopover));
