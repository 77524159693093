import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { toTitleCase } from "utils/utils";
import { getFeatures } from "utils/tiers";

function getTitle(oldTier, newTier) {
  const oldRank = getFeatures(oldTier).rank;
  const newRank = getFeatures(newTier).rank;
  if (newRank > oldRank) return "🎉 Congratulations!";
  else return "Your Plan Has Been Updated";
}

export default function UpgradeConfirmation({
  open,
  handleClose,
  oldTier,
  newTier,
}) {
  return (
    <Dialog id="upgrade-plan-popup" open={open} onClose={handleClose}>
      <div className="x-button">
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className="standard-popup-container flex-container flex-column">
        <h2 className="dialog-title-small">{getTitle(oldTier, newTier)}</h2>
        <p className="text-align-center">
          You have moved from <b>{toTitleCase(oldTier)}</b> to{" "}
          <b>{toTitleCase(newTier)}</b>
        </p>
        <img
          id="checkout-completed-animation"
          src={require("../../assets/img/check-circle.gif")}
          alt={"Checkout Completed"}
        />
      </DialogContent>
    </Dialog>
  );
}
