import React from "react";
import { Button, Box } from "@material-ui/core";

import Logic from "components/SurveyCreation/Logic";
import { getBlankLogicBlock } from "utils/defaults";

class Conditions extends React.Component {
  changeLogicInfo(updatedLogic) {
    this.props.changeQInfo(
      { ...this.props.question, logic: updatedLogic },
      this.props.question.questionId
    );
  };

  addLogic() {
    const newLogic = getBlankLogicBlock();
    const currentLogic = this.props.question && this.props.question.logic;
    this.changeLogicInfo(
      currentLogic ? [...currentLogic, newLogic] : [newLogic]
    );
  };

  render() {
    return(
      <>
        <Box sx={{ mb: 2 }}>
          Add conditional logic to your survey. See{" "}
          <a
            href="https://docs.phonic.ai/docs/skip-logic"
            target="_blank"
            rel="noopener noreferrer"
          >documentation</a>
          {" "}
          for help.
        </Box>
        <Logic
          questions={this.props.questions}
          logic={this.props.question && this.props.question.logic}
          changeLogicInfo={(updates) => this.changeLogicInfo(updates)}
        />
        <Button
          className="secondary-button-color"
          variant="contained"
          onClick={() => this.addLogic() }
          size='small'
          style={{ marginRight: 10 }}
        >
          + Add Logic
        </Button>
      </>
    )
  }
}

export default Conditions