function defaultInputConfig() {
  return {
    isCustom: false,
    bounds: {
      lower: { value: 1, label: "" },
      upper: { value: 5, label: "" },
      interval: 1
    },
    custom: [
      { value: "", label: "1" },
      { value: "", label: "2" },
      { value: "", label: "3" },
      { value: "", label: "4" },
      { value: "", label: "5" },
    ]
  }
}

export const LikertInputTypes = {
  'RADIO': { description: 'Selection', configByOption: false, optionalLabels: false, optionValuesConfig: ['custom'] },
  'SLIDER': { description: 'Slider', configByOption: true, optionalLabels: true, optionValuesConfig: ['bounds'] },
}
  
export function normalizedLikertInputsConfig(optionsMatrix, options) {
  const inputsConfig = optionsMatrix ? optionsMatrix.inputs : [];

  const config = [];
  for(let i = 0; i < options.length; i++) { config.push(defaultInputConfig()) }
  
  if(inputsConfig) {
    config.forEach((_c, index) => { 
      if(inputsConfig[index]) config[index] = inputsConfig[index];
    });
  }

  return config;
}

export function getLikertInputsConfig(optionsMatrix, options) { 
  const inputs = normalizedLikertInputsConfig(optionsMatrix, options);
  const inputType = optionsMatrix ? optionsMatrix.inputType : 'RADIO';
  const { configByOption, optionValuesConfig } = LikertInputTypes[inputType]

  return options.map((opt, index) => {
    const input = configByOption ? inputs[index] : inputs[0];

    // When custom config allowed and custom values enabled -> return custom values
    if(optionValuesConfig.indexOf('custom') !== -1 && input.isCustom) { 
      return { 
        values: input.custom.map(val => val.label), 
        labels: input.custom.map(val => val.label)
      }
    }

    // When bounds not allowed  -> return default values
    if(optionValuesConfig.indexOf('bounds') === -1) {
      return { 
        values: ['1', '2', '3', '4', '5'], 
        labels: ['1', '2', '3', '4', '5']
      }
    }

    // Generated values from bounds
    const { lower, upper, interval } = input.bounds;
    const cols = [];
    const labels = [];

    for(let i = lower.value; i <= upper.value; i += interval) cols.push(`${i}`);
    for(let i = lower.value; i <= upper.value; i += interval) labels.push('');

    labels[0] = lower.label && lower.label.length > 0 ? lower.label : cols[0];
    labels[labels.length - 1] = upper.label && upper.label.length > 0 ? upper.label : cols[cols.length - 1];

    return { values: cols, labels: labels };
  });
}
