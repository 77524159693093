import { DocExtension, ParagraphExtension } from "remirror/extensions";
import ItemExtension from "./ItemExtension";
import SpaceExtension from "./SpaceExtension";
import ItemMarkExtension from "./ItemMarkExtension";
import SpeakerExtension from "./SpeakerExtension";
import PlainTextCopyExtension from "./PlainTextCopyExtension";

export function getExtensions(config, onWordClick, speakers) {
  const extensions = [];

  if (config === "string") {
    // doc does not support paragraphs
    extensions.push(new DocExtension({ content: "text*" }));
  }

  if (config === "items") {
    extensions.push(
      new ParagraphExtension({
        extraAttributes: { class: { default: "transcription-paragraph" } },
        nodeOverride: { content: "(item | space)*" },
      })
    );
    const handleNodeClick = (_, nodeWithPos) => {
      const { node } = nodeWithPos;
      if (node.attrs.startTime && onWordClick) {
        onWordClick(node.attrs.startTime);
      }
      return true;
    };
    extensions.push(new ItemExtension({ onClick: handleNodeClick }));
    extensions.push(new SpaceExtension());
  }

  if (config === "doc") {
    extensions.push(
      new SpeakerExtension({
        extraAttributes: {
          speaker: { default: "1" },
          startTime: { default: "" },
        },
        speakers,
      })
    );
    const handleMarkClick = (_, markRange) => {
      const { mark } = markRange;
      if (mark.attrs.startTime && onWordClick) {
        onWordClick(mark.attrs.startTime);
      }
      return true;
    };
    extensions.push(new ItemMarkExtension({ onClick: handleMarkClick }));
  }

  extensions.push(new PlainTextCopyExtension());

  return extensions;
}
