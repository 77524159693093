import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { LooksOne, LooksTwo, Looks3 } from "@material-ui/icons";

class MediaDownloadPopup extends React.Component {
  state = {
    email: "",
  };

  // This function estimates the media download size. It assumes 1MB audio files and 5MB video files.
  estimateMediaSizeMB = () => {
    if (!this.props.sessions || !this.props.survey) return 0;
    var estimate = 0;
    for (let i = 0; i < this.props.survey.questions.length; i++) {
      if (this.props.survey.questions[i].type.includes("AUDIO")) estimate += 1;
      else if (this.props.survey.questions[i].type.includes("VIDEO"))
        estimate += 5;
      else if (this.props.survey.questions[i].type.includes("SCREEN"))
        estimate += 10;
    }
    return estimate * this.props.sessions.length;
  };

  render() {
    const showDataSizeAlert = this.estimateMediaSizeMB() >= 200; // >1GB we begin to see issues. 200MB is a generous safety factor.

    console.log(this.props.sessions);
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className="popup-container small">
          <h2 className="dialog-title-small" style={{ marginBottom: 20 }}>
            Download Media Responses
          </h2>
          <List>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <LooksOne />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <>
                    <b>Option 1:</b> Recieve a Download Link
                  </>
                }
                secondary="A zip file containing all media collected by this survey will be sent to your email. If no email is provided, media will be sent to your account email."
              />
            </ListItem>
            {showDataSizeAlert && (
              <ListItem>
                <ListItemAvatar />
                <Alert severity="warning">
                  It looks like you have a lot of data. This option isn't best
                  for large media downloads. If you don't receieve an email
                  shortly, please select a different option below.
                </Alert>
              </ListItem>
            )}
            <ListItem>
              <ListItemAvatar />
              <TextField
                variant="outlined"
                placeholder={this.props.user ? this.props.user.email : "email"}
                autoFocus
                fullWidth
                onChange={(e) => {
                  this.setState({ email: e.target.value });
                }}
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <LooksTwo />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <>
                    <b>Option 2:</b> Download via API
                  </>
                }
                secondary={
                  <>
                    <a
                      href="https://phonic.notion.site/Download-Responses-via-API-6232b86cc63e4dcea71a3607e8995c09"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Click here
                    </a>{" "}
                    for instructions on downloading media using the Phonic API.
                    This might require some programming knowledge! (Our
                    documentation uses Python 3)
                  </>
                }
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Looks3 />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <>
                    <b>Option 3:</b> Have our team assist your download
                  </>
                }
                secondary={
                  <>
                    <a
                      href={`mailto:contact@phonic.ai?subject=Media Download Request: ${this.props.surveyId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Click here
                    </a>{" "}
                    to have our team assist. By clicking this option you
                    acknowledge that we will have temporary access to your data
                    during the download process. Please allow 24-48 hours for
                    media export requests.
                  </>
                }
              />
            </ListItem>
          </List>
          <DialogActions>
            <Button
              variant="outlined"
              component="label"
              onClick={(e) => {
                this.props.onClose(e);
              }}
              className="phonic-outlined-button"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              component="label"
              onClick={() => {
                this.props.onClose();
                this.props.startSurveyMediaDownload(
                  this.props.surveyId,
                  this.state.email !== "" ? this.state.email : undefined
                );
              }}
              color="primary"
            >
              Download
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
}
export default MediaDownloadPopup;
