import {
  findParentNodeOfType,
  isElementDomNode,
  isObject,
  isString,
  isEqual,
} from "@remirror/core";
import { ParagraphExtension } from "remirror/extensions";
import {
  chartColors,
  getTimeStringFromSeconds,
  getAvatarUrl,
} from "utils/utils";
import { SpeakerState } from "./speakerPlugin";

// Check that the attributes being changed exist and are valid
function isValidSpeakerAttributes(attributes) {
  if (attributes && isObject(attributes)) {
    const value = attributes["speaker"];
    return isString(value) && value.length > 0;
  }

  return false;
}

// Populate DOM elements with speaker data
function populateSpeakerChildren(
  speakerAvatarDOM,
  speakerTextDOM,
  speaker,
  speakerName
) {
  speakerAvatarDOM.setAttribute(
    "src",
    getAvatarUrl({ displayName: speakerName }, 64, {
      background: chartColors[parseInt(speaker) % chartColors.length].replace(
        "#",
        ""
      ),
      bold: true,
    })
  );

  speakerTextDOM.setAttribute("speaker", speaker);
  speakerTextDOM.textContent = speakerName;
}

class SpeakerExtension extends ParagraphExtension {
  createNodeViews() {
    return (node, _view, _getPos, decorations) => {
      const speakerName = decorations[0]?.spec?.speakerName;

      const dom = document.createElement("div");
      dom.classList.add("transcription-paragraph");
      const contentDOM = document.createElement("div");

      const speakerContainer = document.createElement("div");
      speakerContainer.classList.add("speaker-avatar-container");
      speakerContainer.setAttribute("contenteditable", "false");

      const speakerAvatar = document.createElement("img");
      speakerAvatar.classList.add("speaker-avatar");
      speakerAvatar.setAttribute("contenteditable", "false");

      const speakerWrapper = document.createElement("span");

      populateSpeakerChildren(
        speakerAvatar,
        speakerWrapper,
        node.attrs.speaker,
        speakerName
      );

      speakerContainer.appendChild(speakerAvatar);
      speakerContainer.appendChild(speakerWrapper);
      speakerContainer.append(" - ");
      speakerContainer.append(getTimeStringFromSeconds(node.attrs.startTime));

      dom.appendChild(speakerContainer);
      dom.appendChild(contentDOM);

      const update = (newNode, decorations) => {
        if (newNode.type !== node.type) return false;

        const speakerAvatar = dom.querySelector("img.speaker-avatar");
        const speakerWrapper = dom.querySelector("span[speaker]");

        const newSpeakerName = decorations[0]?.spec?.speakerName;

        if (decorations && speakerWrapper.textContent !== newSpeakerName) {
          populateSpeakerChildren(
            speakerAvatar,
            speakerWrapper,
            newNode.attrs.speaker,
            newSpeakerName
          );
          return true;
        }
      };

      return {
        dom,
        contentDOM,
        update,
      };
    };
  }

  createNodeSpec(extra, override) {
    return {
      ...super.createNodeSpec(extra, override),
      toDOM: (node) => {
        return [
          "div",
          {
            class: "transcription-paragraph",
            speaker: node.attrs.speaker,
            "start-time": node.attrs.startTime,
          },
          0,
        ];
      },
      parseDOM: [
        {
          tag: "div.transcription-paragraph",
          getAttrs: (dom) => {
            if (!isElementDomNode(dom)) return;
            const speaker = dom.getAttribute("speaker");
            const startTime = dom.getAttribute("start-time");
            return {
              class: dom.getAttribute("class"),
              speaker,
              startTime,
            };
          },
        },
        ...(override.parseDOM ?? []),
      ],
    };
  }

  createPlugin() {
    const pluginState = new SpeakerState(this.type, this.options.speakers);
    return {
      state: {
        init(_, state) {
          return pluginState.init(state);
        },
        apply(tr, _, __, state) {
          const action = tr.getMeta(SpeakerExtension.name);
          return pluginState.apply({ tr, action }, state);
        },
      },
      props: {
        decorations() {
          return pluginState.decorationSet;
        },
      },
    };
  }

  createCommands() {
    return {
      // Update node attrs. This is used to update the segment
      updateSegment:
        (attributes, pos) =>
        ({ state: { doc, tr }, dispatch }) => {
          if (!isValidSpeakerAttributes(attributes) || !pos) {
            return false;
          }

          const parent = findParentNodeOfType({
            types: this.type,
            selection: doc.resolve(pos),
          });

          if (!parent || isEqual(attributes, parent.node.attrs)) {
            // Do nothing as attrs are the same
            return false;
          }

          tr.setNodeMarkup(parent.pos, this.type, {
            ...parent.node.attrs,
            ...attributes,
          });

          if (dispatch) {
            dispatch(tr);
          }

          return true;
        },
      updateSpeaker:
        (speakerIdx, speakerInfo) =>
        ({ state: { doc, tr }, dispatch }) => {
          if (dispatch) {
            tr.setMeta(SpeakerExtension.name, {
              type: "REDRAW_SPEAKERS",
              speakerIdx,
              speakerInfo,
            });
            dispatch(tr);
          }

          return true;
        },
    };
  }
}

export default SpeakerExtension;
