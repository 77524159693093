import React from "react";
import { getContrastYIQ } from "../utils/utils";

const SplitChip = ({
  left,
  right,
  color /* color should be a hex code*/,
  style,
}) => {
  return (
    <div className="device-type-chip" style={style}>
      <div className="device-type-chip-left">{left}</div>
      <div
        className="device-type-chip-right"
        style={{
          backgroundColor: color,
          color: color ? getContrastYIQ(color) : null,
        }}
      >
        {right}
      </div>
    </div>
  );
};

export default SplitChip;
