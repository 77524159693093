import React, { Component } from "react";
import { withRouter } from "react-router";
import SurveyQuestion from "./SurveyQuestion";
import AddQuestionButton from "./SurveyCreation/AddQuestionButton";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder } from "../utils/utils";
import WelcomeScreens from "./SurveyCreation/WelcomeScreens";
import NewQuestionPopup from "./Popups/NewQuestionPopup";
import clsx from "clsx";

import SurveyQuestionGroup from "./SurveyQuestionGroup";
import CreateLogicPopup from "./Popups/CreateLogicPopup/index";
import { updateItemInArray } from "utils/utils";
import {
  Dialog, 
  DialogContent, 
  DialogTitle,
  Button
} from "@material-ui/core";

class SurveyItem {
  constructor(type) {
    if (type !== "QUESTION" && type !== "QUESTION-GROUP")
      throw new Error("SurveyItem type must be 'QUESTION' or 'QUESTION-GROUP");
    this._type = type;
    this._item = undefined;
  }

  get item() {
    return this._item;
  }

  get type() {
    return this._type;
  }

  get id() {
    let _id = undefined;
    switch (this.type) {
      case "QUESTION":
        _id = this.item.questionId;
        break;
      case "QUESTION-GROUP":
        if (this.item.length > 0) _id = this.item[0].groupId;
        break;
      default:
        break;
    }
    return _id;
  }

  get length() {
    return (this.item && this.item.length) || 0;
  }

  setItem(item) {
    this._item = item;
  }

  add(question) {
    switch (this.type) {
      case "QUESTION":
        if (this.item !== undefined)
          throw new Error(`SurveyItem is already defined: ${this.item}`);
        if (question !== Object(question) || question.questionId === undefined)
          throw new Error("Question object is invalid");
        this._item = question;
        break;
      case "QUESTION-GROUP":
        if (this._item === undefined) this._item = [];
        if (question !== Object(question) || question.questionId === undefined)
          throw new Error("Question object is invalid");
        this._item.push(question);
        break;
      default:
        break;
    }
  }

  questionInItem(questionId) {
    switch (this.type) {
      case "QUESTION":
        return questionId === this.id;
      case "QUESTION-GROUP":
        for (const question in this.item)
          if (questionId === question.questionId) return true;
        return false;
      default:
        break;
    }
  }

  getGroup(questionGroups) {
    return this.length > 0 ? questionGroups[this.item[0].groupId] : null;
  }

  reorderItems(startIdx, endIdx) {
    if (this.type === "QUESTION-GROUP")
      this._item = reorder(this._item, startIdx, endIdx);
  }
}

class ItemList extends Component {
  /* Using shouldComponentUpdate to avoid rendering of all survey items when a
  Drag is initiated

  See the following link for explanation:
  https://egghead.io/lessons/react-optimize-performance-in-react-beautiful-dnd-with-shouldcomponentupdate-and-purecomponent
  */
  shouldComponentUpdate(nextProps) {
    if (
      nextProps.surveyItems === this.props.surveyItems &&
      nextProps.sourceDroppable === this.props.sourceDroppable
    ) {
      return false;
    }
    return true;
  }

  render() {
    let idxOffset = 0;
    let questionIdx = 0;
    return this.props.surveyItems.map((item, itemIdx) => {
      const isItemSelected = item.questionInItem(this.props.selectedQuestionId);
      questionIdx = itemIdx + idxOffset;
      const itemProps = {
        type: item.type,
        id: item.id,
        idx: questionIdx,
        info: this.props.info,
        key: `${item.type}-${item.id}`,
        qinfo: item.item,
        questionGroup: item.getGroup(this.props.questionGroups),
        modFunctions: this.props.modFunctions,
        borderBottom: questionIdx !== this.props.numQuestions - 1,
        surveyId: this.props.surveyId,
        tier: this.props.tier,
        selected: isItemSelected,
        questionFocusOverride: this.props.questionFocusOverride,
        openLogicPopup: this.props.openLogicPopup,
        // Hide the add question popup during drag
        // actions to prevent layering issues
        hideAddQuestionPopup: this.props.droppableSnapshot.isDraggingOver,
        addQuestionWithPopup: this.props.addQuestionWithPopup,
      };

      idxOffset += Math.max(item.length - 1, 0);

      const draggableID = `DRAGGABLE-${item.type}-${item.id}`;

      return (
        <Draggable key={draggableID} draggableId={draggableID} index={itemIdx}>
          {(provided) => {
            const style = {
              position: "relative",
              zIndex: isItemSelected ? "1" : "10",
              ...provided.draggableProps.style,
            };

            return (
              <>
                {item.type === "QUESTION" && (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={style}
                  >
                    <SurveyQuestion
                      {...itemProps}
                      key={`QUESTION-${item.id}`}
                    />
                  </div>
                )}
                {item.type === "QUESTION-GROUP" && (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={style}
                  >
                    <SurveyQuestionGroup
                      {...itemProps}
                      selectedQuestionId={this.props.selectedQuestionId}
                      providedDragHandleProps={provided.dragHandleProps}
                      sourceDroppable={this.props.sourceDroppable}
                    />
                  </div>
                )}
              </>
            );
          }}
        </Draggable>
      );
    });
  }
}

class SurveyItems extends Component {
  state = {
    addQuestionPopupOpen: false,
    surveyItems: [],
    questions: this.props.questions,
    sourceDroppable: null,
    logicPopup: {
      open: false,
      questionIdx: null,
    },
  };
  surveyItems = [];

  onDragStart = (start) => {
    this.setState({ sourceDroppable: start.source.droppableId });
  };

  onDragEnd = (result) => {
    this.setState({ sourceDroppable: null });
    if (!result.destination) {
      return;
    }

    let itemsClone = [...this.state.surveyItems];
    let reorderedItems = [];
    if (result.destination.droppableId === "ITEM-DROPPABLE") {
      reorderedItems = reorder(
        itemsClone,
        result.source.index,
        result.destination.index
      );
    } else if (result.destination.droppableId.startsWith("GROUP-DROPPABLE")) {
      const groupId = result.destination.droppableId.split("-")[2];
      itemsClone.forEach((item) => {
        if (groupId === item.id) {
          item.reorderItems(result.source.index, result.destination.index);
        }
      });
      reorderedItems = itemsClone;
    }

    let newQuestions = [];
    reorderedItems.forEach((item) => {
      if (item.type === "QUESTION") {
        newQuestions.push(item.item);
      } else if (item.type === "QUESTION-GROUP") {
        newQuestions.push(...item.item);
      }
    });
    this.props.changeInfo({ questions: newQuestions }, true);
  };
  addQuestion = (idx, type) => {
    this.props.modFunctions.addQuestion(idx, type);
  };
  addQuestionWithPopup = (idx) => {
    this.setState({ addQuestionPopupOpen: true, addQuestionIdx: idx });
  };

  /**
   * Create a list of survey items to display from questions. A survey item
   * can be a question or a question group with a list of questions
   *
   * Returns an ordered list of survey items to display
   */
  generateSurveyItems = () => {
    let surveyItems = [];
    let prevQuestionGroupId = undefined;
    let questionGroup = new SurveyItem("QUESTION-GROUP");

    const pushGroupIfExists = () => {
      if (questionGroup.length > 0) {
        surveyItems.push(questionGroup);
        questionGroup = new SurveyItem("QUESTION-GROUP");
      }
    };

    for (const idx in this.props.questions) {
      const question = this.props.questions[idx];
      if (question.groupId === undefined) {
        pushGroupIfExists();
        const questionItem = new SurveyItem("QUESTION");
        questionItem.add(question);
        surveyItems.push(questionItem);
      } else {
        if (question.groupId !== prevQuestionGroupId) pushGroupIfExists();
        prevQuestionGroupId = question.groupId;
        questionGroup.add(question);
      }
    }
    pushGroupIfExists();
    this.setState({ surveyItems: surveyItems });
  };

  updateSurveyItems = () => {
    let surveyItems = [];
    let questionIdx = 0;
    for (const item of this.state.surveyItems) {
      switch (item.type) {
        case "QUESTION":
          if (this.props.questions[questionIdx] !== item.item) {
            const newItem = new SurveyItem("QUESTION");
            newItem.add(this.props.questions[questionIdx]);
            surveyItems.push(newItem);
          } else surveyItems.push(item);
          questionIdx += 1;
          break;
        case "QUESTION-GROUP":
          for (const qIdx in item.item) {
            const questionInGroup = item.item[qIdx];
            if (this.props.questions[questionIdx] !== questionInGroup)
              item.setItem(
                updateItemInArray(
                  item.item,
                  qIdx,
                  this.props.questions[questionIdx]
                )
              );
            questionIdx += 1;
          }
          surveyItems.push(item);
          break;
        default:
      }
    }
    this.setState({ surveyItems: surveyItems });
  };

  componentDidMount() {
    this.generateSurveyItems();
  }

  questionOrderNotChanged(prevQuestions) {
    if (this.props.questions.length === prevQuestions.length) {
      for (let [idx, question] of this.props.questions.entries()) {
        if (question.questionId !== prevQuestions[idx].questionId) return false;
      }
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.questions !== prevProps.questions) {
      if (this.questionOrderNotChanged(prevProps.questions)) {
        this.updateSurveyItems();
      } else {
        this.generateSurveyItems();
      }
    }
  }

  openLogicPopup = (questionIdx) =>
    this.setState({
      logicPopup: {
        open: true,
        questionIdx: questionIdx,
      },
    });

  closeLogicPopup = () =>
    this.setState({
      logicPopup: {
        open: false,
        questionIdx: null,
      },
    });

  render() {
    return (
      <>
        <NewQuestionPopup
          open={this.state.addQuestionPopupOpen}
          handleClose={() => this.setState({ addQuestionPopupOpen: false })}
          addQuestion={this.addQuestion}
          info={this.props.info}
          changeInfo={this.props.changeInfo}
          modFunctions={this.props.modFunctions}
          idx={this.state.addQuestionIdx}
        />
        <Dialog
        open={this.state.logicPopup.open}
        onClose={this.closeLogicPopup}
        aria-labelledby="form-dialog-title"
        >
          <div className="popup-container">
            <DialogTitle id="form-dialog-title"><h2>Question Logic</h2>{" "}</DialogTitle>
            <DialogContent style={{ paddingBottom: 30 }}>
              <CreateLogicPopup
                questions={this.props.questions}
                questionIdx={this.state.logicPopup.questionIdx}
                changeQInfo={this.props.modFunctions.changeQInfo}
                modFunctions={this.props.modFunctions}
              />
              <Button
                className="Mui-Overrides font-dark"
                variant="outlined"
                style={{ float: "right" }}
                onClick={() => this.closeLogicPopup() }
              >
                Done
              </Button>
            </DialogContent>
          </div>
        </Dialog>
        <DragDropContext
          onDragEnd={this.onDragEnd}
          onDragStart={this.onDragStart}
          onDragUpdate={this.onDragUpdate}
        >
          <Droppable
            droppableId="ITEM-DROPPABLE"
            isDropDisabled={this.state.sourceDroppable !== "ITEM-DROPPABLE"}
          >
            {(provided, droppableSnapshot) => (
              <div>
                <div className="question-outer-container">
                  <div
                    className={clsx("pt-10", "pb-10", "fg-1")}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {!this.props.info.removeWelcomeScreen && (
                      <div className="mb-10">
                        <WelcomeScreens
                          info={this.props.info}
                          changeInfo={this.props.changeInfo}
                          modFunctions={this.props.modFunctions}
                        />
                      </div>
                    )}
                    <ItemList
                      surveyItems={this.state.surveyItems}
                      droppableSnapshot={droppableSnapshot}
                      questionGroups={this.props.questionGroups}
                      modFunctions={this.props.modFunctions}
                      surveyId={this.props.surveyId}
                      tier={this.props.tier}
                      addQuestionWithPopup={this.addQuestionWithPopup}
                      questionFocusOverride={this.props.questionFocusOverride}
                      sourceDroppable={this.state.sourceDroppable}
                      openLogicPopup={this.openLogicPopup}
                      numQuestions={this.props.questions.length}
                      info={this.props.info}
                      selectedQuestionId={this.props.selectedQuestionId}
                    />
                    {provided.placeholder}
                  </div>
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div style={{ marginBottom: 20 }}>
          <AddQuestionButton
            action={() => this.addQuestionWithPopup(undefined)}
            text="+ New"
          />
        </div>
      </>
    );
  }
}

export default withRouter(SurveyItems);
