import React, { Component } from "react";
import Chart from "chart.js/auto";
import { getChartColors } from "utils/utils";
import { truncateStringWithEllipsis } from "utils/utils";

export default class BarChart extends Component {
  chartRef = React.createRef();
  chartInstance = null;

  componentDidMount() {
    this.buildChart();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.chartData.data !== this.props.chartData.data) {
      this.buildChart();
    }
  }

  buildChart = () => {
    const myChartRef = this.chartRef.current.getContext("2d");
    if (this.chartInstance) this.chartInstance.destroy();
    this.chartInstance = new Chart(myChartRef, {
      type: "bar",
      data: {
        labels: this.props.chartData.labels,
        datasets: [
          {
            data: this.props.chartData.data,
            backgroundColor: this.props.backgroundColor
              ? this.props.backgroundColor
              : getChartColors(this.props.chartData.data.length),
            borderColor: this.props.borderColor,
            borderWidth: this.props.borderColor ? 2 : undefined,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        borderRadius: 4,
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              callback: (val) => {
                return truncateStringWithEllipsis(
                  this.props.chartData.labels[val],
                  10
                );
              },
            },
            scaleLabel: {
              display: Boolean(this.props.xLabel),
              labelString: this.props.xLabel,
            },
          },
          y: {
            grid: {
              display: null,
            },
            ticks: {
              beginAtZero: true,
              precision: 0,
            },
            scaleLabel: {
              display: this.props.yLabel ? true : false,
              labelString: this.props.yLabel,
            },
          },
        },
      },
    });
  };

  render() {
    return (
      <div>
        <canvas id="bar-chart" className="basic-chart" ref={this.chartRef} />
      </div>
    );
  }
}
