import { getNElements, conversationColors } from "./utils";
import cloneDeep from "lodash/cloneDeep";

/* Transcription Utils */

export const nodeType = {
  PRONUNCIATION: "pronunciation",
  PUNCTUATION: "punctuation",
  PARAGRAPH: "paragraph",
  SPEAKER: "speaker",
};

const getWordsWithinTimesFromItems = (startTime, endTime, items) => {
  // TODO: Make this faster
  return items.filter((item, idx) => {
    if (
      parseFloat(item.start_time) >= startTime &&
      parseFloat(item.end_time) <= endTime
    )
      return true;
    if (
      item.type === nodeType.PUNCTUATION &&
      idx > 0 &&
      items[idx - 1] &&
      parseFloat(items[idx - 1].start_time) >= startTime &&
      parseFloat(items[idx - 1].end_time) <= endTime
    )
      return true;
    return false;
  });
};

function* naturalNumbers() {
  let num = 0;
  while (true) {
    yield num;
    num += 1;
  }
}

const getSegments = (speakers, items, numSpeakers) => {
  const speakerColors = getNElements(
    conversationColors,
    numSpeakers > 1 ? numSpeakers : 1
  );
  return (
    speakers &&
    speakers.segments
      .map((speaker) => {
        const transcriptionItems = getWordsWithinTimesFromItems(
          speaker.start_time,
          speaker.end_time,
          items
        );
        return !transcriptionItems || transcriptionItems.length === 0
          ? null
          : {
              startTime: speaker.start_time,
              endTime: speaker.end_time,
              // attrs should be strings
              speaker: `${parseInt(speaker.speaker_label.split("_")[1]) + 1}`,
              speakerColor:
                speakerColors[parseInt(speaker.speaker_label.split("_")[1])],
              items: transcriptionItems,
            };
      })
      .filter((segment) => segment !== null)
  );
};

// Generates editor value for the ResponseEditor from `transcription_items`
export const itemsToEditorValue = (items, speakers, numSpeakers) => {
  // Ignore the first transcription item if it is an empty object.
  if (items.length > 0 && Object.keys(items[0]).length === 0) {
    /*This happens because of a bug in updateTranscription logic that sometimes
    corrupted transcription items with an empty object upon transcription
    editing. The bug in updateTranscription has been fixed. The code below is
    required to support transcription editing for transcription items
    that have already been corrupted by the bug. We can remove the code below
    when we fix corrupted transcription_items after running a migration */
    items = items.slice(1);
  }

  const segments = getSegments(speakers, items, numSpeakers);
  const masterIndex = naturalNumbers();

  const getNodeFromTranscriptionItem = (acc, item, localIdx) => {
    const spaceBefore = item.type === nodeType.PRONUNCIATION && localIdx !== 0;
    const itemNode = {
      type: "item",
      attrs: {
        type: item.type,
        spaceBefore,
        startTime: item.start_time || null,
        endTime: item.end_time || null,
        transcriptionIdx: masterIndex.next().value,
      },
      content: [
        {
          type: "text",
          text:
            item && item.alternatives && item.alternatives.length
              ? item.alternatives[0].content
              : "",
        },
      ],
    };

    return (
      (spaceBefore
        ? acc.push({ type: "space" }, itemNode)
        : acc.push(itemNode)) && acc
    );
  };

  const paragraphNode = (segment) => ({
    type: nodeType.PARAGRAPH,
    content: segment.items.reduce(getNodeFromTranscriptionItem, []),
    startTime: segment.startTime || 0,
    endTime: segment.endTime,
    speaker: segment.speaker,
    speakerColor: segment.speakerColor,
  });

  return segments
    ? segments.map((segment) => paragraphNode(segment))
    : [
        {
          type: nodeType.PARAGRAPH,
          content: items.reduce(getNodeFromTranscriptionItem, []),
        },
      ];
};

// initializeEditorDoc
export const conversationToEditorValue = (items, speakers, numSpeakers) => {
  let segments = getSegments(speakers, items, numSpeakers);

  const getNodeFromTranscriptionItem = (acc, item, localIdx) => {
    if (!item.alternatives || item.alternatives.length === 0) return acc;

    const itemNode = {
      type: "text",
      text: item.alternatives[0].content,
      ...(item.type === nodeType.PRONUNCIATION && {
        marks: [
          {
            type: "time",
            attrs: {
              startTime: item.start_time || null,
              endTime: item.end_time || null,
            },
          },
        ],
      }),
    };

    const spaceBefore = item.type === nodeType.PRONUNCIATION && localIdx !== 0;
    /**
     * Even if we insert space nodes like this, ProseMirror merges adjacent
     * text nodes with the same marks e.g.
     * [{ type: "text", text: "Hello" }, { type: "text", text: "World" }]
     * becomes
     * [{ type: "text", text: "Hello World" }]
     * So space nodes and punctuation nodes will be merged automatically on save.
     */
    return (
      (spaceBefore
        ? acc.push({ type: "text", text: " " }, itemNode)
        : acc.push(itemNode)) && acc
    );
  };

  const mergeAdjacentTextNodes = (items) => {
    const array = items.reduce(getNodeFromTranscriptionItem, []);
    let joined = null;
    for (let i = 0; i < array.length; i++) {
      let node = array[i];
      if (i && node.marks === undefined && array[i - 1].marks === undefined) {
        if (!joined) joined = array.slice(0, i);
        joined[joined.length - 1].text += node.text;
      } else if (joined) {
        joined.push(node);
      }
    }

    return joined || array;
  };

  const paragraphNode = (segment) => {
    return {
      type: nodeType.PARAGRAPH,
      attrs: { speaker: segment.speaker, startTime: segment.startTime },
      content: mergeAdjacentTextNodes(segment.items),
    };
  };

  segments = segments
    ? segments.map((segment) => paragraphNode(segment))
    : [
        {
          type: nodeType.PARAGRAPH,
          content: mergeAdjacentTextNodes(items),
        },
      ];
  return segments;
};

export function updateTranscriptionItems(editorContent, transcriptionItems) {
  /**
   * Update transcription items given value of edited Prose Mirror document
   * and old transcription items.
   * @param {Array} editorContent: Prose Mirror document content
   * @param {Array} transcriptionItems: old transcription items
   * @returns {Array} new transcription items
   */
  // Flatten editor content to array of transcription items
  const editedItems = editorContent.reduce((acc, paragraph) => {
    return acc.concat(
      paragraph.content.filter((node) => node.type !== "space" && node.content)
    );
  }, []);

  // Build new transcription items by comparing old and edited transcription items
  const newTranscriptionItems = [];

  // Pointers to old and edited transcription items
  let transcriptionIdx = 0;
  let editorIdx = 0;
  // Iterate through transcription items and editor content in parallel
  while (
    transcriptionIdx < transcriptionItems.length &&
    editorIdx < editedItems.length
  ) {
    const transcriptionItem = transcriptionItems[transcriptionIdx];

    const editedItem = editedItems[editorIdx];
    const { content, attrs } = editedItem;
    const [{ text: editedText }] = content;
    const { transcriptionIdx: editedTranscriptionIdx } = attrs;

    /**
     * If we find the transcription item in the editor content, create a new
     * item with updated text and push it to the new transcription items list.
     * Move the pointers to the next transcription item and editor item.
     */
    if (transcriptionIdx === editedTranscriptionIdx) {
      let newTranscriptionItem = transcriptionItem;
      const [alternative] = transcriptionItem.alternatives || [
        { content: "", confidence: 1.0 },
      ];
      if (editedText !== alternative.content) {
        newTranscriptionItem = {
          ...transcriptionItem,
          alternatives: [
            {
              ...alternative,
              content: editedText,
            },
          ],
        };
      }
      newTranscriptionItems.push(newTranscriptionItem);
      transcriptionIdx++;
      editorIdx++;
    } else if (transcriptionIdx < editedTranscriptionIdx) {
      /**
       * If transcriptionIdx < editorTranscriptionIdx, the item was deleted.
       * Update the last item in the new transcription items list with the
       * deleted item's end time. Move to the next transcription item.
       */
      const prevItem = newTranscriptionItems.pop();
      newTranscriptionItems.push({
        ...prevItem,
        end_time: transcriptionItem.endTime,
      });
      transcriptionIdx++;
    } else if (!editedTranscriptionIdx) {
      // If this happens, push the editor item to the new transcription items
      editorIdx++;
    }
  }
  return newTranscriptionItems;
}

export function updateTranscriptionItemsFromDoc(
  editorContent,
  transcriptionItems
) {
  /**
   * Update transcription items given value of edited Prose Mirror document
   * and old transcription items.
   * @param {Array} editorContent: Prose Mirror document content
   * @param {Array} transcriptionItems: old transcription items
   * @returns {Array} new transcription items
   */
  // Flatten editor content to array of transcription items
  let editedItems = [];
  editorContent.forEach((paragraph) => {
    if (paragraph.content) {
      const array = cloneDeep(paragraph.content);
      let joined = [];
      for (let i = 0; i < array.length; i++) {
        const node = array[i];
        if (node.text === " ") continue;
        if (
          node.text.trimEnd() === "." ||
          node.text.trimEnd() === "?" ||
          node.text.trimEnd() === "!" ||
          node.text.trimEnd() === ","
        ) {
          joined.push({ ...node, text: node.text.trimEnd() });
          continue;
        }
        if (i && array[i - 1].marks) {
          joined[joined.length - 1].text += node.text;
          if (node.marks) {
            joined[joined.length - 1].marks[0].attrs = {
              ...joined[joined.length - 1].marks[0].attrs,
              endTime: node.marks[0].attrs.endTime,
            };
          }
        } else {
          joined.push(node);
        }
      }
      editedItems = editedItems.concat(joined);
    }
  });


  // Build new transcription items by comparing old and edited transcription items
  const newTranscriptionItems = [];

  // Pointers to old and edited transcription items
  let transcriptionIdx = 0;
  let editorIdx = 0;
  // Iterate through transcription items and editor content in parallel
  while (editorIdx < editedItems.length) {
    const editedItem = editedItems[editorIdx];
    const { text: editedText, marks } = editedItem;
    if (!marks) {
      newTranscriptionItems.push({
        alternatives: [{ content: editedText, confidence: "0.0" }],
        type: "punctuation",
      });
      editorIdx++;
      continue;
    }

    const { startTime, endTime } = marks[0].attrs;

    const transcriptionItem = transcriptionItems[transcriptionIdx];
    const { start_time, alternatives } = transcriptionItem;

    /**
     * If we find the transcription item in the editor content, create a new
     * item with updated text and push it to the new transcription items list.
     * Move the pointers to the next transcription item and editor item.
     */
    if (!start_time) {
      transcriptionIdx++;
      continue;
    }
    if (startTime === start_time) {
      let newTranscriptionItem = transcriptionItem;
      const [alternative] = alternatives || [{ content: "", confidence: 1.0 }];
      newTranscriptionItem = {
        ...transcriptionItem,
        alternatives: [
          {
            ...alternative,
            content: editedText,
          },
        ],
        end_time: endTime,
      };
      newTranscriptionItems.push(newTranscriptionItem);
      editorIdx++;
      transcriptionIdx++;
    } else if (startTime > start_time) {
      /**
       * If transcriptionIdx < editorTranscriptionIdx, the item was deleted.
       * Update the last item in the new transcription items list with the
       * deleted item's end time. Move to the next transcription item.
       */
      transcriptionIdx++;
    } else {
      if (!marks[0]) {
        newTranscriptionItems.push({
          alternatives: [{ confidence: "0.0", content: editedText }],
          type: "punctuation",
        });
      } else {
        newTranscriptionItems.push({
          start_time: startTime,
          end_time: endTime,
          alternatives: [{ confidence: "1.0", content: editedText }],
          type: "pronunciation",
        });
      }
      editorIdx++;
    }
  }
  return newTranscriptionItems;
}

export const generateSpeakers = (numSpeakers) => {
  return Array.from({ length: numSpeakers }, (_, i) => ({
    name: `Speaker ${i + 1}`,
  }));
};
