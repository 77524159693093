import React from "react";
import Button from "@material-ui/core/Button";
import { MenuItem, Select, TextField, FormHelperText } from "@material-ui/core";

class CrosstabVarMenu extends React.Component {
  render() {
    return (
      <>
        <FormHelperText>{this.props.label}</FormHelperText>
        <Select
          fullWidth
          variant="outlined"
          value={this.props.var}
          onChange={(e) => this.props.setVar(e.target.value)}
        >
          {this.props.varOptions}
        </Select>
        {this.props.var.thresholdEligible && this.props.var.intervalEligible && (
          <>
            <FormHelperText>Transformer type</FormHelperText>
            <Select
              fullWidth
              variant="outlined"
              value={this.props.transformer}
              onChange={(e) => {
                this.props.setTransformer(e.target.value);
                this.props.setIntervals([0]);
              }}
            >
              <MenuItem value="THRESHOLD">Threshold</MenuItem>
              <MenuItem value="INTERVAL">Intervals</MenuItem>
            </Select>
          </>
        )}
        {this.props.transformer === "THRESHOLD" && (
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            type="number"
            label="Variable 1 Threshold"
            value={this.props.threshold}
            onChange={(e) =>
              this.props.setThreshold(parseFloat(e.target.value))
            }
            InputLabelProps={{ shrink: true }}
          />
        )}
        {this.props.transformer === "INTERVAL" && this.props.intervals && (
          <>
            <FormHelperText>Interval Boundaries</FormHelperText>
            {this.props.intervals.map((bounds, index) => (
              <TextField
                key={`CROSSTAB-INTERVAL-${index}`}
                variant="outlined"
                margin="normal"
                fullWidth
                type="number"
                label={`Lower bound of interval ${index + 2}`}
                value={bounds}
                onChange={(e) => {
                  let newBounds = this.props.intervals;
                  newBounds[index] = parseFloat(e.target.value);
                  for (let i = index + 1; i < newBounds.length; i++) {
                    if (newBounds[i] < newBounds[i - 1]) {
                      newBounds[i] = newBounds[i - 1];
                    }
                  }
                  this.props.setIntervals(newBounds);
                }}
                InputLabelProps={{ shrink: true }}
              />
            ))}
            <Button
              className="phonic-outlined-button"
              variant="outlined"
              onClick={() => {
                let newBounds = this.props.intervals;
                newBounds.push(newBounds[newBounds.length - 1]);
                this.props.setIntervals(newBounds);
              }}
            >
              Add an interval boundary
            </Button>
          </>
        )}
      </>
    );
  }
}

export default CrosstabVarMenu;
