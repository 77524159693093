import React from "react";
import clsx from "clsx";
import { getQuestionType } from "../utils/questionTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getQuestionGroup } from "utils/questionTypes";

const TypeTile = ({ type, num, shape, iconsOverride, colorOverride }) => {
  let tileClass = `qtype-tile shape-${shape}`;
  var tileColor, tileIcons;
  // Allow consumers to set their own colors and icons, or depend on the type defaults.
  if (iconsOverride !== undefined && colorOverride !== undefined) {
    tileColor = colorOverride;
    tileIcons = iconsOverride;
  } else if (type !== undefined) {
    let questionType = getQuestionType(type) || getQuestionGroup(type);
    tileColor = questionType.color;
    tileIcons = questionType.icons;
  }

  return (
    <div className={tileClass} style={{ backgroundColor: tileColor }}>
      <span className={clsx(num !== undefined && "mr-5")}>
        {tileIcons.map((icon, idx) => {
          return <FontAwesomeIcon key={`ICON-${idx}-${num}`} icon={icon} />;
        })}
      </span>
      <span>{num}</span>
    </div>
  );
};

export default TypeTile;
