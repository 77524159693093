import firebase from "firebase/app";
import "firebase/auth";
import { trackIdentify, track } from "../analytics";
import { store } from "react-notifications-component";
import {
  notification,
  launchHubspotWidget,
  mapFlatFilesToTree,
} from "utils/utils";
import {
  breakFileIntoParts,
  uploadParts,
  handleUploadError,
} from "utils/awsUtils";
import fetchWithAuth from "utils/fetchWithAuth";
import * as FullStory from "@fullstory/browser";
import { dataToCrosstabs } from "utils/utils";

let BASE_URL = "https://api.phonic.ai";
if (process.env.REACT_APP_BACKEND_ENV === "LOCAL") {
  BASE_URL = "http://127.0.0.1:8080";
} else if (process.env.REACT_APP_BACKEND_ENV === "STAGING") {
  BASE_URL = "https://backend-staging-dot-phonic-2.wl.r.appspot.com";
}

export function addAuthToken() {
  return (dispatch) => {
    if (!firebase.auth().currentUser) return;
    trackIdentify(firebase.auth().currentUser);
    return firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then((idToken) => {
        dispatch({ type: "ADD_AUTH_TOKEN", payload: idToken });
        dispatch(getUser());
      });
  };
}

export function addUser(userData) {
  track("Add User", { user: userData });
  track(`Add Use Case [${userData.useCase}]`);
  return (dispatch) => {
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then((idToken) => {
        return fetch(`${BASE_URL}/user`, {
          method: "POST",
          body: JSON.stringify(userData),
          headers: {
            Authorization: "Bearer " + idToken,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (response.status === 200) {
              dispatch(addAuthToken());
              dispatch(getUser());
            } else {
              throw response.status;
            }
          })
          .finally(() => {
            dispatch({
              type: "SET_LOADING",
              payload: false,
              name: "AddUser",
            });
          });
      });
  };
}

export function sendVerificationEmail(email) {
  track("Send Verification Email", { email: email });
  return (dispatch) => {
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then((idToken) => {
        return fetch(`${BASE_URL}/verify_email/create`, {
          method: "POST",
          body: JSON.stringify({ email: email }),
          headers: {
            Authorization: "Bearer " + idToken,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (response.status !== 200) {
              throw response.status;
            }
          })
          .catch((error) => {
            console.error(error);
            alert("Unable to send verification email.");
          })
          .finally(() =>
            dispatch({
              type: "SET_LOADING",
              payload: false,
              name: "SendVerificationEmail",
            })
          );
      });
  };
}

export function checkVerificationCode(email, code) {
  return (dispatch) => {
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then((idToken) => {
        return fetch(`${BASE_URL}/verify_email/check`, {
          method: "POST",
          body: JSON.stringify({ email: email, code: code }),
          headers: {
            Authorization: "Bearer " + idToken,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (response.status === 200) {
              dispatch({
                type: "EMAIL_VERIFIED",
                payload: true,
              });
            } else {
              throw response.status;
            }
          })
          .catch(() => {
            dispatch({ type: "EMAIL_VERIFIED", payload: false });
          })
          .finally(() =>
            dispatch({
              type: "SET_LOADING",
              payload: false,
              name: "EmailVerification",
            })
          );
      });
  };
}

export function getUser() {
  track("Get User");
  return (dispatch, getState) => {
    if (!getState().authToken) return;
    return fetch(`${BASE_URL}/user`, {
      headers: {
        Authorization: "Bearer " + getState().authToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Response was not ok");
        }
        return response.json();
      })
      .then((json) => {
        if (json && !json.readOnly) launchHubspotWidget();
        dispatch({ type: "RETURN_USER", payload: json });
        FullStory.identify(json._id, { email: json.email });
      })
      .catch((error) => {
        console.error("There was a problem.", error);
      });
  };
}

export function updateUser(userData) {
  track("Update User", { user: userData });
  return (dispatch, getState) => {
    return fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/user`,
      "PUT",
      userData
    )
      .then((response) => {
        if (response.status !== 200) throw response.status;
        else notification("Success", "User successfully updated.", "success");
      })
      .catch(() => {
        notification("Cannot update user.", "Please try again.", "warning");
      })
      .finally(() => {
        dispatch({
          type: "SET_LOADING",
          payload: false,
          name: "UpdateUser",
        });
      });
  };
}

export function getSurveys() {
  track("Get Surveys");
  return (dispatch, getState) => {
    dispatch({ type: "SET_LOADING", payload: true, name: "MySurveys" });
    // Silently swallow if no auth token
    if (!getState().authToken) return;
    return fetch(`${BASE_URL}/surveys`, {
      headers: {
        Authorization: "Bearer " + getState().authToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
      },
    })
      .then((response) => response.json())
      .then((json) => dispatch({ type: "RETURN_SURVEYS", payload: json }))
      .finally(() => {
        dispatch({ type: "SET_LOADING", payload: false, name: "MySurveys" });
      })
      .catch((e) => {
        console.error(e);
        notification(
          "Can't retrieve surveys right now. ",
          "Please try again.",
          "warning"
        );
      });
  };
}

export function getPublishedSurvey(surveyId) {
  return (dispatch, getState) => {
    fetchWithAuth(BASE_URL, getState().authToken, `/surveys/${surveyId}`, "GET")
      .then((response) => response.json())
      .then((publishedSurvey) => {
        if (publishedSurvey.data === "Survey not published.") {
          publishedSurvey = undefined;
        }
        dispatch({
          type: "RETURN_PUBLISHED_SURVEY",
          payload: { publishedSurvey },
        });
      });
  };
}

export function getSurvey(surveyId, opts) {
  return (dispatch, getState) => {
    const options = opts || {};
    let url = new URL(`${BASE_URL}/surveys/${surveyId}`);
    if (options.includeCompletionTimes) {
      url.searchParams.append("include_completion_times", true);
    } else if (options.staging) {
      url.searchParams.append("staging", true);
    }
    fetch(url, {
      headers: {
        Authorization: "Bearer " + getState().authToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        /* Survey duration estimate is returned in the GET request but must
         * be kept separate from the survey object in the app state */
        const { estimatedCompletionTimeMinutes, ...currentSurvey } = json;
        dispatch({
          type: "RETURN_CURRENT_SURVEY",
          payload: {
            currentSurvey,
            estimatedCompletionTimeMinutes,
          },
        });
      });
  };
}

export function deploySurvey(surveyId) {
  track("[Builder V2] Deploying Survey");
  return (dispatch, getState) => {
    fetch(`${BASE_URL}/surveys/${surveyId}/deploy`, {
      headers: {
        Authorization: "Bearer " + getState().authToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
      },
    })
      .then((response) => {
        if (response.status !== 200) throw response.status;
        response.json().then(() => {
          notification(
            "Successfully deployed survey.",
            "It is now available to accept responses.",
            "success"
          );
        });
      })
      .catch(() => {
        notification(
          "Could not deploy survey.",
          "Please try again.",
          "warning"
        );
      });
  };
}

export function getDrafts() {
  track("Viewed Drafts");
  return (dispatch, getState) => {
    // Silently swallow if no auth token
    if (!getState().authToken) return;
    return fetch(`${BASE_URL}/drafts`, {
      headers: {
        Authorization: "Bearer " + getState().authToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
      },
    })
      .then((response) => response.json())
      .then((json) => dispatch({ type: "RETURN_DRAFTS", payload: json }));
  };
}

export function getResponses(surveyId, questionId, search) {
  track("Get Responses", { questionId: questionId });
  return (dispatch, getState) => {
    dispatch({ type: "SET_LOADING", payload: true, name: "Question" });
    dispatch({ type: "SET_LOADING", payload: true, name: "GeneralResponse" });
    let path = `/surveys/${surveyId}/question/${questionId}/responses`;
    if (search) {
      path += search;
    }
    return fetchWithAuth(BASE_URL, getState().authToken, path, "GET")
      .then((response) => {
        if (response.status !== 200) throw response.status;
        return response.json();
      })
      .then((json) => {
        dispatch({
          type: "RETURN_RESPONSES",
          payload: json,
          questionId: questionId,
        });
      })
      .catch((error) => {
        console.error(error);
        alert("Unable to fetch responses.");
      })
      .then(() => {
        dispatch({ type: "SET_LOADING", payload: false, name: "Question" });
        dispatch({
          type: "SET_LOADING",
          payload: false,
          name: "GeneralResponse",
        });
        dispatch({ type: "SET_TOPICS_DIRTIED", payload: false });
      });
  };
}

export function getSessions(surveyId) {
  track("Get Sessions", { surveyId: surveyId });
  return (dispatch, getState) => {
    return fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/surveys/${surveyId}/sessions`,
      "GET"
    )
      .then((response) => {
        if (response.status !== 200) throw response.status;
        return response.json();
      })
      .then((json) => {
        dispatch({
          type: "RETURN_SESSIONS",
          payload: json,
          surveyId: surveyId,
        });
      })
      .catch((error) => {
        notification(
          `Error: ${error.message}`,
          "Unable to fetch sessions right now.",
          "danger"
        );
      });
  };
}

export function getTopics(questionId) {
  return (dispatch, getState) => {
    return fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/topics/${questionId}`
    )
      .then((response) => {
        if (!response.ok) throw response.status;
        return response.json();
      })
      .then((json) =>
        dispatch({
          type: "RETURN_TOPICS",
          payload: json,
          questionId: questionId,
        })
      )
      .catch((error) => {
        console.error(error);
        alert("Unable to fetch topics.");
      });
  };
}

export function getTags(groupId) {
  return (dispatch, getState) => {
    return fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/tags?group=${groupId}`,
      "GET"
    )
      .then((response) => {
        if (!response.ok) throw response.status;
        return response.json();
      })
      .then((json) => {
        dispatch({
          type: "RETURN_TAGS",
          tags: json.tags,
          groupId,
        });
        return json.tags;
      })
      .catch((err) => {
        console.error(err);
        notification(
          "Error",
          "Unable to fetch tags. Please try again",
          "warning"
        );
      });
  };
}

export function updateTag(tagId, update) {
  return (dispatch, getState) => {
    return fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/tags/${tagId}`,
      "PUT",
      update
    )
      .then((response) => {
        if (!response.ok) throw response.status;
        return response.json();
      })
      .then((json) => dispatch({ type: "UPDATE_TAG", tag: json.tag }))
      .catch((err) => {
        console.error(err);
        notification("Unable to edit tag.", "Please try again.", "warning");
      });
  };
}

export function deleteTag(tagId, groupId) {
  return (dispatch, getState) => {
    return fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/tags/${tagId}`,
      "DELETE"
    )
      .then((response) => {
        if (!response.ok) throw response.status;
        return response.json();
      })
      .then((json) =>
        dispatch({ type: "DELETE_TAG", deletedTagId: json.deleted_id, groupId })
      )
      .catch((err) => {
        console.error(err);
        notification("Unable to delete tag.", "Please try again.", "warning");
      });
  };
}

export function getTagHighlights(tagId) {
  return (_, getState) => {
    return fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/files/highlights?tags=${tagId}`
    )
      .then((response) => {
        if (!response.ok) throw response;
        return response.json();
      })
      .then((json) => {
        return json.data;
      })
      .catch((err) => {
        console.error(err);
        notification(
          "Error",
          "Unable to fetch tags. Please try again",
          "warning"
        );
      });
  };
}

export function addHighlight(fileId, highlightData) {
  return (_, getState) => {
    return fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/files/${fileId}/highlights`,
      "POST",
      highlightData
    )
      .then((response) => {
        if (!response.ok) throw response.status;
        return response.json();
      })
      .then((json) => {
        return json.highlight;
      })
      .catch((err) => {
        console.error(err);
        notification(
          "Error",
          "Unable to add highlight. Please try again",
          "warning"
        );
      });
  };
}

export function applyTag(fileId, highlightId, tagId) {
  return (_, getState) => {
    return fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/files/${fileId}/highlights/${highlightId}/tags`,
      "POST",
      { tagId }
    )
      .then((response) => {
        if (!response.ok) throw response.status;
        // Consider dispatch here
        return response.json();
      })
      .catch(() => {
        notification(
          "Unable to add existing topic to response.",
          "Please try again.",
          "warning"
        );
      });
  };
}

export function unapplyTag(fileId, highlightId, tagId) {
  return (_, getState) => {
    return fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/files/${fileId}/highlights/${highlightId}/tags`,
      "DELETE",
      { tagId }
    )
      .then((response) => {
        if (!response.ok) throw response.status;
        return response.json();
      })
      .catch(() => {
        notification(
          "Unable to remove tag from highlight.",
          "Please try again.",
          "warning"
        );
      });
  };
}

export function deleteHighlight(fileId, highlightId) {
  return (_, getState) => {
    return fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/files/${fileId}/highlights/${highlightId}`,
      "DELETE"
    )
      .then((response) => {
        if (!response.ok) throw response.status;
        return response.json();
      })
      .catch(() => {
        notification(
          "Unable to delete highlight.",
          "Please try again.",
          "warning"
        );
      });
  };
}

export function getSession(surveyId, sessionObjectId) {
  track("Get Session", { surveyId: surveyId, _id: sessionObjectId });
  return (dispatch, getState) => {
    dispatch({ type: "SET_LOADING", payload: true, name: "SessionView" });
    return fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/surveys/${surveyId}/sessions/id/${sessionObjectId}`,
      "GET"
    )
      .then((response) => {
        if (response.status !== 200) throw response.status;
        return response.json();
      })
      .then((json) => {
        dispatch({
          type: "RETURN_SESSION",
          payload: json,
          surveyId: surveyId,
          sessionId: sessionObjectId,
        });
      })
      .catch((error) => {
        notification(
          `Error: ${error.message}`,
          "Unable to fetch session. Please try again",
          "warning"
        );
      })
      .then(() =>
        dispatch({ type: "SET_LOADING", payload: false, name: "SessionView" })
      );
  };
}

export function deleteSession(surveyId, sessionObjectId) {
  track("Delete Session", { surveyId: surveyId, _id: sessionObjectId });
  const url = `${BASE_URL}/surveys/${surveyId}/sessions/id/${sessionObjectId}`;
  return (dispatch, getState) => {
    fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + getState().authToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
      },
    })
      .then((response) => {
        if (response.status !== 200) throw response.status;
        return response.json();
      })
      .then(() => {
        notification(
          "Respondent Deleted",
          "Responses for this respondent have been deleted.",
          "success"
        );
        dispatch({
          type: "DELETE_SESSION",
          surveyId: surveyId,
          sessionId: sessionObjectId,
        });
        // Re-fetch survey to update `surveyHasMedia` for "Download Media"
        dispatch(getSurvey(surveyId));
      })
      .catch(() => {
        alert("Unable to fetch session.");
      });
  };
}

export function getResponse(responseId) {
  return (dispatch, getState) => {
    var headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
      "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
      "Content-Type": "application/json",
    };
    if (getState().authToken) {
      headers = {
        ...headers,
        Authorization: "Bearer " + getState().authToken,
      };
    }
    return fetch(`${BASE_URL}/response/${responseId}`, {
      headers: headers,
    })
      .then((response) => response.json())
      .then((json) => {
        dispatch({
          type: "RETURN_RESPONSE",
          payload: json,
          responseId: responseId,
          coalesce: false,
        });
      });
  };
}

export function updateResponse(responseId, responseData) {
  track("Update Response", responseData);
  const url = `${BASE_URL}/response/${responseId}`;
  return (dispatch, getState) => {
    return fetch(url, {
      method: "PUT",
      body: JSON.stringify(responseData),
      headers: {
        Authorization: "Bearer " + getState().authToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        response
          .json()
          .then((json) => {
            if (response.status !== 200) throw new Error(json.message);
            dispatch({
              type: "RETURN_RESPONSE",
              payload: json,
              responseId: responseId,
            });
          })
          .catch((e) => {
            notification("Can't edit this response.", e.message, "warning");
          });
      })
      .catch(() => {
        notification(
          "Error",
          "Unable to update response right now. Please try again.",
          "danger"
        );
      });
  };
}

export function updateResponseLocal(responseId, questionId, responseData) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_RESPONSE",
      payload: {
        responseId: responseId,
        questionId: questionId,
        responseData: responseData,
      },
    });
  };
}

export function updateSession(sessionId, data) {
  track("Update Session", data);
  const url = `${BASE_URL}/session/${sessionId}`;
  return (dispatch, getState) => {
    fetch(url, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        Authorization: "Bearer " + getState().authToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status !== 200) throw response.status;
      })
      .catch(() => {
        notification(
          "Error",
          "Unable to update session right now. Please try again.",
          "danger"
        );
      });
  };
}

export function updateSessionLocal(sessionId, surveyId, data) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SESSION",
      payload: data,
      sessionId: sessionId,
      surveyId: surveyId,
    });
  };
}

export function getSurveyExport(surveyId, questionId, exportParams) {
  track("Export Survey", { surveyId: surveyId });
  return (dispatch, getState) => {
    dispatch({ type: "SET_LOADING", payload: true, name: "SurveyExport" });
    return new Promise((resolve, reject) => {
      // Url either points to a survey or a question
      let url = new URL(`${BASE_URL}/export/surveys/${surveyId}`);
      if (questionId) {
        url = new URL(
          `${BASE_URL}/export/surveys/${surveyId}/questions/${questionId}`
        );
      }
      // Add export params as query params
      if (exportParams.format) {
        url.searchParams.append("format", exportParams.format);
      }
      if (exportParams.includeResponseIds) {
        url.searchParams.append(
          "includeResponseIds",
          exportParams.includeResponseIds
        );
      }
      if (exportParams.includeTopics) {
        url.searchParams.append("includeTopics", exportParams.includeTopics);
      }
      if (exportParams.useDataLabels) {
        url.searchParams.append("useDataLabels", exportParams.useDataLabels);
      }
      if (exportParams.filterBookmarks) {
        url.searchParams.append(
          "filterBookmarks",
          exportParams.filterBookmarks
        );
      }
      fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + getState().authToken,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        },
      })
        .then((response) => {
          if (response.status !== 200) throw response.status;
          notification(
            "Download Successful",
            "Your survey has been successfully exported.",
            "success"
          );
          response.blob().then((blob) => {
            resolve(blob);
          });
        })

        .catch((e) => {
          notification(
            "Download Failed",
            "An error occured downloading your survey.",
            "danger"
          );
          reject(e);
        })
        .then(() =>
          dispatch({
            type: "SET_LOADING",
            payload: false,
            name: "SurveyExport",
          })
        );
    });
  };
}

export function getSurveyQuestions(surveyId) {
  track("Export Survey Questions", { surveyId: surveyId });
  return (dispatch, getState) => {
    dispatch({ type: "SET_LOADING", payload: true, name: "SurveyExport" });
    return new Promise((resolve, reject) => {
      // Url either points to a survey or a question
      let url = new URL(`${BASE_URL}/export/surveys/${surveyId}/questions`);
      // Add export params as query params

      fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + getState().authToken,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        },
      })
        .then((response) => {
          if (response.status !== 200) throw response.status;
          notification(
            "Download Successful",
            "Your survey has been successfully exported.",
            "success"
          );
          response.blob().then((blob) => {
            resolve(blob);
          });
        })

        .catch((e) => {
          notification(
            "Download Failed",
            "An error occured downloading your survey.",
            "danger"
          );
          reject(e);
        })
        .then(() =>
          dispatch({
            type: "SET_LOADING",
            payload: false,
            name: "SurveyExport",
          })
        );
    });
  };
}

export function getSurveyVariables(surveyId, vars) {
  //track("Export Survey Questions", { surveyId: surveyId });
  return (dispatch, getState) => {
    //dispatch({ type: "SET_LOADING", payload: true, name: "SurveyExport" });
    return new Promise((resolve, reject) => {
      // Url either points to a survey or a question
      let url = new URL(`${BASE_URL}/surveys/${surveyId}/variables`);
      // Add export params as query params

      fetch(url, {
        method: "POST",
        body: JSON.stringify({ vars }),
        headers: {
          Authorization: "Bearer " + getState().authToken,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
          "Content-Type": "application/json",
        },
      }).then((response) => {
        response.json().then((data) => {
          return data;
        });
      });
    });
  };
}

export function fetchCrosstabsChart(surveyId, params) {
  track("Get Crosstabs Data", { surveyId: surveyId });
  return (dispatch, getState) => {
    dispatch({
      type: "SET_LOADING",
      payload: true,
      name: "CrossTabs",
    });
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/surveys/${surveyId}/variables`,
      "POST",
      { vars: params.vars }
    )
      .then((response) => {
        response.json().then((rawData) => {
          let crosstabData = dataToCrosstabs(rawData.data, rawData.metadata);
          let v1Labels = rawData.metadata[0].labels;
          let v2Labels = rawData.metadata[1].labels;
          v1Labels.push("No Response");
          v2Labels.push("No Response");
          v1Labels.push("Total");
          v2Labels.push("Total");
          let crosstabChart = {
            chart: { ...params, loading: false },
            data: {
              v1Labels: v1Labels,
              v2Labels: v2Labels,
              crosstabData: crosstabData,
            },
          };
          dispatch({
            type: "RETURN_CHARTS",
            payload: crosstabChart,
            surveyId: surveyId,
          });
        });
      })
      .catch((e) => {
        console.log(e);
        notification(
          "Could not fetch crosstabs.",
          "Something went wrong. Please try again later.",
          "warning"
        );
      })
      .finally(() => {
        dispatch({
          type: "SET_LOADING",
          payload: false,
          name: "CrossTabs",
        });
      });
  };
}

export function removeCrosstabsChart(surveyId, idx) {
  return (dispatch, getState) => {
    dispatch({
      type: "DELETE_CHART",
      payload: idx,
      surveyId: surveyId,
    });
  };
}

export function startSurveyMediaDownload(surveyId, email) {
  track("Download Survey", { surveyId: surveyId });
  return (_, getState) => {
    return new Promise((resolve, reject) => {
      fetchWithAuth(
        BASE_URL,
        getState().authToken,
        `/download/surveys/${surveyId}`,
        "POST",
        { email: email }
      )
        .then((response) => {
          response.json().then((data) => {
            if (response.status !== 200) {
              notification("Download Cannot Start", data.data, "warning");
            } else {
              notification(
                "Download Started",
                "Monitor your email for the download link. Large surveys can take up to 15 minutes to send.",
                "success"
              );
            }
          });
        })

        .catch((e) => {
          notification(
            "Download Failed",
            "An error occured downloading your survey.",
            "danger"
          );
          reject(e);
        });
    });
  };
}

export function getFullAudio(focusGroupId, conversationId) {
  return (dispatch, getState) => {
    return fetch(
      `${BASE_URL}/focusgroup/${focusGroupId}/conversation/${conversationId}/fullaudio`,
      {
        headers: {
          Authorization: "Bearer " + getState().authToken,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        },
      }
    )
      .then((response) => response.json())
      .then((json) => {
        dispatch({
          type: "RETURN_FULL_AUDIO",
          payload: json,
          conversationId: conversationId,
        });
      });
  };
}

export function addSurvey(survey) {
  track("Add Survey", survey);
  const url = `${BASE_URL}/surveys`;
  return (dispatch, getState) => {
    fetch(url, {
      method: "POST",
      body: JSON.stringify(survey),
      headers: {
        Authorization: "Bearer " + getState().authToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((json) => {
            dispatch({
              type: "RETURN_SURVEY_ID",
              payload: json,
            });
          });
        } else {
          notification(
            "Unable to Create Survey.",
            "Please check your usage.",
            "warning"
          );
        }
      })
      .finally(() =>
        dispatch({ type: "SET_LOADING", payload: false, name: "CreateSurvey" })
      );
  };
}

export function deleteSurvey(surveyId, shared) {
  track("Delete Survey", { surveyId: surveyId, shared: shared });
  const url = `${BASE_URL}/surveys/${surveyId}`;
  return (dispatch, getState) => {
    fetch(url, {
      method: "DELETE",
      body: JSON.stringify({ shared: shared }),
      headers: {
        Authorization: "Bearer " + getState().authToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status !== 200) throw response.status;
        notification(
          "Survey Removed.",
          "Your survey has been removed.",
          "success"
        );
        dispatch(removeSurveyFromState(surveyId));
        dispatch(removeDraftFromState(surveyId));
      })
      .catch(() => {
        notification(
          "Survey Not Removed.",
          "Your survey could not be removed. Please try again",
          "warning"
        );
      });
  };
}

// Used to update a single property
export function updateSurvey(surveyId, surveyProps) {
  track("Update Survey", { surveyId: surveyId });
  const url = `${BASE_URL}/surveys/${surveyId}`;
  return (dispatch, getState) => {
    fetch(url, {
      method: "PUT",
      body: JSON.stringify(surveyProps),
      headers: {
        Authorization: "Bearer " + getState().authToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status !== 200) throw response.status;
        response.json().then((json) => {
          dispatch({
            type: "UPDATE_SURVEY_PROP",
            payload: {
              surveyId: surveyId,
              data: json.data,
            },
          });
          notification(
            "Survey Updated",
            "Your changes have been saved.",
            "success"
          );
        });
      })
      .catch(() => {
        notification(
          "Unable to update survey.",
          "This could mean you're not authorized to edit this survey.",
          "warning"
        );
      });
  };
}

export function removeDraftFromState(surveyId) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_DRAFT",
      payload: { surveyId: surveyId },
    });
  };
}

export function removeSurveyFromState(surveyId) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_SURVEY",
      payload: { surveyId: surveyId },
    });
  };
}

export function updateSurveyQuestions(surveyId, surveyQuestions, callback) {
  track("Update Survey Questions", surveyQuestions);
  const url = `${BASE_URL}/surveys/${surveyId}/questions?staging=true`;
  return (dispatch, getState) => {
    fetch(url, {
      method: "POST",
      body: JSON.stringify(surveyQuestions),
      headers: {
        Authorization: "Bearer " + getState().authToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        response.json().then((json) => {
          dispatch({
            type: "UPDATE_DURATION_ESTIMATE",
            payload: {
              estimatedCompletionTimeMinutes:
                json.props.estimatedCompletionTimeMinutes,
            },
          });
        });
        if (callback !== undefined) callback();
      })
      .finally(() =>
        dispatch({ type: "SET_LOADING", payload: false, name: "CreateSurvey" })
      );
  };
}

export function addSurveyFromCSV(survey, file) {
  track("Add Survey", survey);
  const url = `${BASE_URL}/surveys/csv`;
  const formData = new FormData();
  formData.append("survey", JSON.stringify(survey));
  formData.append("file", file);
  return (dispatch, getState) => {
    fetch(url, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: "Bearer " + getState().authToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((json) => {
            dispatch({
              type: "RETURN_SURVEY_ID",
              payload: json,
            });
          });
        } else {
          response.json().then((json) => {
            if (json.data === "Please include a file.") {
              notification(
                "Unable to Create Survey.",
                "Please attach a file.",
                "warning"
              );
            } else {
              notification(
                "Unable to Create Survey.",
                "Please check your usage.",
                "warning"
              );
            }
          });
        }
      })
      .finally(() =>
        dispatch({ type: "SET_LOADING", payload: false, name: "CreateSurvey" })
      );
  };
}

export function searchTextChanged(text) {
  return {
    type: "SEARCH_TEXT_CHANGED",
    payload: text,
  };
}

export function clearCurrentSurveyId() {
  return (dispatch) => {
    dispatch({ type: "CLEAR_CREATE_SURVEY_ID" });
  };
}

export function clearCurrentSurvey() {
  return (dispatch) => {
    dispatch({ type: "CLEAR_CREATE_SURVEY" });
  };
}

export function clearSurveyDurationEstimate() {
  return (dispatch) => {
    dispatch({ type: "CLEAR_SURVEY_DURATION_ESTIMATE" });
  };
}

export function toggleUpgradePopup(open) {
  return (dispatch) => {
    dispatch({ type: "TOGGLE_UPGRADE_POPUP", payload: open });
  };
}

export function getPaymentPortalUrl() {
  track("Payment Portal Launched");
  const url = `${BASE_URL}/payments/portal`;
  return (dispatch, getState) => {
    fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + getState().authToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status !== 200) throw response.status;
        return response.json();
      })
      .then((json) => {
        dispatch({
          type: "RETURN_PAYMENT_PORTAL",
          payload: json,
        });
        window.open(json.url);
      })
      .catch(() => {
        alert("Unable to launch payment portal.");
      })
      .finally(() =>
        dispatch({
          type: "SET_LOADING",
          payload: false,
          name: "BillingPortal",
        })
      );
  };
}

export function getUpgradeCheckoutId(plan, creditBundles, billingPeriod) {
  track("Upgrade Requested");
  return (dispatch, getState) => {
    fetchWithAuth(BASE_URL, getState().authToken, "/payments/upgrade", "POST", {
      plan: plan,
      billingPeriod: billingPeriod,
      creditBundles: creditBundles,
    })
      .then((response) => {
        if (response.status !== 200) throw response.status;
        return response.json();
      })
      .then((json) => {
        dispatch({
          type: "RETURN_UPGRADE_CHECKOUT_ID",
          payload: json,
        });
      })
      .catch((error) => {
        console.error(error);
        alert("Unable to launch payment portal.");
      })
      .finally(() =>
        dispatch({
          type: "SET_LOADING",
          payload: false,
          name: `Upgrade-${plan}`,
        })
      );
  };
}

export function updateSubscription(
  plan,
  creditBundles,
  billingPeriod,
  callback
) {
  track("Upgrade Requested");
  return (dispatch, getState) => {
    dispatch({
      type: "SET_LOADING",
      payload: true,
      name: `Upgrade-${plan}`,
    });
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      "/payments/subscription",
      "PUT",
      {
        plan: plan,
        billingPeriod: billingPeriod,
        creditBundles: creditBundles,
      }
    )
      .then((response) => {
        if (response.status !== 200) throw response.status;
        callback();
        return response.json();
      })
      .catch((error) => {
        console.error(error);
        alert("Unable to update subscription.");
      })
      .finally(() => {
        dispatch({
          type: "SET_LOADING",
          payload: false,
          name: `Upgrade-${plan}`,
        });
      });
  };
}

export function cancelSubscription(data) {
  track("Cancel Subscription");
  const url = `${BASE_URL}/payments/cancel`;
  return (dispatch, getState) => {
    fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: "Bearer " + getState().authToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          // Trigger re-render
          dispatch(getUser());
        } else {
          response.json().then((json) => {
            notification("Error", `Unable to cancel. ${json.data}`, "warning");
          });
        }
      })
      .finally(() =>
        dispatch({
          type: "SET_LOADING",
          payload: false,
          name: "Cancel",
        })
      );
  };
}

export function getCreditCheckoutId(quantity) {
  track("Credits Requested");
  const url = `${BASE_URL}/payments/credits`;
  return (dispatch, getState) => {
    fetch(url, {
      method: "POST",
      body: JSON.stringify({ quantity: quantity }),
      headers: {
        Authorization: "Bearer " + getState().authToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status !== 200) throw response.status;
        return response.json();
      })
      .then((json) => {
        dispatch({
          type: "RETURN_CREDIT_CHECKOUT_ID",
          payload: json,
        });
      })
      .catch((error) => {
        console.error(error);
        alert("Unable to launch payment portal.");
      })
      .finally(() =>
        dispatch({
          type: "SET_LOADING",
          payload: false,
          name: "CreditsCheckout",
        })
      );
  };
}

export function uploadSurveyImage(surveyId, file, action) {
  track("Uploaded a survey image");
  var fd = new FormData();
  fd.append("file", file);
  const url = `${BASE_URL}/surveys/${surveyId}/survey_asset`;
  return (dispatch, getState) => {
    fetch(url, {
      method: "POST",
      body: fd,
      headers: {
        Authorization: "Bearer " + getState().authToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
      },
    }).then((response) =>
      response.json().then((json) => {
        dispatch({ type: action, payload: json });
      })
    );
  };
}

export function duplicateSurvey(surveyId, redirectFunction) {
  track("Duplicating Survey");
  const url = `${BASE_URL}/surveys/${surveyId}/duplicate`;
  return (dispatch, getState) => {
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + getState().authToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
      },
    })
      .then((response) => {
        if (response.status !== 200) throw response.status;
        return response.json();
      })
      .then((json) => {
        dispatch({ type: "APPEND_DRAFT", payload: json.data });
        notification(
          "Duplicated Survey",
          "Duplicated version has been sent to drafts",
          "success"
        );
        redirectFunction();
      })
      .catch(() => {
        notification("", "Unable to duplicate survey", "warning");
      });
  };
}

export function uploadQuestionStimuli(
  surveyId,
  idx,
  stimuliType,
  file,
  mediaProps
) {
  track("Uploaded a stimuli image");
  var fd = new FormData();
  fd.append("file", file);
  const url = `${BASE_URL}/surveys/${surveyId}/survey_asset`;
  return (dispatch, getState) => {
    // Check if other media is uploading
    if (getState().loadingQuestionMedia !== undefined) {
      store.addNotification({
        message: "Please wait for other media to finish uploading. ",
        type: "info",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 2000,
        },
      });
      return;
    }

    if (stimuliType === "VIDEO") {
      // Notification on video uploads
      store.addNotification({
        title: "Question media is uploading now. ",
        message:
          "Please don't refresh or close this tab. The upload can take a few minutes.",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 4000,
        },
      });
    }

    dispatch({
      type: "QUESTION_MEDIA_LOADING",
      payload: { questionIdx: idx },
    });
    fetch(url, {
      method: "POST",
      body: fd,
      headers: {
        Authorization: "Bearer " + getState().authToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
      },
    })
      .then((response) =>
        response.json().then((json) => {
          dispatch({
            type: "UPDATE_QUESTION_STIMULI",
            payload: {
              ...json,
              questionIdx: idx,
              stimuliType: stimuliType,
              mediaProps: mediaProps,
            },
          });
        })
      )
      .catch(() => {
        alert("Failed to upload media. Please try again");
        dispatch({
          type: "UPDATE_QUESTION_STIMULI",
          payload: {},
        });
      });
  };
}

export function updateSurveyRedux(surveyInfo) {
  return (dispatch) => {
    dispatch({ type: "UPDATE_CURRENT_SURVEY", payload: surveyInfo });
  };
}

export function getOrganizationLogo(organizationId) {
  track("Get Organization Logo", organizationId);
  const url = `${BASE_URL}/organization/${organizationId}/logo`;
  return (dispatch, getState) => {
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + getState().authToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET",
        "Access-Control-Allow-Headers": "Origin, Content-Type",
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          dispatch({
            type: "RETURN_ORGANIZATION",
            payload: json,
          });
        });
      } else {
        notification(
          "Unable to fetch organization logo.",
          "Please try again.",
          "warning"
        );
      }
    });
  };
}

export function getOrganization() {
  track("Get Organization");
  return (dispatch, getState) => {
    fetchWithAuth(BASE_URL, getState().authToken, "/organization", "GET")
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(response.status);
        }
      })
      .then((json) => dispatch({ type: "RETURN_ORGANIZATION", payload: json }))
      .catch((err) => {
        if (err.message === "500") {
          notification("Error", "There was a problem.", "warning");
        } else {
          // Swallow 404 errors
        }
      });
  };
}

export function postOrganizationInvite(organizationId, email, readOnly) {
  track("Post Organization Invite");

  return (dispatch, getState) => {
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/organization/${organizationId}/invite`,
      "POST",
      { email: email, readOnly: readOnly }
    ).then((response) => {
      if (response.status === 200) {
        response.json().then(() => {
          dispatch(getOrganization(organizationId)); // Trigger re-render
        });
      } else {
        response.json().then((json) => {
          notification(
            "Error",
            `Unable to invite user to organization. ${json.data}`,
            "warning"
          );
        });
      }
    });
  };
}

export function deleteOrganizationInvite(organizationId, email) {
  track("Delete Organization Invite");
  return (dispatch, getState) => {
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/organization/${organizationId}/invite`,
      "DELETE",
      { email: email }
    ).then((response) => {
      if (response.status === 200) {
        response.json().then(() => {
          // Trigger re-render
          dispatch(getOrganization(organizationId));
          dispatch(getUser());
        });
      } else {
        response.json().then((json) => {
          notification(
            "Error",
            `Unable to delete organization invite. ${json.data}`,
            "warning"
          );
        });
      }
    });
  };
}

export function acceptOrganizationInvite(organizationId) {
  track("Accept Organization Invite");
  return (dispatch, getState) => {
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/organization/${organizationId}/join`,
      "POST"
    )
      .then((response) => {
        if (response.status === 200) {
          dispatch(getUser()); // Trigger re-render
        } else {
          response.json().then((json) => {
            notification(
              "Error",
              `Unable to join organization. ${json.data}`,
              "warning"
            );
          });
        }
      })
      .catch(() => {
        notification(
          "Error",
          "Unable to accept invite right now. Please try again.",
          "danger"
        );
      });
  };
}

export function updateOrganizationMember(organizationId, userId, readOnly) {
  track("Update Organization Member");
  return (dispatch, getState) => {
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/organization/${organizationId}/users/${userId}`,
      "PUT",
      { readOnly: readOnly }
    ).then((response) => {
      if (response.status === 200) {
        response.json().then(() => {
          dispatch(getOrganization(organizationId)); // Trigger re-render
        });
      } else {
        response.json().then((json) => {
          notification(
            "Error",
            `Unable to update organization. ${json.data}`,
            "warning"
          );
        });
      }
    });
  };
}

export function removeOrganizationMember(organizationId, userId) {
  track("Delete Organization Member");
  return (dispatch, getState) => {
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/organization/${organizationId}/users/${userId}`,
      "DELETE"
    ).then((response) => {
      if (response.status === 200) {
        response.json().then(() => {
          dispatch(getOrganization(organizationId)); // Trigger re-render
        });
      } else {
        response.json().then((json) => {
          notification(
            "Error",
            `Unable to remove user. ${json.data}`,
            "warning"
          );
        });
      }
    });
  };
}

export function shareSurvey(surveyId, userEmail, callback) {
  track("Sharing Survey", { survey: surveyId, sharedWith: userEmail });
  const url = `${BASE_URL}/surveys/${surveyId}/share`;
  return (dispatch, getState) => {
    fetch(url, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + getState().authToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "PUT",
        "Access-Control-Allow-Headers": "Origin, Content-Type",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: userEmail,
        permissions: { read: true, write: true },
      }),
    }).then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          dispatch({
            type: "SHARE_SURVEY",
            payload: json,
          });
          notification(
            "Successfully Shared Survey",
            "The survey is now available to both parties",
            "success"
          );
          callback();
        });
      } else {
        response.json().then((json) => {
          notification("Unable to Share Survey", json.data, "warning");
          callback();
        });
      }
    });
  };
}

export function unshareSurvey(surveyId, userId, callback) {
  track("Unsharing Survey", { survey: surveyId, sharedWith: userId });
  const url = `${BASE_URL}/surveys/${surveyId}/unshare`;
  return (dispatch, getState) => {
    fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + getState().authToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "DELETE",
        "Access-Control-Allow-Headers": "Origin, Content-Type",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: userId,
      }),
    }).then((response) => {
      if (response.status === 200) {
        dispatch({
          type: "UNSHARE_SURVEY",
          payload: { surveyId: surveyId, userId: userId },
        });
        notification(
          "Successfully Unshared Survey",
          "The survey is no longer available to this user",
          "success"
        );
        callback();
      } else {
        response.json().then((json) => {
          notification("Unable to Unshare Survey", json.data, "warning");
          callback();
        });
      }
    });
  };
}

export function getReport(reportId) {
  track("Getting Report", { reportId: reportId });
  return (dispatch) => {
    fetch(`${BASE_URL}/reports/${reportId}`)
      .then((response) => {
        if (response.status >= 400 && response.status < 600) {
          throw new Error();
        }

        return response.json();
      })
      .then((json) => dispatch({ type: "RETURN_REPORT", payload: json }))
      .catch(() => dispatch({ type: "REPORT_ERROR" }));
  };
}

export function getReportBuilder(reportId) {
  track("Getting Report Builder", { reportId: reportId });
  return (dispatch, getState) => {
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/reports/${reportId}?mode=builder`,
      "GET"
    )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          throw new Error();
        }
      })
      .then((json) => dispatch({ type: "RETURN_REPORT", payload: json }))
      .catch(() => dispatch({ type: "REPORT_ERROR" }))
      .finally(() =>
        dispatch({ type: "SET_LOADING", payload: false, name: "BuildReport" })
      );
  };
}

export function startLoading(reduxAction, name) {
  return async (dispatch) => {
    dispatch({ type: "SET_LOADING", payload: true, name });
    reduxAction();
  };
}

export function updateResponseBuilder(responseId, responseData) {
  return (dispatch, getState) => {
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/response/${responseId}`,
      "PUT",
      responseData
    )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          notification(
            "Successfully changed response to public",
            "The response is now public",
            "success"
          );
          dispatch(getReportBuilder(getState().report._id));
        } else {
          throw new Error(response.status);
        }
      })
      .catch(() => {
        notification(
          "Error",
          "Unable to update response right now. Please try again.",
          "danger"
        );
      });
  };
}

export function getReports() {
  track("Getting Reports");
  return (dispatch, getState) => {
    dispatch({ type: "SET_LOADING", payload: true, name: "MyReports" });
    fetchWithAuth(BASE_URL, getState().authToken, `/reports`, "GET")
      .then((response) => {
        if (response.status >= 400 && response.status < 600) {
          throw new Error();
        }

        return response.json();
      })
      .then((json) => dispatch({ type: "RETURN_REPORTS", payload: json }))
      .catch(() => dispatch({ type: "REPORT_ERROR" }))
      .finally(() =>
        dispatch({ type: "SET_LOADING", payload: false, name: "MyReports" })
      );
  };
}

/**
 * `history` has to be pased from the component calling createReport,
 * which must have access to history from React Router. It seems clunky,
 * but it's so we can redirect the user after a successful post to /reports.
 * Alternative: Give actions.js access to history using React Router's
 * useHistory() hook, which requires upgrading to 5.1+.
 * Another alternative is to redirect based on state using the new reportId
 * and React Router's <Redirect>, but I think that causes other issues.
 */
export function createReport(report, history) {
  return (dispatch, getState) => {
    dispatch({ type: "SET_LOADING", payload: true, name: "CreateReport" });
    fetchWithAuth(BASE_URL, getState().authToken, `/reports`, "POST", report)
      .then((response) => {
        if (response.status >= 400 && response.status < 600) {
          throw new Error();
        }

        return response.json();
      })
      .then((json) => {
        history.push(`/reports/${json.report_id}/create`);
      })
      .catch(() => {
        notification(
          "Unable to create new report.",
          "Please try again.",
          "warning"
        );
      })
      .finally(() =>
        dispatch({
          type: "SET_LOADING",
          payload: false,
          name: "CreateReport",
        })
      );
  };
}

export function addContentToReport(reportId, blockData) {
  return (dispatch, getState) => {
    dispatch({ type: "SET_LOADING", payload: true, name: "AddToReportPopup" });
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/reports/${reportId}/contents`,
      "POST",
      blockData
    )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          notification(
            "Successfully added to report.",
            "Report contents updated.",
            "success"
          );
        } else {
          throw response.error;
        }
      })
      .catch((err) => {
        notification(
          "Unable to add to report.",
          "Please try again.",
          "warning"
        );
        console.error(err);
      })
      .finally(() =>
        dispatch({
          type: "SET_LOADING",
          payload: false,
          name: "AddToReportPopup",
        })
      );
  };
}

export function addManyContents(reportId, bulkData) {
  return (dispatch, getState) => {
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/reports/${reportId}/contents/add_many`,
      "POST",
      bulkData
    )
      .then((response) => {
        if (response.status === 200) {
          notification(
            "Successfully added to report.",
            "Report contents updated.",
            "success"
          );
          // Reload report
          dispatch(getReportBuilder(reportId));
        }
      })
      .catch(() => {
        notification(
          "Unable to add to report.",
          "Please try again.",
          "warning"
        );
      });
  };
}

/**
 * Update report name and contents. This action is also used by other actions
 * to save the report builder state before doing other changes (e.g. add
 * blocks; toggle response to public). The action returns Promises so that
 * .then() and .catch() can be handled in the component.
 */
export function updateReport(reportId, propChange, reportContents) {
  return (dispatch, getState) => {
    const token = getState().authToken;
    return fetchWithAuth(
      BASE_URL,
      token,
      `/reports/${reportId}`,
      "PUT",
      propChange
    ).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return fetchWithAuth(
          BASE_URL,
          token,
          `/reports/${reportId}/contents`,
          "PUT",
          reportContents
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            // Handle success in component
          } else {
            throw new Error();
          }
        });
      } else {
        throw new Error();
      }
    });
  };
}

export function deleteReport(reportId) {
  return (dispatch, getState) => {
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/reports/${reportId}`,
      "DELETE"
    )
      .then((response) => {
        if (response.status === 200) {
          notification(
            "Report removed",
            "Your report has been removed.",
            "success"
          );
          // Reload reports
          dispatch(getReports());
        } else if (response.status >= 400) {
          throw new Error();
        }
      })
      .catch(() => {
        notification(
          "Unable to remove report.",
          "Please try again.",
          "warning"
        );
      });
  };
}

export function getBookmarks() {
  return (dispatch, getState) => {
    fetchWithAuth(BASE_URL, getState().authToken, `/bookmarks`, "GET")
      .then((response) => response.json())
      .then((json) => dispatch({ type: "RETURN_BOOKMARKS", payload: json }))
      .catch(() => {
        notification(
          "Unable to retrieve bookmarks.",
          "Please try again.",
          "warning"
        );
      });
  };
}

export function getTemplates() {
  return (dispatch, getState) => {
    fetchWithAuth(BASE_URL, getState().authToken, "/templates", "GET")
      .then((response) => response.json())
      .then((json) =>
        dispatch({ type: "RETURN_TEMPLATES", payload: json.templates })
      )
      .catch(() => {
        notification(
          "Unable to retrieve templates.",
          "Please try again.",
          "warning"
        );
      })
      .finally(() =>
        dispatch({ type: "SET_LOADING", payload: false, name: "GetTemplates" })
      );
  };
}

export function applyTopic(topicId, responseId) {
  return (dispatch, getState) => {
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/topics/${topicId}/mentions`,
      "POST",
      { responseId }
    )
      .then((response) => {
        if (!response.ok) throw response.status;
        return response.json();
      })
      .then((json) => {
        // Add to response.topics
        dispatch({
          type: "APPLY_TOPIC",
          payload: json.topic,
          topicId,
        });
      })
      .catch(() => {
        notification(
          "Unable to add existing topic to response.",
          "Please try again.",
          "warning"
        );
      });
  };
}

export function unapplyTopic(topicId, responseId, questionId) {
  return (dispatch, getState) => {
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/topics/${topicId}/mentions`,
      "DELETE",
      { responseId }
    )
      .then((response) => {
        if (!response.ok) throw response.status;
        return response.json();
      })
      .then((json) => {
        // Update response.topics
        dispatch({
          type: "UNAPPLY_TOPIC",
          payload: { ...json.data },
          questionId,
        });
      })
      .catch(() => {
        notification(
          "Unable to remove existing topic from response.",
          "Please try again.",
          "warning"
        );
      });
  };
}

export function createTopic(surveyId, questionId, name, responseId) {
  return (dispatch, getState) => {
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/surveys/${surveyId}/question/${questionId}/topics`,
      "POST",
      { Text: name }
    )
      .then((response) => {
        if (!response.ok) throw response.status;
        return response.json();
      })
      .then((json) => {
        dispatch({ type: "CREATE_TOPIC", payload: json.topic, questionId });
        if (responseId) {
          dispatch(applyTopic(json.topic._id, responseId));
        }
      })
      .catch(() => {
        notification(
          "Unable to create new topic.",
          "Please try again.",
          "warning"
        );
      });
  };
}

// TODO: Merge with createTopic
export function createTag(tagData, onCreateTag) {
  return (dispatch, getState) => {
    fetchWithAuth(BASE_URL, getState().authToken, `/topics`, "POST", tagData)
      .then((response) => {
        if (!response.ok) throw response.status;
        return response.json();
      })
      .then((json) => {
        // Check for race condition here
        dispatch({ type: "CREATE_TAG", tag: json.tag });
        if (json.tag._id && onCreateTag) {
          onCreateTag(json.tag);
        }
      })
      .catch((err) => {
        console.error(err);
        notification(
          "Unable to create new topic.",
          "Please try again.",
          "warning"
        );
      });
  };
}

export function deleteTopic(topicId, questionId) {
  return (dispatch, getState) => {
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/topics/${topicId}`,
      "DELETE"
    )
      .then((response) => {
        if (!response.ok) throw response.status;
        dispatch({ type: "DELETE_TOPIC", questionId, deletedId: topicId });
      })
      .catch(() => {
        notification("Unable to delete topic.", "Please try again.", "warning");
      });
  };
}

export function aliasTopic(topicId, alias, questionId) {
  return (dispatch, getState) => {
    fetchWithAuth(BASE_URL, getState().authToken, `/topics/${topicId}`, "PUT", {
      Text: alias,
    })
      .then((response) => {
        if (!response.ok) throw response.status;
        return response.json();
      })
      .then((json) =>
        dispatch({ type: "EDIT_TOPIC", questionId, payload: json.topic })
      )
      .catch(() => {
        notification("Unable to edit topic.", "Please try again.", "warning");
      });
  };
}

export function mergeTopic(baseTopicId, topicId, questionId) {
  return (dispatch, getState) => {
    const url = Array.isArray(topicId)
      ? `/topic_bulk/${baseTopicId}/aliases`
      : `/topics/${baseTopicId}/aliases`;
    const data = Array.isArray(topicId)
      ? topicId.map((t) => ({ topicId: t._id }))
      : { topicId };

    fetchWithAuth(BASE_URL, getState().authToken, url, "POST", data)
      .then((response) => {
        if (!response.ok) throw response.status;
        dispatch(getTopics(questionId));
        dispatch({ type: "SET_TOPICS_DIRTIED", payload: true });
        notification("Topics merged.", "Reloading topics.", "success");
      })
      .catch(() => {
        notification("Unable to merge topic.", "Please try again.", "warning");
      });
  };
}

export function deleteTopics(topicIds, questionId) {
  return (dispatch, getState) => {
    fetchWithAuth(BASE_URL, getState().authToken, "/topics", "DELETE", {
      topicIds: topicIds,
    })
      .then((response) => {
        if (!response.ok) throw response.status;
        dispatch(getTopics(questionId));
        dispatch({ type: "SET_TOPICS_DIRTIED", payload: true });
        notification("Topics deleted.", "Reloading topics.", "success");
      })
      .catch(() => {
        notification(
          "Unable to delete topics.",
          "Please try again.",
          "warning"
        );
      });
  };
}

export function setTopicsDirtied(topicsDirtied) {
  return (dispatch) => {
    dispatch({ type: "SET_TOPICS_DIRTIED", payload: topicsDirtied });
  };
}

export function setPageTitle(title) {
  return (dispatch) => {
    dispatch({ type: "UPDATE_PAGE_TITLE", payload: { title: title } });
  };
}

export function submitFeedback(feedback) {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_LOADING",
      payload: true,
      name: "Feedback",
    });
    fetchWithAuth(BASE_URL, getState().authToken, `/feedback`, "POST", feedback)
      .then((response) => {
        dispatch({
          type: "REMOVE_FEEDBACK_NOTIF",
          payload: feedback,
        });
      })
      .catch(() => {
        notification(
          "Unable to send feedback.",
          "Please try again.",
          "warning"
        );
      })
      .finally(() => {
        dispatch({
          type: "SET_LOADING",
          payload: false,
          name: "Feedback",
        });
      });
  };
}

export function runModelSample(path, data, callback) {
  return (dispatch) => {
    dispatch({
      type: "SET_LOADING",
      payload: true,
      name: "ModelSample",
    });
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then((idToken) => {
        return fetch(`${BASE_URL}/analytics/${path}`, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            Authorization: "Bearer " + idToken,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((json) => {
            dispatch({ type: "SET_MODEL_SAMPLE", payload: json });
          })
          .catch(() => {
            notification(
              "Unable to run model.",
              "Please try again.",
              "warning"
            );
          });
      })
      .finally(() => {
        dispatch({
          type: "SET_LOADING",
          payload: false,
          name: "ModelSample",
        });
        callback();
      });
  };
}

export function clearModelSample() {
  return (dispatch) => {
    dispatch({ type: "CLEAR_MODEL_SAMPLE" });
  };
}

export function runJobOnSurveyQuestion(surveyId, questionId, data, callback) {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_LOADING",
      payload: true,
      name: "JobRequest",
    });
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/surveys/${surveyId}/questions/${questionId}/jobs/create`,
      "POST",
      data
    )
      .then((r) => {
        if (r.status === 201) callback();
      })
      .finally(() => {
        dispatch({
          type: "SET_LOADING",
          payload: false,
          name: "JobRequest",
        });
        notification(
          "Classification in Progress",
          "This will take several minutes. Feel free to close this popup and revisit later.",
          "success"
        );
      });
  };
}

export function getQuestionJobs(surveyId, questionId) {
  return (dispatch, getState) => {
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/surveys/${surveyId}/questions/${questionId}/jobs`,
      "GET"
    ).then((r) =>
      r.json().then((json) => {
        dispatch({
          type: "RETURN_JOBS",
          payload: { questionId: questionId, jobs: json },
        });
      })
    );
  };
}

export function createShowreel(showreel, callback) {
  track("Creating Showreel");
  return (dispatch, getState) => {
    dispatch({ type: "SET_LOADING", payload: true, name: "CreateShowreel" });
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/showreels`,
      "POST",
      showreel
    )
      .then((response) => response.json())
      .then((json) => {
        if (!json.showreelId) throw new Error("Showreel ID not present.");
        callback({ ...showreel, _id: json.showreelId });
      })
      .catch(() => {
        notification(
          "Unable to create new showreel.",
          "Please try again.",
          "warning"
        );
      })
      .finally(() => {
        dispatch({
          type: "SET_LOADING",
          payload: false,
          name: "CreateShowreel",
        });
      });
  };
}

export function getShowreel(showreelId, projection) {
  track("Getting Showreels");
  return (dispatch, getState) => {
    let url = new URL(`${BASE_URL}/showreels/${showreelId}`);
    if (projection) {
      url.searchParams.append("projection", projection);
    }
    fetch(url, {
      headers: {
        Authorization: "Bearer " + getState().authToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        dispatch({ type: "SET_CURRENT_SHOWREEL", payload: json });
      })
      .catch(() => {
        notification(
          "Unable to fetch showreel.",
          "Please try again.",
          "warning"
        );
      });
  };
}

export function getShowreels() {
  track("Getting Showreels");
  return (dispatch, getState) => {
    dispatch({ type: "SET_LOADING", payload: true, name: "MyShowreels" });
    fetchWithAuth(BASE_URL, getState().authToken, `/showreels`, "GET")
      .then((response) => response.json())
      .then((json) => {
        dispatch({ type: "SET_SHOWREELS", payload: json });
      })
      .catch(() => {
        notification(
          "Unable to fetch showreel.",
          "Please try again.",
          "warning"
        );
      })
      .finally(() =>
        dispatch({ type: "SET_LOADING", payload: false, name: "MyShowreels" })
      );
  };
}

export function updateShowreel(showreelId, showreelData) {
  track("Updating Showreel", { showreelId: showreelId });
  return (dispatch, getState) => {
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/showreels/${showreelId}`,
      "PUT",
      showreelData
    )
      .then((response) => response.json())
      .catch(() => {
        notification(
          "Unable to update showreel.",
          "Please try again.",
          "warning"
        );
      });
  };
}

export function deleteShowreel(showreelId) {
  track("Deleting Showreel", { showreelId: showreelId });
  return (dispatch, getState) => {
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/showreels/${showreelId}`,
      "DELETE"
    )
      .then((response) => {
        if (response.status === 200) {
          notification(
            "Showreel removed",
            "Your showreel has been removed.",
            "success"
          );
          // Reload reports
          dispatch(getShowreels());
        } else if (response.status >= 400) {
          throw new Error();
        }
      })
      .catch(() => {
        notification(
          "Unable to delete showreel.",
          "Please try again.",
          "warning"
        );
      });
  };
}

export function exportShowreel(showreelId, callback) {
  track("Exporting Showreel", { showreelId: showreelId });
  return (dispatch, getState) => {
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/showreels/${showreelId}/export`,
      "GET"
    )
      .then((response) => {
        if (response.status === 200) {
          notification(
            "Export started.",
            "This can take several minutes. Please feel free to navigate elsewhere while we're working behind the scenes.",
            "success",
            4000
          );
        } else if (response.status >= 400) {
          throw new Error();
        }
      })
      .catch(() => {
        notification(
          "Unable to export showreel.",
          "Please try again.",
          "warning"
        );
      })
      .finally(callback);
  };
}

export function addContentToShowreel(showreelId, content) {
  track("Adding Content to Showreel", { showreelId: showreelId });
  return (dispatch, getState) => {
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/showreels/${showreelId}/contents`,
      "POST",
      content
    )
      .then((response) => {
        if (response.status === 200) {
          notification(
            "Content Added to showreel",
            "You may now view your content at the end of this showreel.",
            "success"
          );
        } else if (response.status >= 400) {
          throw new Error();
        }
      })
      .catch(() => {
        notification(
          "Unable to add content to showreel.",
          "Please try again.",
          "warning"
        );
      });
  };
}

export function getShowreelFile(showreelId) {
  track("Getting Showreel File", { showreelId: showreelId });
  return (dispatch) => {
    fetch(`${BASE_URL}/showreels/${showreelId}/file`)
      .then((response) => {
        if (response.status >= 400 && response.status < 600) {
          throw new Error();
        }
        return response.json();
      })
      .then((json) => dispatch({ type: "SET_CURRENT_SHOWREEL", payload: json }))
      .catch(() =>
        notification(
          "Cannot fetch showreel.",
          "Please confirm your intention to access this showreel and contact the owner.",
          "warning"
        )
      );
  };
}

export function uploadShowreelFile(showreelId, file, callback) {
  track("Uploaded a survey image");
  var fd = new FormData();
  fd.append("file", file);
  const url = `${BASE_URL}/showreels/${showreelId}/asset`;
  return (dispatch, getState) => {
    dispatch({
      type: "SET_LOADING",
      payload: true,
      name: "ShowreelImageUpload",
    });
    fetch(url, {
      method: "POST",
      body: fd,
      headers: {
        Authorization: "Bearer " + getState().authToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
      },
    })
      .then((response) =>
        response.json().then((json) => {
          callback(json);
        })
      )
      .finally(() => {
        dispatch({
          type: "SET_LOADING",
          payload: false,
          name: "ShowreelImageUpload",
        });
      });
  };
}

export function updateShowreelRedux(showreel) {
  return (dispatch, getState) => {
    dispatch({ type: "SET_CURRENT_SHOWREEL", payload: showreel });
  };
}

export function clearCurrentShowreel() {
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_CURRENT_SHOWREEL" });
  };
}

/* People Actions */
export function createPerson(person, callback) {
  track("Creating Person");
  return (dispatch, getState) => {
    dispatch({
      type: "SET_LOADING",
      payload: true,
      name: "CreatePerson",
    });
    fetchWithAuth(BASE_URL, getState().authToken, "/people", "POST", person)
      .then((response) => response.json())
      .then((json) => {
        notification(
          "Person Created",
          "This contact was successfully created.",
          "success"
        );
        const newPerson = { ...person, _id: json.personId };
        dispatch({
          type: "ADD_PERSON",
          payload: newPerson,
        });
        if (callback) callback({ ...newPerson });
      })
      .catch((e) => {
        console.error(e);
        notification(
          "Unable to create person.",
          "Please try again.",
          "warning"
        );
      })
      .finally(() => {
        dispatch({
          type: "SET_LOADING",
          payload: false,
          name: "CreatePerson",
        });
      });
  };
}

// This endpoint isn't used for now since all people are fetched individually.
// It may eventually be used to add additional info to survey responses/conversations.
export function getPerson(personId) {
  track("Getting Person");
  return (dispatch, getState) => {
    dispatch({ type: "SET_LOADING", payload: true, name: "GetPerson" });
    fetchWithAuth(BASE_URL, getState().authToken, `/people/${personId}`, "GET")
      .then((response) => response.json())
      .then((json) => {
        dispatch({ type: "ADD_PERSON", payload: json });
      })
      .catch(() => {
        notification("Unable to fetch person.", "Please try again.", "warning");
      })
      .finally(() => {
        dispatch({ type: "SET_LOADING", payload: false, name: "GetPerson" });
      });
  };
}

export function getPeople() {
  track("Getting People");
  return (dispatch, getState) => {
    dispatch({ type: "SET_LOADING", payload: true, name: "MyPeople" });
    fetchWithAuth(BASE_URL, getState().authToken, `/people`, "GET")
      .then((response) => response.json())
      .then((json) => {
        dispatch({ type: "RETURN_PEOPLE", payload: json });
      })
      .catch(() => {
        notification("Unable to fetch people.", "Please try again.", "warning");
      })
      .finally(() =>
        dispatch({
          type: "SET_LOADING",
          payload: false,
          name: "MyPeople",
        })
      );
  };
}

export function updatePerson(personId, person) {
  track("Updating Person", { personId: person });
  return (dispatch, getState) => {
    dispatch({
      type: "SET_LOADING",
      payload: true,
      name: "UpdatePerson",
    });
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/people/${personId}`,
      "PUT",
      person
    )
      .then((response) =>
        response.json().then((json) => {
          dispatch({
            type: "ADD_PERSON",
            payload: { ...person, _id: personId },
          });
          notification(
            "Person Updated",
            "This contact was updated successfully.",
            "success"
          );
        })
      )
      .catch(() => {
        notification(
          "Unable to update person.",
          "Please try again.",
          "warning"
        );
      })
      .finally(() => {
        dispatch({
          type: "SET_LOADING",
          payload: false,
          name: "UpdatePerson",
        });
      });
  };
}

export function deletePerson(personId) {
  track("Deleting Person", { personId: personId });
  return (dispatch, getState) => {
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/people/${personId}`,
      "DELETE"
    )
      .then((response) => {
        if (response.status === 200) {
          notification(
            "Person Deleted",
            "This contact has been deleted.",
            "success"
          );
          // Reload people
          dispatch(getPeople());
        } else if (response.status >= 400) {
          throw new Error();
        }
      })
      .catch(() => {
        notification(
          "Unable to delete person.",
          "Please try again.",
          "warning"
        );
      })
      .finally(() => {});
  };
}

/* Panel request actions */
export function createPanelRequest(panel_request, callback) {
  track("Creating Panel Request");
  return (dispatch, getState) => {
    dispatch({
      type: "SET_LOADING",
      payload: true,
      name: "CreatePanelRequest",
    });
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/panel/requests`,
      "POST",
      panel_request
    )
      .then((response) => response.json())
      .then((json) => {
        if (!json.panelRequestId)
          throw new Error("Panel Request ID not present.");
        callback({ ...panel_request, _id: json.panelRequestId });
      })
      .catch(() => {
        notification(
          "Unable to create panel request.",
          "Please try again.",
          "warning"
        );
      })
      .finally(() => {
        dispatch({
          type: "SET_LOADING",
          payload: false,
          name: "CreatePanelRequest",
        });
      });
  };
}

export function getPanelRequest(requestId) {
  track("Getting Panel Request");
  return (dispatch, getState) => {
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/panel/requests/${requestId}`,
      "GET"
    )
      .then((response) => response.json())
      .then((json) => {
        dispatch({ type: "SET_CURRENT_PANEL_REQUEST", payload: json });
      })
      .catch(() => {
        notification(
          "Unable to fetch panel request.",
          "Please try again.",
          "warning"
        );
      });
  };
}

export function getPanelRequests() {
  track("Getting Panel Requests");
  return (dispatch, getState) => {
    dispatch({ type: "SET_LOADING", payload: true, name: "MyPanelRequests" });
    fetchWithAuth(BASE_URL, getState().authToken, `/panel/requests`, "GET")
      .then((response) => response.json())
      .then((json) => {
        dispatch({ type: "SET_PANEL_REQUESTS", payload: json });
      })
      .catch(() => {
        notification(
          "Unable to fetch panel request.",
          "Please try again.",
          "warning"
        );
      })
      .finally(() =>
        dispatch({
          type: "SET_LOADING",
          payload: false,
          name: "MyPanelRequests",
        })
      );
  };
}

export function updatePanelRequest(requestId, panelRequestData) {
  track("Updating Panel Request", { requestId: requestId });
  return (dispatch, getState) => {
    dispatch({
      type: "SET_LOADING",
      payload: true,
      name: "UpdatePanelRequest",
    });
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/panel/requests/${requestId}`,
      "PUT",
      panelRequestData
    )
      .then((response) =>
        response.json().then((json) => {
          dispatch({
            type: "SET_CURRENT_PANEL_REQUEST",
            payload: json,
          });
        })
      )
      .catch(() => {
        notification(
          "Unable to update panel request.",
          "Please try again.",
          "warning"
        );
      })
      .finally(() => {
        dispatch({
          type: "SET_LOADING",
          payload: false,
          name: "UpdatePanelRequest",
        });
      });
  };
}

export function requestPanelPaymentIntent(requestId, callback) {
  track("Requesting Payment Intent", { requestId: requestId });
  return async (dispatch, getState) => {
    dispatch({
      type: "SET_LOADING",
      payload: true,
      name: "RequestPaymentIntent",
    });
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/panel/requests/${requestId}/checkout`,
      "GET"
    )
      .then((response) => {
        if (response.status === 200) {
          response.json().then((intent) => {
            callback(intent);
          });
        } else if (response.status >= 400) {
          throw new Error();
        }
      })
      .catch(() => {
        notification(
          "Unable to request panel intent.",
          "Please try again later.",
          "warning"
        );
      })
      .finally(() => {
        dispatch({
          type: "SET_LOADING",
          payload: false,
          name: "RequestPaymentIntent",
        });
      });
  };
}

export function deletePanelRequest(requestId) {
  track("Deleting Panel Request", { requestId: requestId });
  return (dispatch, getState) => {
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/panel/requests/${requestId}`,
      "DELETE"
    )
      .then((response) => {
        if (response.status === 200) {
          notification(
            "Panel Request removed",
            "Your panel request has been removed.",
            "success"
          );
          // Reload panel requests
          dispatch(getPanelRequests());
        } else if (response.status >= 400) {
          throw new Error();
        }
      })
      .catch(() => {
        notification(
          "Unable to delete panel request.",
          "Please try again.",
          "warning"
        );
      })
      .finally(() => {});
  };
}

/* File actions */

export function getFiles() {
  track("Getting Files");
  return (dispatch, getState) => {
    dispatch({ type: "SET_LOADING", payload: true, name: "MyFiles" });
    fetchWithAuth(BASE_URL, getState().authToken, `/files`, "GET")
      .then((response) => response.json())
      .then((json) => {
        dispatch({
          type: "ADD_FILES",
          payload: mapFlatFilesToTree(json.files),
        });
        dispatch({
          type: "ADD_FILES_USAGE",
          payload: json,
        });
      })
      .catch((e) => {
        notification("Unable to fetch files.", "Please try again.", "warning");
        console.error(e);
      })
      .finally(() =>
        dispatch({ type: "SET_LOADING", payload: false, name: "MyFiles" })
      );
  };
}

export function addFolder(path) {
  return (dispatch, getState) => {
    dispatch({
      type: "MERGE_FILES",
      payload: mapFlatFilesToTree([{ path: path, type: "FOLDER" }]),
    });
  };
}

export function deleteFolder(path) {
  // TODO: Finish implementing this
  return (dispatch, getState) => {
    dispatch({
      type: "DELETE_FILES",
      payload: path.split("/"),
    });
  };
}

export function addFile(file) {
  track("Adding Files", file);
  return (dispatch, getState) => {
    dispatch({
      type: "MERGE_FILES",
      payload: mapFlatFilesToTree([file]),
    });
    dispatch({
      type: "SET_FILE_UPLOAD_STATUS",
      payload: { [file.path]: "IN_PROGRESS" },
    });
    fetchWithAuth(BASE_URL, getState().authToken, `/files`, "POST", file)
      .then((response) => {
        if (response.status === 200) {
          notification(
            "File Added",
            "Your file has been successfully added.",
            "success"
          );
          return response.json();
        } else if (response.status >= 400) {
          throw new Error();
        }
      })
      .then((json) => {
        dispatch({
          type: "MERGE_FILES",
          payload: mapFlatFilesToTree([json]),
        });
        dispatch({
          type: "SET_FILE_UPLOAD_STATUS",
          payload: { [file.path]: "SUCCESS" },
        });
      })
      .catch((e) => {
        dispatch({
          type: "SET_FILE_UPLOAD_STATUS",
          payload: { [file.path]: "FAILED" },
        });
        notification("Unable to add file.", "Please try again.", "warning");
        console.error(e);
      });
  };
}

export function updateFile(fileId, updates, path = null) {
  track("Updating File", { fileId: fileId });

  return (dispatch, getState) => {
    dispatch({
      type: "SET_LOADING",
      payload: true,
      name: "ConversationEditor",
    });
    return fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/files/${fileId}`,
      "PUT",
      updates
    )
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: "UPDATE_FILE",
            payload: { fileId: fileId, path, updates },
          });
        } else if (response.status >= 400) {
          throw new Error();
        }
      })
      .catch((e) => {
        notification("Unable to update item.", "Please try again.", "warning");
        console.error(e);
      })
      .finally(() =>
        dispatch({
          type: "SET_LOADING",
          payload: false,
          name: "ConversationEditor",
        })
      );
  };
}

export function updateFiles(collection, updates) {
  track("Updating Files", updates);
  return (dispatch, getState) => {
    fetchWithAuth(BASE_URL, getState().authToken, `/files`, "PUT", {
      collection: collection,
      ...updates,
    })
      .then((response) => {
        if (response.status === 200) {
          notification(
            "Update Successful",
            "Your item has been successfully updated.",
            "success"
          );
          dispatch(getFiles()); // Trigger re-render.
        } else if (response.status >= 400) {
          throw new Error();
        }
      })
      .catch((e) => {
        notification("Unable to update item.", "Please try again.", "warning");
        console.error(e);
      });
  };
}

export function deleteFiles(data) {
  track("Deleting Files", data);
  return (dispatch, getState) => {
    fetchWithAuth(BASE_URL, getState().authToken, `/files`, "DELETE", data)
      .then((response) => {
        if (response.status === 200) {
          notification(
            "Deletion Successful",
            "Your item has been successfully removed.",
            "success"
          );
          dispatch(getFiles()); // Trigger re-render.
        } else if (response.status >= 400) {
          throw new Error();
        }
      })
      .catch((e) => {
        notification("Unable to delete item.", "Please try again.", "warning");
        console.error(e);
      });
  };
}

export function uploadFile(data, file) {
  track("Uploading File");
  return (dispatch, getState) => {
    dispatch({
      type: "SET_FILE_UPLOAD_STATUS",
      payload: { [data.path]: "IN_PROGRESS" },
    });
    fetchWithAuth(BASE_URL, getState().authToken, `/files/upload`, "POST", data)
      .then((response) => {
        if (response.status >= 400 && response.status < 600) throw new Error();
        return response.json();
      })
      // Upload to S3
      .then((resp) => {
        dispatch({
          type: "MERGE_FILES",
          payload: mapFlatFilesToTree([resp.file]),
        });

        const formData = new FormData();
        Object.keys(resp.fields).forEach((key) => {
          formData.append(key, resp.fields[key]);
        });
        formData.append("file", file);
        const params = {
          method: "POST",
          body: formData,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST",
            "Access-Control-Allow-Headers": "Origin, Content-Type",
          },
        };

        fetch(resp.upload, params)
          .then((response) => {
            if (response.status >= 400 && response.status < 600)
              throw new Error();
          })
          .then(() => {
            fetchWithAuth(
              BASE_URL,
              getState().authToken,
              `/files/${resp.fileId}`,
              "PUT",
              {
                status: "UPLOADED",
              }
            ).then((resp) => {
              dispatch({
                type: "SET_FILE_UPLOAD_STATUS",
                payload: { [data.path]: "SUCCESS" },
              });
              dispatch(getFiles());
              notification(
                "File Added",
                `${file.name} has been successfully added.`,
                "success"
              );
            });
          });
      })
      .catch((e) => {
        dispatch({
          type: "SET_FILE_UPLOAD_STATUS",
          payload: { [data.path]: "FAILED" },
        });
        notification("Unable to upload file.", "Please try again.", "warning");
        console.error(e);
      });
  };
}

/**
 * Uploads a file in multiple parts using S3's multipart upload.
 * @param {*} data Contains information relating to the file
 * @param {*} file The file to be uploaded
 */
export function uploadMulti(data, file) {
  track("Uploading File");
  return (dispatch, getState) => {
    // Sets upload status and progress to signal start of upload
    dispatch({
      type: "SET_FILE_UPLOAD_STATUS",
      payload: { [data.path]: "IN_PROGRESS" },
    });
    var updateProgress = (status) => {
      dispatch({
        type: "SET_FILE_UPLOAD_PROGRESS",
        payload: { [data.path]: parseInt(status) },
      });
    };
    updateProgress(0);
    // Breaks files into 5 MB chunks
    let chunkList = breakFileIntoParts(file);
    // Initializes multipart upload and generates list of presigned urls to upload to
    const dataWithParts = {
      ...data,
      contentType: file.type,
      parts: chunkList.length,
    };
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      `/files/upload_multi`,
      "POST",
      dataWithParts
    )
      .then((response) => {
        if (response.status === 403) {
          notification(
            "It looks like you've exceeded your usage.",
            "Please upgrade your plan to continue.",
            "warning"
          );
        } else if (response.status >= 400 && response.status < 600)
          throw new Error();
        else return response.json();
      })
      // Upload to S3
      .then((resp) => {
        if (!resp) return;
        // Adds file to file trees in redux store
        dispatch({
          type: "MERGE_FILES",
          payload: mapFlatFilesToTree([resp.file]),
        });
        uploadParts(
          chunkList,
          resp.presignedUrls,
          resp.uploadId,
          resp.objectName,
          updateProgress,
          dispatch,
          getState,
          BASE_URL,
          data.path
        ).then((etags) => {
          // Sends ETags to backend to reassemble the parts and complete the upload
          const completionData = {
            uploadId: resp.uploadId,
            parts: etags,
            objectName: resp.objectName,
          };
          fetchWithAuth(
            BASE_URL,
            getState().authToken,
            "/files/upload_complete",
            "PUT",
            completionData
          )
            .then((response) => {
              if (response.status >= 400 && response.status < 600)
                throw new Error(
                  "Upload Failed - UploadId:" +
                    resp.uploadId +
                    "Object Name:" +
                    resp.objectName
                );
            })
            .then(() => {
              // Updates DB with uploaded status
              fetchWithAuth(
                BASE_URL,
                getState().authToken,
                `/files/${resp.fileId}`,
                "PUT",
                {
                  status: "UPLOADED",
                }
              ).then(() => {
                // Sets redux store upload status as complete, upload progress as 100%, and sends user a notification
                dispatch(getFiles());
                updateProgress(100);
                dispatch({
                  type: "SET_FILE_UPLOAD_STATUS",
                  payload: { [data.path]: "SUCCESS" },
                });
                notification(
                  "File Added",
                  `${file.name} has been successfully added.`,
                  "success"
                );
              });
            })
            .catch((e) => {
              handleUploadError(e, dispatch, getState, BASE_URL, data.path);
            });
        });
      })
      .catch((e) => {
        handleUploadError(e, dispatch, getState, BASE_URL, data.path);
      });
  };
}

export function generateGSheet(surveyId, email) {
  track("Generating GSheet", { surveyId: surveyId });
  notification(
    "Generating Google Sheet...",
    "This may take a moment.",
    "success"
  );
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      fetchWithAuth(
        BASE_URL,
        getState().authToken,
        `/gsheets/${surveyId}/generate`,
        "POST",
        { email: email }
      )
        .then((response) => {
          if (response.status !== 200) {
            throw response.status;
          } else {
            response.json().then((json) => {
              dispatch({
                type: "UPDATE_SURVEY_PROP",
                payload: {
                  surveyId: surveyId,
                  data: json.data,
                },
              });
              notification(
                "Google Sheet Created",
                "You will now have access to the live GSheet associated with this survey.",
                "success"
              );
            });
          }
        })
        .catch((e) => {
          notification(
            "GSheets Generation Failed",
            "An error has occured.",
            "danger"
          );
          reject(e);
        });
    });
  };
}

export function getUsage(params) {
  track("Getting User Usage");
  return (dispatch, getState) => {
    fetchWithAuth(BASE_URL, getState().authToken, "/user/usage", "POST", params)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(response.status);
        }
      })
      .then((json) => {
        dispatch({ type: "RETURN_USAGE", payload: json, params: params });
      })
      .catch((err) => {
        notification(
          "Error",
          "There was a problem retrieving usage metrics.",
          "warning"
        );
      });
  };
}

export function getCreditInfo() {
  track("Getting Credits Info");
  return (dispatch, getState) => {
    dispatch({
      type: "SET_LOADING",
      payload: true,
      name: "CreditInfo",
    });
    fetchWithAuth(BASE_URL, getState().authToken, "/user/credits/info")
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(response.status);
        }
      })
      .then((json) => {
        dispatch({ type: "RETURN_CREDIT_INFO", payload: json });
      })
      .catch((err) => {
        notification(
          "Error",
          "There was a problem retrieving credits info",
          "warning"
        );
      })
      .finally(() =>
        dispatch({
          type: "SET_LOADING",
          payload: false,
          name: "CreditInfo",
        })
      );
  };
}

export function getCreditUsage(params) {
  track("Getting Credits Usage");
  return (dispatch, getState) => {
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      "/user/credits/usage",
      "POST",
      params
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(response.status);
        }
      })
      .then((json) => {
        dispatch({ type: "RETURN_CREDIT_USAGE", payload: json });
      })
      .catch((err) => {
        notification(
          "Error",
          "There was a problem retrieving credits usage",
          "warning"
        );
      });
  };
}

export function getCreditSummary(params) {
  track("Getting Credits Summary");
  return (dispatch, getState) => {
    fetchWithAuth(
      BASE_URL,
      getState().authToken,
      "/user/credits/summary",
      "POST",
      params
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(response.status);
        }
      })
      .then((json) => {
        dispatch({ type: "RETURN_CREDIT_SUMMARY", payload: json });
      })
      .catch((err) => {
        notification(
          "Error",
          "There was a problem retrieving credits summary",
          "warning"
        );
      });
  };
}
