import React from "react";
import FileGroup from "./FileGroup";
import AddCollectionButton from "./AddCollectionButton";
import Skeleton from "@material-ui/lab/Skeleton";

const SkeletonFiles = () => {
  return (
    <div className="dummy-results-tile">
      <Skeleton variant="text" style={{ width: "80%", margin: "5% 10%" }} />
      <Skeleton variant="text" style={{ width: "80%", margin: "5% 10%" }} />
      <Skeleton variant="text" style={{ width: "80%", margin: "5% 10%" }} />
      <Skeleton variant="text" style={{ width: "80%", margin: "5% 10%" }} />
    </div>
  );
};

class FileBrowser extends React.Component {
  state = { searchText: "" };
  handleSearch = (e) => {
    this.setState({ searchText: e.target.value });
  };
  searchFilter = (file) => {
    if (!file) return false;
    return (
      (file.path + file.name)
        .toLowerCase()
        .includes(this.state.searchText.toLowerCase()) ||
      Object.values(file.children).some((c) => this.searchFilter(c))
    );
  };
  render() {
    return (
      <div className="file-browser">
        <div className="small-search-container">
          <input
            type="text"
            placeholder="Search..."
            onChange={this.handleSearch}
            className="conversations-search"
          />
        </div>
        <div className="files-header">
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              className="collection-title-container"
              role="button"
              tabIndex={0}
            >
              <span className="collection-title">{`Collections [${
                this.props.loading
                  ? "Loading..."
                  : Object.keys(this.props.files).length
              }]`}</span>
            </div>
          </div>
          <AddCollectionButton
            type="CONTAINER"
            handleClick={this.props.openNewCollectionPopup}
          />
        </div>
        {this.props.loading && !this.props.files ? (
          <SkeletonFiles />
        ) : (
          <div className="display-block">
            {Object.values(this.props.files)
              .filter(this.searchFilter)
              .map((f) => (
                <FileGroup
                  {...this.props}
                  {...f}
                  key={f.name}
                  path={f.name}
                  depth={1}
                />
              ))}
          </div>
        )}
      </div>
    );
  }
}

export default FileBrowser;
