import React, { Component } from "react";
import DescriptionIcon from "@material-ui/icons/Description";
import PersonAdd from "@material-ui/icons/PersonAdd";
import PersonRemove from "@material-ui/icons/RemoveCircleOutline";
import Waveform from "components/Waveform";
import SplitChip from "components/SplitChip";
import { getSentimentJSX } from "../utils/utils";
import { Paper, Grid, Chip, IconButton, Tooltip } from "@material-ui/core";
import {
  isVideoType,
  isAudioType,
  isSelectionNature,
  getTypeJSXIcon,
  isAudioOrVideoType,
  getChartColors,
  getTransparentChartColors,
} from "utils/utils";
import Skeleton from "@material-ui/lab/Skeleton";
import { withRouter } from "react-router";
import HighlightedTranscription from "components/HighlightedTranscription";
import VideoPlayer from "./VideoPlayer";
import TypeTile from "./TypeTile";
import PieChart from "components/Graphs/PieChart";
import { faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { rainbow } from "utils/brandPalette";
import { getAverageSentimentPieChartData } from "utils/graphData";
import { getDemographics } from "utils/utils";
import SimpleMenu from "components/SessionPopupMenu";
import ResponseViewPopup from "components/Popups/ResponseViewPopup.jsx";
import { hasPlayScreen } from "utils/utils";
import { getBestSessionTitles } from "utils/utils";
import { PeoplePopover } from "./Popovers/PeoplePopover";
import CreatePersonPopup from "components/Popups/CreatePersonPopup";

class SessionView extends Component {
  state = {
    playbackTimes: {},
    skipTimes: {},
    responsePopupOpen: false,
    createPersonPopupOpen: false,
    currentResponse: null,
  };

  handleKeyStroke = (event) => {
    if (this.state.responsePopupOpen) {
      return;
    } else if (event.keyCode === 37) {
      this.props.prevSession();
    } else if (event.keyCode === 39) {
      this.props.nextSession();
    }
  };

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyStroke, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyStroke, false);
  }

  getResponse = (questionId) => {
    if (!this.props.session) return null;
    for (var r in this.props.session.responses) {
      if (this.props.session.responses[r].questionId === questionId)
        return this.props.session.responses[r];
    }
    return null;
  };

  showAudioPlayer = (question) => {
    return question.type.includes("AUDIO") || question.type === "THINKALOUD";
  };
  showVideoPlayer = (question) => {
    return question.type.includes("VIDEO") || question.includes === "SCREEN";
  };

  closeResponsePopup = () => {
    this.setState({
      responsePopupOpen: false,
      currentResponse: null,
    });
  };

  sentimentJSX = (response) => {
    if (!response || !response.features || !response.features.sentiment)
      return null;
    return <div>{getSentimentJSX(response.features.sentiment, response)}</div>;
  };

  getSessionChips = () => {
    if (
      !this.props.session ||
      !this.props.session.features ||
      !(
        this.props.session.features.completionStatus ||
        this.props.session.features.device
      )
    )
      return null;

    const completionStatusColors = {
      IN_PROGRESS: "#578fc07a",
      FINISHED: "#d3eae5",
      SCREENED_OUT: "#ac5a5a6e",
    };

    const demographics = this.props.session.demographics;
    const url = this.props.session.features.url;
    const queryParams = this.props.session.features.queryParams;

    return (
      <div className="session-device-chip-container">
        <div className="chip-container">
          <SplitChip left="Session ID" right={this.props.session.sessionId} />
          {this.props.session.features.completionStatus && (
            <SplitChip
              left="Status"
              right={this.props.session.features.completionStatus
                .toLowerCase()
                .replace("_", " ")}
              color={
                completionStatusColors[
                  this.props.session.features.completionStatus
                ]
              }
              style={{ textTransform: "capitalize" }}
            />
          )}
          {this.props.session.features.device && (
            <SplitChip
              left="Device"
              right={this.props.session.features.device}
              color="grey"
              style={{ textTransform: "capitalize" }}
            />
          )}
          {demographics &&
            getDemographics(demographics, true).map((d) => {
              return (
                <SplitChip
                  key={`DEMO-CHIP-${d.label}}`}
                  left={d.label}
                  right={d.value}
                  color="grey"
                />
              );
            })}
          {typeof this.props.session.features === "string" &&
            !url.includes("survey.phonic.ai") && (
              <SplitChip
                left="url"
                right={
                  <a
                    href={this.props.session.features.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {this.props.session.features.url}
                  </a>
                }
                color="#578fc07a"
              />
            )}
          {queryParams &&
            Object.keys(queryParams).map((k, i) => (
              <SplitChip
                key={`QP-SPLITCHIP-${i}`}
                left={`?${k}`}
                right={queryParams[k]}
                color="#578fc07a"
              />
            ))}
        </div>
        <div className="respondent-options">
          <SimpleMenu
            surveyId={this.props.session.surveyId}
            sessionObjectId={this.props.session._id}
            closeSession={this.props.close}
          />
        </div>
      </div>
    );
  };

  openResponse = (res) => {
    if (res && hasPlayScreen(res)) {
      this.setState({
        responsePopupOpen: true,
        currentResponse: res,
      });
    }
  };

  updatePlaybackResponse = (responseId, time) => {
    this.setState({
      playbackTimes: { ...this.state.playbackTimes, [responseId]: time },
    });
  };

  getResponseJSX = (question, response, loading) => {
    if (loading) {
      return (
        <>
          <Skeleton variant="text" style={{ width: "100%" }} />
          <Skeleton variant="text" style={{ width: "50%", marginBottom: 30 }} />
        </>
      );
    }

    if (!response) return <div className="response-title">No Response</div>;

    // Problems occur if the type changes to or from SELECTION, or if new options
    // are added. This should be blocked, but some old surveys have this issue.
    if (
      ((isSelectionNature(question.type) && !Array.isArray(response.data)) ||
        (Array.isArray(response.data) && !isSelectionNature(question.type))) &&
      !question.optional
    )
      return <div className="response-title">Question Type Changed</div>;
    if (
      Array.isArray(response.data) &&
      Array.isArray(question.options) &&
      response.data.length !== question.options.length
    )
      return <div className="response-title">Options Changed</div>;

    var result = null;
    switch (question.type) {
      case "AUDIO":
      case "THINKALOUD":
      case "VIDEO":
      case "SCREEN":
        break;
      case "TEXT":
      case "AUDIO_TEXT":
      case "NUMBER":
      case "SLIDER":
      case "AUDIO_SLIDER":
        if (response.data !== undefined) {
          result = (
            <>
              <div className="response-title">Response Content</div>
              <div className="response-text">{response.data}</div>
            </>
          );
        }
        break;
      case "SELECTION":
      case "DROPDOWN":
      case "AUDIO_SELECTION":
        if (!Array.isArray(response.data)) break;

        result = (
          <>
            <div className="response-title">Selection Response</div>
            <div className="response-text">
              {response.data.map((d, i) => {
                return d ? (
                  <li key={`RESPONSE-${i}`}>{question.options[i]}</li>
                ) : null;
              })}
            </div>
          </>
        );
        break;
      case "LIKERT":
        if (!response.data || !response.data.length) break;
        result = response.data.map((data, idx) => {
          if (data)
            return (
              <li className="options-text" key={`likert-option-${idx}`}>
                {question.options[idx]} ({data})
              </li>
            );
          return null;
        });
        break;
      case "DISPLAY":
        result = <div className="response-title">No Response</div>;
        break;
      case "RANKING":
        result = (
          <>
            <div className="response-title">Ranking Response</div>
            <div className="response-text">
              <ol>
                {response.data.map((d, i) => {
                  return d && <li key={`RESPONSE-${i}`}>{response.data[i]}</li>;
                })}
              </ol>
            </div>
          </>
        );
        break;
      case "AUDIO_RANKING":
        result = (
          <>
            <div className="response-title">Ranking Response</div>
            <div className="response-text">
              <ol>
                {response.data.map((d, i) => {
                  return d && <li key={`RESPONSE-${i}`}>{response.data[i]}</li>;
                })}
              </ol>
            </div>
          </>
        );
        break;
      case "FILE":
        result = (
          <div className="response-text file-chip-container">
            {response.uri && (
              <Chip
                size="small"
                icon={<DescriptionIcon />}
                label="Attached File"
                color="primary"
                onClick={() => window.open(response.uri, "_blank")}
              />
            )}
            {response.uris &&
              response.uris.map((uri, idx) => (
                <Chip
                  key={idx}
                  size="small"
                  icon={<DescriptionIcon />}
                  label={`Attached File - ${idx + 1}`}
                  color="primary"
                  onClick={() => window.open(uri, "_blank")}
                />
              ))}
            {!response.uri && !response.uris && "No Media Found"}
          </div>
        );
        break;
      default:
        result = <div className="response-text">Unsupported Question Type</div>;
        break;
    }
    return (
      <>
        {/* TODO: replace with util functions */}
        {(isAudioType(question.type) || isVideoType(question.type)) &&
          response.uri && (
            <>
              {this.showAudioPlayer(question) ? (
                <div className="audio-player">
                  <Waveform
                    key={`waveform-${response._id}`}
                    interactiveTranscription={true}
                    playTime={
                      this.state.skipTimes[response._id]
                        ? this.state.skipTimes[response._id]
                        : 0
                    }
                    updatePlaybackResponse={(time) =>
                      this.updatePlaybackResponse(response._id, time)
                    }
                    height={50}
                    src={response.uri}
                    style={{ width: "100%", height: 20 }}
                  />
                </div>
              ) : (
                <div onClick={(e) => e.stopPropagation()}>
                  <VideoPlayer
                    key={`video-${response._id}`}
                    uri={response.uri}
                    responseId={response._id}
                    onTimeUpdate={(time) =>
                      this.updatePlaybackResponse(response._id, time)
                    }
                    skipTime={
                      this.state.skipTimes[response._id]
                        ? this.state.skipTimes[response._id]
                        : 0
                    }
                  />
                </div>
              )}
              <br />

              {question.doTranscription && response.features && (
                <Grid container spacing={2}>
                  <Grid item xs={question.doSentiment ? 8 : 12}>
                    <div className="response-title">Transcription</div>
                    {(response.features.transcription_items ||
                      response.features.transcription) && (
                      <HighlightedTranscription
                        time={this.state.playbackTimes[response._id]}
                        onWordClick={(e, item) => {
                          e.stopPropagation();
                          this.setState({
                            skipTimes: {
                              ...this.state.skipTimes,
                              [response._id]: parseFloat(item.start_time),
                            },
                          });
                        }}
                        transcription={response.features.transcription}
                        transcriptionEdited={
                          response.features.transcription_edited
                        }
                        transcriptionItems={
                          response.features.transcription_items
                        }
                      />
                    )}
                  </Grid>
                  {question.doSentiment && (
                    <Grid item xs={4} container justifyContent="flex-end">
                      <div className="response-title">Sentiment</div>
                      <div className="square-max-md">
                        <PieChart
                          chartData={getAverageSentimentPieChartData([
                            response,
                          ])}
                          backgroundColor={getTransparentChartColors(4, 0.4)}
                          borderColor={getChartColors(4)}
                          displayLegend={false}
                        />
                      </div>
                    </Grid>
                  )}
                </Grid>
              )}
            </>
          )}

        {response.backupText && (
          <>
            <div className="response-title">Backup Text</div>
            <div className="response-text">{response.backupText}</div>
          </>
        )}
        {result}
      </>
    );
  };

  openPeoplePopover = (e) => {
    this.setState({
      peopleAnchorEl: e.currentTarget,
    });
  };
  peoplePopoverClosed = (e) => {
    this.setState({ peopleAnchorEl: null });
  };

  tagPerson = (personId) => {
    this.props.updateSession(this.props.session, { personId: personId });
  };

  removePersonTag = () => {
    this.props.updateSession(this.props.session, { personId: null });
  };

  openNewPersonPopup = () => {
    this.setState({ createPersonPopupOpen: true, peopleAnchorEl: false });
  };

  createPersonAndClosePopup = (person) => {
    // Creates a person and uses the success popup to close the popup if this request is successful.
    this.props.createPerson(person, (p) => {
      this.tagPerson(p._id);
      this.setState({ createPersonPopupOpen: false });
    });
  };

  render() {
    const sessionTitles = getBestSessionTitles(this.props.session);
    return (
      <div className="session-view">
        {this.state.responsePopupOpen && (
          <ResponseViewPopup
            open={this.state.responsePopupOpen}
            response={this.state.currentResponse}
            onClose={this.closeResponsePopup}
            user={this.props.user}
            updateResponse={(id, update) => {
              this.props.updateResponse(id, update).then(() => {
                this.props.getSession(
                  this.props.match.params.surveyId,
                  this.props.session._id
                );
              });
            }}
          />
        )}
        <CreatePersonPopup
          open={this.state.createPersonPopupOpen}
          onClose={() => this.setState({ createPersonPopupOpen: false })}
          loading={this.props.createPersonLoading}
          onSubmit={this.createPersonAndClosePopup}
        />
        {this.props.survey && (
          <div>
            <div id="title-block">
              <div className="mr-10">
                <TypeTile
                  shape="square"
                  iconsOverride={[faUserFriends]}
                  colorOverride={rainbow.gray}
                />
              </div>
              <div className="title-block-text">
                <div className="question-title overflow-ellipsis">
                  {this.props.session.personId ? (
                    <>
                      <a
                        onClick={() => {
                          this.props.history.push(
                            `/people/${this.props.session.personId}`
                          );
                        }}
                      >
                        {sessionTitles.text}
                      </a>
                      <span className="ml-5">
                        <Tooltip title="Untag Person">
                          <IconButton onClick={this.removePersonTag}>
                            <PersonRemove />
                          </IconButton>
                        </Tooltip>
                      </span>
                    </>
                  ) : (
                    <>
                      <span>{sessionTitles.text}</span>
                      <span className="ml-5">
                        <Tooltip title="Tag Person">
                          <IconButton onClick={this.openPeoplePopover}>
                            <PersonAdd />
                          </IconButton>
                        </Tooltip>
                      </span>
                    </>
                  )}

                  <PeoplePopover
                    open={Boolean(this.state.peopleAnchorEl)}
                    anchorEl={this.state.peopleAnchorEl}
                    handleClose={this.peoplePopoverClosed}
                    people={this.props.people}
                    peopleLoading={this.props.peopleLoading}
                    tagPerson={this.tagPerson}
                    handleNewPerson={this.openNewPersonPopup}
                  />
                </div>
                <div style={{ color: "#808080" }}>
                  {`${this.props.sessionId}`}
                </div>
              </div>
            </div>

            <Paper>
              {this.getSessionChips()}
              {this.props.survey.questions.map((q, i) => {
                var response = this.getResponse(q.questionId);
                return (
                  <React.Fragment key={`SESSIONVIEW-Q${i}`}>
                    <hr />
                    <div
                      className={`session-question ${
                        isAudioOrVideoType(q.type) ? "hover" : null
                      }`}
                      key={`sessionQuestionGroup-${q.questionId}-${i}`}
                      onClick={() => this.openResponse(response)}
                    >
                      <div className="question-identifier">
                        {getTypeJSXIcon(q.type, i + 1)}
                      </div>
                      <div className="question-content">
                        <h3>{q.text}</h3>
                        <div>
                          {this.getResponseJSX(q, response, this.props.loading)}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </Paper>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(SessionView);
