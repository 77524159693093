/**
 * Lightweight fetch abstraction.
 * @param {*} baseUrl The URL for which to fetch not including request path.
 * @param {*} token Authentication token.
 * @param {*} path Path to be appended to base URL.
 * @param {*} method REST API method to apply.
 * @param {*} data Data included in request body.
 * @returns
 */
const fetchWithAuth = (baseUrl, token, path, method, data) => {
  const options = {
    method: method,
    headers: {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
      "Content-Type": "application/json",
    },
  };
  if (data) options["body"] = JSON.stringify(data);
  return fetch(`${baseUrl}${path}`, options);
};

export default fetchWithAuth;
