import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Button, DialogActions, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { toTitleCase } from "utils/utils";
import { Alert } from "@material-ui/lab";

export default function UpgradePopup({
  open,
  handleClose,
  oldTier,
  oldUsage,
  newTier,
  newUsage,
  newBillingPeriod,
  updateSubscription,
}) {
  const getTitle = () => {
    if (oldTier == newTier) {
      return (
        <>
          Add credits to <b>{toTitleCase(oldTier)}</b> plan
        </>
      );
    } else {
      return (
        <>
          {newTier == "PREMIUM" ? "Upgrade" : "Downgrade"} from{" "}
          <b>{toTitleCase(oldTier)}</b> to <b>{toTitleCase(newTier)}</b>?
        </>
      );
    }
  };
  return (
    <Dialog id="upgrade-plan-popup" open={open} onClose={handleClose}>
      <div className="x-button">
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className="standard-popup-container">
        <h2 className="dialog-title-small">{getTitle()}</h2>
        <h3 className="sharing-title">
          {newUsage > oldUsage
            ? "Upgrades are applied instantly, and your first month will be prorated based on the current billing cycle."
            : <>To downgrade, cancel your current subscription in <a href="/settings">Settings</a> and subscribe to {toTitleCase(
              newTier
            )}.</>}
        </h3>
        {newUsage < oldUsage && (
          <Alert severity="info">
            You will be downgraded at the end of your billing cycle and will lose
            unused credits.
          </Alert>
        )}
        {newUsage > oldUsage && (
          <Alert severity="info">
            The card associated with your Phonic account will be charged
          </Alert>
        )}
        {newUsage > oldUsage && (
          <div className="mt-20">
            <p>
              New Billing Cycle: <b>{toTitleCase(newBillingPeriod)}</b>
            </p>
            <p>
              Current Monthly Credits: <b>{oldUsage}</b>
            </p>
            <p>
              New Monthly Credits: <b>{newUsage}</b>
            </p>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          margin="dense"
          variant="contained"
          color="primary"
          onClick={() => {
            if (newUsage > oldUsage) {
              updateSubscription();
            }
            handleClose();
          }}
        >
          {newUsage > oldUsage ? "Pay and upgrade" : "Close"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
