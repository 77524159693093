import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenSquare,
  faVolumeUp,
  faChartBar,
} from "@fortawesome/free-solid-svg-icons";

const NewBlockPopup = ({
  open,
  closePopup,
  addMarkdown,
  setBookmarkBrowser,
}) => {
  const [currentStep, setCurrentStep] = useState(0);

  const BLOCK_TYPES = [
    {
      key: "MARKDOWN",
      icon: faPenSquare,
      name: "Markdown",
      action: addMarkdown,
    },
    {
      key: "RESPONSE",
      icon: faVolumeUp,
      name: "Response",
      action: () => {
        closePopup();
        setBookmarkBrowser(true);
      },
    },
    {
      key: "GRAPH",
      icon: faChartBar,
      name: "Graph",
      action: () => setCurrentStep(1),
    },
  ];

  const getStepView = (currentStep) => {
    switch (currentStep) {
      case 1:
        return (
          <DialogContent style={{ paddingBottom: 40 }}>
            <Grid container spacing={2} justifyContent="center" align="center">
              <Grid item xs={8}>
                Graphs can be added from an individual question page in a survey
                of interest.
              </Grid>
            </Grid>
          </DialogContent>
        );
      case 0:
      default:
        return (
          <DialogContent style={{ paddingBottom: 40 }}>
            <Grid container spacing={2} justifyContent="center">
              {BLOCK_TYPES.map((type, i) => (
                <Grid
                  key={type.key}
                  item
                  xs={12}
                  sm={3}
                  id="question-types"
                  style={{ height: "auto" }}
                >
                  <Paper className="quesiton-type-tile" onClick={type.action}>
                    <FontAwesomeIcon
                      key={`ICON-${i}`}
                      icon={type.icon}
                      className="question-type-tile-icon"
                    />
                    <div className="question-type-tile-text">{type.name}</div>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </DialogContent>
        );
    }
  };

  const handleClose = () => {
    closePopup();

    /**
     * Popup takes 300 ms to close, so setTimeout is to ensure modal is closed
     * before view changes to default
     */
    setTimeout(() => setCurrentStep(0), 500);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="add-to-report"
      fullWidth
      maxWidth={currentStep === 1 ? "xs" : "sm"}
    >
      <DialogTitle>
        <span
          className="dialog-title chip-table-container"
          style={{ marginTop: 10 }}
        >
          Add a New Block
        </span>
      </DialogTitle>
      {getStepView(currentStep)}
    </Dialog>
  );
};

export default NewBlockPopup;
