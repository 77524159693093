import React from "react";
import { Switch } from "@material-ui/core";
import PricingCard from "components/Pricing/PricingCard";
import { track, trackPage } from "../../analytics";
import stripePromise from "../../stripe";
import {
  getUser,
  getUpgradeCheckoutId,
  updateSubscription,
  startLoading,
  cancelSubscription,
} from "../../redux/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getDiscountReadable } from "utils/tiers";
import { Alert } from "@material-ui/lab";

const PHONIC_PAID_PLANS = ["STARTER", "PREMIUM", "ENTERPRISE"];
const PHONIC_PLANS = ["FREE", ...PHONIC_PAID_PLANS];

const mapStateToProps = (state) => {
  return {
    user: state.user,
    stripeCheckoutId: state.stripeCheckoutId,
    loading: state.loading,
    upgradePopupOpen: state.upgradePopupOpen,
  };
};

const mapDispatchToProps = {
  getUser,
  getUpgradeCheckoutId,
  startLoading,
  updateSubscription,
  cancelSubscription,
};

class Plans extends React.Component {
  state = { billingPeriod: "YEARLY", cancelSubscriptionPopupOpen: false };

  createSubscription = (tier, creditBundles, billingPeriod) => {
    this.props.startLoading(() => {
      this.props.getUpgradeCheckoutId(
        tier,
        creditBundles,
        this.state.billingPeriod
      );
    }, `Upgrade-${tier}`);
  };

  updateSubscription = (tier, creditBundles, billingPeriod) => {
    this.props.updateSubscription(tier, creditBundles, billingPeriod, () => {
      const oldTier = this.props.user.tier;
      this.props.getUser();
      this.props.history.push({
        pathname: "/home",
        search: `?newTier=${tier}&oldTier=${oldTier}`,
      });
    });
  };

  billingPeriodChange = (e) => {
    this.setState({
      billingPeriod: e.target.checked ? "YEARLY" : "MONTHLY",
    });
  };

  componentDidMount() {
    trackPage("Plans");
    this.setState({
      billingPeriod:
        this.props.user && this.props.user.billingInterval === "month"
          ? "MONTHLY"
          : "YEARLY",
    });
  }

  routeToSignup = () => {
    this.props.history.push("/signup");
  };

  billingPeriodDiffer = () => {
    if (!this.props.user.billingInterval) return false;
    const isMonthly =
      this.props.user.billingInterval === "month" &&
      this.state.billingPeriod === "MONTHLY";
    const isYearly =
      this.props.user.billingInterval === "year" &&
      this.state.billingPeriod === "YEARLY";
    if (isMonthly || isYearly) return false;
    else return true;
  };

  async componentDidUpdate(prevProps) {
    if (this.props.stripeCheckoutId) {
      track("Tier Premium Clicked (Redirected to stripe)");
      const stripe = await stripePromise;
      stripe.redirectToCheckout({ sessionId: this.props.stripeCheckoutId });
    }
    if (this.props.open !== prevProps.open && this.props.open === true) {
      track("Premium Popup Opened");
    }
    if (this.props.user !== prevProps.user) {
      this.setState({
        billingPeriod:
          this.props.user && this.props.user.billingInterval === "month"
            ? "MONTHLY"
            : "YEARLY",
      });
    }
  }

  cancelSubscription = () => {
    this.setState({
      cancelSubscriptionPopupOpen: true,
    });
  };

  getTier = () => {
    if (this.props.user) return this.props.user.tier;
  };

  getDiscount = () => {
    if (this.props.user) return this.props.user.discount;
  };

  render() {
    return (
      <div>
        <div className="plans-container">
          <h1>
            Pricing designed for teams of{" "}
            <span className="green-text-accent">all sizes.</span>
          </h1>
          <p>
            Whether you're an enterprise looking to scale or individual
            academic, Phonic has a plan to support your research needs.
          </p>
          <p>
            Check out our <a style={ { textDecoration: "underline" } } href='https://www.phonic.ai/phonic-referral-program' target='_blank'>referral program</a> to earn free credits!
          </p>
          {this.props.user && getDiscountReadable(this.props.user.discount) && (
            <p>
              <span className="green-text-accent">
                <b>{getDiscountReadable(this.props.user.discount)}</b>
              </span>{" "}
              discount applied.
            </p>
          )}
          <span>
            <b>Monthly</b>
          </span>
          <Switch
            color="primary"
            inputProps={{ "aria-label": "checkbox with default color" }}
            onChange={this.billingPeriodChange}
            checked={this.state.billingPeriod === "YEARLY"}
          />
          <span style={{ width: 110, textAlign: "left" }}>
            <b>Yearly</b> (save 40%)
          </span>
          {this.props.user && this.billingPeriodDiffer() && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 10,
                marginBottom: 30,
              }}
            >
              <Alert severity="warning">
                You are currently on a {this.props.user.billingInterval}ly plan.
                To switch to {this.state.billingPeriod.toLowerCase()} pricing to
                please contact support at contact@phonic.ai
              </Alert>
            </div>
          )}
          <div className="plan-container">
            {(this.props.user ? PHONIC_PAID_PLANS : PHONIC_PLANS).map(
              (tier) => {
                return (
                  <div
                    key={`PRICING-CARD-${tier}`}
                    className="plan-inner-container"
                  >
                    <PricingCard
                      createSubscription={this.createSubscription}
                      updateSubscription={this.updateSubscription}
                      contact={() => {
                        track("Tier Enterprise Clicked (Contact Us)");
                        document.location.href = "mailto:contact@phonic.ai";
                      }}
                      userTier={this.props.user?.tier}
                      monthlyCredits={this.props.user?.monthlyCredits}
                      tier={tier}
                      // No tier implies free
                      selectedTier={this.getTier() ? this.getTier() : "FREE"}
                      discount={this.getDiscount()}
                      loading={this.props.loading[`Upgrade-${tier}`]}
                      billingPeriod={this.state.billingPeriod}
                      routeToSignup={this.routeToSignup}
                      disableButtons={
                        this.props.user && this.billingPeriodDiffer()
                      }
                    />
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Plans));
