import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileMedical,
  faFolderPlus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Menu, MenuItem } from "@material-ui/core";

export default function AddCollectionButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "add-file-menu" : undefined;
  const useMenu = props.type === "FOLDER";

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (props.type !== "FOLDER" && props.type !== "CONTAINER") {
    return null;
  }
  return (
    <>
      <div
        role="button"
        tabIndex={0}
        className="file-button-wrapper"
        onClick={
          props.handleClick
            ? props.handleClick
            : useMenu
            ? handleClick
            : undefined
        }
      >
        <svg
          viewBox="0 0 62 62"
          className="file-button plus"
          style={{ enableBackground: "new 0 0 60.364 60.364" }}
        >
          <FontAwesomeIcon icon={faPlus} />
        </svg>
      </div>
      {props.type === "FOLDER" && (
        <Menu
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem onClick={props.addFile}>
            <FontAwesomeIcon icon={faFileMedical} />
            &nbsp;&nbsp;Add Files
          </MenuItem>
          <MenuItem onClick={props.addFolder}>
            <FontAwesomeIcon icon={faFolderPlus} />
            &nbsp;&nbsp;Add Folder
          </MenuItem>
        </Menu>
      )}
    </>
  );
}
