import { isString } from "lodash";

export const CREDIT_BUNDLE_AMOUNT = 50;

export const TIER_USAGE = {
  FREE: {
    responses: 25,
    surveys: 3,
  },
  STARTER: {
    responses: 50,
    surveys: -1,
  },
  PREMIUM: {
    responses: 200,
    surveys: -1,
  },
  ENTERPRISE: {
    responses: 1000,
    surveys: -1,
  },
};

const BASE_CREDITS = {
  STARTER: 25,
  PREMIUM: 200,
};

const TIER_PRICING = {
  FREE: { MONTHLY: { PLAN: "0" }, YEARLY: { PLAN: "0" } },
  STARTER: {
    MONTHLY: { PLAN: 59, CREDITS: 35 },
    YEARLY: { PLAN: 36, CREDITS: 21 },
  },
  PREMIUM: {
    MONTHLY: { PLAN: 129, CREDITS: 25 },
    YEARLY: { PLAN: 79, CREDITS: 15 },
  },
  ENTERPRISE: {
    MONTHLY: { PLAN: 198, CREDITS: 0 },
    YEARLY: { PLAN: 139, CREDITS: 0 },
  },
};

export function getColor(tier) {
  if (tier === "FREE") return "#12b361";
  else if (tier === "STARTER") return "#5772ff";
  else if (tier === "PREMIUM") return "#5772ff";
  else if (tier === "ENTERPRISE") return "#ff6501";
  return "#000000";
}

export function getFeatures(tier, creditBundles) {
  switch (tier) {
    case "FREE":
      return {
        name: "Free",
        rank: 0,
        hasStartPrice: false,
        description:
          "Kick off a 14 day trial without providing any payment details.",
        featureList: [
          "25 credits",
          "3 surveys",
          "10 questions / survey",
          "MP3/MP4 recording formats",
          "Sentiment analysis",
          "Phonic Showreels",
          "Phonic Reports",
          "Phonic Files"
        ],
      };
    case "STARTER":
      return {
        name: "Starter",
        rank: 1,
        hasStartPrice: false,
        description: `For when you're just getting started.`,
        credits: BASE_CREDITS.STARTER + CREDIT_BUNDLE_AMOUNT * creditBundles,
        featureList: [
          "All Free features",
          "5 surveys / mo",
          "Unlimited questions / survey",
          "MP3/MP4/WAV recording formats",
          "Survey conditions, logic, randomization",
          "Survey localization",
          "AI response coding",
          "Response translation",
          "CSV or TXT data export",
          "Showreel export",
          "Qualtrics integration",
          "Bulk file upload (up to 1GB)"
        ],
      };
    case "PREMIUM":
      return {
        name: "Premium",
        rank: 2,
        hasStartPrice: false,
        description: `For when you're collecting data and scaling research.`,
        credits: BASE_CREDITS.PREMIUM + CREDIT_BUNDLE_AMOUNT * creditBundles,
        featureList: [
          "All Starter features",
          "Unlimited surveys / mo",
          "Variable video bitrates",
          "Custom branded surveys",
          "Showreel export (no watermark)",
          "Embedded feedback",
          "All survey platform integrations",
          "Bulk file upload (no size limit)"
        ],
      };
    case "ENTERPRISE":
      return {
        name: "Business",
        rank: 3,
        hasStartPrice: true,
        description:
          "For teams and large-scale projects",
        featureList: [
          "Uncapped credits",
          "Multiple users",
          "All Premium features",
          "Sharing and collaboration",
          "Multilingual surveys",
          "All speech analytics",
          "PII Redaction",
          "Custom survey Javascript",
          "Platform whitelabel",
          "Priority support",
          "API access",
          "Google sheets integration",
        ],
      };
    default:
      return {};
  }
}

export function getPrice(tier, creditBundles, billingPeriod, discount) {
  let price = TIER_PRICING[tier][billingPeriod].PLAN;
  if (isString(price)) return price;
  price = price + TIER_PRICING[tier][billingPeriod].CREDITS * creditBundles;
  if (discount) return Math.round(price * getDiscountMultiplier(discount));
  return price;
}

export function getTierUsage(tier) {
  if (TIER_USAGE[tier]) return TIER_USAGE[tier];
  return TIER_USAGE.FREE;
}

export function isPaidPlan(tier) {
  if (tier === "STARTER" || tier === "PREMIUM" || tier === "ENTERPRISE")
    return true;
  return false;
}

export function isAtLeastStarter(tier) {
  return isPaidPlan(tier);
}

export function isAtLeastPremium(tier) {
  if (tier === "PREMIUM" || tier === "ENTERPRISE") return true;
  return false;
}

export function getDiscountMultiplier(discount) {
  if (discount === "ACADEMIC") return 0.5;
  if (discount === "NONPROFIT") return 0.75;
  if (discount === "FRIENDS") return 0.8;
  return 1.0;
}

export function getDiscountReadable(discount) {
  if (discount === "ACADEMIC") return "Academic";
  if (discount === "NONPROFIT") return "Non-Profit";
  if (discount === "FRIENDS") return "Friends & Family";
  return null;
}

export function getNumCreditBundles(tier, monthlyCredits) {
  if (tier == "PREMIUM") {
    return (monthlyCredits - BASE_CREDITS.PREMIUM) / CREDIT_BUNDLE_AMOUNT;
  } else if (tier == "STARTER") {
    return (monthlyCredits - BASE_CREDITS.STARTER) / CREDIT_BUNDLE_AMOUNT;
  }
}
