import React from "react";
import { Grid } from "@material-ui/core";
import { isPaidPlan } from "utils/tiers";
import PieChart from "components/Graphs/PieChart";

export default class UsageTile extends React.Component {
  render() {
    const monthly = isPaidPlan(this.props.user.tier);
    return (
      <Grid
        item
        onClick={this.routeToCreation}
        className="main-survey-tile usage"
      >
        <div className="mb-10">
          <h3 style={{ fontSize: 18 }}>{`${
            monthly ? "Monthly Usage" : "Free Tier Usage"
          }`}</h3>
        </div>
        <PieChart
          chartData={{
            labels: ["Credits remaining", "Responses collected"],
            data: [this.props.creditBalance, this.props.monthlyResponses],
          }}
          displayLegend={false}
        />
        <div className="info-card">
          You have collected <strong>{this.props.monthlyResponses}</strong>{" "}
          responses this month
        </div>
      </Grid>
    );
  }
}
