import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Select,
  TextField,
  MenuItem,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { removeItemFromArray } from "utils/utils";

const TRIGGER_PLACEHOLDERS = {
  email: "ada@emaill.com",
  webhook: "https://...",
};

class TriggerItem extends React.PureComponent {
  updateTrigger = (trigger) => {
    this.setState(trigger);
    this.props.updateTrigger(trigger, this.props.idx);
  };
  deleteTrigger = () => this.props.deleteTrigger(this.props.idx);

  render() {
    return (
      <div id="logic-container">
        <div className="remove-button">
          <FontAwesomeIcon icon={faTimes} onClick={this.deleteTrigger} />
        </div>
        <div className="logic-line">
          <div className="logic-text">When</div>
          <Select
            value={this.props.event}
            variant="outlined"
            margin="dense"
            className="logic-long"
            onChange={(e) => this.updateTrigger({ event: e.target.value })}
          >
            <MenuItem value="SURVEY_COMPLETED">A survey is completed</MenuItem>
            <MenuItem value="TRANSCRIPTION_COMPLETED">
              Response transcription completes
            </MenuItem>
          </Select>
        </div>
        <div className="logic-line">
          <div className="logic-text">Then</div>
          <Select
            value={this.props.type}
            variant="outlined"
            margin="dense"
            className="logic-short"
            onChange={(e) => this.updateTrigger({ type: e.target.value })}
          >
            <MenuItem value="email">Send an email to</MenuItem>
            <MenuItem value="webhook">Post to webhook</MenuItem>
          </Select>
          <TextField
            variant="outlined"
            margin="dense"
            className="logic-long"
            value={this.props[this.props.type] || ""}
            placeholder={
              TRIGGER_PLACEHOLDERS[this.props.type] || "Notification Address"
            }
            onChange={(e) =>
              this.updateTrigger({ [this.props.type]: e.target.value })
            }
          ></TextField>
        </div>
      </div>
    );
  }
}

const DEFAULT_TRIGGER_OBJ = { type: "email", event: "SURVEY_COMPLETED" };
class NotificationsPopup extends React.Component {
  state = { triggers: [], populated: false };
  componentDidUpdate() {
    if (
      this.props.survey &&
      this.props.survey.triggers &&
      this.state.populated === false
    )
      this.setState({ triggers: this.props.survey.triggers, populated: true });
  }
  updateTrigger = (update, idx) => {
    const newTriggers = Object.assign([...this.state.triggers], {
      [idx]: {
        ...this.state.triggers[idx],
        ...update,
      },
    });
    this.setState({ triggers: newTriggers });
  };
  deleteTrigger = (idx) => {
    this.setState({
      triggers: removeItemFromArray(this.state.triggers, idx),
    });
  };
  cancel = () => {
    if (this.props.survey && this.props.survey.triggers)
      this.setState({ triggers: this.props.survey.triggers });
    this.props.onClose();
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        fullwidth
        aria-labelledby="add-to-report"
      >
        <div className="x-button">
          <IconButton aria-label="close" onClick={this.props.onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent className="popup-container">
          <div id="form-dialog-title" style={{ marginBottom: 20 }}>
            <h2 className="dialog-title-small" style={{ marginBottom: 10 }}>
              Notifications
            </h2>
            <p>
              Set up email and webhook notifications on survey complete events
            </p>
          </div>
          {this.state.triggers.map((t, idx) => (
            <TriggerItem
              key={`TRIGGER-ITEM-${idx}`}
              {...t}
              idx={idx}
              updateTrigger={this.updateTrigger}
              deleteTrigger={this.deleteTrigger}
            />
          ))}
          <DialogActions>
            <Button
              className="secondary-button-color"
              variant="contained"
              onClick={() => {
                this.setState({
                  triggers: [
                    ...this.state.triggers,
                    { ...DEFAULT_TRIGGER_OBJ },
                  ],
                });
              }}
            >
              + Add Notification
            </Button>
            <div className="flex-grow-basis-1" />
            <Button
              variant="outlined"
              component="label"
              onClick={this.cancel}
              className="phonic-outlined-button"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              component="label"
              onClick={() => {
                this.props.saveTriggers(this.state.triggers);
                this.props.onClose();
              }}
              color="primary"
            >
              Save
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
}

export default NotificationsPopup;
