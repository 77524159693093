import React, { Component } from "react";
import { connect } from "react-redux";
import { trackPage } from "analytics";
import {
  Link,
  Breadcrumbs,
  Paper,
  Grid,
  TextField,
  Tooltip,
  IconButton,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Chip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import { ScaleLoader, ClipLoader } from "react-spinners";
import { getPerson, updatePerson, setPageTitle } from "../redux/actions";
import { truncateStringWithEllipsis, getReadableSessionId, getFrontEndBaseUrl} from "utils/utils";
import _ from "lodash";

let BASE_URL = getFrontEndBaseUrl()

const mapStateToProps = (state, ownProps) => {
  return {
    people: state.people,
    user: state.user,
    person: ownProps.match.params.personId
      ? state.people[ownProps.match.params.personId]
      : null,
    personLoading: state.loading.GetPerson,
    updatePersonLoading: state.loading["UpdatePerson"],
  };
};
const mapDispatchToProps = {
  getPerson,
  setPageTitle,
  updatePerson,
};

class Person extends Component {
  state = {
    person: null,
    email: null,
    lastName: null,
    firstName: null,
    editingPerson: false,
  };
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    trackPage("Viewed Person", this.props.match.params.personId);

    // TODO: Only make this request if the person isn't already loaded in redux.
    this.props.getPerson(this.props.match.params.personId);
    this.props.setPageTitle();
  }

  componentDidUpdate() {
    this.props.setPageTitle(this.getBreadCrumbs());

    if (this.props.person) {
      let personUpdates = {};
      if (this.state.email === null)
        personUpdates.email = this.props.person.email;
      if (this.state.firstName === null)
        personUpdates.firstName = this.props.person.firstName;
      if (this.state.lastName === null)
        personUpdates.lastName = this.props.person.lastName;
      if (!_.isEmpty(personUpdates)) this.setState({ ...personUpdates });
    }
  }

  getBreadCrumbs = () => {
    if (this.props.match.params.personId && this.props.person) {
      return (
        <div className="breadcrumbs-title">
          <Breadcrumbs aria-label="breadcrumb">
            {this.props.people && (
              <Link
                color="inherit"
                onClick={() => this.props.history.push("/people")}
              >
                People
              </Link>
            )}
            {this.props.person && (
              <Link color="inherit">
                {truncateStringWithEllipsis(this.props.person._id, 36)}
              </Link>
            )}
          </Breadcrumbs>
        </div>
      );
    }
  };

  emailUpdated = (event) => {
    this.setState({ email: event.target.value });
  };

  firstNameUpdated = (event) => {
    this.setState({ firstName: event.target.value });
  };

  lastNameUpdated = (event) => {
    this.setState({ lastName: event.target.value });
  };

  confirmUpdate = () => {
    this.props.updatePerson(this.props.match.params.personId, {
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
    });
    this.setState({
      editingPerson: false,
    });
  };

  cancelUpdate = () => {
    this.setState({
      email: this.props.person.email,
      firstName: this.props.person.firstName,
      lastName: this.props.person.lastName,
      editingPerson: false,
    });
  };

  getTitle = () => {
    if (this.props.person.firstName || this.props.person.lastName)
      return `${this.props.person.firstName} ${this.props.person.lastName}`;
    if (this.props.person && this.props.person.email)
      return this.props.person.email;
    return "Person";
  };

  render() {
    return (
      <div className="content">
        {this.props.personLoading && (
          <div className="prompt-container-indash">
            <ScaleLoader size={"30px"} color={"#62cb88"} loading />
          </div>
        )}
        {!this.props.personLoading && this.props.person && (
          <Paper style={{ width: "100%", marginTop: "8px" }}>
            <Grid container id="play-information" spacing={2}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={9}>
                    <h3 style={{ marginBottom: "0px" }}>{this.getTitle()}</h3>
                    <h5 className="gray-text">
                      {getReadableSessionId(this.props.person._id)}
                    </h5>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="profile-edit-buttons">
                      {this.props.updatePersonLoading && (
                        <div style={{ margin: 6 }}>
                          <ClipLoader
                            size={18}
                            color={"rgba(0, 0, 0, 0.54)"}
                            loading={this.props.updatePersonLoading}
                          />
                        </div>
                      )}

                      {!this.props.updatePersonLoading &&
                        !this.state.editingPerson && (
                          <Tooltip title="Edit">
                            <IconButton
                              aria-label="Edit info"
                              onClick={() =>
                                this.setState({ editingPerson: true })
                              }
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      {!this.props.updatePersonLoading &&
                        this.state.editingPerson && (
                          <>
                            <Tooltip title="Cancel">
                              <IconButton
                                aria-label="Cancel editing info"
                                onClick={() => {
                                  this.cancelUpdate();
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Confirm">
                              <IconButton
                                aria-label="Confirm edits"
                                onClick={() => {
                                  this.confirmUpdate();
                                }}
                              >
                                <CheckIcon />
                              </IconButton>
                            </Tooltip>
                          </>
                        )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled={!this.state.editingPerson}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="First Name"
                      value={this.state.firstName ? this.state.firstName : ""}
                      size="small"
                      onChange={this.firstNameUpdated}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled={!this.state.editingPerson}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Last Name"
                      value={this.state.lastName ? this.state.lastName : ""}
                      size="small"
                      onChange={this.lastNameUpdated}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={!this.state.editingPerson}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Email"
                      value={this.state.email ? this.state.email : ""}
                      size="small"
                      onChange={this.emailUpdated}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Divider />
                <h5>Survey Responses</h5>
                {!this.props.person.surveyResponses && (
                  <div className="response-title">
                    This person has not responded to any surveys.
                  </div>
                )}
                {this.props.person.surveyResponses && (
                  <div className="sharing-table">
                    <Table className="align-middle mb-0 table table-borderless table-hover">
                      <TableHead>
                        <TableRow>
                          <th>Date</th>
                          <th>Survey</th>
                          <th>Status</th>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.props.person.surveyResponses.map((session, i) => {
                          return (
                            <TableRow key={`PERSON-SURVEY-RESPONSE-${i}`}>
                              <td>
                                <Tooltip title="View Response">
                                  <Link
                                    onClick={() => {
                                      this.props.history.push(
                                        `/surveys/${session.surveyId}/respondents/${session._id}`
                                      );
                                    }}
                                  >
                                    {session.createdAt}
                                  </Link>
                                </Tooltip>
                              </td>
                              <td>
                                <Tooltip title="View Survey">
                                  <Link
                                    href={`${BASE_URL}/${session.surveyId}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {session.survey && session.survey.name}
                                  </Link>
                                </Tooltip>
                              </td>

                              <td>
                                {session.features && (
                                  <Chip
                                    size="small"
                                    label={session.features.completionStatus}
                                  />
                                )}{" "}
                              </td>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </div>
                )}
              </Grid>

              <Grid item xs={12}>
                <Divider />
                <h5>File Tags</h5>
                <div className="response-title">
                  This person is not tagged in any files.
                </div>
              </Grid>
            </Grid>
          </Paper>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Person);
