import React, { Component } from "react";
import { track } from "analytics";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faDesktop,
  faBookmark as faBookmarkSolid,
  faArrowRight,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { faBookmark as faBookmarkOutlined } from "@fortawesome/free-regular-svg-icons";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import Check from "@material-ui/icons/Check";
import Tooltip from "@material-ui/core/Tooltip";
import { Alert } from "@material-ui/lab";
import Waveform from "components/Waveform";
import { Grid, Button } from "@material-ui/core";
import {
  isAudioType,
  isVideoType,
  isFileType,
  isAudioOrVideoType,
  getDemographicsBlock,
  getReadableSessionId,
} from "utils/utils";
import PieChart from "./Graphs/PieChart";
import StackedArea from "./Graphs/StackedArea";
import RadarChart from "./Graphs/RadarChart";
import {
  getAverageSentimentPieChartData,
  getEmotionLineChartData,
  getAverageEmotionChartData,
} from "../utils/graphData";
import VideoPlayer from "./VideoPlayer";
import ResponseEditor from "./ResponseEditor";
import PlainTextEditor from "components/PlainTextEditor";
import FilePreview from "components/FilePreview";
import { withRouter } from "react-router";
import { isPaidPlan } from "utils/tiers";

class ResponseViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editingTranscription: false,
      editingTranslation: false,
      playbackTime: 0,
      localTranscriptionItems: undefined,
      localTranslation: "",
      bookmarked: this.props.response.bookmarked,
    };
  }

  /**
   * Since bookmarking is extracted from response state.
   * TODO: Use the response itself.
   */
  componentDidUpdate(prevProps) {
    if (prevProps.response !== this.props.response && this.props.response) {
      this.setState({ bookmarked: this.props.response.bookmarked });
    }
  }

  canEdit = () => {
    if (!this.props.user || this.props.user.readOnly || !this.props.response) {
      return false;
    }
    const surveyId = this.props.response.surveyId;
    for (let i = 0; i < this.props.user.surveys.length; i++) {
      if (this.props.user.surveys[i] === surveyId) return true;
    }
    if (Array.isArray(this.props.user.sharedSurveys)) {
      for (let i = 0; i < this.props.user.sharedSurveys.length; i++) {
        if (
          this.props.user.sharedSurveys[i].surveyId === surveyId &&
          this.props.user.sharedSurveys[i].permissions.write === true
        ) {
          return true;
        }
      }
    }
    return false;
  };

  translationExists = () => {
    if (
      this.props.response &&
      this.props.response.features &&
      this.props.response.features.translations &&
      this.props.response.features.translations["en"]
    ) {
      return true;
    }
    return false;
  };

  downloadAudio = () => {
    if (!this.props.user || !isPaidPlan(this.props.user.tier)) {
      alert("Please upgrade to Premium to use this feature.");
      return;
    }
    if (!this.props.response || !this.props.response.uri) {
      alert("Audio not available. Please try again.");
      return;
    }
    track("Audio Downloaded");
    let url = this.props.response.uri;
    let a = document.createElement("a");
    a.href = url;
    a.download =
      this.props.response._id +
      (isAudioType(this.props.response.type)
        ? this.props.response.audioPath.split(".").slice(-1).pop()
        : isVideoType(this.props.response.type)
        ? this.props.response.videoPath.split(".").slice(-1).pop()
        : this.props.response.filePath.split(".").slice(-1).pop());
    a.click();
  };

  editTranscription = () => {
    this.setState({
      editingTranscription: true,
      localTranscriptionItems: this.props.response.features.transcription_items,
    });
  };

  editTranslation = () => {
    this.setState({
      editingTranslation: true,
      localTranslation: this.props.response.features.translations["en"],
    });
  };

  debounceAction = (action) => {
    if (this.state.updateTimeout) {
      clearTimeout(this.state.updateTimeout);
    }
    this.setState({
      updateTimeout: setTimeout(() => {
        action();
      }, 500),
    });
  };

  saveTranscription = () => {
    const key = Array.isArray(this.state.localTranscriptionItems)
      ? "transcription_items"
      : typeof this.state.localTranscriptionItems === "string"
      ? "transcription"
      : null;

    if (key) {
      this.props.updateResponse(this.props.response._id, {
        [key]: this.state.localTranscriptionItems,
      });
    }

    this.setState({ editingTranscription: false });
  };

  saveTranslation = () => {
    this.props.updateResponse(this.props.response._id, {
      translations: { en: this.state.localTranslation },
    });
    this.setState({ editingTranslation: false });
  };

  updateLocalTranscriptionItems = (updatedTranscriptionItems) => {
    this.setState({ localTranscriptionItems: updatedTranscriptionItems });
  };

  updateLocalTranslation = (updatedTranslation) => {
    this.setState({ localTranslation: updatedTranslation });
  };

  getFeaturesJSON = () => {
    var json = Object.assign({}, this.props.response.features); // Copy object
    delete json.transcription;
    return json;
  };

  updatePlaybackResponse = (time) => {
    this.setState({ playbackTime: time });
  };

  getMedia = () => {
    if (
      !this.props.response ||
      (!this.props.response.uri && !this.props.response.uris)
    )
      return "No Media Found.";
    return (
      <>
        {isAudioType(this.props.response.type) && (
          <Waveform
            interactiveTranscription={true}
            updatePlaybackResponse={(time) => this.updatePlaybackResponse(time)}
            src={this.props.response.uri}
            playTime={this.state.skipTime ? this.state.skipTime : 0}
            height={50}
            key={`PlayAudio-Waveform-${this.props.match.params.responseId}-${this.props.response.uri}`}
          />
        )}
        {isVideoType(this.props.response.type) && (
          <div className="flex-container">
            <VideoPlayer
              uri={this.props.response.uri}
              responseId={this.props.response._id}
              onTimeUpdate={(time) => this.updatePlaybackResponse(time)}
              skipTime={this.state.skipTime ? this.state.skipTime : 0}
            />
          </div>
        )}
        {isFileType(this.props.response.type) && this.props.response.uri && (
          <FilePreview
            path={this.props.response.filePath}
            uri={this.props.response.uri}
          />
        )}
        {isFileType(this.props.response.type) &&
          this.props.response.uris &&
          this.props.response.uris.map((uri, idx) => (
            <FilePreview
              key={uri}
              path={this.props.response.filePath[idx]}
              uri={uri}
              idx={idx}
            />
          ))}
      </>
    );
  };

  showEmotionCharts = () => {
    return (
      this.props.response &&
      this.props.response.features &&
      this.props.response.features.Faces
    );
  };

  showEmotionWarnings = () => {
    return (
      this.props.response &&
      this.props.response.features &&
      this.props.response.features.FaceAttributes &&
      this.props.response.features.FaceAttributes.Warnings &&
      this.props.response.features.FaceAttributes.Warnings.length > 0
    );
  };

  onWordClick = (e, item) => {
    e.stopPropagation();
    this.setState({
      skipTime: parseFloat(item.start_time),
    });
  };

  seekTime = (newTime) => {
    this.setState({ skipTime: parseFloat(newTime) });
  };

  render() {
    if (!this.props.response) {
      return <div></div>;
    }
    return (
      <>
        <Grid container id="play-information" spacing={2}>
          <Grid
            item
            xs={12}
            style={{ lineHeight: "48px", marginBottom: "10px" }}
          >
            <div className="display-ilb m-0 mt-10">
              <h3 style={{ marginBottom: "0px" }}>
                <Tooltip
                  title={this.state.bookmarked ? "Unbookmark" : "Bookmark"}
                  aria-label={this.state.bookmarked ? "Unbookmark" : "Bookmark"}
                >
                  <div
                    className="display-ilb rounded-icon-container m-0 mr-10 text-lg"
                    onClick={() => {
                      this.props.updateResponse(this.props.response._id, {
                        bookmarked: !this.state.bookmarked,
                      });
                      this.setState({
                        bookmarked: !this.state.bookmarked,
                      });
                    }}
                  >
                    <FontAwesomeIcon
                      icon={
                        this.state.bookmarked
                          ? faBookmarkSolid
                          : faBookmarkOutlined
                      }
                    />
                  </div>
                </Tooltip>
                {isAudioType(this.props.response.type) && "Audio Response"}
                {isVideoType(this.props.response.type) && "Video Response"}
                {isFileType(this.props.response.type) && "File Response"}
              </h3>
              <h5 className="gray-text">
                {getReadableSessionId(this.props.response.sessionId)}
              </h5>
            </div>
            <div className="float-right flex-container">
              {this.props.prevResponse && (
                <Tooltip title="Previous" aria-label="Previous" placement="top">
                  <div
                    className="keyboard-icon-container flex-container mr-5"
                    onClick={() => {
                      this.props.prevResponse();
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </div>
                </Tooltip>
              )}
              {this.props.nextResponse && (
                <Tooltip title="Next" aria-label="Next" placement="top">
                  <div
                    className="keyboard-icon-container flex-container mr-5"
                    onClick={() => {
                      this.props.nextResponse();
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                </Tooltip>
              )}
              {this.props.response && (
                <Tooltip title="Download File" aria-label="Download File">
                  <Button
                    variant="contained"
                    component="label"
                    onClick={() => {
                      this.downloadAudio();
                    }}
                    className="secondary-button-color"
                    style={{
                      marginRight: "5px",
                      display: isFileType(this.props.response.type)
                        ? "none"
                        : "inline-flex",
                    }}
                    disabled={
                      this.props.user
                        ? this.props.user.tier === "FREE" ||
                          this.props.user.readOnly
                        : true
                    }
                    aria-label="Download File"
                  >
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                </Tooltip>
              )}
              {this.props.response && this.props.inPopup && (
                <Tooltip title="View Play Screen" aria-label="View Play Screen">
                  <Button
                    variant="contained"
                    component="label"
                    className="secondary-button-color"
                    onClick={() => {
                      this.props.history.push(
                        `/surveys/${
                          this.props.match.params.surveyId
                        }/questions/${
                          this.props.match.params.questionId
                            ? this.props.match.params.questionId
                            : this.props.questionId
                        }/responses/${this.props.response._id}`
                      );
                    }}
                    aria-label="View Play Screen"
                  >
                    <FontAwesomeIcon icon={faDesktop} />
                  </Button>
                </Tooltip>
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={8}>
            <div className="media">{this.getMedia()}</div>
            {isAudioOrVideoType(this.props.response.type) && (
              <>
                <h3>
                  Transcription{" "}
                  {this.canEdit() && (
                    <>
                      {this.state.editingTranscription ? (
                        <Tooltip title="Save Transcription" placement="right">
                          <IconButton
                            aria-label="save"
                            onClick={this.saveTranscription}
                          >
                            <Check />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Edit Transcription" placement="right">
                          <IconButton
                            aria-label="edit"
                            onClick={this.editTranscription}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  )}
                </h3>

                {this.props.response.features &&
                this.props.response.features.transcription_items ? (
                  <ResponseEditor
                    key={this.props.response._id}
                    transcriptionItems={
                      this.props.response.features.transcription_items
                    }
                    time={this.state.playbackTime}
                    onWordClick={this.seekTime}
                    saveTranscription={this.updateLocalTranscriptionItems}
                    readOnly={!this.state.editingTranscription}
                  />
                ) : (
                  <PlainTextEditor
                    key={this.props.response._id}
                    transcriptionString={
                      this.props.response.features.transcription
                    }
                    saveTranscription={this.updateLocalTranscriptionItems}
                    readOnly={!this.state.editingTranscription}
                  />
                )}
              </>
            )}
            {this.canEdit() && this.translationExists() && (
              <>
                {this.state.editingTranslation ? (
                  <Tooltip title="Save Translation" placement="right">
                    <IconButton
                      aria-label="save"
                      onClick={this.saveTranslation}
                    >
                      <Check />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Edit Translation">
                    <IconButton
                      aria-label="edit"
                      onClick={this.editTranslation}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
            {this.translationExists() &&
              Object.values(this.props.response.features.translations).map(
                (translation) => (
                  <PlainTextEditor
                    key={this.props.response._id}
                    transcriptionString={translation}
                    saveTranscription={this.updateLocalTranslation}
                    readOnly={!this.state.editingTranslation}
                    className="font-bold"
                  />
                )
              )}
            {this.props.response && this.props.response.data && (
              <div className="mt-10">
                <h3>Other Response Data</h3>
                <pre>
                  {JSON.stringify(this.props.response.data, undefined, 2)}
                </pre>
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            {getDemographicsBlock(this.props.response)}
            <br></br>
            {this.props.response.features &&
              this.props.response.features.sentiment && (
                <PieChart
                  key={`PIE-${this.props.response._id}`}
                  chartData={getAverageSentimentPieChartData([
                    this.props.response,
                  ])}
                />
              )}
          </Grid>
          {this.showEmotionCharts() && (
            <>
              <Grid item xs={12}>
                <h3>Facial Expressions</h3>
                {this.showEmotionWarnings() && (
                  <Grid item xs={12} md={6}>
                    <Alert severity="warning">
                      Some issues were detected with the video that may reduce
                      accuracy:
                      {this.props.response.features.FaceAttributes.Warnings.map(
                        (warningCode, i) => {
                          if (
                            warningCode.type === "DOMINANT_FACE_WAS_NOT_FOUND"
                          )
                            return (
                              <li key={warningCode}>
                                There were multiple faces detected
                              </li>
                            );
                          else if (warningCode.type === "FACE_MISSING")
                            return (
                              <li key={warningCode}>
                                The person leaves the video frame
                              </li>
                            );
                          return (
                            <li key={`${warningCode}-${i}`}>
                              Unspecified warning
                            </li>
                          );
                        }
                      )}
                    </Alert>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} md={7}>
                <StackedArea
                  chartData={getEmotionLineChartData(
                    this.props.response.features.Faces
                  )}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <RadarChart
                  chartData={getAverageEmotionChartData(
                    this.props.response.features.Faces
                  )}
                />
              </Grid>
            </>
          )}
        </Grid>
      </>
    );
  }
}

export default withRouter(ResponseViewer);
