import React, { Component, createRef } from "react";
import { Grid, LinearProgress } from "@material-ui/core";
import ConversationEditor from "./ConversationEditor";
import FolderTile from "./FolderTile";
import { Alert, AlertTitle } from "@material-ui/lab";
import { truncateStringWithEllipsis } from "utils/utils";
import _ from "lodash";
import UploadedMedia from "./UploadedMedia";
import FileContext from "./FileContext";
import {
  faCloudUploadAlt,
  faPlusSquare,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Skeleton from "@material-ui/lab/Skeleton";

function SectionTitle({ title, count }) {
  return (
    <h3 className="conversations-section">
      {title}
      <div className="file-viewer-count">{count}</div>
    </h3>
  );
}

function NavPath({ path, files, handleSelectClick }) {
  const dirs = path.split("/");
  return (
    <div className="nav-path">
      <span
        className="directory"
        onClick={() => handleSelectClick({ path: "" })}
      >
        <FontAwesomeIcon icon={faHome} />
      </span>
      {" / "}
      {dirs.map((dir, idx) => {
        let file = files[dirs[0]];
        for (let i = 1; i <= idx; i++) {
          file = file.children[dirs[i]];
        }
        if (!file) return null;
        return (
          <span
            key={`${dir}-${idx}`}
          >
            <span
              className="directory"
              onClick={() => handleSelectClick(file)}
            >
              {file.name}
            </span>
            {idx < dirs.length - 1 && " / "}
          </span>
        );
      })}
    </div>
  );
}

function FileAndFolders({
  childFolders,
  childFiles,
  handleSelectClick,
  addFolder,
  addFile,
}) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <SectionTitle title="Folders" count={childFolders.length} />
      </Grid>
      {childFolders.map((c, idx) => {
        const children = Object.values(c.children);
        return (
          <Grid item key={`FOLDER-TILE-${idx}`}>
            <FolderTile
              onClick={() => {
                handleSelectClick(c);
              }}
              title={c.name}
              date={c.createdAt}
              comment={c.path}
              fileType={c.type}
              numFolders={children.filter((f) => f.type === "FOLDER").length}
              numFiles={children.filter((f) => f.type !== "FOLDER").length}
            />
          </Grid>
        );
      })}
      {addFolder && (
        <Grid item>
          <div className="upload-folder-tile" onClick={addFolder}>
            <FontAwesomeIcon icon={faPlusSquare} /> &nbsp; Add Folder
          </div>
        </Grid>
      )}
      {(addFile || childFiles.length > 0) && (
        <Grid item xs={12}>
          <SectionTitle title="Files" count={childFiles.length} />
        </Grid>
      )}
      {childFiles.map((c, idx) => {
        const children = Object.values(c.children);
        return (
          <Grid item key={`FILE-TILE-${idx}`}>
            <FolderTile
              onClick={() => {
                handleSelectClick(c);
              }}
              title={c.name}
              date={c.createdAt}
              comment={c.path}
              fileType={c.type}
              numFolders={children.filter((f) => f.type === "FOLDER").length}
              numFiles={children.filter((f) => f.type !== "FOLDER").length}
            />
          </Grid>
        );
      })}
      {addFile && (
        <Grid item>
          <div className="upload-folder-tile" onClick={addFile}>
            <FontAwesomeIcon icon={faCloudUploadAlt} /> &nbsp; Upload Files
          </div>
        </Grid>
      )}
    </Grid>
  );
}

const SkeletonFileViewer = () => {
  return (
    <div className="dummy-results-tile">
      <Skeleton
        variant="rect"
        width={"60%"}
        height={300}
        style={{ marginBottom: "20px" }}
      />
      <Skeleton variant="text" style={{ width: "60%", marginBottom: "10px" }} />
      <Skeleton variant="text" style={{ width: "60%", marginBottom: "10px" }} />
      <Skeleton variant="text" style={{ width: "60%", marginBottom: "10px" }} />
      <Skeleton variant="text" style={{ width: "60%", marginBottom: "10px" }} />
      <Skeleton variant="text" style={{ width: "60%", marginBottom: "10px" }} />
    </div>
  );
};
class FileViewer extends Component {
  state = { playbackTime: 0 };

  mediaPlayerRef = createRef();

  getTitle = () => {
    if (!this.props.file) return "Conversations";
    return truncateStringWithEllipsis(this.props.file.name, 40);
  };
  updatePlaybackTime = (time) => {
    this.setState({ playbackTime: time });
  };
  seekTime = (time) => {
    if (this.mediaPlayerRef.current) {
      this.mediaPlayerRef.current.seek(parseFloat(time));
    }
  };

  showDemographics = () => {
    return this.props.file.type !== "FOLDER";
  };

  saveDemographic = (demProps) => {
    if (demProps.name) {
      var splitName = demProps.name.split(" ");
      if (splitName.length === 1) {
        demProps.firstName = splitName[0];
        demProps.lastName = "";
      } else {
        demProps.firstName = splitName[0];
        demProps.lastName = _.tail(splitName).join(" "); // All other space-seperated names are combined into lastName
      }
      delete demProps.name;
    }
    if (this.props.file._id) {
      this.props.updateFile(
        this.props.file._id,
        {
          demographics: { ...this.props.file.demographics, ...demProps },
        },
        this.props.file.path
      );
    } else {
      alert("Unable to update demographics. Please refresh and try again.");
    }
  };

  saveTranscription = (doc, transcriptionItems) => {
    this.props.updateFile(
      this.props.file._id,
      { doc, transcription_items: transcriptionItems },
      this.props.file.path
    );
  };

  updateSpeakers = (speakers) => {
    this.props.updateFile(
      this.props.file._id,
      { speakers },
      this.props.file.path
    );
  };

  getTimeStamps = () => {
    let ti = this.props.file.features.transcription_items;
    if (!ti || ti.length === 0) return { startTime: 0 };
    return {
      startTime: ti[0] && ti[0].start_time ? ti[0].start_time : 0,
      endTime:
        ti[ti.length - 1] && ti[ti.length - 1].end_time
          ? ti[ti.length - 1].end_time
          : undefined,
    };
  };

  toggleEditingDemographics = () => {
    this.setState({ editingDemographics: !this.state.editingDemographics });
  };

  render() {
    var childFolders = [],
      childFiles = [];

    let fileChildren = null;

    if (this.props.file && this.props.file.children) {
      fileChildren = Object.values(this.props.file.children);
    } else {
      fileChildren = Object.values(this.props.allFiles);
    }

    childFolders = fileChildren.filter((c) => c.type === "FOLDER");
    childFiles = fileChildren.filter((c) => c.type !== "FOLDER");

    return (
      <>
        <div id="file-viewer">
          {this.props.file && (
            <NavPath
              path={this.props.file.path}
              files={this.props.allFiles}
              handleSelectClick={this.props.handleSelectClick}
            />
          )}
          {this.props.file && this.props.file.type !== "FOLDER" && (
            <div className="context-banner">
              <FileContext
                updateSaving={this.props.updateSaving}
                createdDate={this.props.file.createdAt}
              />
            </div>
          )}
          {this.props.loading && <SkeletonFileViewer />}
          {!this.props.loading && !this.props.file && (
            <FileAndFolders
              childFolders={childFolders}
              childFiles={childFiles}
              allFiles={this.props.allFiles}
              addFolder={this.props.addFolder}
              handleSelectClick={this.props.handleSelectClick}
            />
          )}
          {!this.props.loading &&
            this.props.file &&
            this.props.file.type === "FOLDER" && (
              <FileAndFolders
                childFolders={childFolders}
                childFiles={childFiles}
                addFile={this.props.addFile}
                addFolder={this.props.addFolder}
                allFiles={this.props.allFiles}
                handleSelectClick={this.props.handleSelectClick}
              />
            )}

          {this.props.file && (
            <Grid container spacing={3}>
              <Grid item xs={8}>
                {this.props.file.status === "PENDING" ? (
                  <div>
                    {this.props.fileUploadStatus[this.props.file.path] !==
                    "FAILED" ? (
                      <Alert severity="info">
                        {this.props.fileUploadProgress[this.props.file.path] !==
                          undefined && (
                          <>
                            <AlertTitle>
                              Upload in progress:{" "}
                              {
                                this.props.fileUploadProgress[
                                  this.props.file.path
                                ]
                              }
                              % complete
                            </AlertTitle>
                            <LinearProgress
                              variant="determinate"
                              value={
                                this.props.fileUploadProgress[
                                  this.props.file.path
                                ]
                              }
                            />
                          </>
                        )}
                        {this.props.fileUploadProgress[this.props.file.path] ===
                          undefined && (
                          <>
                            <AlertTitle>Upload in progress</AlertTitle>
                            Please check back later.
                          </>
                        )}
                      </Alert>
                    ) : (
                      <Alert severity="error">
                        <AlertTitle>Upload Failed</AlertTitle>
                        Please try again.
                      </Alert>
                    )}
                  </div>
                ) : (
                  this.props.file.uri &&
                  this.props.file.type && (
                    <UploadedMedia
                      ref={this.mediaPlayerRef}
                      type={this.props.file.type}
                      uri={this.props.file.uri}
                      onTimeUpdate={this.updatePlaybackTime}
                    />
                  )
                )}
              </Grid>
              <Grid item xs={12} sm={8}>
                {this.props.file.doTranscription &&
                !this.props.file.features ? (
                  <Alert severity="info">
                    <>
                      <AlertTitle>Transcribing in progress</AlertTitle>
                      Please check back later. You may need to refresh the page.
                    </>
                  </Alert>
                ) : (
                  this.props.file.features &&
                  this.props.file.features.transcriptionStatus === "FAILED" &&
                  !this.props.file.features.transcription_items && (
                    <Alert severity="error">
                      <AlertTitle>Transcription Failed</AlertTitle>
                      Try uploading the file again.
                    </Alert>
                  )
                )}
                {this.props.file.features &&
                  this.props.file.features.transcription_items &&
                  this.props.file.features.transcription_items.length > 0 && (
                    <ConversationEditor
                      docProps={{
                        collection: "files",
                        documentId: this.props.file._id,
                      }}
                      transcriptionItems={
                        this.props.file.features.transcription_items
                      }
                      speakerLabels={this.props.file.features.speaker_labels}
                      numSpeakers={this.props.file.numSpeakers}
                      speakers={this.props.file.speakers}
                      time={this.state.playbackTime}
                      onWordClick={this.seekTime}
                      saveTranscription={this.saveTranscription}
                      doc={this.props.file.features.doc}
                      updateSpeakers={this.updateSpeakers}
                    />
                  )}
              </Grid>
            </Grid>
          )}
        </div>
      </>
    );
  }
}

export default FileViewer;
