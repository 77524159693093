import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import {
  Paper,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
} from "@material-ui/core";
import TimeChart from "components/Graphs/TimeChart";
import StatsCard from "components/StatsCard/StatsCard";
import { getAverageSessionDuration, secondsToString } from "../../utils/utils";
import { getSurveyDateData, getDevicePieChartData } from "utils/graphData";
import ResultsChart from "components/Graphs/ResultsChart";
import { rainbow } from "utils/brandPalette";
import {
  getAgeData,
  getIncomeData,
  getLocationData,
  getGenderData,
  getInitialConditionData,
} from "utils/graphData";
import { getChartColors } from "utils/utils";

class Completion extends React.PureComponent {
  getAverageResponseDuration = () => {
    if (!this.props.survey) return null;
    const labels = [];
    const data = [];
    for (let i = 0; i < this.props.survey.questions.length; i++) {
      labels.push(`Q${i + 1}`);
      const value = this.props.survey.questions[i].avgResponseDuration || 0;
      data.push((value / 1000).toFixed(1));
    }
    /**
     * toFixed returns a string, not a number.
     * It would be better to customize the bar chart's tooltip, but this works.
     */
    if (data.every((val) => val === "0.0")) return null;
    return { data, labels };
  };

  getDropoutData = () => {
    if (!this.props.sessions || !this.props.survey)
      return {
        data: null,
        labels: null,
      };

    var labels = [];
    var data = [];
    for (var i = 0; i < this.props.survey.questions.length; i++) {
      labels.push(`Q${i + 1}`.toString());
      data.push(this.props.survey.questions[i].numResponses);
    }
    return {
      data: data,
      labels: labels,
    };
  };

  render() {
    const averageResponseDurations = this.getAverageResponseDuration();
    const deviceData = getDevicePieChartData(this.props.sessions);
    const dropoutData = this.getDropoutData();
    const surveyDateData = getSurveyDateData(this.props.sessions);
    const avgSessionDuration = getAverageSessionDuration(this.props.sessions);
    const initialConditionsData = getInitialConditionData(this.props.sessions);

    const showAgeChart =
      this.props.survey.demographics && this.props.survey.demographics.age;
    const showHHIncomeChart =
      this.props.survey.demographics && this.props.survey.demographics.hHIncome;
    const showLocationChart =
      this.props.survey.demographics && this.props.survey.demographics.location;
    const showGenderChart =
      this.props.survey.demographics && this.props.survey.demographics.gender;
    const showConditionsTable = Object.keys(initialConditionsData).length > 0;

    return (
      <div id="completion-screen">
        <Grid container spacing={3}>
          {/* TODO: Convert time chart into a ResultsChart */}
          <Grid item xs={12} md={8}>
            <div className="graph-questions-header">
              When are people taking your survey?
            </div>
            <Paper className="graph-questions-container">
              <div className="graph-title">
                <span>Surveys Started</span>
              </div>
              <TimeChart
                chartData={surveyDateData}
                xLabel="Date Started"
                yLabel="# Responses"
              />
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            {deviceData && (
              <ResultsChart
                header={"What is the device breakdown?"}
                title={"Device Used"}
                loading={Boolean(!this.props.sessions)}
                loadingColor={rainbow.green}
                chartType={"PIE"}
                chartDataFn={() => getDevicePieChartData(this.props.sessions)}
                backgroundColor={["#3F3D48", "#62CB88", "#46B1C9"]}
                hideMenu
              />
            )}
            <StatsCard
              bigIcon={
                <>
                  <svg
                    viewBox="0 0 100 100"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ position: "absolute", height: 40 }}
                  >
                    <circle
                      cx="50"
                      cy="50"
                      r="50"
                      fill="rgba(0, 209, 97, 0.11)"
                    />
                  </svg>
                  <FontAwesomeIcon icon={faClock} color="#22CE81" />
                </>
              }
              statsText="Average Completion Time"
              statsValue={`${secondsToString(avgSessionDuration)}`}
            />
          </Grid>

          {dropoutData && (
            <Grid item xs={12} sm={6} md={4}>
              <ResultsChart
                header={"How many questions are they answering?"}
                title={"Survey Dropout"}
                loading={Boolean(!this.props.sessions)}
                loadingColor={rainbow.green}
                chartType={"BAR"}
                chartDataFn={() => {
                  return dropoutData;
                }}
                backgroundColor={["#2B4257"]}
                hideMenu
              />
            </Grid>
          )}

          {averageResponseDurations && (
            <Grid item xs={12} md={4}>
              <ResultsChart
                header={"How long is each question?"}
                title={"Average Time Spent"}
                loading={Boolean(!this.props.sessions)}
                loadingColor={rainbow.green}
                chartType={"BAR"}
                chartDataFn={() => {
                  return averageResponseDurations;
                }}
                backgroundColor={["#c3726d"]}
                hideMenu
              />
            </Grid>
          )}

          {/* Demographic Charts */}
          {showAgeChart && (
            <Grid item xs={12} md={4}>
              <ResultsChart
                header={"What is their age?"}
                title={"Age"}
                loading={Boolean(!this.props.sessions)}
                loadingColor={rainbow.green}
                chartType={"BAR"}
                chartDataFn={() => getAgeData(this.props.sessions)}
                backgroundColor={getChartColors(1)}
                hideMenu
              />
            </Grid>
          )}
          {showHHIncomeChart && (
            <Grid item xs={12} md={4}>
              <ResultsChart
                header={"What is their income?"}
                title={"Household Income"}
                loading={Boolean(!this.props.sessions)}
                loadingColor={rainbow.green}
                chartType={"BAR"}
                chartDataFn={() => getIncomeData(this.props.sessions)}
                backgroundColor={getChartColors(1)}
                hideMenu
              />
            </Grid>
          )}
          {showLocationChart && (
            <Grid item xs={12} md={4}>
              <ResultsChart
                /* TODO: Make this a map chart. */
                header={"Where are they from?"}
                title={"Location"}
                loading={Boolean(!this.props.sessions)}
                loadingColor={rainbow.green}
                chartType={"BAR"}
                chartDataFn={() => getLocationData(this.props.sessions)}
                backgroundColor={getChartColors(1)}
                hideMenu
              />
            </Grid>
          )}
          {showGenderChart && (
            <Grid item xs={12} md={4}>
              <ResultsChart
                header={"How do they identify?"}
                title={"Gender"}
                loading={Boolean(!this.props.sessions)}
                loadingColor={rainbow.green}
                chartType={"PIE"}
                chartDataFn={() => getGenderData(this.props.sessions)}
                backgroundColor={["#3F3D48", "#62CB88", "#46B1C9", "#FAFAFA"]}
                hideMenu
              />
            </Grid>
          )}
          {showConditionsTable && (
            <Grid item xs={12} md={8}>
              <div className="graph-questions-header">
                What query parameters are initially provided?
              </div>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Condition</TableCell>
                      <TableCell>Value</TableCell>
                      <TableCell>Frequency</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(initialConditionsData).map((q, i) => (
                      <React.Fragment key={`INITIAL-CONDITIONS-KEY-${i}`}>
                        {Object.keys(initialConditionsData[q]).map((p, j) => (
                          <TableRow key={`INITIAL-CONDITIONS-VALUE-${j}`}>
                            <TableCell>
                              <b>{j === 0 ? q : null}</b>
                            </TableCell>
                            <TableCell>{p}</TableCell>
                            <TableCell>{initialConditionsData[q][p]}</TableCell>
                          </TableRow>
                        ))}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

export default Completion;
