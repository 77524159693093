import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Popover,
  List,
  ListSubheader,
  ListItem,
} from "@material-ui/core";
import ToggleSwitch from "components/SurveyCreation/ToggleSwitch";
import StyledToggleButtonGroup from "components/SurveyCreation/StyledToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  subheader: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 16,
    fontWeight: 300,
  },
});

const PreviewSettings = ({
  icon,
  autoRefresh,
  toggleAutoRefresh,
  preview,
  setPreview,
  previewOptions,
  exportQuestions,
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e) => setAnchorEl(e.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleClickItem = (_, newPreview) => {
    if (newPreview !== null) {
      setPreview(newPreview);
    }
  };

  return (
    <>
      <Button
        className="preview-outlined-button"
        variant="outlined"
        aria-controls="preview-menu"
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl)}
        onClick={handleClick}
      >
        {icon}
      </Button>
      <Popover
        id="preview-menu"
        aria-labelledby="preview-menu"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List
          aria-labelledby="subheader"
          subheader={
            <ListSubheader id="subheader" classes={{ root: classes.subheader }}>
              Preview Settings
            </ListSubheader>
          }
          className={classes.list}
        >
          <hr style={{ margin: "0 4px" }} />
          <ListItem dense>
            <ToggleSwitch
              autoRefresh={autoRefresh}
              onChange={toggleAutoRefresh}
              label="Auto-Refresh"
            />
          </ListItem>
          <ListItem dense>
            <div className="auto-refresh-toggle">
              <span className="auto-refresh-toggle-label">Format</span>
              <StyledToggleButtonGroup
                size="small"
                exclusive
                value={preview}
                onChange={handleClickItem}
              >
                {previewOptions.map((option) => (
                  <ToggleButton
                    key={option}
                    value={option}
                    aria-label={option}
                    disableRipple
                  >
                    {option}
                  </ToggleButton>
                ))}
              </StyledToggleButtonGroup>
            </div>
          </ListItem>
          <hr style={{ margin: 4 }} />
          <ListItem dense>
            <Button
              className="preview-outlined-button"
              variant="outlined"
              color="primary"
              onClick={exportQuestions}
              style={{ margin: "0 auto" }}
            >
              &nbsp;Export Questions to CSV
            </Button>
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

export default PreviewSettings;
