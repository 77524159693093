import React from "react";
import {
  Popover,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
} from "@material-ui/core";
import PersonAdd from "@material-ui/icons/PersonAdd";
import { PersonOutline } from "@material-ui/icons";
import { ClipLoader } from "react-spinners";

export class PeoplePopover extends React.Component {
  state = { searchText: "" };
  filterFunc = (person) => {
    const key = this.state.searchText.toLowerCase();
    const haystacks = [
      person.firstName,
      person.lastName,
      person.email,
      `${person.firstName} ${person.lastName}`,
    ];
    for (const i in haystacks) {
      if (haystacks[i].toLowerCase().includes(key)) return true;
    }
    return false;
  };

  render() {
    return (
      <div>
        <Popover
          id={this.props.open ? "people-popover" : undefined}
          open={this.props.open}
          anchorEl={this.props.anchorEl}
          onClose={this.props.handleClose}
          disableAutoFocus
          disableEnforceFocus
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          style={{ marginTop: 20, maxHeight: 600 }}
        >
          <div>
            {this.props.peopleLoading ? (
              <ClipLoader
                size={14}
                color={"grey"}
                loading={this.props.peopleLoading}
              />
            ) : (
              <>
                <TextField
                  id="response-searchbar"
                  variant="outlined"
                  margin="dense"
                  type="text"
                  placeholder="Search People..."
                  onChange={(e) =>
                    this.setState({ searchText: e.target.value })
                  }
                  value={this.state.searchText}
                  style={{ margin: 12 }}
                />
                <Divider />
                <List>
                  {this.props.people ? (
                    <>
                      <ListItem
                        dense
                        button
                        onClick={this.props.handleNewPerson}
                      >
                        <ListItemIcon>
                          <PersonAdd />
                        </ListItemIcon>
                        <ListItemText>Create New Person</ListItemText>
                      </ListItem>
                      {Object.values(this.props.people)
                        .filter(this.filterFunc)
                        .map((p, i) => {
                          return (
                            <ListItem
                              key={`PEOPLE-POPOVER-LIST-${i}`}
                              dense
                              button
                              onClick={() => {
                                this.props.tagPerson(p._id);
                              }}
                            >
                              <ListItemIcon>
                                <PersonOutline />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  p.firstName || p.lastName
                                    ? `${p.firstName} ${p.lastName}`
                                    : "No name"
                                }
                                secondary={p.email ? p.email : "No email"}
                              />
                            </ListItem>
                          );
                        })}
                    </>
                  ) : (
                    <div className="section">There are no people.</div>
                  )}
                </List>
              </>
            )}
          </div>
        </Popover>
      </div>
    );
  }
}
