import React from "react";
import { connect } from "react-redux";
import { track } from "analytics";
import { withRouter } from "react-router";
import {
  Chip,
  Grid,
  Typography,
  TableRow,
  TableCell,
  Tooltip,
} from "@material-ui/core";
import Waveform from "components/Waveform";
import HighlightedTranscription from "../../components/HighlightedTranscription";
import TopicsList from "views/Responses/TopicsList";
import {
  isTextType,
  isVideoType,
  isAudioType,
  isRankingType,
  isAudioOrVideoType,
  isSelectionNatureStrict,
  truncateStringWithEllipsis,
  getDemographicsBlock,
} from "utils/utils";
import DescriptionIcon from "@material-ui/icons/Description";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookmark as faBookmarkSolid,
  faEllipsisV,
  faLock,
  faUnlock,
  faUserEdit,
} from "@fortawesome/free-solid-svg-icons";
import { faBookmark as faBookmarkOutlined } from "@fortawesome/free-regular-svg-icons";
import VideoPlayer from "components/VideoPlayer";
import {
  updateResponse,
  updateResponseLocal,
  getReports,
  createTopic,
  deleteTopic,
  mergeTopic,
  aliasTopic,
  deleteTopics,
} from "redux/actions";
import SentimentBar from "components/SentimentBar";

const mapDispatchToProps = {
  updateResponse,
  updateResponseLocal,
  getReports,
  createTopic,
  deleteTopic,
  deleteTopics,
  mergeTopic,
  aliasTopic,
};

class Response extends React.Component {
  state = {
    playback: {},
    skipTime: undefined,
  };

  onWordClick = (e, response, item) => {
    track("[Question Viewer] Clicked on Transcription Item");
    e.stopPropagation();
    this.setState({
      skipTime: parseFloat(item.start_time),
    });
  };

  updatePlaybackResponse = (response, time) => {
    this.setState({
      playback: { ...this.state.playback, time: time },
    });
  };

  getData = (response, index) => {
    var data = [];

    // Default headers
    data.push(
      <TableCell
        className="bookmark"
        key={response._id}
        onClick={(e) => {
          e.stopPropagation();
          this.props.updateResponse(response._id, {
            bookmarked: response.bookmarked ? !response.bookmarked : true,
          });
          this.props.updateResponseLocal(
            response._id,
            this.props.question.questionId,
            { bookmarked: response.bookmarked ? !response.bookmarked : true }
          );
        }}
      >
        <div className="flex-container">
          <div
            className="rounded-icon-container"
            onClick={(e) => {
              e.stopPropagation();
              this.routeToRespondent(response.sessionId);
            }}
          >
            {this.props.num + 1}
          </div>
          <FontAwesomeIcon
            icon={response.bookmarked ? faBookmarkSolid : faBookmarkOutlined}
          />
        </div>
      </TableCell>
    );

    // External ID
    if (this.props.displayOptions.externalId) {
      data.push(
        <TableCell key={index + "-EXT-ID"}>{response.responseId}</TableCell>
      );
    }

    // Public audio
    if (this.props.displayOptions.public) {
      data.push(
        <TableCell key={index + "-PUBLIC"}>
          <Chip
            size="small"
            icon={
              response.isPublic ? (
                <FontAwesomeIcon icon={faUnlock} />
              ) : (
                <FontAwesomeIcon icon={faLock} />
              )
            }
            label={response.isPublic ? "Public" : "Private"}
            color={response.isPublic ? "secondary" : "primary"}
            onClick={(e) => {
              e.stopPropagation();
              this.props.updateResponse(response._id, {
                isPublic: response.isPublic ? !response.isPublic : true,
              });
              this.props.updateResponseLocal(
                response._id,
                this.props.question.questionId,
                {
                  isPublic: response.isPublic ? !response.isPublic : true,
                }
              );
            }}
          />
        </TableCell>
      );
    }

    // Audio & Video Data
    if (isAudioType(response.type) || isVideoType(response.type)) {
      if (response.uri) {
        if (isVideoType(response.type)) {
          data.push(
            <TableCell
              key={index + "-VIDEO"}
              className="video-player-container "
              onClick={(e) => e.stopPropagation()}
            >
              <VideoPlayer
                size="small"
                uri={response.uri}
                responseId={response._id}
                updatePlaybackResponse={(time) =>
                  this.updatePlaybackResponse(response._id, time)
                }
                skipTime={this.state.skipTime ? this.state.skipTime : 0}
              />
            </TableCell>
          );
        } else {
          data.push(
            <TableCell
              key={index + "-AUDIO"}
              className="text-center play-button"
              onClick={(e) => e.stopPropagation()}
            >
              <Waveform
                interactiveTranscription={true}
                updatePlaybackResponse={(time) =>
                  this.updatePlaybackResponse(response._id, time)
                }
                playTime={this.state.skipTime ? this.state.skipTime : 0}
                src={response.uri}
                height={50}
                smaller
              ></Waveform>
            </TableCell>
          );
        }
      } else if (response.backupText) {
        data.push(
          <TableCell>
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <FontAwesomeIcon icon={faUserEdit} style={{ margin: "0 15px" }} />
              <Typography variant="overline" noWrap>
                Backup Text
              </Typography>
            </div>
          </TableCell>
        );
      } else {
        data.push(
          <TableCell key={index + "-BLANK"}>
            <div className="response-title">No Response</div>
          </TableCell>
        );
      }
      if (this.props.question && this.props.question.doTranscription) {
        let cellJSX = null;
        if (response.backupText) {
          cellJSX = <div>{response.backupText}</div>;
        } else if (response.features && response.features.transcription) {
          cellJSX = (
            <div
              style={{
                borderLeft: "1px solid #cccccc",
                padding: "0.5em 10px",
                minWidth: 400,
              }}
            >
              {this.props.displayOptions.translations &&
              response.features &&
              response.features.translations &&
              response.features.translations[
                this.props.displayOptions.translations
              ] ? (
                <b>
                  {
                    response.features.translations[
                      this.props.displayOptions.translations
                    ]
                  }
                </b>
              ) : (
                <>
                  {response.features && (
                    <HighlightedTranscription
                      time={
                        this.state.playback
                          ? this.state.playback.time
                          : undefined
                      }
                      onWordClick={(e, item) =>
                        this.onWordClick(e, response, item)
                      }
                      transcription={response.features.transcription}
                      transcriptionEdited={
                        response.features.transcription_edited
                      }
                      transcriptionItems={response.features.transcription_items}
                    />
                  )}
                </>
              )}
            </div>
          );
        }

        data.push(
          <TableCell key={index + "-TRANSCRIPT OR BACKUP"}>
            {cellJSX && (
              <>
                {cellJSX}
                <TopicsList
                  topics={response.topics}
                  allTopics={this.props.entities}
                  responseId={response._id}
                  questionId={this.props.question.questionId}
                  surveyId={this.props.survey._id}
                  canEdit
                />
              </>
            )}
          </TableCell>
        );
      }
    }
    // Selection Stuff
    if (isSelectionNatureStrict(response.type)) {
      data.push(
        <TableCell key={index + "-SELECTION"}>
          <Grid container className="selection-results">
            {this.getSelectionDataJSX(response)}
          </Grid>
        </TableCell>
      );
    }
    // Text Stuff
    if (isTextType(response.type)) {
      data.push(<TableCell key={index + "-TEXT"}>{response.data}</TableCell>);
    }
    //Likert Stuff
    if (response.type.includes("LIKERT")) {
      data.push(
        <TableCell key={index + "-LIKERT"}>
          <Grid container className="selection-results">
            {this.getLikerTableCellDataJSX(response)}
          </Grid>
        </TableCell>
      );
    }
    // Ranking Stuff
    if (isRankingType(response.type)) {
      data.push(
        <TableCell key={index + "-RANKING"}>
          <Grid container className="ranking-results">
            {this.getRankingDataJSX(response)}
          </Grid>
        </TableCell>
      );
    }
    // File Stuff
    if (response.type.includes("FILE")) {
      data.push(
        <TableCell key={index + "-FILE"}>
          <div className="file-chip-container">
            {response.uri && (
              <Chip
                size="small"
                icon={<DescriptionIcon />}
                label="Attached File"
                color="primary"
                onClick={() => {
                  window.open(response.uri, "_blank");
                }}
              />
            )}
            {response.uris &&
              response.uris.map((uri, idx) => (
                <Chip
                  key={idx}
                  size="small"
                  icon={<DescriptionIcon />}
                  label={`Attached File - ${idx + 1}`}
                  color="primary"
                  onClick={() => {
                    window.open(uri, "_blank");
                  }}
                />
              ))}
            {!response.uri && !response.uris && "No Media Found"}
          </div>
        </TableCell>
      );
    }
    // Demographics
    data.push(this.getDemographics(response));

    if (isAudioOrVideoType(response.type) && this.props.question.doSentiment) {
      data.push(
        response.features &&
          response.features.sentiment &&
          response.features.sentiment_score ? (
          <TableCell key={index + "-SENTIMENT"}>
            <SentimentBar score={response.features.sentiment_score} />
          </TableCell>
        ) : (
          <TableCell key={index + "-SENTIMENT-EMPTY"} />
        )
      );
    }
    // Emotion
    if (
      (this.props.question.type.includes("AUDIO") ||
        this.props.question.type.includes("THINKALOUD")) &&
      this.props.question.doEmotion
    )
      data.push(
        response.features &&
          response.features.emotions &&
          response.features.emotions.emojis ? (
          <TableCell>
            {response.features.emotions.emojis.split(" ").map((emoji, idx) => {
              return (
                <Tooltip
                  key={`TOOLTIP-${idx}`}
                  title={
                    response.features.emotions.text[idx]
                      ? response.features.emotions.text[idx].text
                      : "Unknown"
                  }
                >
                  <span
                    key={`${index}-${idx}-EMOTION`}
                    className="emoji-filter-element"
                  >
                    {emoji}
                  </span>
                </Tooltip>
              );
            })}
          </TableCell>
        ) : (
          <td />
        )
      );
    // Response Menu
    data.push(
      <td key={index + "-MENU"}>
        <div
          onClick={(e) => {
            e.stopPropagation();
            this.props.openResponseMenu(e, response);
          }}
          className="rounded-icon-container"
        >
          <FontAwesomeIcon icon={faEllipsisV} />
        </div>
      </td>
    );
    return data;
  };

  getDemographics = (response) => {
    if (this.state.displayOptions && !this.state.displayOptions.demographics)
      return null;
    if (!response.demographics) return <TableCell />;
    return (
      <TableCell key="DEMOGRAPHICS">{getDemographicsBlock(response)}</TableCell>
    );
  };

  /**
   * Utility
   */
  getLikerTableCellDataJSX = (response) => {
    if (!response.data || !response.data.length) return null;
    const options = this.props.question.options;
    return response.data.map((data, idx) => {
      if (data)
        return (
          <Grid item className="selection-result">
            <div>{options[idx]}</div>
            <div>
              <b style={{ paddingLeft: 5 }}>({data})</b>
            </div>
          </Grid>
        );
      return null;
    });
  };
  getRankingDataJSX = (response) => {
    if (!response.data || !response.data.length) return null;
    return response.data.map((data, idx) => {
      if (data) {
        return (
          <Grid item className="ranking-result" key={idx + "-RANKING"}>
            {`${idx + 1}: ${truncateStringWithEllipsis(data)}`}
          </Grid>
        );
      }
      return null;
    });
  };
  getSelectionDataJSX = (response) => {
    if (!response.data || !response.data.length) return null;
    const options = this.props.question.options;
    return response.data.map((data, idx) => {
      if (data)
        return (
          <Grid item className="selection-result" key={idx + "-SELECTION"}>
            {truncateStringWithEllipsis(options[idx])}
          </Grid>
        );
      return null;
    });
  };

  render() {
    const { response } = this.props;
    return (
      <TableRow onClick={() => this.props.openResponse(response)}>
        {this.getData(response)}
      </TableRow>
    );
  }
}

export default withRouter(connect(null, mapDispatchToProps)(Response));
