import React, { Component } from "react";
import { connect } from "react-redux";
import { trackPage } from "analytics";
import { Link, Breadcrumbs, Paper } from "@material-ui/core";
import { ScaleLoader } from "react-spinners";
import {
  getResponse,
  updateResponse,
  getUser,
  getSurveys,
  setPageTitle,
} from "../redux/actions";
import {
  truncateStringWithEllipsis,
} from "utils/utils";
import ResponseViewer from "components/ResponseViewer";

const getResponseMap = (state, ownProps) => {
  var s = {};
  if (state.surveys) {
    s.survey = state.surveys[ownProps.match.params.surveyId];
    if (s.survey && s.survey.questions) {
      for (let i = 0; i < s.survey.questions.length; ++i) {
        const q = s.survey.questions[i];
        if (q.questionId === ownProps.match.params.questionId) {
          s.question = q;
          s.questionIdx = i;
          break;
        }
      }
    }
  }
  if (state.user) s.user = state.user;
  return s;
};

const mapStateToProps = (state, ownProps) => {
  return {
    response: state.response,
    user: state.user,
    authToken: state.authToken,
    ...getResponseMap(state, ownProps),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getResponse: (id) => dispatch(getResponse(id)),
    getSurveys: () => dispatch(getSurveys()),
    getUser: () => dispatch(getUser()),
    updateResponse: (responseId, responseData) =>
      dispatch(updateResponse(responseId, responseData)),
    setPageTitle: (title) => dispatch(setPageTitle(title)),
  };
};

class PlayAudio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promptSignIn: false,
    };
    this.props.getSurveys();
    this.props.getResponse(this.props.match.params.responseId);
    this.props.getUser();
  }

  componentDidMount() {
    trackPage("Viewed Response", this.props.match.params.responseId);
    setTimeout(() => {
      this.setState({ promptSignIn: true });
    }, 5000);
  }

  componentDidUpdate(prevProps) {
    // Fetch responses again once the auth token is fetched
    if (prevProps.authToken !== this.props.authToken) {
      this.props.getUser();
      this.props.getResponse(this.props.match.params.responseId);
    }
    this.props.setPageTitle(this.getBreadCrumbs());
  }

  getBreadCrumbs = () => {
    if (
      this.props.match.params.surveyId &&
      this.props.match.params.questionId &&
      this.props.survey
    ) {
      return (
        <div className="breadcrumbs-title">
          <Breadcrumbs aria-label="breadcrumb">
            {this.props.survey && (
              <Link
                color="inherit"
                onClick={() => this.props.history.push("/surveys")}
              >
                Surveys
              </Link>
            )}
            {this.props.survey && (
              <Link
                color="inherit"
                onClick={() =>
                  this.props.history.push(
                    `/surveys/${this.props.match.params.surveyId}`
                  )
                }
              >
                {truncateStringWithEllipsis(this.props.survey.name, 36)}
              </Link>
            )}
            {this.props.survey && (
              <Link
                color="inherit"
                onClick={() =>
                  this.props.history.push(
                    `/surveys/${this.props.match.params.surveyId}/questions/${this.props.match.params.questionId}`
                  )
                }
              >{`Question ${this.props.questionIdx + 1}`}</Link>
            )}
            {this.props.survey && <Link color="inherit">{"Response"}</Link>}
          </Breadcrumbs>
        </div>
      );
    }
  };

  render() {
    return (
      <div className="content">
        {this.props.response && this.props.response.error === undefined ? (
          <Paper style={{ width: "100%", marginTop: '8px'}}>
            <ResponseViewer
              {...this.props}
            />
          </Paper>
        ) : (
          <div className="prompt-container-indash">
            {!this.state.promptSignIn ? (
              <ScaleLoader size={"30px"} color={"#62cb88"} loading={true} />
            ) : (
              <div>
                <h3>You don't have access to this response.</h3>
                <p>
                  <a href="/signin">Click here to sign in.</a>
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayAudio);
