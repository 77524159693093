import React from "react";
import { Grid, Button } from "@material-ui/core";
import CrosstabPopup from "components/Popups/CrosstabPopup";
import { removeItemFromArray } from "utils/utils";
import { connect } from "react-redux";
import {
  fetchCrosstabsChart,
  updateSurvey,
  removeCrosstabsChart,
} from "redux/actions";
import CrosstabChart from "components/Graphs/CrosstabChart";
import { ScaleLoader } from "react-spinners";

const mapStateToProps = (state, ownProps) => {
  return {
    charts: state.charts
      ? state.charts[ownProps.survey._id.toString()]
      : undefined,
    crossTabsLoading: state.loading["CrossTabs"],
  };
};

const mapDispatchToProps = {
  fetchCrosstabsChart,
  updateSurvey,
  removeCrosstabsChart,
};

class Crosstabs extends React.Component {
  state = {
    newCrosstabPopupOpen: false,
  };

  createNewCrosstab = (vars, title) => {
    var params = {
      type: "CROSSTAB",
      surveyId: this.props.survey._id.toString(),
      vars: vars,
      title: title,
      loading: true,
    };

    this.props.fetchCrosstabsChart(this.props.survey._id, params);
    if (!this.props.survey.comparisons) {
      var update = {
        comparisons: [{ type: "CROSSTAB", vars: vars, title: title }],
      };
    } else {
      var update = {
        comparisons: [
          ...this.props.survey.comparisons,
          { type: "CROSSTAB", vars: vars, title: title },
        ],
      };
    }
    this.props.updateSurvey(this.props.survey._id, update);
  };

  deleteCrossTab = (index) => {
    var sliced = removeItemFromArray(
      this.props.survey.comparisons[this.props.survey._id],
      index
    );
    this.props.updateSurvey(this.props.survey._id, { comparisons: sliced });
    this.props.removeCrosstabsChart(this.props.survey._id, index);
  };

  componentDidUpdate(prevProps) {
    // wait for: survey to load, update to exist, chart to exist, loading to be false (otherwise null error)
    if (
      prevProps.survey !== this.props.survey &&
      this.props.survey &&
      !this.props.charts &&
      !this.props.crossTabsLoading
    ) {
      if (this.props.survey.comparisons) {
        // specify type === "CROSSTAB" in case we want comparisons that aren't crosstabs in the future
        for (var i in this.props.survey.comparisons.filter(
          (c) => c.type === "CROSSTAB"
        )) {
          var params = {
            ...this.props.survey.comparisons[i],
            surveyId: this.props.survey._id.toString(),
            loading: true,
          };
          this.props.fetchCrosstabsChart(this.props.survey._id, params);
        }
      }
    }
  }

  render() {
    var showCrosstabs = false;
    showCrosstabs = this.props.charts && this.props.charts.length > 0;
    return (
      <div id="completion-screen">
        <CrosstabPopup
          open={this.state.newCrosstabPopupOpen}
          onClose={() => this.setState({ newCrosstabPopupOpen: false })}
          survey={this.props.survey}
          submit={this.createNewCrosstab}
        />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <h1 className="page-title">Crosstabs</h1>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => this.setState({ newCrosstabPopupOpen: true })}
            >
              + New Crosstab
            </Button>
          </Grid>
          <Grid item xs={12}>
            {this.props.crossTabsLoading ? (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <ScaleLoader size={"30px"} color={"#62cb88"} />
              </Grid>
            ) : showCrosstabs ? (
              this.props.charts.map((c, i) => (
                <CrosstabChart
                  idx={i}
                  key={`CROSSTAB-CHART-${i}`}
                  chart={c}
                  title={c.chart.title}
                  loading={c.chart.loading}
                  hideMenu={true}
                  deleteCrossTab={this.deleteCrossTab}
                />
              ))
            ) : (
              <div className="documentation-help">
              <p>
              You have not added a crosstab yet.{" "}
                <a
                  href="https://docs.phonic.ai/docs/what-are-crosstabs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click here
                </a>{" "}
                to learn about crosstabs.
              </p>
            </div>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Crosstabs);
