import React, { Component } from "react";

export class StatsCard extends Component {
  render() {
    return (
      <div className="card card-stats">
        <div className="space-evenly">
          <div className="icon-big text-center icon-warning">
            {this.props.bigIcon}
          </div>
          <div className="numbers">{this.props.statsValue}</div>
          <div className="stat-label">{this.props.statsText}</div>
        </div>
      </div>
    );
  }
}

export default StatsCard;
