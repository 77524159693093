import React, { Component } from "react";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import {
  getSurveys,
  getUser,
  setPageTitle,
  updateSurvey,
  submitFeedback,
  toggleUpgradePopup,
  getDrafts,
  getCreditInfo
} from "redux/actions";
import { trackPage } from "analytics";
import { Helmet } from "react-helmet";
import { Paper, Grid, Table } from "@material-ui/core";
import LinkTextField from "components/Text/LinkTextField";
import SyncLoader from "react-spinners/SyncLoader";
import MySurveysPopup from "components/Popups/MySurveysPopup";
import SharingPopup from "components/Popups/SharingPopup";
import FeedbackPopup from "components/Popups/FeedbackPopup";
import DeletePopup from "components/Popups/DeletePopup";
import SurveyPopupMenu from "components/SurveyPopupMenu";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import BookmarkToggleButton from "components/Buttons/BookmarkToggleButton";
import AppsIcon from "@material-ui/icons/Apps";
import ListIcon from "@material-ui/icons/List";
import NewSurveyTile from "components/Tiles/NewSurveyTile";
import UsageTile from "components/Tiles/UsageTile";
import LoaderTile from "components/Tiles/LoaderTile";
import FreeTrialAlert from "components/Alerts/FreeTrialAlert";
import OverUsageAlert from "components/Alerts/OverUsageAlert";
import { getStateJSX } from "utils/utils";
import SurveyTile from "./SurveyTile";
import { parseQuery, getFrontEndBaseUrl} from "utils/utils";

let BASE_URL = getFrontEndBaseUrl()

const mapStateToProps = (state) => {
  return {
    surveys: state.surveys,
    drafts: state.drafts,
    sharedSurveys: state.sharedSurveys,
    numResponses: state.numResponses,
    numSurveys: state.numSurveys,
    user: state.user,
    monthlyResponses: state.monthlyResponses,
    creditBalance: state.creditBalance,
    feedbackLoading: state.loading ? state.loading.Feedback : undefined,
  };
};

const mapDispatchToProps = {
  getSurveys,
  updateSurvey,
  getUser,
  setPageTitle,
  submitFeedback,
  toggleUpgradePopup,
  getDrafts,
  getCreditInfo,
};

class MySurveys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listOrGrid: "grid",
      newUser: false,
      shareSurveyPopup: false,
      bookmarkFilter: false,
      menuAnchorEl: null,
      feedbackOpen: false,
      deletePopupOpen: false,
      viewing: parseQuery(this.props.location.search).viewing
        ? parseQuery(this.props.location.search).viewing
        : "live",
    };
    this.props.getSurveys();
    this.props.getDrafts();
  }

  componentDidMount() {
    trackPage("Surveys");
    this.props.setPageTitle();
    if (this.props.location && this.props.location.state) {
      if (this.props.location.state.referrer === "signUp") {
        window.analytics.track("User Welcomed");
        this.setState({
          newUser: this.props.location.state.referrer === "signUp",
        });
        this.props.history.replace("/surveys", { referrer: undefined });
      }
    }
    this.props.getCreditInfo();
    // Check if the top notification is regarding panel feedback
    if (this.props.user) {
      if (
        this.props.user.feedback &&
        this.props.user.feedback.length > 0 &&
        this.props.user.feedback[0].type === "PANEL"
      ) {
        this.setState({
          feedbackOpen: true,
          feedbackNotif: this.props.user.feedback[0],
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location !== prevProps.location) {
      this.setState({
        viewing: parseQuery(this.props.location.search).viewing
          ? parseQuery(this.props.location.search).viewing
          : "live",
      });
    }
  }

  routeToSurvey(survey) {
    this.props.history.push({
      pathname: `/surveys/${survey._id}/respondents`,
    });
  }
  routeToBuilder(survey) {
    this.props.history.push({
      pathname: `/surveys/${survey._id}/create`,
    });
  }
  routeToRecruit = () => {
    this.props.history.push(`recruit`);
  };

  // Copy text to clipboard
  linkClick = (event, id) => {
    event.stopPropagation();
    var text = document.getElementById(id);
    text.select();
    document.execCommand("copy");
    alert("Survey link has been copied to clipboard.\n\n" + text.value);
  };

  onPopupClose = () => {
    this.setState({
      newUser: false,
    });
  };
  onShareSurveyPopupClose = () => {
    this.setState({ shareSurveyPopup: false });
  };

  changelistOrGrid = (value) => {
    this.setState({ listOrGrid: value });
  };

  openShareSurveyPopup = () => {
    this.setState({ shareSurveyPopup: true });
  };

  openDeletePopup = (deletePopupConfig) => {
    this.setState({
      deletePopupOpen: true,
      deletePopupConfig: deletePopupConfig,
    });
  };
  closeDeletePopup = () => {
    this.setState({ deletePopupOpen: false });
  };

  toggleBookmarkFilter = (selected) => {
    this.setState({ bookmarkFilter: selected });
  };
  feedbackClose = () => {
    this.setState({ feedbackOpen: false });
  };

  handleTriggerMenu = (event, id, name, state, shared) => {
    event.stopPropagation();
    this.setState({
      menuAnchorEl: event.currentTarget,
      surveyId: id,
      surveyName: name,
      menuConfig: { surveyState: state, shared },
    });
  };
  handleCloseMenu = () => {
    this.setState({
      menuAnchorEl: null,
      surveyId: undefined,
      menuConfig: undefined,
    });
  };
  routeToPlans = () => {
    this.props.history.push("/plans");
  };

  render() {
    // TODO: Add tabs and change surveys depending on if you're viewing shared or not
    let surveys =
      this.state.viewing === "live" ? this.props.surveys : this.props.drafts;

    const showTrialEndingAlert =
      this.props.user &&
      this.props.user.tier === "FREE" &&
      this.props.user.trialDaysLeft >= 0 &&
      this.props.user.trialDaysLeft <= 3;

    const showOverUsageAlert =
      !showTrialEndingAlert &&
      this.props.user && this.props.creditBalance <= 0

    return (
      <>
        <Helmet>
          <title>Surveys</title>
        </Helmet>
        <div className="content">
          <MySurveysPopup
            open={this.state.newUser}
            onClose={this.onPopupClose}
            user={this.props.user}
          />
          <SharingPopup
            open={this.state.shareSurveyPopup}
            onClose={this.onShareSurveyPopupClose}
            surveyId={this.state.surveyId}
          />
          <FeedbackPopup
            open={this.state.feedbackOpen}
            onClose={this.feedbackClose}
            feedbackNotif={this.state.feedbackNotif}
            submitFeedback={this.props.submitFeedback}
            loading={this.props.feedbackLoading}
          />
          <DeletePopup
            open={this.state.deletePopupOpen}
            onClose={this.closeDeletePopup}
            {...this.state.deletePopupConfig}
          />
          <SurveyPopupMenu
            menuAnchorEl={this.state.menuAnchorEl}
            handleCloseMenu={this.handleCloseMenu}
            surveyId={this.state.surveyId}
            surveyName={this.state.surveyName}
            {...this.state.menuConfig}
            openShareSurveyPopup={this.openShareSurveyPopup}
            user={this.props.user}
            openDeletePopup={this.openDeletePopup}
          />
          <Grid container className="screen-margin">
            {showTrialEndingAlert && (
              <Grid item xs={12} style={{ marginBottom: 20 }}>
                <FreeTrialAlert
                  user={this.props.user}
                  routeToPlans={this.routeToPlans}
                />
              </Grid>
            )}
            {showOverUsageAlert && (
              <Grid item xs={12} style={{ marginBottom: 20 }}>
                <OverUsageAlert
                  creditExpiryDate={this.props.user.creditsExpired}
                  routeToPlans={this.routeToPlans}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <h1 className="page-title page-title-surveys">Surveys</h1>
            </Grid>
            <Grid item xs={6}>
              <div style={{ float: "right" }} className="flex-container">
                <ToggleButtonGroup
                  value={this.state.viewing}
                  style={{ marginRight: 6 }}
                >
                  <ToggleButton
                    value="live"
                    onClick={() => {
                      this.setState({ viewing: "live" });
                      this.props.history.push({
                        pathname: "/surveys",
                        search: "?viewing=live",
                      });
                    }}
                  >
                    Live
                  </ToggleButton>
                  <ToggleButton
                    value="drafts"
                    onClick={() => {
                      this.setState({ viewing: "drafts" });
                      this.props.history.push({
                        pathname: "/surveys",
                        search: "?viewing=drafts",
                      });
                    }}
                  >
                    Drafts
                  </ToggleButton>
                </ToggleButtonGroup>
                <ToggleButtonGroup style={{ marginRight: 6 }}>
                  <BookmarkToggleButton
                    toggleBookmarkFilter={this.toggleBookmarkFilter}
                  />
                </ToggleButtonGroup>
                <ToggleButtonGroup value={this.state.listOrGrid}>
                  <ToggleButton
                    value="list"
                    className="filter-button"
                    onClick={() => this.changelistOrGrid("list")}
                  >
                    <ListIcon />
                  </ToggleButton>
                  <ToggleButton
                    value="grid"
                    className="filter-button"
                    onClick={() => this.changelistOrGrid("grid")}
                  >
                    <AppsIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </Grid>
          </Grid>
          {this.state.listOrGrid === "list" ? (
            <Grid>
              <Paper>
                <Grid container>
                  <Grid item md={12}>
                    <Table className="align-middle mb-0 table table-borderless table-striped table-hover">
                      <thead>
                        <tr>
                          <th>Survey Link</th>
                          <th>Title</th>
                          <th>Tag</th>
                          <th># Questions</th>
                          <th># Responses</th>
                          <th>Date Created</th>
                          <th>State</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {!this.state.newUser && !surveys && (
                          <tr>
                            <td align="center" colSpan="8">
                              <div
                                style={{ margin: 10 }}
                                className="sweet-loading"
                              >
                                <SyncLoader
                                  size={"10px"}
                                  color={"#9BA5BA"}
                                  loading={!surveys}
                                />
                              </div>
                            </td>
                          </tr>
                        )}

                        {(() => {
                          if (surveys && Object.keys(surveys).length === 0) {
                            return (
                              <tr>
                                <td align="center" colSpan="7">
                                  <i>You don't have any surveys yet.</i>
                                </td>
                              </tr>
                            );
                          }
                        })()}

                        {surveys &&
                          Object.values(surveys)
                            .filter(
                              (s) => !this.state.bookmarkFilter || s.bookmarked
                            )
                            .map((s) => (
                              <tr
                                key={s._id}
                                onClick={this.routeToSurvey.bind(this, s)}
                              >
                                <td>
                                  <LinkTextField
                                    id={s._id}
                                    link={`${BASE_URL}/${s._id}`}
                                    onClick={(e) => this.linkClick(e, s._id)}
                                  />
                                </td>
                                <td>{s.name}</td>
                                <td>{s.tag}</td>
                                <td>{s.questions.length}</td>
                                <td>{s.numResponses}</td>
                                <td>{s.createdAt}</td>
                                <td>{getStateJSX(s)}</td>
                                <th>
                                  <div
                                    onClick={(event) =>
                                      this.handleTriggerMenu(
                                        event,
                                        s._id,
                                        s.name,
                                        s.state,
                                        s.shared
                                      )
                                    }
                                    className="survey-menu-container"
                                  >
                                    <FontAwesomeIcon
                                      icon={faEllipsisV}
                                      aria-controls="simple-menu"
                                      aria-haspopup="true"
                                      className="survey-menu-icon"
                                    />
                                  </div>
                                </th>
                              </tr>
                            ))}
                      </tbody>
                    </Table>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ) : (
            <Grid container>
              {this.props.user &&
                !this.props.user.readOnly &&
                (this.props.user.trialDaysLeft === undefined ||
                  this.props.user.trialExpired === false) && <NewSurveyTile />}
              {this.props.user &&
                surveys &&
                !this.props.user.readOnly &&
                this.state.viewing === "live" && (
                  <UsageTile
                    user={this.props.user}
                    numResponses={this.props.numResponses}
                    numSurveys={
                      this.props.numSurveys ? this.props.numSurveys : 0
                    }
                    monthlyResponses={this.props.monthlyResponses}
                    creditBalance={this.props.creditBalance}
                    routeToPlans={this.routeToPlans}
                  />
                )}
              {!surveys && <LoaderTile surveys={surveys} />}
              {surveys &&
                Object.values(surveys)
                  .filter((s) => !this.state.bookmarkFilter || s.bookmarked)
                  .map((s) => (
                    <SurveyTile
                      key={`SURVEY-${s._id}`}
                      survey={s}
                      action={() => {
                        if (s.state === "DRAFT") {
                          this.routeToBuilder(s);
                          return;
                        }
                        this.routeToSurvey(s);
                      }}
                      handleTriggerMenu={this.handleTriggerMenu}
                      updateSurvey={this.props.updateSurvey}
                    />
                  ))}
            </Grid>
          )}
        </div>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MySurveys));
