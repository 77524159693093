import { findChildrenByNode, getChangedNodes } from "@remirror/core";
import { Decoration, DecorationSet } from "@remirror/pm/view";

export class SpeakerState {
  /**
   * Store the Node type of "paragraph"
   * https://prosemirror.net/docs/ref/#model.Node
   */
  #type;

  #speakers = [];

  // Store decorations to minimize DOM updates
  decorationSet = DecorationSet.empty;

  constructor(type, speakers) {
    this.#type = type;
    this.#speakers = speakers;
  }

  /**
   * Creates the initial set of decorations
   */
  init(state) {
    const blocks = findChildrenByNode({ node: state.doc, type: this.#type });

    this.setDecorations(state.doc, blocks);

    return this;
  }

  setDecorations(doc, blocks) {
    const decorations = blocks.map((block) => {
      return Decoration.node(
        block.pos,
        block.pos + block.node.nodeSize,
        {},
        {
          speakerName:
            this.#speakers[parseInt(block.node.attrs.speaker) - 1].name,
        }
      );
    });

    this.decorationSet = DecorationSet.create(doc, decorations);
  }

  // Apply the state and update decorations when the editor has changed
  apply({ tr, action }, state) {
    if (!action && !tr.docChanged) {
      return this;
    }

    if (action?.type === "REDRAW_SPEAKERS") {
      this.redrawSpeakers(state.doc, action);
    } else {
      this.decorationSet = this.decorationSet.map(tr.mapping, tr.doc);

      /**
       * No need to check for deletions because when `decorationSet` is mapped
       * over, decorations from a deleted node are removed. So we just need to
       * find the changed nodes and update their decorations.
       */
      const changedNodes = getChangedNodes(tr, {
        descend: true,
        predicate: (node) => node.type === this.#type,
      });
      this.updateDecorationSet(tr, changedNodes);
    }

    return this;
  }

  /**
   * Removes all decorations which relate to the changed block node before creating new decorations
   * and adding them to the decorationSet.
   */
  updateDecorationSet(tr, blocks) {
    if (blocks.length === 0) {
      return;
    }

    let decorationSet = this.decorationSet;

    for (const { node, pos } of blocks) {
      const toRemove = this.decorationSet.find(pos, pos + node.nodeSize);

      decorationSet = this.decorationSet.remove(
        toRemove.filter((d) => d.from === pos && d.to === pos + node.nodeSize)
      );
    }

    const decorations = blocks.map((block) => {
      return Decoration.node(
        block.pos,
        block.pos + block.node.nodeSize,
        {},
        {
          speakerName:
            this.#speakers[parseInt(block.node.attrs.speaker) - 1].name,
        }
      );
    });

    this.decorationSet = decorationSet.add(tr.doc, decorations);
  }

  redrawSpeakers(doc, action) {
    const blocks = findChildrenByNode({ node: doc, type: this.#type });

    const { speakerIdx, speakerInfo } = action;

    this.#speakers = [
      ...this.#speakers.slice(0, speakerIdx),
      speakerInfo,
      ...this.#speakers.slice(speakerIdx + 1),
    ];

    this.setDecorations(doc, blocks);
  }
}
