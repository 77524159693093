import React from "react";
import { Grid } from "@material-ui/core";
import { withRouter } from "react-router";

class NewSurveyTile extends React.Component {
  routeToCreation = () => {
    this.props.history.push({
      pathname: `/surveys/create`,
    });
  };
  render() {
    return (
      <Grid
        item
        onClick={this.routeToCreation}
        className="main-survey-tile main-survey-new"
      >
        <div style={{ display: "inline-block", textAlign: "center" }}>
          <p
            style={{
              fontSize: 26,
              fontWeight: 700,
              marginBottom: -5,
              marginTop: -10,
            }}
          >
            +
          </p>
          <h3 style={{ fontSize: 22 }}>New Survey</h3>
        </div>
      </Grid>
    );
  }
}

export default withRouter(NewSurveyTile);
