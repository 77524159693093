import React from "react";
import { 
  Grid, 
  MenuItem, 
  Select, 
  TextField
} from "@material-ui/core";

class Selection extends React.Component {
  render() {
    console.log(this.props);
    return(
      <div style={{ marginBottom: 20 }}>
        {this.props.question &&
          this.props.question.options &&
          this.props.question.options.map((option, idx) => {
            return (
              <Grid
                key={`LOGIC-SKIP-OPTION-${idx}`}
                container
                spacing={2}
              >
                <Grid item xs={8}>
                  <TextField
                    variant="filled"
                    value={option.text}
                    disabled
                    margin="dense"
                    style={{
                      width: "100%",
                      margin: 0,
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Select
                    variant="outlined"
                    margin="dense"
                    className="option-fullwidth"
                    value={option.skip}
                    onChange={(e) =>
                      this.props.modFunctions.changeQuestionOption(
                        this.props.question,
                        idx,
                        { skip: e.target.value }
                      )
                    }
                  >
                    <MenuItem key={"QUESTION-" + idx} value={0}>
                      Next Question
                    </MenuItem>
                    <MenuItem key={"END-SURVEY-" + idx} value={-1}>
                      End Survey
                    </MenuItem>
                    <MenuItem key={"SCREEN-OUT-" + idx} value={-2}>
                      Screen Out
                    </MenuItem>
                    {this.props.questions.map((q, idx) => {
                      if (
                        this.props.questionIdx !== null &&
                        idx <= this.props.questionIdx
                      )
                        return null;
                      return (
                        <MenuItem
                          key={"QUESTION-" + idx}
                          value={q.questionId}
                        >
                          Q{idx + 1}. {q.text}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              </Grid>
            );
          })}
      </div>
    )
  }
}

export default Selection