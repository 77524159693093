import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateResponseBuilder } from "redux/actions";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Fade from "@material-ui/core/Fade";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faUserEdit,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import MediaPlayer from "components/MediaPlayer";
import SelectedOptions from "components/SelectedOptions";
import FilePreview from "components/FilePreview";
import ConditionalWrapper from "utils/ConditionalWrapper";
import {
  getDemographics,
  isVideoType,
  isFileType,
  getTranscriptionItemsAsString,
} from "utils/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "none",
    backgroundColor: "#f9f9f9",
    borderRadius: 0,
  },
  pad1: {
    paddingTop: "25px",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "40px",
      paddingRight: "40px",
    },
  },
  pad2: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "75px",
      paddingRight: "75px",
    },
  },
  result: {
    paddingBottom: "16px",
  },
  transcript: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "70px",
      paddingRight: "70px",
    },
  },
  demographicsPane: {
    textAlign: "right",
    fontStyle: "italic",
    fontSize: "0.875rem",
  },
  descriptor: {
    fontWeight: "bold",
    textTransform: "capitalize",
    letterSpacing: "0.1em",
  },
  demographicsText: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontSize: "0.875rem",
  },
  private: {
    backgroundColor: "#f9f9f9",
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 60px 0",
  },
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    fontSize: "0.875rem",
    boxShadow: theme.shadows[1],
  },
  translationButton: {
    backgroundColor: "transparent",
    color: "white",
  },
}));

const ResponseCard = ({ question, response, hidden, changeReport }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [showTranslation, setShowTranslation] = useState(null);

  let result = null;

  if (response.data) {
    if (Array.isArray(response.data)) {
      result = (
        <SelectedOptions
          responseData={response.data}
          options={question.options}
          exclusive={question.exclusive}
          customLikertOptions={
            question.useCustomLikertOptions && question.customLikertOptions
          }
        />
      );
    } else {
      result = <Typography variant="body2">{response.data}</Typography>;
    }
  }

  const availableTranscription =
    response.features &&
    (response.features.transcription_items
      ? getTranscriptionItemsAsString(response.features.transcription_items)
      : response.features.transcription);
  // TODO: Implement user configuration of SHOW_TRANSCRIPTION
  const SHOW_TRANSCRIPTION = question.doTranscription && true;
  const IS_VIDEO = isVideoType(response.type);
  const SHOWN_DEMOGRAPHICS = response.demographics
    ? getDemographics(response.demographics)
    : [];
  const availableTranslation =
    response.features &&
    response.features.translations &&
    response.features.translations["en"];

  const handleUpdateResponse = () => {
    if (changeReport) {
      changeReport({ suppressNotify: true }).then(() => {
        dispatch(updateResponseBuilder(response._id, { isPublic: true }));
      });
    }
  };

  const toggleTranslation = () => {
    setShowTranslation(!showTranslation);
  };

  return (
    <Card className={classes.root} elevation={0}>
      {hidden && (
        <div className={classes.private}>
          <Tooltip
            title="Because this response isn't public, it won't appear in the report. Only
          public responses or responses from surveys with public media will appear
          in reports."
            placement="bottom"
            classes={{ tooltip: classes.tooltip }}
          >
            <Chip
              size="small"
              icon={<FontAwesomeIcon icon={faExclamationTriangle} />}
              label="Make Public"
              onClick={handleUpdateResponse}
            />
          </Tooltip>
        </div>
      )}
      <CardHeader
        title={
          <Typography component="h3" variant="h4">
            {question.text}
          </Typography>
        }
        disableTypography
        className={classes.pad1}
      />
      <CardContent className={classes.pad2}>
        {result && <div className={classes.result}>{result}</div>}
        {response.backupText && (
          <>
            {`${response.backupText}`}
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <FontAwesomeIcon
                icon={faUserEdit}
                style={{ marginTop: 15, marginBottom: 15, marginRight: 15 }}
              />
              <Typography variant="overline" noWrap>
                Backup Text
              </Typography>
            </div>
          </>
        )}
        {response.uri && (
          /**
           * If there is both video and transcription,
           * layout changes to 2-column at medium breakpoint
           */
          <Grid container spacing={5}>
            <Grid
              container
              item
              xs={12}
              md={
                IS_VIDEO && SHOW_TRANSCRIPTION && availableTranscription
                  ? 5
                  : 12
              }
              justifyContent="center"
            >
              <Grid
                item
                xs={12}
                sm={IS_VIDEO ? 6 : 12}
                md={IS_VIDEO && !SHOW_TRANSCRIPTION ? 6 : 12}
              >
                <MediaPlayer type={response.type} uri={response.uri} />
              </Grid>
            </Grid>
            {SHOW_TRANSCRIPTION && availableTranscription && (
              <Grid item xs={12} md={IS_VIDEO ? 7 : 12}>
                <ConditionalWrapper
                  condition={availableTranslation}
                  wrapper={(children) => (
                    <Tooltip
                      title={
                        <Button
                          onClick={toggleTranslation}
                          startIcon={<FontAwesomeIcon icon={faGlobe} />}
                          className={classes.translationButton}
                          size="small"
                          disableRipple
                        >
                          Show {showTranslation ? "original" : "translation"}
                        </Button>
                      }
                      placement="top"
                      interactive
                      TransitionComponent={Fade}
                    >
                      {children}
                    </Tooltip>
                  )}
                >
                  <Typography
                    variant="body2"
                    className={!IS_VIDEO ? classes.transcript : null}
                  >
                    {showTranslation ? (
                      <b>{availableTranslation}</b>
                    ) : (
                      availableTranscription
                    )}
                  </Typography>
                </ConditionalWrapper>
              </Grid>
            )}
          </Grid>
        )}
        {isFileType(response.type) && response.uri && (
          <FilePreview
            path={response.filePath}
            uri={response.uri}
            options={{ imageStyle: { maxWidth: "100%", maxHeight: 500 } }}
          />
        )}
        {isFileType(response.type) &&
          response.uris &&
          response.uris.map((uri, idx) => (
            <FilePreview
              key={uri}
              path={response.filePath[idx]}
              uri={uri}
              idx={idx}
              options={{ imageStyle: { maxWidth: "100%", maxHeight: 500 } }}
            />
          ))}
      </CardContent>
      {SHOWN_DEMOGRAPHICS.length > 0 ? (
        <CardContent className={`${classes.demographicsPane} ${classes.pad2}`}>
          {SHOWN_DEMOGRAPHICS.map((d, i) => (
            <span key={`${d.answer}-${i}`}>
              <Typography
                variant="subtitle2"
                className={classes.descriptor}
                display="inline"
              >
                {d.label ? `${d.label}` : null}
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle1"
                className={classes.demographicsText}
                display="inline"
                component="p"
              >
                {`${d.value}${i < SHOWN_DEMOGRAPHICS.length - 1 ? `, ` : ``}`}
              </Typography>
            </span>
          ))}
        </CardContent>
      ) : null}
    </Card>
  );
};

export default ResponseCard;
