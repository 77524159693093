import React, { Component } from "react";
import clsx from "clsx";
import { Button, LinearProgress, Paper } from "@material-ui/core";
import { withRouter } from "react-router";

const ONBOARDING_ITEMS = {
  surveys: {
    imgSrc: require("assets/img/home-screen/3dchart_green.png"),
    inactiveImageSrc: require("assets/img/home-screen/3dchart_gray.png"),
  },
  conversations: {
    imgSrc: require("assets/img/home-screen/3dspeech_green.png"),
    inactiveImageSrc: require("assets/img/home-screen/3dspeech_gray.png"),
  },
  showreels: {
    imgSrc: require("assets/img/home-screen/3dcamera_green.png"),
    inactiveImageSrc: require("assets/img/home-screen/3dcamera_gray.png"),
  },
  docs: {
    imgSrc: require("assets/img/home-screen/3ddocs_green.png"),
    inactiveImageSrc: require("assets/img/home-screen/3ddocs_gray.png"),
  },
};

const OnboardingCard = ({
  imgSrc,
  inactiveImageSrc,
  active,
  title,
  description,
  action,
}) => {
  return (
    <Paper className={clsx("onboarding-card", !active && "inactive")}>
      <img
        className="onboarding-image"
        src={active ? imgSrc : inactiveImageSrc}
      />
      <h3>{title}</h3>
      <p>{description}</p>
      <div className="button-tray flex-container">
        <Button
          className={active ? "" : "preview-outlined-button"}
          variant={active ? "contained" : "outlined"}
          color={active ? "primary" : "secondary"}
          onClick={action}
        >
          Get Started
        </Button>
      </div>
    </Paper>
  );
};

class Onboarding extends Component {
  render() {
    const createdSurvey =
      this.props.user &&
      this.props.user.surveys &&
      this.props.user.surveys.length > 1;
    const createdConversation =
      this.props.user &&
      this.props.user.fileCollections &&
      this.props.user.fileCollections.length > 1;
    const createdShowreel =
      this.props.user &&
      this.props.user.showreels &&
      this.props.user.showreels.length > 1;

    const progress =
      Boolean(createdSurvey) +
      Boolean(createdConversation) +
      Boolean(createdShowreel);

    return (
      <div id="onboarding">
        <h2>
          👋 Hi {this.props.user.firstName}, you have some features to discover.
        </h2>
        <div className="flex-container flex-column">
          <div>
            <div className="flex-container">
              <OnboardingCard
                {...ONBOARDING_ITEMS.surveys}
                active={!createdSurvey}
                title={
                  <span>
                    Create a <b>Phonic Survey</b>
                  </span>
                }
                description="Collect media asynchronously through the Phonic survey platform or leverage our integrations to collect anywhere."
                action={() => this.props.history.push("/surveys/create")}
              />
              <OnboardingCard
                {...ONBOARDING_ITEMS.conversations}
                active={!createdConversation}
                title={
                  <span>
                    Upload a <b>Conversation</b>
                  </span>
                }
                description="Upload conversations for transcription, annotation, and storage. Use Phonic as a repository for all your research."
                action={() => this.props.history.push("/files")}
              />
              <OnboardingCard
                {...ONBOARDING_ITEMS.showreels}
                active={!createdShowreel}
                title={
                  <span>
                    Create a <b>Showreel</b>
                  </span>
                }
                description="Assemble any of your collected media into a concise Phonic showreel. Connect either your unmoderated work, or conversations to create stunning deliverables."
                action={() => this.props.history.push("/showreels/create")}
              />
              {/* NOTE: leaving documentation intentionally commented for now. */}
              {/* <OnboardingCard
            {...ONBOARDING_ITEMS.docs}
            active
            title={
              <span>
                Dive into our <b>Documentation</b>
              </span>
            }
            description="Have a question about how something works in Phonic? Most of our frequently asked questions can be answered through a quick browse of our documentation."
            action={() => window.open("https://docs.phonic.ai", "_blank")}
          /> */}
            </div>
            <div className="fg-1 text-align-center ml-30 mr-30">
              <LinearProgress
                variant="determinate"
                sx={{ width: "100%" }}
                value={(progress * 100) / 3}
              />
              <p className="mt-10">
                Nice! You've checked out <b>{progress}/3</b> of our major
                features.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Onboarding);
