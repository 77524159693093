import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

export class UploadTranslationPopup extends Component {
  state = {
    file: null,
    translations: [],
  };

  parseTranslationJson = (json) =>
    Object.entries(json).map(([text, translationDict]) => ({
      text: text,
      ...translationDict,
    }));

  fileInputChange = (event) => {
    this.setState({ file: event.target.files[0] });
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      let json = JSON.parse(event.target.result);

      this.setState({ translations: this.parseTranslationJson(json) });
    };
    reader.readAsText(file);
  };

  handleSave = () => {
    this.props.onSubmit(
      this.props.translations.concat(this.state.translations)
    );
    this.props.onClose();
  };

  render() {
    return (
      <div>
        {" "}
        <Dialog
          open={this.props.open}
          onClose={this.props.onClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle>Upload Translations</DialogTitle>
          <div className="image-upload-container">
            <DialogContent className="info-text">
              <DialogContentText className="dialog-text">
                Upload a translations JSON file.{" "}
                <a
                  href="https://www.loom.com/share/0f8359d7d28f4b6b9ea4a61964ad2d66"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more.
                </a>
              </DialogContentText>
              <input
                type="file"
                className="form-control"
                multiple=""
                accept="application/json"
                onChange={this.fileInputChange}
              />
              {this.state.file && (
                <div className="translation-file-line">
                  <div className="translation-file-text">
                    Found {this.state.translations.length} translations - Click
                    Upload to add them. Any duplicates will be overwritten.
                  </div>
                </div>
              )}
            </DialogContent>
          </div>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button
              className="secondary-button-color"
              variant="contained"
              component="label"
              onClick={this.handleSave}
            >
              Upload
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default UploadTranslationPopup;
