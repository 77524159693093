import React from "react";
import { truncateStringWithEllipsis } from "utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faFolder,
  faMicrophone,
  faSuitcase,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { rainbow } from "../../utils/brandPalette";

export default function FolderTile({ ...props }) {
  const getIcon = () => {
    switch (props.fileType) {
      case "AUDIO":
        return (
          <FontAwesomeIcon
            icon={faMicrophone}
            style={{ color: rainbow.green }}
          />
        );
      case "VIDEO":
        return (
          <FontAwesomeIcon icon={faVideo} style={{ color: rainbow.red }} />
        );
      case "FOLDER":
        return (
          <FontAwesomeIcon icon={faFolder} style={{ color: rainbow.gray }} />
        );

      default:
        return null;
    }
  };

  return (
    <div
      className="folder-tile"
      onClick={props.onClick}
      tabIndex={0}
      role="button"
    >
      <div className="title">
        {getIcon()} &nbsp;
        {truncateStringWithEllipsis(props.title, 49)}
      </div>
      <div className="info">
        <hr />
        <div className="comment">
          <FontAwesomeIcon icon={faCalendar} /> &nbsp; {props.date}
        </div>
        {props.fileType === "FOLDER" && (
          <div className="comment">
            <FontAwesomeIcon icon={faSuitcase} />
            &nbsp; {props.numFolders}
            &nbsp;Folders&nbsp;&&nbsp;{props.numFiles}&nbsp;Files
          </div>
        )}
      </div>
    </div>
  );
}
