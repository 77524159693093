import _ from "lodash";
import React from "react";
import { 
  Grid, 
  Select, 
  TextField
} from "@material-ui/core";
import { isSelectionNatureStrict } from "utils/utils";

function buildConditions(totalOptions, optionsDisplayConditions) {
  const conditions = new Array(totalOptions);
    
  for(let i = 0; i < totalOptions; i++) {
    conditions[i] = [{ type: 'question', negative: false, value: { questionId: null, value: null, check: 'equals' }  }];
  }

  optionsDisplayConditions.forEach((c, idx) => { conditions[idx] = c });

  return conditions;
}

// { type: 'or', value: [
//    {type: 'question', negative: false, value: { questionId: 'abcd', value: '0', check: 'equals' }},
// ]}

class Options extends React.Component {
  constructor(props) {
    super(props);
    
    const { question } = this.props;

    let optionsDisplayConditions = []
    if(question && question.optionsDisplayConditions) {
      optionsDisplayConditions = question.optionsDisplayConditions.map(c => c.value);
    }

    this.conditions = buildConditions(question.options.length, optionsDisplayConditions)
  }

  questionOptions() {
    return this.props.questions.map((q, idx) => {
      if(!isSelectionNatureStrict(q.type) || q.questionId ===  this.props.question.questionId) return null;

      return(
        <option key={`QUESTION-${q.questionId}`} value={q.questionId}>
          { `Q${idx + 1}. ${q.text}` }
        </option>
      )
    });
  }

  updateCondition(idx, updates) {
    const qInfo = _.cloneDeep(this.props.question);
    this.conditions = _.cloneDeep(this.conditions);
    this.conditions[idx][0]['value'] =  {...this.conditions[idx][0]['value'], ...updates};
    qInfo.optionsDisplayConditions = this.conditions.map(c => ({ type: 'or' , value: c}));
    this.props.modFunctions.changeQInfo(qInfo, this.props.question.questionId, true);
  }

  options(idx) {
    const conditions = this.conditions[idx];
    if(!conditions || conditions.length === 0) return [];

    const questionId = conditions[0].value.questionId;
    const questionIds = this.props.questions.map(q => q.questionId);
    const questionIdx = questionIds.indexOf(questionId);
    
    if(questionIdx === -1) return [];

    const question = this.props.questions[questionIdx];

    const opts = []

    opts.push(<option value="">None</option>)

    question.options.forEach((option, i) => (
      opts.push(
        <option key={`OPTION-${questionId}-${i}`} value={`${i}`}>
          { `Q${questionIdx + 1}. Option ${i + 1} - ${option.text}` }
        </option>
      )
    ));

    return opts
  }

  render() {
    if(!this.props.question) return null;

    return(
      <div style={{ marginBottom: 20 }}>
        { this.props.question.options.map((option, idx) => {
            return (
              <Grid
                key={`LOGIC-DISPLAY-OPTION-${idx}`}
                container
                spacing={2}
              >
                <Grid item xs={4}>
                  <label>Option</label>
                  <TextField
                    variant="filled"
                    value={option.text}
                    disabled
                    margin="dense"
                    style={{
                      width: "100%",
                      margin: 0,
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <label>Question</label>
                  <Select
                    variant="outlined"
                    margin="dense"
                    className="option-fullwidth"
                    native={true}
                    value={this.conditions[idx][0].value.questionId}
                    onChange={(e) => {
                      const val = e.target.value === "" ? null : e.target.value;
                      this.updateCondition(idx, { questionId: val, value: null });
                    }}
                  >
                    <option value="">None</option>
                    { this.questionOptions() }
                  </Select>
                </Grid>
                <Grid item xs={4}>
                  <label>Is</label>
                  <Select
                    variant="outlined"
                    margin="dense"
                    className="option-fullwidth"
                    native={true}
                    value={this.conditions[idx][0].value.value}
                    onChange={(e) => {
                      const val = e.target.value === "" ? null : e.target.value;
                      this.updateCondition(idx, { value: val });
                    } }
                  >
                    { this.options(idx) }
                  </Select>
                </Grid>
              </Grid>
            );
          })}
      </div>
    )
  }
}

export default Options