import React from "react";
import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ResponseViewer from "../ResponseViewer";

class ResponseViewPopup extends React.Component {
  render() {
    return (
      <Dialog
        onClose={this.props.onClose}
        open={this.props.open}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <div className="x-button">
          <IconButton aria-label="close" onClick={this.props.onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent style={{ height: "100vh" }}>
          <ResponseViewer {...this.props} inPopup={true} />
        </DialogContent>
      </Dialog>
    );
  }
}

export default ResponseViewPopup;
