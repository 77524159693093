import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { Checkbox, MenuItem, Select, Typography } from "@material-ui/core";

class ExportPopup extends React.Component {
  state = {
    format: "csv",
    includeResponseIds: false,
    useDataLabels: false,
    includeTopics: false,
  };
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className="popup-container small">
          <h2 className="dialog-title-small" style={{ marginBottom: 20 }}>
            Export Survey Data
          </h2>
          <Typography variant={"body1"}>Export Format</Typography>
          <Select
            fullWidth
            variant="outlined"
            value={this.state.format}
            onChange={(e) => this.setState({ format: e.target.value })}
          >
            <MenuItem value={"csv"}>.CSV</MenuItem>
            <MenuItem value={"txt"}>.TXT</MenuItem>
          </Select>
          {this.state.format === "csv" && (
            <>
              <div className="checkbox-container">
                <div>
                  <Checkbox
                    color="default"
                    checked={this.state.includeResponseIds}
                    onChange={(e) => {
                      this.setState({ includeResponseIds: e.target.checked });
                    }}
                  />
                </div>
                <div>
                  <h3 className="demographic">Include Response IDs</h3>
                </div>
              </div>
              <div className="checkbox-container">
                <div>
                  <Checkbox
                    color="default"
                    checked={this.state.includeTopics}
                    onChange={(e) => {
                      this.setState({ includeTopics: e.target.checked });
                    }}
                  />
                </div>
                <div>
                  <h3 className="demographic">Include Topics</h3>
                </div>
              </div>
              <div className="checkbox-container">
                <div>
                  <Checkbox
                    color="default"
                    checked={this.state.useDataLabels}
                    onChange={(e) => {
                      this.setState({ useDataLabels: e.target.checked });
                    }}
                  />
                </div>
                <div>
                  <h3 className="demographic">
                    Use custom{" "}
                    <a href="https://docs.phonic.ai/docs/using-data-mapping">
                      data labels
                    </a>{" "}
                    as column headers
                  </h3>
                </div>
              </div>

              <div className="checkbox-container">
                <div>
                  <Checkbox
                    color="default"
                    checked={this.state.filterBookmarks}
                    onChange={(e) => {
                      this.setState({ filterBookmarks: e.target.checked });
                    }}
                  />
                </div>
                <div>
                  <h3 className="demographic">
                    Export only bookmarked responses
                  </h3>
                </div>
              </div>
            </>
          )}
          <DialogActions>
            <Button
              variant="outlined"
              component="label"
              onClick={(e) => {
                this.props.onClose(e);
              }}
              className="phonic-outlined-button"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              component="label"
              onClick={() => {
                this.props.onClose();
                this.props.export({
                  format: this.state.format,
                  includeResponseIds: this.state.includeResponseIds,
                  includeTopics: this.state.includeTopics,
                  useDataLabels: this.state.useDataLabels,
                  filterBookmarks: this.state.filterBookmarks,
                });
              }}
              color="primary"
            >
              Export
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
}
export default ExportPopup;
