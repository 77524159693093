import React, { useEffect } from "react";
import {
  TableCell,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { checkSurveyCount } from "utils/utils";

function CreateFromDraft({ user, drafts, getDrafts, history }) {
  // Mimic the behavior of componentDidMount()
  useEffect(() => {
    getDrafts();
  }, []);

  const routeToSurveyCreate = (survey) => {
    if (!checkSurveyCount(user)) return;
    history.push({
      pathname: `/surveys/${survey._id}/create`,
    });
  };

  return (
    <div id="survey-creation-drafts-screen">
      <h4>Continue from Draft</h4>
      <p className="mb-10">
        Pick up where you left off building another study.
      </p>
      <TableContainer id="drafts-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Date Created</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(drafts).map((s) => (
              <TableRow
                key={`DRAFTS-TABLE-${s._id}`}
                onClick={() => routeToSurveyCreate(s)}
              >
                <TableCell>{s.name}</TableCell>
                <TableCell>{s.createdAt}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
export default withRouter(CreateFromDraft);
