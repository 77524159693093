import React, { Component } from "react";
import clsx from "clsx";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import {
  getPeople,
  setPageTitle,
  createPerson,
  deletePerson,
} from "../redux/actions";
import {
  Grid,
  Button,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { ClipLoader } from "react-spinners";
import CreatePersonPopup from "components/Popups/CreatePersonPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const mapStateToProps = (state) => {
  return {
    people: state.people,
    user: state.user,
    peopleLoading: state.loading.MyPeople,
    createPersonLoading: state.loading.CreatePerson,
  };
};

const mapDispatchToProps = {
  getPeople,
  setPageTitle,
  createPerson,
  deletePerson,
};

class MyPeople extends Component {
  state = {
    createPopupOpen: false,
  };

  componentDidMount() {
    this.props.getPeople();
    this.props.setPageTitle();
  }

  createPersonAndClosePopup = (person) => {
    // Creates a person and uses the success popup to close the popup if this request is successful.
    this.props.createPerson(person, () => {
      this.setState({ createPopupOpen: false });
    });
  };

  noPeople = () => {
    if (this.props.people && Object.keys(this.props.people).length === 0)
      return (
        <TableRow onClick={() => this.setState({ createPopupOpen: true })}>
          <TableCell align="center" colSpan="7">
            <br />
            <img
              src={require("../assets/img/no_panel_requests.svg")}
              alt="No people found."
              style={{ height: 200 }}
            ></img>
            <br />
            <br />
            <span>No people yet.</span>
            <span>
              {" "}
              <b>Click here</b> to get started.
            </span>
          </TableCell>
        </TableRow>
      );
  };

  render() {
    return (
      <div className="content">
        <Helmet>
          <title>People</title>
        </Helmet>
        <CreatePersonPopup
          open={this.state.createPopupOpen}
          onClose={() => this.setState({ createPopupOpen: false })}
          loading={this.props.createPersonLoading}
          onSubmit={this.createPersonAndClosePopup}
        />
        <Grid container className="screen-margin" spacing={2}>
          <Grid item xs={6}>
            <h1 className="page-title">People</h1>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => this.setState({ createPopupOpen: true })}
            >
              + New Person
            </Button>
          </Grid>
        </Grid>
        <Grid container className="screen-margin">
          <Paper style={{ width: "100%" }}>
            {!this.props.peopleLoading || this.props.people ? (
              <Table
                aria-label="people-table"
                className="align-middle mb-0 table table-borderless table-striped table-hover"
              >
                <TableHead>
                  <TableRow>
                    <th />
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>email</th>
                    <th />
                  </TableRow>
                </TableHead>
                {this.props.people && (
                  <TableBody>
                    {Object.values(this.props.people).map((p, i) => (
                      <TableRow
                        key={`PEOPLE-${p._id}`}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        onClick={() => {
                          this.props.history.push(`/people/${p._id}`);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <TableCell>{i + 1}</TableCell>
                        <TableCell component="th">{p.firstName}</TableCell>
                        <TableCell component="th">{p.lastName}</TableCell>
                        <TableCell component="th">{p.email}</TableCell>
                        <TableCell component="th">
                          <FontAwesomeIcon
                            className={clsx("hover-button")}
                            icon={faTrash}
                            onClick={(e) => {
                              e.stopPropagation();
                              this.props.deletePerson(p._id);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                    {this.noPeople()}
                  </TableBody>
                )}
              </Table>
            ) : (
              <div className="flex-container" style={{ margin: 10 }}>
                <ClipLoader size={26} color={"gray"} />
              </div>
            )}
          </Paper>
        </Grid>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyPeople);
