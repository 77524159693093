import React, { useState } from "react";

import ResultsSidebarTile from "components/Results/ResultsSidebarTile";
import { Button, Chip } from "@material-ui/core";

export default function TemplateSummary({
  template,
  createSurveyFromTemplate,
  template: { name, templateCategory, templateDescription, questions },
}) {
  const [page, setPage] = useState(0);
  return (
    <div id="template-summary">
      <div id="template-summary-left">
        {questions.map((q, index) => {
          return (
            <ResultsSidebarTile
              key={`TEMPLATE-QUESTION-${index}`}
              idx={index + 1}
              type={q.type}
              text={q.text}
              subText={q.subText}
              onClick={() => setPage(index)}
              slim
            />
          );
        })}
        <div className="button-container">
          <Button
            color="primary"
            variant="contained"
            onClick={() => createSurveyFromTemplate(template["_id"])}
            fullWidth
          >
            Use this Template
          </Button>
        </div>
      </div>
      <div id="template-summary-right">
        <iframe
          key={page}
          title="Template Preview"
          id="preview"
          src={`https://survey.phonic.ai/${template._id}?preview=true&page=${page}`}
          allow="microphone;camera"
          className=""
        ></iframe>
        <div id="description">
          <h2>{name}</h2>
          <Chip
            size="small"
            className="template-chip"
            label={templateCategory}
          />
          <p>{templateDescription}</p>
        </div>
      </div>
    </div>
  );
}
