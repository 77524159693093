import { NodeExtension, ExtensionTag, isElementDomNode } from "@remirror/core";
import { findNodeAtSelection } from "@remirror/core";
import { TextSelection } from "@remirror/pm/state";
import { nodeType } from "utils/transcription";

/**
 * <span item-type="pronunciation">
 * {
 *   type: "item",
 *   attrs: { type: "pronunciation" },
 *   content: [{ type: "text", text: "" }],
 * }
 */
class ItemExtension extends NodeExtension {
  get name() {
    return "item";
  }

  createTags() {
    return [ExtensionTag.InlineNode];
  }

  createNodeSpec(extra, override) {
    return {
      /**
       * extra.defaults() defines extra attributes, so it must be called when
       * creating a node specification (unless extra attributes are disabled).
       * Attributes with no default MUST be defined.
       * It `type` is not provided, default to "pronunciation"
       */
      content: "text*", // zero or more text nodes
      inline: true,
      inlineContent: true,
      ...override,
      attrs: {
        ...extra.defaults(),
        type: { default: "pronunciation" },
        startTime: {},
        endTime: {},
        transcriptionIdx: {},
        spaceBefore: {},
      },
      /**
       * Function returns DOM node or array that describes one.
       * Optional `0` indicates where to insert content.
       */
      toDOM: (node) => {
        return [
          "span",
          {
            "item-type": node.attrs.type,
            "start-time": node.attrs.startTime,
            "end-time": node.attrs.endTime,
          },
          0,
        ];
      },
      // toDOM and parseDOM should be symmetric as best practice
      parseDOM: [
        {
          tag: "span",
          getAttrs: (dom) => {
            if (!isElementDomNode(dom)) return;
            const type = dom.getAttribute("item-type");
            const startTime = dom.getAttribute("start-time");
            const endTime = dom.getAttribute("end-time");
            return { type, startTime, endTime };
          },
        },
      ],
    };
  }

  createKeymap() {
    const itemBackspace = ({ tr, dispatch }) => {
      // If selection is not empty, let base keymap handle delete
      if (!tr.selection.empty) return false;

      const { $from } = tr.selection;

      // If not in pronunciation node, return false
      if (
        $from.parent.type !== this.type ||
        $from.parent.attrs.type !== nodeType.PRONUNCIATION
      ) {
        return false;
      }

      /**
       * By default, ProseMirror deletes an item node when backspace results
       * in empty content. Because we've restricted paragraph content to
       * `item` and `space` nodes, disallowing text nodes, we could be left
       * unable to add characters when the cursor is not next to an item node
       * after backspace e.g.
       *
       * [space](if cursor is here, can backspace but not add char)[space]
       *
       * This workaround prevents adjacent space nodes in the above situation
       * by deleting the extra space node. However, this "double backspace"
       * isn't the most intuitive text editor behavior.
       *
       * A better solution would be to somehow reconcile PM text nodes with
       * our transcription items model.
       */
      // If about to delete pronunciation item by backspace
      if ($from.parentOffset === 1 && $from.parent.content.size === 1) {
        const pos = $from.pos;
        if ($from.parent.attrs.spaceBefore) {
          tr.delete(pos - 1, pos);
        }

        if (dispatch) dispatch(tr);
        return false;
      }

      // Merge pronunciation items when adjacent
      // If not at start of current pronunciation node, return false
      if ($from.parentOffset !== 0) return false;

      const previousNodePosition = $from.before($from.depth) - 2;

      // If nothing previous to join with, return false
      if (previousNodePosition === 0) return false;

      const previousNodePos = tr.doc.resolve(previousNodePosition);

      // If resolving previous position fails, exit early
      if (!previousNodePos || !previousNodePos.parent) return false;

      const previousNode = previousNodePos.parent;
      const { node, pos } = findNodeAtSelection(tr.selection);

      // If previous node is pronunciation
      if (
        previousNode.type === this.type &&
        previousNode.attrs.type === nodeType.PRONUNCIATION
      ) {
        const { content, nodeSize } = node;
        tr.delete(pos - 1, pos + nodeSize);
        tr.setSelection(TextSelection.create(tr.doc, previousNodePosition));
        // Insert content of previous node
        tr.insert(previousNodePosition, content);
        tr.setSelection(TextSelection.create(tr.doc, previousNodePosition));

        if (dispatch) dispatch(tr);

        return true;
      }
      return false;
    };

    return {
      Backspace: itemBackspace,
    };
  }

  createEventHandlers() {
    return {
      click: (event, clickState) => {
        const nodeWithPosition = clickState.getNode(this.type);

        if (!nodeWithPosition) {
          return;
        }

        return this.options.onClick(event, nodeWithPosition);
      },
    };
  }
}

export default ItemExtension;
