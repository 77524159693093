import React, { Component } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import CreateReportPopup from "components/CreateReportPopup";
import reportTheme from "reportTheme";

class PreBuildReport extends Component {
  render() {
    return (
      <div className="content">
        {/* Report */}
        <ThemeProvider theme={reportTheme}>
          <div className="report-wrapper" />
        </ThemeProvider>
        <CreateReportPopup
          handleClose={() => this.props.history.push("/reports")}
        />
      </div>
    );
  }
}

export default PreBuildReport;
