import React from "react";
import { DialogActions, DialogContent, Button } from "@material-ui/core";

const PANEL_TUTORIAL_URL =
  "https://phonic-public-media.s3.us-west-1.amazonaws.com/recruit_tutorial_cropped.mp4";

export default function PanelTutorial({ proceed }) {
  return (
    <DialogContent>
      <h2 className="md">
        <span role="img" aria-label="handwave">
          👋
        </span>{" "}
        Welcome to Phonic Recruit!
      </h2>
      <div style={{ marginBottom: 20 }}>
        <p className="lg">
          Phonic Recruit lets you source survey respondents from business and
          consumer audiences. Start by creating a campaign or check out our
          tutorial below for a quick walkthrough.
        </p>
      </div>
      <video controls className="tutorial-video">
        <source src={PANEL_TUTORIAL_URL} />
      </video>
      <DialogActions>
        <Button
          variant="contained"
          component="label"
          onClick={proceed}
          color="primary"
        >
          Let's go!
        </Button>
      </DialogActions>
    </DialogContent>
  );
}
