import React, { useMemo } from "react";
import { Remirror, useRemirror } from "@remirror/react";
import EditingComponent from "./EditingComponent";
import SpeakerPicker from "./SpeakerPicker";
import Cursor from "./Cursor";
import { getExtensions } from "./getExtensions";
import debounce from "lodash/debounce";
import "remirror/styles/all.css";

/**
 * @param {List} initialValue: initial value of editor. string | Remirror doc json
 * @param {String} config: name of config for RemirrorProps. "doc" | "items"
 * @param {Function} onUpdate: callback to handle editor update
 * @param {float} time: current time of video
 * @param {Function} onWordClick: callback to handle word click
 * @param {Boolean} readOnly: whether editor is read only or not
 */
const Editor = ({
  initialValue,
  config,
  onUpdate,
  time,
  onWordClick,
  readOnly,
  className,
  speakers,
  updateSpeakers,
}) => {
  const { manager, state, setState } = useRemirror({
    ...(typeof initialValue === "string"
      ? { content: initialValue, stringHandler: "text" }
      : { content: { type: "doc", content: initialValue } }),
    // Do not put extensions in a constant
    extensions: () => getExtensions(config, onWordClick, speakers),
    builtin: { persistentSelectionClass: "selection" },
  });

  const debouncedUpdate = useMemo(
    () =>
      debounce((docState) => {
        if (onUpdate) {
          onUpdate(docState);
        }
      }, 1000),
    [onUpdate]
  );

  const handleChange = ({ tr, state }) => {
    let nextState = state;

    // Check if document content changed
    if (tr && tr.docChanged) {
      debouncedUpdate(nextState.doc);
    }

    if (config === "doc") {
      // Check if selection changed
      if (tr && tr.selectionSet) {
        // nodeAt gives the node *after* the pos, which is what we want
        const marks = state.doc.nodeAt(state.selection.from)?.marks;
        if (marks && marks[0] && marks[0].type.name === "time") {
          /**
           * This should apply marks to text inserted at the leading boundary
           * of a mark when it normally does not.
           */
          nextState = state.applyTransaction(
            state.tr.setStoredMarks(marks)
          ).state;
        }
      }
    }

    /**
     * Update state to latest value. Remirror usually does this automatically,
     * but do it manually here because we're intercepting the change callback
     */
    setState(nextState);
  };

  return (
    <div className="remirror-theme">
      <Remirror
        manager={manager}
        state={state}
        onChange={handleChange}
        editable={!readOnly}
        attributes={{ spellcheck: false }}
      >
        {/* Editor above menu to make z-index of popups easier to manage */}
        <EditingComponent className={className} />
        <Cursor time={time} config={config} />
        {config === "doc" && (
          <SpeakerPicker speakers={speakers} updateSpeakers={updateSpeakers} />
        )}
      </Remirror>
    </div>
  );
};

export default Editor;
