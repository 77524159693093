import TimeChart from "components/Graphs/TimeChart";
import ChartContainer from "components/Graphs/ChartContainer";
import React from "react";
import { getUsageData } from "utils/graphData";
import { Grid } from "@material-ui/core";

function Usage({ usage }) {
  const surveyUsage =
    usage && usage["survey-sessions"]
      ? getUsageData(usage["survey-sessions"].data)
      : undefined;

  const convoUsage =
    usage && usage["files"] ? getUsageData(usage["files"].data) : undefined;

  const showreelsUsage =
    usage && usage["showreels"]
      ? getUsageData(usage["showreels"].data)
      : undefined;

  return (
    <>
      <Grid item lg={4} md={6} sm={12} xs={12}>
        <ChartContainer
          title="Survey Responses"
          loading={!surveyUsage}
          hideMenu
        >
          <TimeChart
            chartData={surveyUsage}
            xLabel="Date"
            yLabel="# Responses"
          />
        </ChartContainer>
      </Grid>
      <Grid item lg={4} md={6} sm={12} xs={12}>
        <ChartContainer
          title="Conversation Uploads"
          loading={!convoUsage}
          hideMenu
        >
          <TimeChart chartData={convoUsage} xLabel="Date" yLabel="# Uploaded" />
        </ChartContainer>
      </Grid>
      <Grid item lg={4} md={6} sm={12} xs={12}>
        <ChartContainer
          title="Showreels Created"
          loading={!showreelsUsage}
          hideMenu
        >
          <TimeChart
            chartData={showreelsUsage}
            xLabel="Date"
            yLabel="# Showreels"
          />
        </ChartContainer>
      </Grid>
    </>
  );
}

export default Usage;
