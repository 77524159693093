import { Button, Paper } from "@material-ui/core";
import React from "react";

export default function HomeScreenTile({ src, title, description, action }) {
  return (
    <Paper className="home-screen-tile">
      <div className="bottom-border">
        <h3 className="pt-10 pb-10 m-0">{title}</h3>
      </div>
      <div className="p-10">
        <img src={src} />
        <p>{description}</p>
      </div>
      <div className="button-tray flex-container">
        <Button variant="contained" color="primary" onClick={action}>
          Get Started
        </Button>
      </div>
    </Paper>
  );
}
