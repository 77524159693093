import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { deleteSurvey } from "redux/actions";
import { connect } from "react-redux";
import { Alert, AlertTitle } from "@material-ui/lab";

const mapDispatchToProps = {
  deleteSurvey,
};

class DeletePopup extends React.Component {
  state = {
    confirmText: "",
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <div
          className="popup-container"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <DialogContent>
            <div id="form-dialog-title" className="mb-20">
              <h2 className="dialog-title-small mb-10">{this.props.title}</h2>
              <p>{this.props.subTitle}</p>
            </div>
            {this.props.warning && (
              <div className="mb-20">
                <Alert severity="warning">
                  <AlertTitle>Warning</AlertTitle>
                  {this.props.warning}
                </Alert>
              </div>
            )}
            {this.props.typeToConfirm && (
              <>
                <p>
                  Please type <b>{this.props.confirmText}</b> to confirm.
                </p>
                <TextField
                  fullWidth
                  value={this.state.confirmText}
                  variant="outlined"
                  margin="dense"
                  placeholder={this.props.confirmTextPlaceholder}
                  onChange={(e) => {
                    this.setState({ confirmText: e.target.value });
                  }}
                />
              </>
            )}
          </DialogContent>
          <DialogContent style={{ marginBottom: 20 }}>
            <Button
              className="gray-outlined-button"
              variant="outlined"
              component="label"
              onClick={(e) => {
                this.props.onClose(e);
              }}
              style={{ marginRight: 10 }}
            >
              Cancel
            </Button>
            <Button
              className="delete-button"
              variant="contained"
              component="label"
              onClick={(e) => {
                e.stopPropagation();
                this.props.deleteFunction();
                this.props.onClose();
              }}
              disabled={
                this.props.typeToConfirm &&
                this.props.confirmText !== this.state.confirmText
              }
            >
              {this.props.buttonText}
            </Button>
          </DialogContent>
        </div>
      </Dialog>
    );
  }
}
export default connect(null, mapDispatchToProps)(DeletePopup);
