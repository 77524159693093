import React from "react";
import { useState } from "react";

import {
  DialogContent,
  Button,
  Dialog,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import BillingDetailsFields from "../Checkout/BillingDetailsFields";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

const PanelCheckoutPopup = ({
  open,
  onClose,
  panelRequest,
  getPaymentIntent,
  onCheckoutCompleted,
}) => {
  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();
  const [isCheckoutCompleted, setCheckoutCompletedTo] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleCardDetailsChange = (ev) => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };

  const handleFormSubmit = async (ev) => {
    ev.preventDefault();

    const billingDetails = {
      name: ev.target.name.value,
      email: ev.target.email.value,
      address: {
        city: ev.target.city.value,
        line1: ev.target.address.value,
        state: ev.target.state.value,
        postal_code: ev.target.zip.value,
      },
    };
    const cardElement = elements.getElement("card");

    getPaymentIntent(panelRequest._id, async (clientSecret) => {
      setProcessingTo(true);
      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: billingDetails,
      });

      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setProcessingTo(false);
        return;
      }

      const { error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethodReq.paymentMethod.id,
      });

      if (error) {
        setCheckoutError(error.message);
        setProcessingTo(false);
        return;
      }

      setProcessingTo(false);
      setCheckoutCompletedTo(true);
    });
  };

  const cardElementOpts = {
    iconStyle: "solid",
    hidePostalCode: true,
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogContent className="checkout">
          {isCheckoutCompleted ? (
            <div className="checkout-inner">
              <h1>Congratulations!</h1>
              <h3>
                Your recruit request has been submitted for review. If approved,
                you'll begin to receive participants immediately.
              </h3>
              <div className="flex-container flex-column">
                <img
                  id="checkout-completed-animation"
                  src={require("../../assets/img/check-circle.gif")}
                  alt={"Checkout Completed"}
                />
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onCheckoutCompleted}
                >
                  Return to Recruit
                </Button>
              </div>
            </div>
          ) : (
            <div className="checkout-inner">
              <h1>Ready to Recruit?</h1>
              <Stepper activeStep={0} alternativeLabel>
                <Step key="PAYMENT">
                  <StepLabel>Payment</StepLabel>
                </Step>
                <Step key="REVIEW">
                  <StepLabel>Review</StepLabel>
                </Step>
                <Step key="COLLECTING">
                  <StepLabel>Field</StepLabel>
                </Step>
              </Stepper>
              <form onSubmit={handleFormSubmit}>
                <div className="checkout-row">
                  <BillingDetailsFields />
                </div>
                <div className="checkout-row">
                  <div className="card-element-container">
                    <CardElement
                      onChange={handleCardDetailsChange}
                      options={cardElementOpts}
                    />
                  </div>
                </div>
                {checkoutError && (
                  <div style={{ color: "red" }}>{checkoutError}</div>
                )}
                <div>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    disabled={isProcessing}
                    type="submit"
                  >
                    {isProcessing
                      ? "Processing..."
                      : `Pay $${panelRequest.pricing}`}
                  </Button>
                </div>
              </form>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PanelCheckoutPopup;
