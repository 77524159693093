import React, { Component } from "react";
import { connect } from "react-redux";
import { Paper, Grid } from "@material-ui/core";
import { trackPage } from "../analytics";
import { Helmet } from "react-helmet";
import Button from "@material-ui/core/Button";
import { setPageTitle } from "redux/actions";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";

const FEEDBACK_SURVEY_URL = "https://survey.phonic.ai/5f62e72601792c3849faa1f3";

const mapDispatchToProps = (dispatch) => {
  return {
    setPageTitle: (title) => dispatch(setPageTitle(title)),
  };
};

class Support extends Component {
  componentDidMount() {
    trackPage("Support");
    this.props.setPageTitle();
  }

  state = {
    expanded: null,
  };

  handleChange = (panel) => {
    if (panel === this.state.expanded) {
      // Collapse all divs.
      this.setState({ expanded: null });
    } else {
      this.setState({ expanded: panel });
    }
  };

  supportQuestions = [
    {
      question: "Can my participants choose between audio and text responses?",
      answer:
        <>Yes they can! To give participants a choice between audio and text or video and text, check-off “Backup Text” in question settings. More information about this can be found <a href="https://docs.phonic.ai/docs/add-backup-text">here</a>.'</>,
    },
    {
      question: "Why are participants experiencing long audio or video upload times?",
      answer:
        <>Participants may be experiencing long upload times due to:<ol><li><b>Geographic location.</b> Phonic’s infrastructure uses AWS. Proximity to an AWS data centre may impact upload speeds. To see where the closest data centre is to you, <a href="https://aws.amazon.com/about-aws/global-infrastructure/?p=ngi&loc=1">click here</a>.</li><li><b>Internet connection.</b> Participants with poor internet connection will experience slower upload speed.</li></ol>If your participants are experiencing slow upload speeds, we recommend <a href="https://docs.phonic.ai/docs/how-do-i-change-the-audio-format">switching your audio format to MP3</a> and/or record in a lower bitrate (optional in survey settings).</>,
    },

    {
      question: "Why can’t I export my data?",
      answer:
        <>Data exports are only available on paid subscriptions. If you’re on our Free Trial, then this is why you’re unable to export your data! <a href="http://app.phonic.ai/plans">Check out our pricing page.</a></>,
    },
    {
      question:
        "How do I share my data with a colleague and/or client?",
      answer:
        <>Data can be shared in a few different ways.<ol><li><a href="Sharing single audio responses through Playlinks.">Sharing single audio responses through Playlinks.</a></li><li><a href="https://docs.phonic.ai/docs/create-a-showreel">Creating a showreel.</a></li><li><a href="https://docs.phonic.ai/doc/how-do-i-create-a-report">Creating a report.</a></li></ol>If you would like to share and collaborate on surveys with your colleagues, reach out to our team at contact@phonic.ai to discuss upgrading to a business subscription.</>,
    },
    {
      question: "Why am I missing data?",
      answer:
        <>Missing data in the Phonic dashboard may occur for a few reasons.<ol><li>You recently deleted a question from a live survey. Deleting questions from a live survey will effectively remove the data from the Phonic dashboard. Reach out to our team at contact@phonic.ai for data recovery.</li><li>You are out of monthly credits. If you are out of monthly credits, then you must purchase additional credits to view all of the responses in your dashboard.</li><li>You have recently cancelled your subscription. Once you cancel your subscription you will lose access to the data that you’ve collected. To gain access, you can upgrade your subscription.</li></ol>If you are not missing data for any of the reasons above, reach out to our support team at contact@phonic.ai.</>,
    },
    {
      question:
        "Why isn’t the Header HTML saving in Qualtrics?",
      answer:
        <>If your header HTML isn’t saving in Qualtrics, this is most likely because your current Qualtrics plan does not support custom HTML in the Header. You must be on Qualtrics’ “Advanced” plan to access this feature.<img
          src={require("../assets/img/qualtrics-tiers.png")}
          alt="Qualtrics tiers with advanced tier selected."
          style={{ width: "100%" }}
        ></img></>,
    },
    {
      question: "How do I link my Qualtrics and Phonic data?",
      answer:
        <>Phonic will automatically store Qualtrics IDs so long as the attribute {"responseid=\"$\{e:\/\/Field\/ResponseID\}\""} is included in your HTML code. For more information about this, <a href="https://docs.phonic.ai/docs/integrate-with-qualtrics#link-ids">click here</a>.</>,
    },
    {
      question: "Why are my participants receiving an “Unable to post response” error?",
      answer: <>Participants might be receiving an “Unable to post response” error for a few different reasons.<ol><li>Poor internet connection resulting in slow upload speeds. If participants have poor internet connection, then the audio upload may time-out and result in this error.</li><li>Incorrect HTML code when using the Phonic widget. The Phonic widget is sensitive to typing errors in your HTML code. This could be an incorrect survey or question ID, a missing end angle bracket (>) or using slanted quotations rather than straight quotations.</li><li>Audio response is too long. If participants are submitting lengthy responses, we recommend <a href="https://docs.phonic.ai/docs/how-do-i-change-the-audio-format">switching the recording format to MP3</a>. If using the Phonic widget, audio responses have a limit of 3 minutes. If you require longer responses, you can use version 2.1.7 (<a href="https://docs.phonic.ai/docs/widget-release-notes">click here</a> for more information).</li></ol></>
    }, {
      question: "My project needs to be compliant with GDPR laws. How do I request a Data Processing Agreement?",
      answer: "Phonic ensures that you are compliant with GDPR laws. To request a Data Processing Agreement, reach out to our team at contact@phonic.ai.",
    }, {
      question: "How do I change my account log-in?",
      answer: <>You can change your account log-in credentials in <a href="/settings">account settings</a>. More information can be found <a href="https://docs.phonic.ai/docs/change-email-address">here</a>.</>
    },
    {
      question: "I accidentally purchased a yearly plan instead of the monthly plan. How do I get a refund?",
      answer: "If you require a refund for this purpose, reach out to our team at contact@phonic.ai. Refund requests for yearly plans will be approved so long as the request is within 48 hours of the original upgrade. "
    },
    {
      question: "Are credits consumed for each audio question in a survey?",
      answer: <>Credits are consumed at the survey-level, not the question-level. When a participant begins a Phonic survey, a single credit will be consumed. You ca,n read more about our credit system, <a href="https://docs.phonic.ai/docs/credits">here</a>.</>
    },
    {
      question: "What happens to my data when I cancel my subscription?",
      answer: <>When you cancel your subscription you will lose access to the data that was previously collected and will not be able to collect any new data or create new surveys. We recommend completing a <a href="https://docs.phonic.ai/docs/sharing-and-exporting">data export</a> prior to cancelling your subscription. That being said, Phonic will never remove your data from the platform, and it will be accessible if you choose to upgrade again.</>
    }
  ];

  render() {
    return (
      <>
        <Helmet>
          <title>Support</title>
        </Helmet>
        <div className="content">
          <div className="screen-margin support-content-wrapper">
            <Paper>
              <div className="support-container">
                <Grid xs={12} style={{ textAlign: "center" }}>
                  <h3 className="support-header">Need some help?</h3>
                </Grid>
                <Grid xs={12}>
                  <p className="support-text-center">
                    If you can't find what you're looking for in our Frequently
                    Asked Questions or Knowledge Base, don't hesitate to reach
                    out!
                  </p>
                </Grid>
                <Grid xs={12}>
                  <p className="support-text-box">
                    email us at: <b>contact@phonic.ai</b>
                  </p>
                </Grid>
              </div>
            </Paper>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Paper style={{ marginTop: 15 }}>
                  <div className="support-container">
                    <h3 className="support-header">
                      Frequently Asked Questions
                    </h3>
                  </div>
                  {this.supportQuestions.map((element, idx) => {
                    return (
                      <Accordion
                        key={`FAQ-${idx}`}
                        expanded={this.state.expanded === idx}
                        onClick={this.handleChange.bind(this, idx)}
                      >
                        <AccordionDetails
                          aria-controls="panel2d-content"
                          id="panel2d-header"
                        >
                          <p className="support-text">
                            {element.question}
                          </p>
                        </AccordionDetails>
                        <AccordionDetails>
                          <div className="documentation-help">
                            <p>{element.answer}</p>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Paper>
                      <div
                        style={{ marginTop: 15 }}
                        className="support-container"
                      >
                        <Grid>
                          <h3 className="support-header">Knowledge Base</h3>
                        </Grid>
                        <Grid>
                          <p className="support-text">
                            Supporting documentation to help you find solutions
                            and learn more about the platform
                          </p>
                        </Grid>
                        <Grid>
                          <div>
                            <Button
                              className="knowledgebase-button secondary-button-color"
                              variant="contained"
                              href="https://docs.phonic.ai/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Let's Go!
                            </Button>
                          </div>
                        </Grid>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper>
                      <div
                        style={{ marginTop: 15 }}
                        className="support-container"
                      >
                        <Grid>
                          <h3 className="support-header">Feedback</h3>
                        </Grid>
                        <Grid>
                          <p className="support-text">
                            Is Phonic missing some features? Let us know what
                            you think of the platform the best way possible.
                          </p>
                        </Grid>
                        <Grid>
                          <div>
                            <Button
                              className="knowledgebase-button secondary-button-color"
                              variant="contained"
                              href={FEEDBACK_SURVEY_URL}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Let's go!
                            </Button>
                          </div>
                        </Grid>
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </>
    );
  }
}

export default connect(undefined, mapDispatchToProps)(Support);
