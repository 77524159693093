import React from "react";
import Chip from "@material-ui/core/Chip";
import DescriptionIcon from "@material-ui/icons/Description";
import { hasImageExtension } from "utils/utils";

const FilePreview = ({ path, uri, idx, options }) => {
  const opts = options || {};

  if (!(path instanceof String || typeof path === "string")) return null;
  if (hasImageExtension(path.split(".")[1]))
    return (
      <div className="flex-container full-width file-preview-container">
        <img
          src={uri}
          style={opts.imageStyle || { maxWidth: "100%", maxHeight: 600 }}
          alt="preview"
        />
      </div>
    );
  return (
    <div className="file-chip-container">
      <Chip
        size="small"
        icon={<DescriptionIcon />}
        label={`Attached File ${idx ? idx + 1 : ""}`}
        color="primary"
        onClick={() => window.open(uri, "_blank")}
      />
    </div>
  );
};

export default FilePreview;
