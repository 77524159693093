import React from "react";
import Popover from "@material-ui/core/Popover";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Checkbox } from "@material-ui/core";
import PickResponseSort from "components/PickResponseSort";

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    languages:
      state.languages && state.languages[ownProps.match.params.questionId],
  };
};

function SimplePopover(props) {
  return (
    <div>
      <Popover
        open={props.open}
        anchorEl={props.anchorEl}
        onClose={props.handleClose}
        disableAutoFocus
        disableEnforceFocus
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{ marginTop: 20, maxHeight: 600 }}
      >
        <div className="filter-popover">
          <h3>Display Options</h3>
          <div className="section">
            <PickResponseSort
              change={props.updateDisplayOptions}
              initialState={props.displayOptions.responseSort}
            />
          </div>
          <div className="section">
            <h3>Filters</h3>
            <div className="checkbox-container">
              <Checkbox
                checked={props.filters.bookmarked}
                onChange={(e) =>
                  props.updateFilters({ bookmarked: e.target.checked })
                }
              />
              <p>Show only Bookmarked</p>
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
}

export default withRouter(connect(mapStateToProps, undefined)(SimplePopover));