import React, { Component } from "react";
import Chart from "chart.js/auto";

export default class RadarChart extends Component {
  chartRef = React.createRef();

  chart = null;
  componentDidMount() {
    const myChartRef = this.chartRef.current.getContext("2d");
    this.chart = new Chart(myChartRef, {
      type: "radar",
      data: {
        labels: this.props.chartData.labels,
        datasets: this.props.chartData.data,
      },
      options: {
        responsive: true,
        elements: {
          line: {
            borderWidth: 3,
          },
        },
        plugins: {
          title: {
            display: true,
            text: "Average Emotion",
          },
        },
      },
    });
  }
  render() {
    return (
      <div>
        <canvas id="radar-chart" className="basic-chart" ref={this.chartRef} />
      </div>
    );
  }
}
