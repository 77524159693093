import React from "react";
import { PositionerPortal, usePositioner } from "@remirror/react";
import {
  Positioner,
  selectionPositioner,
} from "@remirror/extension-positioner";
import { nodeType } from "utils/transcription";

const CURSOR_PADDING = 1;

const getPlaybackNode = (doc, time) => {
  let playbackNode = undefined;
  let previousNode = undefined;

  doc.descendants((node, pos) => {
    // Stop looking through nodes when playback node is found
    if (playbackNode) return false;

    // If node is pronunciation item, check if it is the playback node
    if (node.attrs.type === nodeType.PRONUNCIATION) {
      if (
        time >= parseFloat(node.attrs.startTime) &&
        time < parseFloat(node.attrs.endTime)
      ) {
        playbackNode = { node, pos };
        /**
         * If time is between previous node and current node, set playback node
         * to previous node. Merged items in the editor do not have start and
         * end times stitched together correctly (whereas updated
         * transcription items do), so this gives the illusion of correct
         * cursor placement until refresh. This avoids the expensive
         * calculation of the editor value with each transcription update.
         */
      } else if (
        previousNode &&
        time >= parseFloat(previousNode.node.attrs.endTime) &&
        time < parseFloat(node.attrs.startTime)
      ) {
        playbackNode = previousNode;
      } else {
        previousNode = { node, pos };
      }
    }
  });
  return playbackNode;
};

const getPlaybackNodeMarks = (doc, time) => {
  let playbackNode = undefined;
  let previousNode = undefined;

  doc.descendants((node, pos) => {
    // Stop looking through nodes when playback node is found
    if (playbackNode) return false;

    // If node is pronunciation item, check if it is the playback node
    if (node.isText && node.marks && node.marks.length) {
      if (
        time >= parseFloat(node.marks[0].attrs.startTime) &&
        time < parseFloat(node.marks[0].attrs.endTime)
      ) {
        playbackNode = { node, pos };
        /**
         * If time is between previous node and current node, set playback node
         * to previous node. Merged items in the editor do not have start and
         * end times stitched together correctly (whereas updated
         * transcription items do), so this gives the illusion of correct
         * cursor placement until refresh. This avoids the expensive
         * calculation of the editor value with each transcription update.
         */
      } else if (
        previousNode &&
        time >= parseFloat(previousNode.node.marks[0].attrs.endTime) &&
        time < parseFloat(node.marks[0].attrs.endTime)
      ) {
        playbackNode = previousNode;
      } else {
        previousNode = { node, pos };
      }
    }
  });
  return playbackNode;
};

const timePositioner = (time, config) =>
  selectionPositioner.clone(() => ({
    hasChanged: () => true,
    getActive: (props) => {
      const playbackNode =
        config === "doc"
          ? getPlaybackNodeMarks(props.state.doc, time)
          : getPlaybackNode(props.state.doc, time);
      return playbackNode
        ? [
            {
              from: props.view.coordsAtPos(playbackNode.pos),
              to: props.view.coordsAtPos(
                playbackNode.pos + playbackNode.node.nodeSize
              ),
            },
          ]
        : Positioner.EMPTY;
    },
  }));

const Cursor = ({ time, config }) => {
  const {
    ref,
    width,
    height,
    x: left,
    y: top,
  } = usePositioner(timePositioner(time, config), [time, config]);

  return (
    <PositionerPortal>
      <span
        ref={ref}
        className="floating-cursor"
        style={{
          width: width + CURSOR_PADDING * 2,
          height: height + CURSOR_PADDING * 2,
          top: top - CURSOR_PADDING,
          left: left - CURSOR_PADDING,
        }}
      />
    </PositionerPortal>
  );
};

export default Cursor;
